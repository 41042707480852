import React from 'react';
import { SourceRegisterNormalizedDTO } from '../modules/generated/api';

type CarsTableNormalizedModelCellProps = {
  normalizedModel?: SourceRegisterNormalizedDTO;
};
const CarsTableNormalizedModelCell = ({ normalizedModel }: CarsTableNormalizedModelCellProps) => (
  <div>{normalizedModel?.fullModelName}</div>
);

export default CarsTableNormalizedModelCell;
