import { Checkbox, FormControlLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import MUIDataTable, {
  CustomHeadLabelRenderOptions,
  MUIDataTableColumn,
  MUIDataTableOptions,
  MUISortOptions,
} from 'mui-datatables';
import React, { ChangeEventHandler, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ListCarDTO,
  SourceRegisterPotentialDTO,
  SourceRegisterValuationIncludedCarDTO,
  ValidityType,
  ValuationWithIncludedCarsDTO,
} from '../modules/generated/api';
import { RefCarTableColumTypes } from '../modules/table-data';
import getMUIDataTableDefaultOptions from '../setup/MUIDataTableOptions';
import CarInfoPreviewGrid from './CarInfoPreviewGrid';
import UnitValue from './UnitValue';
import UnitValueCell from './UnitValueCell';

const useStyles = makeStyles(() => ({
  tableCell: {
    padding: '20px',
  },
  left: {
    textAlign: 'left',
    '& div': {
      padding: 0,
      textAlign: 'left',
    },
    '&:first-of-type': {
      paddingLeft: 0,
    },
  },
  kpi: {
    width: '15rem',
  },
  radioBtns: {
    display: 'flex',
    flexDirection: 'row',
  },
  right: {
    textAlign: 'right',
    '& div': {
      padding: 0,
    },
  },
  center: {
    textAlign: 'center',
    '& div': {
      padding: 0,
    },
  },
  onlineTime: {
    width: '120px',
  },
  lastPrice: {
    width: '90px',
  },
  checkbox: {
    padding: 0,
  },
  infoCell: {
    width: '640px',
  },
  filter: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '38rem',
  },
  refCar: {
    width: '800px',
    paddingLeft: '20px',
  },
  row: {
    cursor: 'pointer',
    '&--deselected': {
      cursor: 'pointer',
      backgroundColor: '#f5f5f5',
      '& .MuiTableCell-body': {
        color: 'rgba(0, 0, 0, 0.54)',
      },
      filter: 'grayscale(100%)',
    },
  },
}));

type ReferenceVehicleProps = {
  refCar: ListCarDTO;
  valuation: ValuationWithIncludedCarsDTO;
  potential?: SourceRegisterPotentialDTO;
};

const filterCarsByState = (cars: SourceRegisterValuationIncludedCarDTO[], state: string) => {
  switch (state) {
    case 'online':
      return cars.filter((car) => car.online);
    case 'sold':
      return cars.filter((car) => !car.online);
    default:
      return cars;
  }
};

const ReferenceVehicle = ({
  potential,
  valuation,
  refCar: {
    mileage,
    firstRegistration,
    imageLink,
    model,
    equipmentKeys,
    validity,
    bidStateInfo,
    bidSuccessStatus,
    valuation: refCarValuation,
  },
}: ReferenceVehicleProps) => {
  const classes = useStyles();
  const [deselectedCarsIdx, setDeselectedCarsIdx] = useState(new Set<number>());
  const [detailsExpanded, setDetailsExpanded] = useState<number | false>(false);
  const [onlineState, setOnlineState] = useState<string>('all');
  const refCarsList = filterCarsByState(valuation.includedCars || [], onlineState);
  const [sortOrder, setSortOrder] = useState<MUISortOptions>({
    name: RefCarTableColumTypes.Similarity,
    direction: 'desc',
  });
  const { t } = useTranslation();

  const calcOnlineTime = (firstVisible?: string, lastVisible?: string) => {
    if (!firstVisible) return undefined;
    return DateTime.fromISO(firstVisible)
      .until(lastVisible ? DateTime.fromISO(lastVisible) : DateTime.now().startOf('day'))
      .toDuration('days')
      .toObject();
  };
  const handleFilterChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setOnlineState(event.target.value);
  };

  const toggleDeselectRefCar = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, idx: number) => {
    event.stopPropagation();
    setDeselectedCarsIdx((prevState) => {
      const newDeselect = new Set(prevState);
      if (newDeselect.has(idx)) newDeselect.delete(idx);
      else newDeselect.add(idx);
      return newDeselect;
    });
  };

  const columns: MUIDataTableColumn[] = [
    {
      name: '',
      options: {
        setCellHeaderProps: () => ({
          className: clsx(classes.left),
        }),
        customBodyRenderLite: (dataIndex) => (
          <Checkbox
            checked={!deselectedCarsIdx.has(dataIndex)}
            onClick={(event) => toggleDeselectRefCar(event, dataIndex)}
          />
        ),
        setCellProps: () => ({ className: clsx(classes.left, classes.checkbox) }),
        sort: false,
      },
    },
    {
      name: RefCarTableColumTypes.ModelInformation,
      label: t('referencePopup.refCar'),
      options: {
        customHeadLabelRender: ({ label }: CustomHeadLabelRenderOptions) => (
          <div className={classes.filter}>
            <strong>{label}</strong>
            <RadioGroup
              aria-label="onlineStatus"
              className={classes.radioBtns}
              name="onlineStatus"
              onChange={handleFilterChange}
              value={onlineState}
            >
              <FormControlLabel value="all" control={<Radio />} label={t('common.all').toLowerCase()} />
              <FormControlLabel value="online" control={<Radio />} label={t('referencePopup.online')} />
              <FormControlLabel value="sold" control={<Radio />} label={t('referencePopup.sold')} />
            </RadioGroup>
          </div>
        ),
        setCellHeaderProps: () => ({
          className: clsx(classes.tableCell, classes.left),
        }),
        customBodyRenderLite: (dataIndex) => {
          const car = refCarsList[dataIndex];
          return (
            <CarInfoPreviewGrid
              dataIndex={dataIndex}
              equipment={car.equipment}
              equipmentHighlights={car.equipmentHighlight}
              equipmentKeys={equipmentKeys}
              expanded={detailsExpanded}
              firstRegistration={car.firstRegistration}
              fuel={car.fuel}
              gearing={car.gearing}
              image={car.image}
              mileage={car.mileage}
              model={car.model}
              sold={!car.online}
              detailsExpanded={setDetailsExpanded}
              title={car.title}
              deepLinks={car.urls}
            />
          );
        },
        setCellProps: () => ({ className: classes.infoCell }),
        sort: false,
      },
    },
    {
      name: RefCarTableColumTypes.PriceLast,
      label: t('referencePopup.lastPrice'),
      options: {
        setCellHeaderProps: () => ({
          className: clsx(classes.tableCell, classes.right),
        }),
        customBodyRenderLite: (dataIndex) => {
          const price = refCarsList[dataIndex].priceLast;
          return price && <UnitValueCell value={price.amount} unit={price.currency} />;
        },
        setCellProps: () => ({ className: clsx(classes.tableCell, classes.right, classes.lastPrice) }),
        sortCompare: (order) => (priceLast1, priceLast2) => {
          const val1 = priceLast1.data?.amount;
          const val2 = priceLast2.data?.amount;
          if (val1 === undefined || val2 === undefined) {
            return order === 'asc' ? -1 : 1;
          }
          return (val1 - val2) * (order === 'asc' ? 1 : -1);
        },
      },
    },
    {
      name: RefCarTableColumTypes.DateOnlineTimeInMinutes,
      label: t('car.onlineTime'),
      options: {
        setCellHeaderProps: () => ({
          className: clsx(classes.tableCell, classes.left, classes.onlineTime),
        }),
        customBodyRenderLite: (dataIndex) => {
          const { firstVisibleAt, lastVisibleAt } = refCarsList[dataIndex];
          const onlineTime = calcOnlineTime(firstVisibleAt, lastVisibleAt);
          return (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {onlineTime && (
                <>
                  <UnitValueCell unit={t('referencePopup.day', { count: onlineTime.days })} value={onlineTime.days} />
                  {lastVisibleAt && (
                    <>
                      {t('referencePopup.till')}: {DateTime.fromISO(lastVisibleAt).toFormat('dd.LL.yy')}
                    </>
                  )}
                </>
              )}
            </>
          );
        },
        setCellProps: () => ({ className: clsx(classes.tableCell, classes.left) }),
      },
    },
    {
      name: RefCarTableColumTypes.Location,
      label: t('referencePopup.location'),
      options: {
        setCellHeaderProps: () => ({
          className: clsx(classes.tableCell, classes.center),
        }),
        customBodyRenderLite: (dataIndex) => {
          const car = refCarsList[dataIndex];
          return (
            <div>
              {car.country} {car.zipCode}
            </div>
          );
        },
        setCellProps: () => ({ className: clsx(classes.tableCell, classes.center) }),
      },
    },
    {
      name: RefCarTableColumTypes.Similarity,
      label: t('referencePopup.similarity'),
      options: {
        setCellHeaderProps: () => ({
          className: clsx(classes.tableCell, classes.right),
        }),
        customBodyRenderLite: (dataIndex) => {
          const car = refCarsList[dataIndex];
          return car.score ? (
            <UnitValue
              className={clsx(car.score >= 0.8 && 'green', car.score <= 0.4 && 'red')}
              unit="%"
              value={car.score}
            />
          ) : (
            '-'
          );
        },
        setCellProps: () => ({ className: clsx(classes.tableCell, classes.right) }),
      },
    },
  ];

  const options: MUIDataTableOptions = {
    ...getMUIDataTableDefaultOptions(),
    download: false,
    draggableColumns: {
      enabled: false,
    },
    rowHover: false,
    pagination: false,
    viewColumns: false,
    tableBodyMaxHeight: 'calc(100vh - 300px)',
    sortOrder,
    onRowClick: (_, { dataIndex }) => {
      setDetailsExpanded(detailsExpanded === dataIndex ? false : dataIndex); // toggle
    },
    setRowProps: (row, dataIndex) => {
      if (deselectedCarsIdx.has(dataIndex)) {
        return { className: `${classes.row}--deselected` };
      }
      return { className: `${classes.row}` };
    },
    onColumnSortChange: (changedColumn, direction) => {
      setSortOrder({
        name: changedColumn,
        direction,
      });
    },
    customSort: (data, colIndex, order) => {
      const colName = columns[colIndex].name;
      return data.sort((a, b) => {
        const aData = refCarsList[a.index];
        const bData = refCarsList[b.index];

        if (colName === RefCarTableColumTypes.DateOnlineTimeInMinutes) {
          const onlineTime1 = calcOnlineTime(aData.firstVisibleAt, aData.lastVisibleAt);
          const onlineTime2 = calcOnlineTime(bData.firstVisibleAt, bData.lastVisibleAt);
          const oneUndefined = onlineTime1 === undefined || onlineTime2 === undefined;

          if (oneUndefined) return -1;
          return ((onlineTime1?.days as number) < (onlineTime2?.days as number) ? -1 : 1) * (order === 'desc' ? 1 : -1);
        }
        return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order === 'desc' ? -1 : 1);
      });
    },
  };

  return (
    <>
      <CarInfoPreviewGrid
        equipmentKeys={equipmentKeys}
        firstRegistration={firstRegistration}
        image={imageLink}
        mileage={mileage}
        model={model}
        purchaseGross={potential?.purchaseGross}
        origin
        sold={validity === ValidityType.Expired}
        refCarsList={refCarsList}
        deselectedCarsIdx={deselectedCarsIdx}
        bidStateInfo={bidStateInfo}
        bidSuccessStatus={bidSuccessStatus}
        valuationDate={refCarValuation?.valuationDate}
      />

      <MUIDataTable columns={columns} data={refCarsList} title="" options={options} />
    </>
  );
};

export default memo(ReferenceVehicle);
