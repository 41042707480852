import { Badge, IconButton } from '@material-ui/core';
import HotIcon from '@material-ui/icons/Whatshot';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BidFilterStatus } from '../modules/generated/api';
import { transformForSubmit } from '../modules/cars-filter';
import ApiService from '../modules/api-service';
import ActionTooltip from './ActionTooltip';
import { useCarsFilter } from '../hooks/useCarsFilter';

/**
 * CarsFilter "Hot"-preset button. Shows re-auctioned cars with more than one bid received in the past.
 */
export const HotButton = (): ReactElement => {
  const [count, setCount] = useState(0);
  const { form, makePreset } = useCarsFilter();
  const { t } = useTranslation();

  const hotPreset = useMemo(() => makePreset({ expiredBidState: BidFilterStatus.Bidden }), [makePreset]);

  const handleClick = () => {
    form.reset(hotPreset);
  };

  useEffect(() => {
    ApiService.listCars
      .listCarsControllerList(transformForSubmit(hotPreset), 2)
      .then((res) => setCount(res.data.recordsTotal ?? 0));
  }, [hotPreset]);

  return (
    <ActionTooltip title={t('filterSearch.hotButtonTooltip')} position="top">
      <IconButton aria-label="hot-cars" color={count > 0 ? 'primary' : 'default'} size="small" onClick={handleClick}>
        <Badge badgeContent={count} color="primary">
          <HotIcon
            style={{
              transform: 'scale(-1, 1)', // flip horizontally
            }}
          />
        </Badge>
      </IconButton>
    </ActionTooltip>
  );
};
