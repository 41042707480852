import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

export type PageHeaderProps = {
  headline: React.ReactNode;
  body?: React.ReactNode;
  className?: string;
};

const useStyles = makeStyles((theme) => ({
  body: {
    maxWidth: theme.breakpoints.values.md,
  },
}));

export const PageHeader = ({ headline, body, className }: PageHeaderProps) => {
  const classes = useStyles();

  return (
    <div className={className}>
      <Typography variant="h1" gutterBottom>
        {headline}
      </Typography>
      {body ? <Typography className={classes.body}>{body}</Typography> : null}
    </div>
  );
};
