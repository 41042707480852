import {
  FormControl,
  makeStyles,
  Button,
  Typography,
  DrawerProps as MuiDrawerProps,
  Grid,
  IconButton,
} from '@material-ui/core';
import { Drawer, DrawerHeader, DrawerContent, DrawerFooter, Select, Stack } from '@components/ui';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PacketDTO } from 'src/modules/generated/api';
import ApiService from 'src/modules/api-service';
import useApi from 'src/hooks/useApi';
import clsx from 'clsx';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Merge } from 'type-fest';
import useCustomSnackbarGlobal from 'src/hooks/useSnackbarGlobal';
import { Edit } from '@material-ui/icons';
import { navigate } from '@reach/router';
import { getLabel, sources } from 'src/modules/labels';
import { DealerSourcesPermission } from '@components/DealerSourcesPermission';
import { CountryCode, DetailedDealerDTO } from '../../modules/generated/api/api';
import { RoutePaths } from '../../constants';
import { DealerNameCol } from './DealerNameCol';
import DeliveryAddressSelector from './DeliveryAddressSelector';
import { setToArray } from '../../modules/util';
import { InputLabel } from '../../components/ui/InputLabel';

const useStyles = makeStyles({
  root: {
    '& .MuiDrawer-paper': {
      width: '460px',
    },
  },
  formControl: {
    width: '250px',
  },
  footerBtn: {
    padding: 10,
    justfiyContent: 'flex-start',
  },
});

export type EditDealerDrawerProps = {
  open: boolean;
  onClose: () => void;
  dealerId: string;
  detailedDealer?: DetailedDealerDTO;
  refetch: () => void;
};

export const EditDealerDrawer = ({
  className,
  open,
  onClose,
  dealerId,
  detailedDealer,
  refetch,
  ...drawerProps
}: Merge<MuiDrawerProps, EditDealerDrawerProps>) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { showSuccess, showError } = useCustomSnackbarGlobal();
  const { data: configurations = [], fetch } = useApi<PacketDTO[]>();

  useEffect(() => {
    fetch(ApiService.packet.packetControllerGetPackets());
  }, [fetch]);

  const assignPacket = async (dealer: string, packet: string) => {
    const response = await ApiService.dealer.dealerControllerAssignPacket(dealer, packet);
    if (response.status !== 200) {
      showError(t('alerts.errorRaised'));
      return;
    }
    showSuccess(t('alerts.successSaved'));
    refetch();
  };

  const unAssignPacket = async (dealer: string, packet: string) => {
    const response = await ApiService.dealer.dealerControllerUnassignPacket(dealer, packet);
    if (response.status !== 200) {
      showError(t('alerts.errorRaised'));
      return;
    }
    showSuccess(t('alerts.successSaved'));
    refetch();
  };

  const toggleActivate = async (id: string) => {
    const response = detailedDealer?.isAllowedToLogin
      ? await ApiService.dealer.dealerControllerDisallowDealerToLogin(id)
      : await ApiService.dealer.dealerControllerAllowDealerToLogin(id);
    if (response.status !== 200) {
      showError(t('alerts.errorRaised'));
      return;
    }
    showSuccess(t('alerts.successAccess'));
    refetch();
  };

  const allowedSource = detailedDealer?.allowedSource || [];

  const getPacketOptions = (src: string, country: CountryCode) => {
    const packetOptions = configurations
      .filter((item) => item.source === src && item.country === country)
      .map((value) => ({
        label: value.name,
        value: value.packetId,
      }));
    return packetOptions;
  };

  const handleAdd = (packetId: string) => {
    assignPacket(dealerId, packetId);
  };

  const addresses = setToArray(detailedDealer?.deliveryAddresses);

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={onClose} className={clsx(classes.root, className)} {...drawerProps}>
        <DrawerHeader>
          <Typography>
            {detailedDealer?.dealer?.id} - {detailedDealer?.name}
          </Typography>
        </DrawerHeader>
        <DrawerContent>
          <Stack spacing={2}>
            <Stack spacing={2} direction="row">
              <FormControl size="small" fullWidth variant="outlined">
                <InputLabel>{t('common.name')}</InputLabel>
                <DealerNameCol refetch={refetch} dealer={detailedDealer as DetailedDealerDTO} edit={open} />
              </FormControl>
              {detailedDealer?.isAllowedToLogin ? (
                <Button onClick={() => toggleActivate(dealerId)} size="small" variant="contained" color="secondary">
                  {t('configuration.withdrawAccess')}
                </Button>
              ) : (
                <Button onClick={() => toggleActivate(dealerId)} size="small" variant="contained" color="primary">
                  {t('configuration.grantAccess')}
                </Button>
              )}
            </Stack>

            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel>{t('contact.address')}</InputLabel>
              <DeliveryAddressSelector
                dealer={detailedDealer as DetailedDealerDTO}
                dealerID={detailedDealer?.dealer?.id}
                deliveryAddresses={addresses}
              />
            </FormControl>
            <div>
              <InputLabel>{t('common.source_plural')}</InputLabel>
              <DealerSourcesPermission
                dealerId={dealerId}
                edit={open}
                refetch={refetch}
                dealer={detailedDealer as DetailedDealerDTO}
              />
            </div>
            <InputLabel>{t('common.linkPackages')}</InputLabel>
            <Stack spacing={1}>
              {allowedSource.map((src) => (
                <Grid key={src} container justifyContent="space-between" alignItems="center" wrap="nowrap">
                  <Grid item>
                    <Typography style={{ overflowWrap: 'break-word' }}>{getLabel(sources, src)}</Typography>
                  </Grid>
                  <Grid item>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <FormControl className={classes.formControl}>
                        <Select
                          clearable
                          value={detailedDealer?.assignedPackets?.[src]?.packetId ?? ''}
                          onClear={() =>
                            unAssignPacket(dealerId, detailedDealer?.assignedPackets?.[src]?.packetId as string)
                          }
                          onChange={(packetId) => handleAdd(packetId)}
                          options={getPacketOptions(src, detailedDealer?.dealer?.country as CountryCode)}
                          name="Select"
                        />
                      </FormControl>

                      <IconButton
                        style={{ visibility: detailedDealer?.assignedPackets?.[src] ? 'visible' : 'hidden' }}
                        size="small"
                        onClick={() =>
                          navigate(`${RoutePaths.packets}/${detailedDealer?.assignedPackets?.[src].packetId}`)
                        }
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              ))}
            </Stack>
          </Stack>
        </DrawerContent>

        <DrawerFooter>
          <Grid container justifyContent="space-between">
            <Button size="small" className={classes.footerBtn} color="secondary" variant="outlined" onClick={onClose}>
              {t('common.back')}
            </Button>
          </Grid>
        </DrawerFooter>
      </Drawer>
    </div>
  );
};
