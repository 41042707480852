import { useTranslation } from 'react-i18next';
import { makeStyles, Tooltip } from '@material-ui/core';
import { EditLocation } from '@material-ui/icons';
import React, { memo } from 'react';
import { ValuationCountryCode } from '../modules/generated/api';
import { getIcon, IconType } from '../modules/data';
import { getBaseCountry } from '../modules/valuation-country-helpers';
import { countryCodeToRegion } from '../modules/labels';

type CarsTableValuationCountryIndicatorProps = {
  valuationCountry: ValuationCountryCode;
};

const useStyles = makeStyles(() => ({
  iconBox: {
    position: 'absolute',
    left: 5,
    top: 18,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  locationIcon: {
    fontSize: '1rem',
  },
}));

const CarsTableValuationCountryIndicator = ({ valuationCountry }: CarsTableValuationCountryIndicatorProps) => {
  const classes = useStyles();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <Tooltip
      title={`${t('carsTable.chosenValuationCountry')}: ${countryCodeToRegion(valuationCountry, language).label}`}
    >
      <div className={classes.iconBox}>
        <div className={classes.column}>
          <EditLocation className={classes.locationIcon} />
          <img src={getIcon(IconType.COUNTRY, getBaseCountry(valuationCountry))} alt={valuationCountry} width={15} />
        </div>
      </div>
    </Tooltip>
  );
};

export default memo(CarsTableValuationCountryIndicator);
