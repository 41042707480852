import React, { memo } from 'react';
import { Checkbox, makeStyles, FormControlLabel } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import clsx from 'clsx';
import { Controller } from 'react-hook-form';

const useStyles = makeStyles({
  root: {
    '&--error': {
      color: red[900],
    },
  },
  checkbox: {
    color: 'inherit',
  },
});

type BooleanCheckboxProps = {
  label: string;
  name: string;
  disabled?: boolean;
  error?: boolean;
  required?: boolean;
};

const BooleanCheckbox = ({ label, name, disabled = false, error, required }: BooleanCheckboxProps) => {
  const classes = useStyles();

  return (
    <Controller
      name={name}
      rules={{ required }}
      render={({ field: { value, onChange } }) => (
        <FormControlLabel
          key={name}
          className={clsx(classes.root, error && `${classes.root}--error`)}
          control={
            <Checkbox
              className={classes.checkbox}
              checked={!!value}
              onChange={(event) => onChange(event?.target.checked)}
              disabled={disabled}
            />
          }
          label={label}
        />
      )}
    />
  );
};

export default memo(BooleanCheckbox);
