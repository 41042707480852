import { Rating } from '@material-ui/lab';
import React, { ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core';

import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import useApi from '../hooks/useApi';
import ApiService from '../modules/api-service';
import { DetailCarDTO, ListCarDTO, ValuationCountryCode } from '../modules/generated/api';
import { useCarsTableStore } from '../stores/CarsTable';
import useCustomSnackbarGlobal from '../hooks/useSnackbarGlobal';
import { getCarDetailsTM } from '../modules/tag-manager-helpers';
import { DEFAULT_VALUATION_COUNTRY } from '../modules/data';
import UserRole from '../types/UserRoles';
import useRole from '../hooks/useRole';
import { useValuationSettings } from '../hooks/useValuationSettings';

const useStyles = makeStyles(() => ({
  rating: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type UserRatingProps = {
  car: ListCarDTO;
  namePrefix: string;
  refresh?: () => Promise<void>;
};

const UserRating = ({ car: { rating, ...car }, namePrefix, refresh }: UserRatingProps) => {
  const carsTableCtx = useCarsTableStore(true);
  const { fetch } = useApi<DetailCarDTO>();

  const { carId, source } = car;
  const classes = useStyles();
  const { t } = useTranslation();
  const snackbar = useCustomSnackbarGlobal();
  const { valuationCountry } = useValuationSettings();
  const { hasRole } = useRole();

  const handleOnChange = async (_: ChangeEvent<{}>, value: number | null) => {
    if (!carId || !source) return;
    const updatedRating = value ?? 0;
    const requestValuationCountry: ValuationCountryCode = hasRole(UserRole.ADMIN)
      ? valuationCountry || DEFAULT_VALUATION_COUNTRY
      : DEFAULT_VALUATION_COUNTRY;
    const result = await fetch(
      ApiService.listCars.listCarsControllerUpdateCar(
        source,
        carId,
        { rating: updatedRating },
        2,
        requestValuationCountry,
      ),
    );
    if (!result?.data || result.status !== 200) {
      snackbar.showError(t('alerts.errorRaised'));
      return;
    }
    const { data: updatedCar } = result;
    if (carsTableCtx) {
      carsTableCtx.updateCar(updatedCar);
    }
    if (refresh) await refresh();
    snackbar.showSuccess(t('alerts.successSaved'));
    TagManager.dataLayer({
      dataLayer: {
        event: 'car-rating',
        ...getCarDetailsTM(car).dataLayer,
      },
    });
  };

  return (
    <Rating
      className={classes.rating}
      disabled={!carId || !source}
      name={`${namePrefix}${carId}UserRating`}
      onChange={handleOnChange}
      onClick={(event) => event.stopPropagation()}
      value={rating ?? 0}
    />
  );
};

export default UserRating;
