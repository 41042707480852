import { styled } from '@material-ui/core';

/**
 * ContentCell can be used to ensure horizontal rhythm for content.
 */
export const ContentCell = styled('div')(
  {
    paddingInline: 12,
  },
  {
    name: 'ContentCell',
  },
);
