import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useCurrency } from 'src/hooks/useCurrency';
import { DEFAULT_CURRENCY } from 'src/modules/currency';
import { getPositionsTotal } from './lib';
import { Position } from './types';

type ServicePositionTotalProps = {
  positions: Position[];
  className?: string;
};

const useStyles = makeStyles({
  total: {
    textAlign: 'right',
    flexBasis: '12ch',
  },
});

export const ServicePositionTotal = ({ positions }: ServicePositionTotalProps) => {
  const classes = useStyles();
  const { format } = useCurrency();
  const total = getPositionsTotal(positions);

  return (
    <Typography variant="body2" className={classes.total}>
      {format(total, { to: DEFAULT_CURRENCY.code, maximumFractionDigits: 2 })}
    </Typography>
  );
};
