import { IconButton, Paper, Table, TableBody, TableContainer, TableRow, Tooltip, makeStyles } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import clsx from 'clsx';
import { capitalize, isEmpty } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useRole from 'src/hooks/useRole';
import UserRole from 'src/types/UserRoles';
import { BidSuccessStatus, IndividualBidStateInfoDTO, SourceType } from '../modules/generated/api';
import BidTableCells from './BidTableCells';
import CarDetailHeadline from './CarDetailHeadline';
import CarDetailTableHeadCell from './CarDetailTableHeadCell';
import ConfirmDeclineBidsDialog from './ConfirmDeclineBidsDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  disableElevation: {
    borderRadius: 0,
  },
  headingBox: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h2': {
      paddingRight: '5px',
    },
  },
  layoutFixed: {
    tableLayout: 'fixed',
  },
  declineButton: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(2),
  },
}));

type BidInfoTableProps = {
  title: string;
  layoutFixed?: boolean;
  disableElevation?: boolean;
  className?: string;
  bids: IndividualBidStateInfoDTO;
  source?: SourceType;
  refresh: () => void;
  dateEnd?: string;
  bidId?: string;
  carId?: string;
};

const BidInfoTable = ({
  title,
  layoutFixed,
  disableElevation = false,
  className,
  bids,
  source,
  refresh,
  dateEnd,
  bidId,
  carId,
}: BidInfoTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [declineDialogOpen, setDeclineDialogOpen] = useState(false);

  const { hasRole } = useRole();
  const isAdmin = hasRole(UserRole.ADMIN);
  const auctionHasEnded = dateEnd && new Date(dateEnd) < new Date();
  const hasAllocateBid =
    bids.ownCurrentBids?.some((bid) => bid.status === BidSuccessStatus.Allocate) ||
    bids.otherCurrentBids?.some((bid) => bid.status === BidSuccessStatus.Allocate) ||
    bids.allHistoricBids?.some((bid) => bid.status === BidSuccessStatus.Allocate);

  return (
    <TableContainer
      component={Paper}
      elevation={disableElevation ? 0 : 3}
      className={clsx(classes.root, disableElevation && classes.disableElevation, className)}
    >
      <div className={classes.headingBox}>
        <CarDetailHeadline title={title} />
        {hasAllocateBid && auctionHasEnded && isAdmin && source === SourceType.SpotdealsAllmobil && (
          <Tooltip title={t('bids.declineBid')} arrow placement="left">
            <IconButton size="small" className={classes.declineButton} onClick={() => setDeclineDialogOpen(true)}>
              <BlockIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      {declineDialogOpen && (
        <ConfirmDeclineBidsDialog
          open={declineDialogOpen}
          handleClose={setDeclineDialogOpen}
          refresh={refresh}
          source={source}
          carId={carId}
        />
      )}
      <Table size="small" className={layoutFixed ? classes.layoutFixed : ''}>
        <TableBody>
          <TableRow>
            <CarDetailTableHeadCell />
            <CarDetailTableHeadCell>{t('common.user')}</CarDetailTableHeadCell>
            <CarDetailTableHeadCell>{t('common.bid')}</CarDetailTableHeadCell>
            <CarDetailTableHeadCell>{t('bids.status.sent')}</CarDetailTableHeadCell>
            <CarDetailTableHeadCell>{t('common.status')}</CarDetailTableHeadCell>
          </TableRow>
          {!isEmpty(bids.ownCurrentBids) &&
            bids.ownCurrentBids?.map((bid, idx) => (
              <TableRow key={`(${bid.created}-${bid.bid?.value}-${bid.dealer?.id}) `}>
                <CarDetailTableHeadCell>{idx === 0 ? t('bids.bidHammers.ownCurr') : ''}</CarDetailTableHeadCell>
                <BidTableCells bid={bid} source={source} refresh={refresh} dateEnd={dateEnd} bidId={bidId} />
              </TableRow>
            ))}

          {!isEmpty(bids.otherCurrentBids) &&
            bids.otherCurrentBids?.map((bid, idx) => (
              <TableRow key={`(${bid.created}-${bid.bid?.value}-${bid.dealer?.id}) `}>
                <CarDetailTableHeadCell>{idx === 0 ? t('bids.bidHammers.otherCurr') : ''}</CarDetailTableHeadCell>
                <BidTableCells
                  bid={bid}
                  key={bid.biddingTime}
                  source={source}
                  refresh={refresh}
                  dateEnd={dateEnd}
                  bidId={bidId}
                />
              </TableRow>
            ))}
          {!isEmpty(bids.allHistoricBids) &&
            bids.allHistoricBids?.map((bid, idx) => (
              <TableRow key={`(${bid.created}-${bid.bid?.value}-${bid.dealer?.id}) `}>
                <CarDetailTableHeadCell>{idx === 0 ? capitalize(t('bids.expired')) : ''}</CarDetailTableHeadCell>
                <BidTableCells
                  bid={bid}
                  key={bid.biddingTime}
                  source={source}
                  refresh={refresh}
                  dateEnd={dateEnd}
                  bidId={bidId}
                />
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BidInfoTable;
