import { client } from '@iris/content';
import { useQuery } from '@tanstack/react-query';
import groq from 'groq';
import { useTranslation } from 'react-i18next';
import { QueryKeys } from 'src/constants';

type UseCategoryProps = {
  slug: string;
};

const categoryBySlug = groq`*[
  _type == 'category' &&
  __i18n_lang == $lang && 
  slug.current == $slug
][0] {
  title,
  description
}`;

export const fetchCategoryBySlug = (variables: { slug: string; lang: string }) =>
  client.fetch(categoryBySlug, variables);

export const useCategory = ({ slug }: UseCategoryProps) => {
  const { i18n } = useTranslation();
  const variables = {
    slug,
    lang: i18n.language,
  };

  return useQuery([QueryKeys.category, variables], () => fetchCategoryBySlug(variables), {
    staleTime: Infinity,
    suspense: true,
    useErrorBoundary: true,
  });
};
