import { useTranslation } from 'react-i18next';
import { useTitle } from 'src/hooks/useTitle';

export type PageMetaProps = {
  title: string;
};

export const PageMeta = ({ title }: PageMetaProps): null => {
  const { t } = useTranslation();

  useTitle(t(title as any));

  return null;
};
