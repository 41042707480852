import React, { memo, ReactElement, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useTranslation } from 'react-i18next';
import { find, isEmpty } from 'lodash';
import { searchAgentSaveModes } from 'src/modules/labels';
import DialogWithClose from '@components/DialogWithClose';
import { SearchAgentDTO } from 'src/modules/generated/api';
import { tr } from 'src/modules/i18n-helpers';
import { obsoleteSearchAgentFields } from 'src/modules/data';
import { useValuationSettings } from 'src/hooks/useValuationSettings';
import { CarsTableNextFilterData } from '@components/cars-table-next/filter/types';
import { transformForSubmit } from '../filter/lib';

// Ported from `src/components/SearchAgentSaveDialog.tsx`

type SearchAgentSaveDialogProps = {
  open: boolean;
  searchAgents: SearchAgentDTO[];
  onClose: () => void;
  onCreate: (searchAgent: SearchAgentDTO) => Promise<void>;
  onUpdate: (id: string, searchAgent: SearchAgentDTO) => Promise<void>;
  refetch: () => void;
  filterControl: any;
};

type SearchAgentFormData = {
  searchAgentDisplayName: string;
  searchAgentEdit: SearchAgentDTO;
  searchAgentSaveMode: string;
  searchAgentFavorite: boolean;
  searchAgentActive: boolean;
};

const useStyles = makeStyles(() => ({
  formGroup: {
    flexDirection: 'row',
  },
}));

const SearchAgentSaveDialog = ({
  open,
  searchAgents,
  onClose,
  onCreate,
  onUpdate,
  refetch,
  filterControl,
}: SearchAgentSaveDialogProps) => {
  const classes = useStyles();
  const filterValues = useWatch<CarsTableNextFilterData>({ control: filterControl });
  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors },
    reset,
  } = useForm<SearchAgentFormData>();
  const { valuationCountry, valuationType } = useValuationSettings();
  const saveMode = watch('searchAgentSaveMode');
  const { t } = useTranslation();
  const [oldSearchAgent, setOldSearchAgent] = useState<SearchAgentDTO | undefined>(undefined);
  const watchDisplayName = watch('searchAgentDisplayName');

  const getLabelName = (label: string) => {
    switch (label) {
      case 'brands':
        return t('car.manufacturer');
      case 'modelGroups':
        return t('car.modelGroup');
      case 'engineFuel':
        return t('car.fuel');
      default:
        return label;
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    const common = {
      ...transformForSubmit(filterValues as any),
      searchAgentDisplayName: data.searchAgentDisplayName,
      valuationCountry: valuationCountry || undefined,
      valuationType: valuationType || undefined,
      favorite: data.searchAgentFavorite,
      active: data.searchAgentActive,
    };

    if (saveMode === 'NEW') {
      await onCreate(common);
    } else if (saveMode === 'EDIT') {
      await onUpdate(data.searchAgentEdit.id as string, {
        ...data.searchAgentEdit,
        ...common,
      });
    }

    reset({ searchAgentSaveMode: 'NEW' });
    refetch();
    onClose();
  });

  useEffect(() => {
    if (open === false) return undefined;
    searchAgents.map((searchAgent) => {
      if (searchAgent.searchAgentDisplayName === watchDisplayName) {
        Object.keys(searchAgent).some((entry) =>
          obsoleteSearchAgentFields.includes(entry) && !isEmpty(searchAgent[entry as keyof SearchAgentDTO])
            ? setOldSearchAgent(searchAgent)
            : '',
        );
      }
      return undefined;
    });
    return undefined;
  }, [open, searchAgents, watchDisplayName]);

  useEffect(() => {
    const prevValues = getValues();

    if (saveMode === 'EDIT') {
      const selectedSearchAgent = find(searchAgents, { searchAgentDisplayName: watchDisplayName });

      if (!selectedSearchAgent) {
        return;
      }

      const { active, favorite } = selectedSearchAgent;

      reset({
        ...prevValues,
        searchAgentActive: active,
        searchAgentFavorite: favorite,
      });
    } else if (saveMode === 'NEW') {
      reset({
        ...prevValues,
        searchAgentActive: true,
        searchAgentFavorite: true,
      });
    }
  }, [watchDisplayName, saveMode, searchAgents, getValues, reset]);

  return (
    <>
      <DialogWithClose
        title={t('filterSearch.saveAsSearchAgent')}
        maxWidth="sm"
        open={open}
        onClose={onClose}
        actionsBottom={
          <>
            <Button onClick={onClose} color="primary">
              {t('common.cancel')}
            </Button>
            <Button type="submit" variant="contained" form="edit-searchagent" color="primary">
              {t('common.save')}
            </Button>
          </>
        }
      >
        <form id="edit-searchagent" onSubmit={onSubmit}>
          <FormControl component="fieldset" margin="dense" fullWidth>
            <Controller
              control={control}
              defaultValue="NEW"
              name="searchAgentSaveMode"
              render={({ field: { name, value, onChange } }) => (
                <RadioGroup
                  className={classes.formGroup}
                  name={name}
                  value={value}
                  onChange={(event, changeValue) => {
                    onChange(event, changeValue);
                    if (changeValue === 'EDIT') {
                      setValue(
                        'searchAgentDisplayName',
                        getValues('searchAgentEdit')?.searchAgentDisplayName ||
                          searchAgents[0].searchAgentDisplayName ||
                          '',
                      );
                    }
                  }}
                >
                  {searchAgentSaveModes.map(({ value: itemValue, label }) => (
                    <FormControlLabel
                      key={label}
                      value={itemValue}
                      control={<Radio />}
                      label={tr(label)}
                      disabled={itemValue === 'EDIT' && searchAgents.length === 0}
                    />
                  ))}
                </RadioGroup>
              )}
            />
          </FormControl>
          {saveMode === 'EDIT' && (
            <Controller
              name="searchAgentEdit"
              control={control}
              defaultValue={searchAgents[0]}
              rules={{ required: true }}
              render={({ field: { onChange, ...field } }): ReactElement => (
                <Autocomplete
                  {...field}
                  onChange={(event, searchAgent) => {
                    onChange(searchAgent);
                    setValue('searchAgentDisplayName', searchAgent?.searchAgentDisplayName || '');
                  }}
                  options={searchAgents}
                  getOptionLabel={(option) => option.searchAgentDisplayName || ''}
                  noOptionsText={t('carsTable.noEntriesFound')}
                  renderOption={(option, { selected }): ReactElement => (
                    <>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.searchAgentDisplayName}
                    </>
                  )}
                  renderInput={(params): ReactElement => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t('filterSearch.editSearchAgent')}
                      margin="dense"
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.searchAgentEdit}
                    />
                  )}
                />
              )}
            />
          )}
          <Controller
            name="searchAgentDisplayName"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label={t('common.name')}
                variant="outlined"
                margin="dense"
                fullWidth
                InputLabelProps={{ shrink: true }}
                error={!!errors.searchAgentDisplayName}
                autoFocus
              />
            )}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Controller
                  control={control}
                  name="searchAgentFavorite"
                  // eslint-disable-next-line react/jsx-boolean-value
                  defaultValue={true}
                  render={({ field: { ref, value, ...field } }) => (
                    <Switch inputRef={ref} {...field} checked={value} color="primary" />
                  )}
                />
              }
              label={t('searchAgentTableDrawer.display.favorites')}
            />

            <FormControl>
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name="searchAgentActive"
                    // eslint-disable-next-line react/jsx-boolean-value
                    defaultValue={true}
                    render={({ field: { ref, value, ...field } }) => (
                      <Switch inputRef={ref} {...field} checked={value} color="primary" />
                    )}
                  />
                }
                label={`${t('notification.E_MAIL')} ${t('common.notification')}`}
              />
            </FormControl>
          </FormGroup>
        </form>
      </DialogWithClose>
      <DialogWithClose
        maxWidth="sm"
        onClose={() => setOldSearchAgent(undefined)}
        open={oldSearchAgent !== undefined}
        title={<Typography variant="h2">{t('outdatedSearchAgent.attention')}</Typography>}
      >
        <Typography>{t('outdatedSearchAgent.outdated', { target: oldSearchAgent?.searchAgentDisplayName })}</Typography>
        <Typography>{t('outdatedSearchAgent.changeFields')}</Typography>
        <ul>
          {oldSearchAgent &&
            Object.keys(oldSearchAgent)
              .filter((entry) => obsoleteSearchAgentFields.includes(entry))
              .map((item) => (
                <li>
                  <strong>{getLabelName(item)}</strong>:{' '}
                  {(oldSearchAgent[item as keyof SearchAgentDTO] as Array<string>).join(', ')}
                </li>
              ))}
        </ul>
      </DialogWithClose>
    </>
  );
};

export default memo(SearchAgentSaveDialog);
