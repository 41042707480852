import { defaultColumn } from '@components/cars-table-next/columns';
import { Cell, Column, Row, VisibilityState } from '@tanstack/react-table';
import { find, gt, isArray, isNumber, isObject, isString, lt } from 'lodash';
import { CSSProperties } from 'react';
import {
  ConditionalFormattingDTO,
  ConditionalFormattingDetailDTO,
  ConditionalFormattingScope,
  MonetaryAmount,
  UnitEntry,
} from 'src/modules/generated/api';
import {
  BidsTableNextItem,
  CarsTableNextItem,
  ConditionalFormatDef,
  ConditionalFormatKey,
  PercentEntry,
  StrictColumnDef,
} from './types';

const repeatingLinearGradient = ({
  colors,
  toDirection,
  fallback,
}: {
  colors: string[];
  toDirection?: string;
  fallback?: string;
}) => {
  const colorStops = colors.map((color, index) => `${color} ${index * 24}px, ${color} ${(index + 1) * 24}px`);
  return {
    backgroundImage: `repeating-linear-gradient(${toDirection}, ${colorStops.join(',')})`,
    backgroundColor: fallback,
  };
};

export const isMonetaryAmount = (x: unknown): x is MonetaryAmount => isObject(x) && 'currency' in x;

export const isUnitEntry = (x: unknown): x is UnitEntry => isObject(x) && 'unit' in x;

export const isPercentEntry = (x: unknown): x is PercentEntry => isObject(x) && 'percent' in x;

export const isAbsTaxEntry = (x: unknown): x is { total: MonetaryAmount } => isObject(x) && 'total' in x;

export const isDefined = <T>(x: T | boolean): x is T => typeof x !== 'boolean';

/**
 * Serialize conditional format keys from column definitions.
 */
export const getConditionalFormatKey = (conditionalFormatDef: ConditionalFormatDef): ConditionalFormatKey =>
  isString(conditionalFormatDef) ? conditionalFormatDef : conditionalFormatDef.key;

/**
 * Get typed and simplified value for condition operations.
 */
const getCompareValue = (value: unknown): number | string | string[] | undefined => {
  if (isMonetaryAmount(value)) {
    return value.amount;
  }
  if (isUnitEntry(value)) {
    return value.value;
  }
  if (isPercentEntry(value)) {
    return value.percent;
  }
  if (isAbsTaxEntry(value)) {
    return value.total.amount;
  }
  if (isNumber(value)) {
    return value;
  }
  if (isArray(value)) {
    return value;
  }
  if (isString(value)) {
    return value;
  }
  return undefined;
};

/**
 * Test if a certain condition is matching the cell value
 */
const isConditionMatching = (value: unknown, condition: ConditionalFormattingDetailDTO): boolean => {
  const compareValue = getCompareValue(value);

  if (isNumber(compareValue)) {
    if (condition.gt !== undefined && condition.lt !== undefined) {
      return gt(compareValue, condition.gt) && lt(compareValue, condition.lt);
    }
    if (condition.gt !== undefined) {
      return gt(compareValue, condition.gt);
    }
    if (condition.lt !== undefined) {
      return lt(compareValue, condition.lt);
    }
  }

  if (isString(compareValue)) {
    if (condition.eq) {
      return compareValue === condition.eq;
    }
    if (condition.contains) {
      return new RegExp(condition.contains, 'ig').test(compareValue);
    }
  }

  if (isArray(compareValue)) {
    if (condition.contains !== undefined) {
      return compareValue.includes(condition.contains);
    }
  }

  return false;
};

/**
 * Get all active matching format conditions for a certain cell
 */
const getMatchingFormatConditions = (
  cell: Cell<CarsTableNextItem, unknown>,
  conditionalFormats: ConditionalFormattingDTO[],
): ConditionalFormattingDetailDTO[] | undefined => {
  const conditionalFormatDef = cell.column.columnDef.meta?.conditionalFormat;

  if (!conditionalFormatDef) {
    return undefined;
  }

  const conditionalFormatDefs = Array.isArray(conditionalFormatDef) ? conditionalFormatDef : [conditionalFormatDef];

  // Support display-cells which might combine multiple properties.
  // E.g. Damage-Cell contains `priceDamage` + `damageType`
  const reducedMatchingConditions = conditionalFormatDefs.reduce<ConditionalFormattingDetailDTO[]>((acc, def) => {
    const value = isObject(def) && def.getValue ? def.getValue(cell) : cell.getValue();

    const formatKey = getConditionalFormatKey(def);
    const conditionalFormat = find(conditionalFormats, { field: formatKey });
    const conditions = conditionalFormat?.conditions ?? [];
    const matchingConditions = conditions.filter((condition) => isConditionMatching(value, condition));

    return [...acc, ...matchingConditions];
  }, []);

  return reducedMatchingConditions;
};

export const getMatchingRowFormatConditions = (
  row: Row<CarsTableNextItem>,
  conditionalFormats: ConditionalFormattingDTO[],
): ConditionalFormattingDetailDTO[] =>
  row
    .getAllCells()
    .reduce<ConditionalFormattingDetailDTO[]>(
      (acc, cell) => [...acc, ...(getMatchingFormatConditions(cell, conditionalFormats) ?? [])],
      [],
    )
    .filter((condition) => condition.scope === ConditionalFormattingScope.Row);

const getBackgroundStyles = (conditions: ConditionalFormattingDetailDTO[]): CSSProperties | undefined => {
  const resetStyles = {
    '--text_cell__text_color___text_solo': '',
  } as CSSProperties;

  if (conditions === undefined || conditions.length === 0) {
    return undefined;
  }

  if (conditions.length > 1) {
    return {
      ...resetStyles,
      ...repeatingLinearGradient({
        colors: conditions.map((c) => c.backgroundColor ?? '#fff'),
        toDirection: '135deg',
      }),
    };
  }

  return {
    ...resetStyles,
    background: conditions[0].backgroundColor,
  };
};

/**
 * Get cell scoped styles from conditional formattings.
 */
export const getConditionalFormatStyles = (
  cell: Cell<CarsTableNextItem, unknown>,
  conditionalFormats: ConditionalFormattingDTO[],
): CSSProperties | undefined => {
  const matchingConditions = getMatchingFormatConditions(cell, conditionalFormats);

  return {
    ...(matchingConditions && getBackgroundStyles(matchingConditions)),
  };
};

export const getConditionalRowFormatStyles = (
  row: Row<CarsTableNextItem>,
  conditionalFormats: ConditionalFormattingDTO[],
): CSSProperties | undefined => {
  const matchingConditions = getMatchingRowFormatConditions(row, conditionalFormats);

  return {
    ...(matchingConditions && getBackgroundStyles(matchingConditions)),
  };
};

/**
 * Retrieve initial visibility state from column definitions.
 */
export const getInitialColumnVisibility = (
  columns: StrictColumnDef<CarsTableNextItem | BidsTableNextItem>[],
): VisibilityState =>
  columns.reduce(
    (visibility, columnDef) => ({
      ...visibility,
      [columnDef.id]: columnDef.meta?.columnVisible ?? !!defaultColumn.meta?.columnVisible,
    }),
    {},
  );

export const getAllExportableColumns = (columns: Column<CarsTableNextItem | BidsTableNextItem, unknown>[]) =>
  columns.filter((column) => column.columnDef.meta?.export?.enabled !== false);

export const getInternalColumns = (columns: Column<any, unknown>[]) =>
  columns.filter((colum) => !colum.columnDef.meta?.isExternalColumn);

export const getExternalColumns = (columns: Column<any, unknown>[]) =>
  columns.filter((colum) => colum.columnDef.meta?.isExternalColumn === true);

export const getColumnSortKey = (id: string, columns: StrictColumnDef<any>[]): string | null => {
  const column = columns.find((col) => col.id === id);

  if (!column) {
    return null;
  }

  return column.meta?.sortKey ?? id;
};
