import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAuthenticationStore } from 'src/stores/Authentication';
import FullPageCenterContainer from '@components/FullPageCenterContainer';
import mail from 'src/modules/global-vars';
import { PageMeta } from '@components/page-meta';

const useStyles = makeStyles(({ spacing }) => ({
  heading: {
    marginBottom: spacing(4),
  },
  underHeadline: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    maxWidth: '70vw',
    margin: '0 auto',
    textAlign: 'center',
  },
  logo: {
    margin: spacing(2),
    height: '60px',
  },
}));

const ForbiddenPage = (_props: RouteComponentProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    state: { objectToken },
  } = useAuthenticationStore();

  return (
    <>
      <PageMeta title="account.forbidden" />
      <FullPageCenterContainer>
        <Typography variant="h1" className={classes.heading}>
          {t('account.activationNeeded')}
        </Typography>
        <br />
        <div className={classes.underHeadline}>
          {objectToken?.dealer_id ? (
            <>
              <div>{t('account.notAuthorizedId', { target: mail })}</div>
              <br />
              <div>{objectToken?.dealer_id}</div>
            </>
          ) : (
            <div>{t('account.notAuthorized', { target: mail })}</div>
          )}
        </div>
      </FullPageCenterContainer>
    </>
  );
};

export default ForbiddenPage;
