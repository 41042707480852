import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import type { ThemeOptions } from '@material-ui/core';

const palette = {
  primary: {
    light: '#ff9a3a',
    main: '#ec6707',
    dark: '#b93400',
    accent: '#fffaf5',
  },
  secondary: {
    light: '#4f5b62',
    main: '#263238',
    dark: '#000a12',
  },
  info: {
    light: '#0093A1',
    main: '#1F6FED', // 0093A1 oder 1368ED oder 1F6FED
    dark: '#0E336E',
  },
  highlight: '#FF4D4D',
};

const theme = createMuiTheme({
  palette,
  typography: {
    h1: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      textDecorationColor: '#ec6707',
      textDecorationLine: 'underline',
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 'bold',
      textDecorationColor: '#ec6707',
      textDecorationLine: 'underline',
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      textDecorationColor: '#ec6707',
      textDecorationLine: 'underline',
    },
    fontFamily: 'Arial, sans-serif',
  },
  overrides: {
    // MuiAutocomplete: {
    //   listbox: {
    //     padding: '4px 0',
    //   },
    //   option: {
    //     paddingTop: 0,
    //     paddingRight: 8,
    //     paddingBottom: 0,
    //     paddingLeft: 8,
    //   },
    // },
    // MuiTooltip: {
    //   tooltip: {
    //     fontSize: '0.75rem',
    //     whiteSpace: 'pre-line',
    //   },
    // },
    MUIDataTable: {
      responsiveBase: {
        paddingLeft: 16,
        paddingRight: 16,
      },
      tableRoot: {
        position: 'relative',
        '&::after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          right: -16,
          bottom: 0,
          width: 16,
        },
      },
    },
    MuiTableRow: {
      hover: {
        cursor: 'pointer',
        '&:hover .car-row-edit-button': {
          visibility: 'visible',
        },
      },
    },
    MUIDataTableHeadCell: {
      root: {
        fontWeight: 'bold',
        textAlign: 'center',
        lineHeight: '1.25rem',
      },
      hintIconAlone: {
        color: 'rgba(0, 0, 0, 0.54)',
      },
      hintIconWithSortIcon: {
        color: 'rgba(0, 0, 0, 0.54)',
      },
      toolButton: {
        justifyContent: 'center',
      },
    },
    MuiCssBaseline: {
      '@global': {
        mark: {
          padding: '0 0.25em',
          backgroundColor: palette.primary.light,
        },
      },
    },
  },
  props: {
    MuiMenu: {
      // prevent removing focus from usersnap widget
      disableEnforceFocus: true,
    },
    MuiDialog: {
      // prevent removing focus from usersnap widget
      disableEnforceFocus: true,
    },
    MuiDrawer: {
      // prevent removing focus from usersnap widget
      disableEnforceFocus: true,
    },
  },
} as ThemeOptions);

export default theme;
