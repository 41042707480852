import { makeStyles, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FirstCallType } from 'src/modules/generated/api';

const useStyles = makeStyles((theme) => ({
  icon: {
    border: '1px solid',
    borderRadius: 4,
  },
  own: {
    borderColor: theme.palette.primary.main,
  },
  other: {
    borderColor: '#2196f3',
  },
}));

type FirstCallIconProps = {
  call?: FirstCallType;
};

export const FirstCallIcon = ({ call }: FirstCallIconProps) => {
  const ownCall = call === FirstCallType.Own;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Tooltip title={ownCall ? t('source.realFirstCallOwn') : t('source.realFirstCallOther')} placement="top" arrow>
      <img
        className={clsx(classes.icon, ownCall ? classes.own : classes.other)}
        src="/images/promotions/FIRST_CALL.png"
        alt={ownCall ? t('source.realFirstCallOwn') : t('source.realFirstCallOther')}
        width={20}
      />
    </Tooltip>
  );
};
