import { createContext, createElement, ReactNode, useCallback, useContext, useEffect } from 'react';
import ApiService from '../modules/api-service';
import { WizardDTO, WizardStep, WizardStepDTO } from '../modules/generated/api';
import useApi from './useApi';

type WizardProviderProps = {
  hasReallyAccess: boolean;
  children?: ReactNode;
};

type WizardCtxType = {
  wizard: WizardDTO | undefined;
  onConfirmCallback: (step: WizardStep, keepShowFlagTrue?: boolean) => Promise<void>;
  hasWizardStepsToShow: (except?: WizardStep[]) => boolean;
  showWizardBadge: (wizardKey: WizardStep) => boolean;
  isWizardConfirmed: (wizardKey: WizardStep) => boolean;
  refetchWizard: () => void;
  useTrackWizardConfirmationOnPageView: (step: WizardStep, showInWizard?: boolean) => void;
};

const wizardCtx = createContext<WizardCtxType>(undefined!);

export const WizardProvider = ({ hasReallyAccess, children }: WizardProviderProps) => {
  const { fetch: confirmWizard } = useApi<WizardStepDTO>();
  const { data: wizard, fetch: fetchWizard, mutate } = useApi<WizardDTO>();

  const onConfirmCallback = useCallback(
    (step: WizardStep, keepShowFlagTrue?: boolean) =>
      confirmWizard(ApiService.config.configControllerConfirmWizardStep(step)).then((newWizardStep) => {
        mutate((prevState) => {
          const newState = {
            ...prevState?.steps,
            [step]: { ...newWizardStep?.data, show: keepShowFlagTrue ? true : newWizardStep?.data.show },
          };
          return { ...prevState, steps: newState };
        });
      }),
    [confirmWizard, mutate],
  );

  const hasWizardStepsToShow = (except: WizardStep[] = []) =>
    !!wizard?.steps &&
    Object.entries(wizard.steps)
      .filter(([key]) => !except.includes(key as WizardStep))
      .some(([, step]) => step.show);

  const showWizardBadge = useCallback((wizardKey: WizardStep) => !!wizard?.steps?.[wizardKey].show, [wizard?.steps]);

  const isWizardConfirmed = useCallback(
    (wizardKey: WizardStep) => !!wizard?.steps?.[wizardKey].confirmed,
    [wizard?.steps],
  );

  const refetchWizard = () => {
    fetchWizard(ApiService.config.configControllerGetWizard());
  };

  const useTrackWizardConfirmationOnPageView = (step: WizardStep, showInWizard?: boolean) => {
    useEffect(() => {
      if (!showInWizard && showWizardBadge(step)) onConfirmCallback(step, showInWizard);
    }, [showInWizard, step]);
  };

  useEffect(() => {
    if (hasReallyAccess) {
      fetchWizard(ApiService.config.configControllerGetWizard());
    }
  }, [fetchWizard, hasReallyAccess]);

  const value = {
    onConfirmCallback,
    hasWizardStepsToShow,
    showWizardBadge,
    isWizardConfirmed,
    wizard,
    useTrackWizardConfirmationOnPageView,
    refetchWizard,
  };

  return createElement(wizardCtx.Provider, { value }, children);
};

export const useWizard = () => useContext(wizardCtx);
