import { Address } from '@components/ui/address/types';
import { Button, CircularProgress, FormControl, FormHelperText, Grid, OutlinedInput } from '@material-ui/core';
import countries from 'i18n-iso-countries';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSetSourceAddress } from 'src/hooks/useTransportOptions';
import { DELIVERY_ADDRESS_VALIDATION_MSG, getIcon, IconType, SHIPPING_COUNTRIES_COUNTRY_CODES } from 'src/modules/data';
import { CountryCode } from 'src/modules/generated/api';
import i18n from 'src/setup/i18n';
import { Autocomplete } from './cars-table-next/filter/common/Autocomplete';
import { InputLabel, Stack } from './ui';

type SourceAddressFormProps = {
  sourceAddress?: Address;
  bidId: string;
  handleClose: () => void;
  refetch: () => void;
};

export const SourceAddressForm = ({ sourceAddress, bidId, handleClose, refetch }: SourceAddressFormProps) => {
  const { t } = useTranslation();
  const methods = useForm<Address>({
    defaultValues: sourceAddress,
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = methods;
  const validationRules = DELIVERY_ADDRESS_VALIDATION_MSG;

  const { mutate: postTransportId, isLoading } = useSetSourceAddress(bidId);

  const onSubmit = (data: Address) => {
    postTransportId({ ...data, countryCode: data.countryCode as CountryCode, contactPersonName: data.name });
    handleClose();
    refetch();
  };

  return (
    <form id="address-form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <Stack spacing={1}>
          <FormControl size="small" fullWidth variant="outlined">
            <InputLabel required>{t('contact.companyName')}</InputLabel>
            <Controller
              name="companyName"
              control={control}
              rules={validationRules?.companyName}
              render={({ field: { ref, ...field } }) => <OutlinedInput inputRef={ref} {...field} />}
            />
            {errors.companyName ? (
              <FormHelperText error>{errors.companyName.message || errors.companyName.type}</FormHelperText>
            ) : null}
          </FormControl>

          <FormControl size="small" fullWidth variant="outlined">
            <InputLabel required>{t('configuration.contactPerson')}</InputLabel>
            <Controller
              name="name"
              control={control}
              rules={validationRules?.name}
              render={({ field: { ref, ...field } }) => <OutlinedInput inputRef={ref} {...field} />}
            />
            {errors.name ? <FormHelperText error>{errors.name.message || errors.name.type}</FormHelperText> : null}
          </FormControl>

          <FormControl size="small" fullWidth variant="outlined">
            <InputLabel required>{t('contact.street')}</InputLabel>
            <Controller
              name="street"
              control={control}
              rules={validationRules?.street}
              render={({ field: { ref, ...field } }) => <OutlinedInput inputRef={ref} {...field} />}
            />
            {errors.street ? (
              <FormHelperText error>{errors.street.message || errors.street.type}</FormHelperText>
            ) : null}
          </FormControl>

          <FormControl size="small" fullWidth variant="outlined">
            <InputLabel required>{t('contact.city')}</InputLabel>
            <Controller
              name="city"
              control={control}
              rules={validationRules?.city}
              render={({ field: { ref, ...field } }) => <OutlinedInput inputRef={ref} {...field} />}
            />
            {errors.city ? <FormHelperText error>{errors.city.message || errors.city.type}</FormHelperText> : null}
          </FormControl>

          <FormControl size="small" fullWidth variant="outlined">
            <InputLabel required>{t('contact.postal')}</InputLabel>
            <Controller
              name="zip"
              control={control}
              rules={validationRules?.zip}
              render={({ field: { ref, ...field } }) => <OutlinedInput disabled inputRef={ref} {...field} />}
            />
            {errors.zip ? <FormHelperText error>{errors.zip.message || errors.zip.type}</FormHelperText> : null}
          </FormControl>

          <FormControl size="small" fullWidth variant="outlined">
            <InputLabel required>{t('contact.country')}</InputLabel>
            <Controller
              name="countryCode"
              control={control}
              rules={validationRules?.countryCode}
              render={({ field: { ref: _ref, ...field } }) => (
                <Autocomplete
                  {...field}
                  disabled
                  optionIcon={(option) => getIcon(IconType.COUNTRY, option)}
                  error={!!errors.countryCode}
                  helperText={errors.countryCode && errors.countryCode.message}
                  label=""
                  options={SHIPPING_COUNTRIES_COUNTRY_CODES}
                  getOptionLabel={(option: string) => (countries.getName(option, i18n.language) as string) || option}
                  disableCloseOnSelect={false}
                  onChange={(value) => {
                    setValue('countryCode', value as any); // Overwrites default behaviour
                    setValue(
                      'country',
                      value && !Array.isArray(value) ? (countries.getName(value, i18n.language) as string) : '',
                    );
                  }}
                />
              )}
            />
          </FormControl>

          <FormControl size="small" fullWidth variant="outlined">
            <InputLabel required>{t('contact.E-Mail')}</InputLabel>
            <Controller
              name="email"
              control={control}
              rules={validationRules?.email}
              render={({ field: { ref, ...field } }) => <OutlinedInput inputRef={ref} {...field} />}
            />
            {errors.email ? <FormHelperText error>{errors.email.message || errors.email.type}</FormHelperText> : null}
          </FormControl>

          <FormControl size="small" fullWidth variant="outlined">
            <InputLabel required>{t('contact.phone')}</InputLabel>
            <Controller
              name="phoneNumber"
              control={control}
              rules={validationRules?.phoneNumber}
              render={({ field: { ref, ...field } }) => <OutlinedInput inputRef={ref} {...field} />}
            />
            {errors.phoneNumber ? (
              <FormHelperText error>{errors.phoneNumber.message || errors.phoneNumber.type}</FormHelperText>
            ) : null}
          </FormControl>

          <FormControl size="small" fullWidth variant="outlined">
            <InputLabel>{t('contact.fax')}</InputLabel>
            <Controller
              name="faxNumber"
              control={control}
              render={({ field: { ref, ...field } }) => <OutlinedInput inputRef={ref} {...field} />}
            />
            {errors.faxNumber ? (
              <FormHelperText error>{errors.faxNumber.message || errors.faxNumber.type}</FormHelperText>
            ) : null}
          </FormControl>

          <Grid container style={{ marginTop: '16px' }} justifyContent="space-between">
            <Button size="small" variant="outlined" color="secondary" onClick={handleClose}>
              {t('common.cancel')}
            </Button>
            <Button
              type="submit"
              form="address-form"
              size="small"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              {t('configuration.commissionTransport')}
              {isLoading ? <CircularProgress size={20} /> : null}
            </Button>
          </Grid>
        </Stack>
      </FormProvider>
    </form>
  );
};
