import { IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import React, { KeyboardEvent, memo, useEffect, useState } from 'react';
import { InputAttributes, NumberFormatValues } from 'react-number-format';
import { Done } from '@material-ui/icons';
import { useCurrency } from 'src/hooks/useCurrency';
import EditIcon from '@material-ui/icons/Edit';
import { NumericCell } from '@components/cars-table-next/cells/NumericCell';
import { currencyFormat } from '@components/cars-table-next/format';
import { useCarUpdate } from '@components/cars-table-next/useCarUpdate';
import { AvatarGroupCell } from '@components/cars-table-next/cells/AvatarGroupCell';
import { ListCarDTO } from '../../../modules/generated/api';
import AmountInput from '../../AmountInput';
import handleStopPropagation from '../../../modules/jsx-helpers';
import theme from '../../../setup/theme';
import { makeMonetaryAmount, MonetaryAmountStrict, SUPPORTED_CURRENCIES } from '../../../modules/currency';
import { getIcon, IconType } from '../../../modules/data';
import { damageTypes, getLabel } from '../../../modules/labels';

type CarsTableDamageCellProps = {
  car: ListCarDTO;
  maxIcons: number;

  priceDamage?: MonetaryAmountStrict;
};

const useStyles = makeStyles(() => ({
  dmgContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    '&:hover': {
      '& .MuiSvgIcon-root': {
        visibility: 'visible',
      },
    },
  },
  tableInput: {
    marginInline: 12,
    border: 'none',
    fontFamily: 'Arial, sans-serif',
    '& .MuiInputBase-root': {
      fontSize: '0.875rem',
      textAlign: 'right',
    },
    '& .MuiInputBase-input': {
      textAlign: 'right',
      padding: 0,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.light,
    },
    '& .Mui-error.MuiInput-underline:after': {
      borderBottomColor: theme.palette.error.dark,
    },
  },
  submitIcon: {
    '& .MuiSvgIcon-root': { fontSize: '1rem' },
  },
  editIcon: {
    position: 'absolute',
    left: 10,
    '& .MuiSvgIcon-root': { fontSize: '1rem', visibility: 'hidden' },
  },
}));

const DamageCell = ({
  car: { carId, source, damageType },
  car,
  priceDamage: currentPriceDamage,
  maxIcons,
}: CarsTableDamageCellProps) => {
  const { currency, convert } = useCurrency();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [priceDamage, setPriceDamage] = useState<MonetaryAmountStrict>(
    convert(makeMonetaryAmount(currentPriceDamage ?? 0), {}),
  );
  const { mutate: updateCar } = useCarUpdate();
  const classes = useStyles();

  const handleOnValueChange = async ({ floatValue }: NumberFormatValues) => {
    setPriceDamage((prev) => ({ ...prev, amount: floatValue ?? 0 }));
  };

  const handleOnSubmit = () => {
    const updatedPriceDamage = { unit: priceDamage?.currency, value: priceDamage.amount };
    const updatedCar = { ...car, priceDamage: updatedPriceDamage };
    updateCar(updatedCar, { onSuccess: () => setIsEdit(false) });
  };

  const handleOnKeyDown = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') return;
    handleOnSubmit();
  };

  useEffect(() => {
    if (currentPriceDamage?.amount === undefined) return;
    setPriceDamage(convert(makeMonetaryAmount(currentPriceDamage), {}));
  }, [currentPriceDamage, convert]);

  useEffect(() => {
    setIsEdit(false);
  }, [carId]);

  return (
    <div
      className={classes.dmgContainer}
      onClick={handleStopPropagation}
      onKeyDown={handleStopPropagation}
      role="button"
      tabIndex={0}
    >
      {isEdit ? (
        <AmountInput<InputAttributes>
          className={classes.tableInput}
          decimalScale={2}
          disabled={!carId || !source}
          onValueChange={handleOnValueChange}
          onKeyDown={handleOnKeyDown}
          suffix={` ${SUPPORTED_CURRENCIES[currency].symbol}`}
          value={priceDamage.amount}
          emptyInputAllowed
          // @ts-ignore
          customInput={TextField}
          autoFocus
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton size="small" type="submit" className={classes.submitIcon} onClick={handleOnSubmit}>
                  <Done />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <>
          <IconButton className={classes.editIcon} onClick={() => setIsEdit(true)} size="small">
            <EditIcon />
          </IconButton>
          <NumericCell value={currentPriceDamage || '-'} format={currencyFormat.format} />
        </>
      )}
      <AvatarGroupCell
        style={{
          justifyContent: 'flex-end',
        }}
        items={
          damageType?.map((damageKey: string) => ({
            key: damageKey,
            src: getIcon(IconType.DAMAGE_TYPE, damageKey),
            style: {
              width: 20,
              height: 20,
            },
            imgProps: {
              style: {
                // strip icon border
                width: 'calc(100% + 2px)',
                height: 'calc(100% + 2px)',
              },
            },
            helpText: getLabel(damageTypes, damageKey),
          })) ?? []
        }
        max={maxIcons}
      />
    </div>
  );
};
export default memo(DamageCell);
