import { getSourceOptions, useSources } from 'src/hooks/useSources';
import { sortBy } from 'lodash';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import FilterAutocomplete from './FilterAutocomplete';

const SourceSelect = () => {
  const { t } = useTranslation();
  const { data: sources = [] } = useSources();
  const sourceOptions = getSourceOptions(sources);

  return (
    <FilterAutocomplete
      name="source"
      label={t('common.source', { count: sourceOptions.length })}
      multiple
      options={sortBy(
        sourceOptions.map((source) => source),
        (option) => option.label.toLowerCase(),
        ['asc'],
      )}
    />
  );
};

export default memo(SourceSelect);
