import { ExportType } from '@components/cars-table-next/constants';
import { Cell, ColumnDef, RowData, Table } from '@tanstack/react-table';
import {
  ConditionalFormattingType,
  ListBidDTO,
  ListCarDTO,
  MonetaryAmount,
  UnitEntry,
} from 'src/modules/generated/api';

export type CarsTableNextItem = ListCarDTO & {
  isNew?: boolean;
};

export type BidsTableNextItem = CarsTableNextItem & ListBidDTO;

export type CarsTableNextInstance = Table<CarsTableNextItem>;

export type BidsTableNextInstance = Table<BidsTableNextItem>;

export type ConditionalFormatKey = ConditionalFormattingType;

export enum TableType {
  Cars = 'cars',
  Bids = 'bids',
}

export enum BidTypes {
  Pending = 'pending',
  Past = 'past',
  Orders = 'orders',
}

export type ConditionalFormatDef =
  | ConditionalFormatKey
  | {
      key: ConditionalFormatKey;
      /**
       * Get the comparison value for the conditional formatting matching operation.
       */
      getValue?: (
        cell: Cell<CarsTableNextItem, unknown>,
      ) => string | number | MonetaryAmount | UnitEntry | string[] | undefined | null;
    };

export type ExportDef = {
  /**
   * Is the column available as export?
   * @default true
   */
  enabled?: boolean;
  /**
   * Override the export key
   * @default `column.id`
   */
  // TODO: add deepPath typings
  key?: keyof CarsTableNextItem | string;
  /**
   * Allowed export types. Default value of the select equals the first option.
   */
  typeOptions?: {
    value: ExportType;
    label: string;
  }[];
  /**
   * Translations provided to the export API (e.g. promotions, countryOrigin)
   */
  valueLabels?: {
    name: string;
    label: string;
  }[];
  /**
   * Export additional car properties
   */
  exports?: SubExportDef[];
};

export type SubExportDef = {
  key: string;
  label: string;
  valueLabels?: {
    name: string;
    label: string;
  }[];
};

export type PercentEntry = {
  percent: number;
  name?: string;
};

export type StrictColumnDef<TData extends RowData, TValue = unknown> = ColumnDef<TData, TValue> & {
  id: string; // required in order to populate initial drag and drop state
};
