import CustomSnackbarGlobal from '@components/CustomSnackbarGlobal';
import Navigation from '@components/Navigation';
import { ProtectedRoute } from '@components/ProtectedRoute';
import { AppSpinner } from '@components/app-spinner';
import { Redirect, Router } from '@reach/router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { SnackbarProvider } from 'material-ui-snackbar-provider';
import { Suspense } from 'react';
import { OverlayProvider } from 'react-aria';
import { RecoilRoot } from 'recoil';
import { AppLoadingIndicator } from 'src/components/AppLoadingIndicator';
import { RoutePaths } from 'src/constants';
import WizardPopup from 'src/containers/WizardPopup';
import { ExperimentsProvider } from 'src/hooks/useExperiments';
import { ExtendedUserSnapProvider } from 'src/hooks/useExtendUsersnap';
import { useGTM } from 'src/hooks/useGTM';
import { ValuationSettingsProvider } from 'src/hooks/useValuationSettings';
import { WizardProvider } from 'src/hooks/useWizard';
import { Usersnap } from 'src/lib/Usersnap';
import { HelpIndexPage } from 'src/pages/help';
import { CategoryPage } from 'src/pages/help/[category]';
import { AnswerPage } from 'src/pages/help/[category]/[answer]';
import { RouteDef, routes } from 'src/routes';
import { useAuthenticationStore } from 'src/stores/Authentication';
import PacketsPage from './pages/settings/packets';
import PacketDetailPage from './pages/settings/packets/[id]';

const queryClient = new QueryClient();

const renderRoute = (route: RouteDef): JSX.Element => {
  const { element: Element, path, ...rest } = route;

  if (route.protected) {
    return <ProtectedRoute key={path} component={Element} path={path} {...rest} />;
  }

  return <Element key={path} path={path} {...rest} />;
};

const App = () => {
  const {
    state: { hasAccess, objectToken: { exp } = {} },
  } = useAuthenticationStore();
  const hasReallyAccess = hasAccess && exp && exp > DateTime.now().plus({ minute: 1 }).toSeconds();

  useGTM();

  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <ValuationSettingsProvider>
          <SnackbarProvider SnackbarComponent={CustomSnackbarGlobal}>
            <ExtendedUserSnapProvider>
              <WizardProvider hasReallyAccess={!!hasReallyAccess}>
                <ExperimentsProvider>
                  <OverlayProvider id="aria-container">
                    <Suspense fallback={<AppSpinner />}>
                      <Usersnap />
                      <Navigation />
                      {/* <ScrollToTopButton /> */}
                      <WizardPopup />

                      <Router>
                        {routes.map(renderRoute)}

                        <HelpIndexPage path="/help">
                          <CategoryPage path=":category" />
                          <AnswerPage path=":category/:answer" />
                        </HelpIndexPage>

                        <PacketsPage path={RoutePaths.packets}>
                          <PacketDetailPage path=":id" />
                        </PacketsPage>

                        {/* Silently redirect 404 errors to cars page */}
                        <Redirect from="*" to={RoutePaths.cars} noThrow />
                      </Router>
                    </Suspense>
                    <AppLoadingIndicator />
                  </OverlayProvider>
                </ExperimentsProvider>
              </WizardProvider>
            </ExtendedUserSnapProvider>
          </SnackbarProvider>
        </ValuationSettingsProvider>
      </RecoilRoot>
    </QueryClientProvider>
  );
};

export default App;
