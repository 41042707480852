import { Collapse, Grid, IconButton, Link, makeStyles, Typography } from '@material-ui/core';
import { DoubleArrow, ExpandLess, ExpandMore, LocalOffer } from '@material-ui/icons';
import clsx from 'clsx';
import React, { CSSProperties, memo, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { getIcon, IconType } from '../modules/data';
import { EquipmentHighlight, MonetaryAmount, SourceRegisterValuationIncludedCarDTO } from '../modules/generated/api';
import CarDetailTileFirstReg from './CarDetailTileFirstReg';
import CarDetailTileUnit from './CarDetailTileUnit';
import CarsTableImageCell from './CarsTableImageCell';
import HighlightIcon from './HighlightIcon';
import ImageLabel from './ImageLabel';
import { useAuthenticationStore } from '../stores/Authentication';
import RefCarsKpi from './RefCarsKpi';
import { DEFAULT_CURRENCY } from '../modules/currency';
import { BidSuccessStatus, IndividualBidStateInfoDTO } from '../modules/generated/api/api';

const useStyles = makeStyles((theme) => ({
  btn: {
    padding: '6px',
  },
  center: {
    alignContent: 'center',
  },
  column: {
    flexDirection: 'column',
  },
  fixedSize: {
    minWidth: '187px',
  },
  flexStart: {
    justifyContent: 'flex-start',
    gap: '15px',
  },
  header: {
    paddingLeft: '20px',
  },
  highlights: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: theme.spacing(12.5),
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  origin: {
    '& svg': {
      transform: 'scaleX(-1)',
    },
  },
  spacing: {
    justifyContent: 'space-between',
  },
  deepLinks: {
    marginBlockStart: theme.spacing(2),
  },
  linkList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    rowGap: theme.spacing(1),
    listStyle: 'none',
    margin: 0,
    paddingInlineStart: 0,
  },
  linkListItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    position: 'relative',

    '&::before': {
      content: '""',
      display: 'inline-block',
      width: 16,
      height: 16,
      background: 'var(--list-icon-url)',
      backgroundSize: 'contain',
    },
  },
}));

const getDomainSafe = (url: string): string => {
  try {
    const subdomainReg = /^[^.]*\.(?=\w+\.\w+$)/;
    const { hostname } = new URL(url);
    return hostname.replace(subdomainReg, '');
  } catch {
    return url;
  }
};

type CarInfoPreviewGridProps = {
  dataIndex?: number;
  detailsExpanded?: (value: false | number) => void;
  equipment?: string[];
  equipmentHighlights?: EquipmentHighlight[];
  equipmentKeys?: EquipmentHighlight[];
  expanded?: false | number;
  firstRegistration?: string;
  fuel?: string;
  gearing?: string;
  image?: string;
  mileage?: number;
  model?: string;
  origin?: boolean;
  purchaseGross?: MonetaryAmount;
  refCarsList?: SourceRegisterValuationIncludedCarDTO[];
  deselectedCarsIdx?: Set<number>;
  sold?: boolean;
  title?: string;
  deepLinks?: string[];
  bidSuccessStatus?: BidSuccessStatus;
  bidStateInfo?: IndividualBidStateInfoDTO;
  valuationDate?: string;
};

const CarInfoPreviewGrid = ({
  dataIndex,
  detailsExpanded: setDetailsExpanded,
  equipment,
  equipmentHighlights,
  equipmentKeys,
  expanded,
  firstRegistration,
  fuel,
  gearing,
  image,
  mileage,
  model,
  origin,
  purchaseGross,
  refCarsList,
  deselectedCarsIdx,
  sold,
  title,
  deepLinks,
  bidStateInfo,
  bidSuccessStatus,
  valuationDate,
}: CarInfoPreviewGridProps) => {
  const classes = useStyles();
  const {
    state: { accessToken },
  } = useAuthenticationStore();
  const { t } = useTranslation();

  const otherBought = bidStateInfo?.otherCurrentBids?.find((otherBid) => otherBid.status === BidSuccessStatus.Bought);
  const isCarBought = bidSuccessStatus === BidSuccessStatus.Bought || !!otherBought;

  const handleExpansion: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    if (!setDetailsExpanded || dataIndex === undefined) return;
    if (expanded === dataIndex) {
      setDetailsExpanded(false);
      return;
    }
    setDetailsExpanded(dataIndex);
  };

  return (
    <Grid container className={clsx(origin && classes.spacing)}>
      <Grid container item md={origin ? 9 : 12} xs={12} className={clsx(origin && classes.header)}>
        {model && (
          <Grid item>
            <strong>{model}</strong>
            {title && (
              <>
                {' | '}
                {title?.replace(model?.toString().replace('VW', 'Volkswagen'), '').replace('Volkswagen', '')}
              </>
            )}
          </Grid>
        )}

        <Grid container item spacing={2}>
          <Grid item>
            <ImageLabel disable={!sold} size="medium" text={isCarBought ? t('referencePopup.sold') : t('bids.expired')}>
              {image ? (
                <CarsTableImageCell
                  imageLinks={origin ? [`${image}`] : [`${image}?access_token=${accessToken}`]}
                  size="medium"
                />
              ) : (
                <CarsTableImageCell imageLinks={['']} size="medium" />
              )}
            </ImageLabel>
          </Grid>

          <Grid className={clsx(classes.column, classes.center)} container item xs={9}>
            <Grid className={clsx(classes.center, classes.flexStart)} container item>
              <Grid className={classes.fixedSize} item>
                <CarDetailTileUnit icon={DoubleArrow} label={t('car.mileage')} unit="km" value={mileage} />
              </Grid>

              <Grid className={classes.noWrap} item>
                <CarDetailTileFirstReg firstRegistration={firstRegistration} />
              </Grid>

              {origin && purchaseGross && (
                <Grid className={clsx(classes.noWrap, classes.origin)} item>
                  <CarDetailTileUnit
                    hint={t('car.prices.purchaseGross')}
                    icon={LocalOffer}
                    label={t('car.prices.purchaseGross_short')}
                    unit={purchaseGross.currency || DEFAULT_CURRENCY.code}
                    value={purchaseGross.amount}
                  />
                </Grid>
              )}
            </Grid>

            <Grid container item wrap="nowrap">
              {equipmentHighlights && equipmentKeys && (
                <div className={classes.highlights}>
                  {equipmentKeys.map((equipmentItem: string) => (
                    <HighlightIcon
                      color={!equipmentHighlights.includes(equipmentItem as EquipmentHighlight) ? 'red' : undefined}
                      key={equipmentItem}
                      type={IconType.EQUIPMENT}
                      value={equipmentItem}
                      titleSuffix={
                        !equipmentHighlights.includes(equipmentItem as EquipmentHighlight)
                          ? `(${t('referencePopup.highlight.missing')})`
                          : undefined
                      }
                    />
                  ))}
                  {equipmentHighlights
                    .filter((highlight) => !equipmentKeys.includes(highlight))
                    .map((equipmentItem: string) => (
                      <HighlightIcon
                        color="green"
                        key={equipmentItem}
                        type={IconType.EQUIPMENT}
                        value={equipmentItem}
                        titleSuffix={`(${t('referencePopup.highlight.extra')})`}
                      />
                    ))}
                </div>
              )}

              {origin && (
                <div className={classes.highlights}>
                  {(equipmentKeys || []).map((equipmentItem: string) => (
                    <HighlightIcon key={equipmentItem} type={IconType.EQUIPMENT} value={equipmentItem} />
                  ))}
                </div>
              )}
            </Grid>

            {deepLinks && deepLinks.length > 0 && (
              <Grid item className={classes.deepLinks}>
                <Typography gutterBottom>{t('referencePopup.deepLinks')}</Typography>
                <ul className={classes.linkList}>
                  {deepLinks.map((link) => (
                    <li
                      key={link}
                      className={classes.linkListItem}
                      style={
                        {
                          '--list-icon-url': `url(${getIcon(IconType.EXTERNAL_PORTAL, getDomainSafe(link))})`,
                        } as CSSProperties
                      }
                    >
                      <Link target="_blank" title="" href={link} rel="noreferrer">
                        {getDomainSafe(link)}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Grid>
            )}
          </Grid>
        </Grid>

        {(fuel || gearing || equipment) && (
          <Grid container item>
            <Grid item>
              <IconButton className={classes.btn} onClick={handleExpansion}>
                {expanded === dataIndex ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
              <Collapse in={expanded === dataIndex}>
                {fuel}
                {fuel && gearing && ' | '}
                {/* eslint-disable no-nested-ternary */}
                {gearing === 'AUTOMAT' ? 'Automatik' : gearing === 'MANUAL' ? 'Schaltgetriebe' : ''}
                <br />
                {equipment && <div>{equipment.join(' • ')}</div>}
              </Collapse>
            </Grid>
          </Grid>
        )}
      </Grid>

      {origin && refCarsList && deselectedCarsIdx && (
        <Grid item md={3} xs={12}>
          <RefCarsKpi
            refCarsList={refCarsList.filter((refCar, idx) => !deselectedCarsIdx.has(idx))}
            valuationDate={valuationDate}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default memo(CarInfoPreviewGrid);
