import { Avatar, Backdrop, CircularProgress, Fade, Grid, makeStyles, Typography } from '@material-ui/core';
import { Check, DriveEta, Error } from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: 'rgb(0,0,0,0.8)',
    zIndex: theme.zIndex.drawer + 1,
  },
  avatar: {
    backgroundColor: '#FFFFFF',
    boxShadow:
      '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
    cursor: 'default',
    height: '56px',
    width: '56px',
    '&--error': {
      backgroundColor: theme.palette.error.main,
    },
    '&--success': {
      backgroundColor: theme.palette.success.main,
    },
    '&--zoomed': {
      transform: 'scale(1.8) translateY(-16px)',
      transition: 'all .3s ease-out',
    },
  },
  avatarIcon: {
    color: theme.palette.secondary.light,
  },
  avatarLoadingIndicator: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  feedbackMessage: {
    color: '#FFFFFF',
  },
  avatarWrapper: {
    position: 'relative',
  },
}));

export type BidBuyStatus = {
  message: null | string;
  success: null | boolean;
};

type BidBuyFeedbackProps = {
  bidBuyStatus: BidBuyStatus;
  isLoading: boolean;
  open: boolean;
  toggleOpen: () => void;
};

const BidBuyAnimation = ({ bidBuyStatus, isLoading, open, toggleOpen }: BidBuyFeedbackProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Backdrop className={classes.backdrop} onClick={!isLoading ? toggleOpen : undefined} open={open}>
      <Grid container alignItems="center" direction="column" spacing={1}>
        <Grid item className={clsx(bidBuyStatus.success !== null && `${classes.avatar}--zoomed`)}>
          <div className={classes.avatarWrapper}>
            <Avatar
              aria-label={t('alerts.successIndicator')}
              className={clsx(
                classes.avatar,
                bidBuyStatus.success !== null && `${classes.avatar}--${bidBuyStatus.success ? 'success' : 'error'}`,
              )}
            >
              {isLoading && <DriveEta className={classes.avatarIcon} />}
              {bidBuyStatus.success && <Check className={classes.avatarIcon} />}
              {bidBuyStatus.success === false && <Error className={classes.avatarIcon} />}
            </Avatar>
            {isLoading && <CircularProgress className={classes.avatarLoadingIndicator} size={68} />}
          </div>
        </Grid>
        <Grid item>
          <Fade in={bidBuyStatus.message !== null} timeout={{ enter: 400 }}>
            <Typography className={classes.feedbackMessage} variant="subtitle1">
              {bidBuyStatus.message}
            </Typography>
          </Fade>
        </Grid>
      </Grid>
    </Backdrop>
  );
};

export default BidBuyAnimation;
