import React, { memo, useCallback, useState } from 'react';
import {
  FormLabel,
  Grid,
  InputAdornment,
  makeStyles,
  Slider,
  TextField,
  TextFieldProps,
  Theme,
} from '@material-ui/core';
import { useFormContext, useWatch } from 'react-hook-form';
import { debounce } from 'lodash';
import AmountInput from './AmountInput';
import { useDidUpdateEffect } from '../hooks/useDidUpdateEffect';

const useStyles = makeStyles<Theme, { marginTop: boolean }>((theme) => ({
  textField: {
    marginTop: theme.spacing(-0.5),
    marginBottom: theme.spacing(2),
    width: '150px',
  },
}));

type SliderInputProps = {
  name: string;
  label: string;
  unit: string;
  step: number;
  min: number;
  max: number;
  marginTop?: boolean;
};

const SliderInput = ({ name, label, unit, step, min, max, marginTop = false }: SliderInputProps) => {
  const classes = useStyles({ marginTop });
  const { setValue: setFormValue, getValues: getFormValue, control } = useFormContext();
  const [value, setValue] = useState<[number, number]>(getFormValue(name));
  const formValue = useWatch({ name, control });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setFormValueDebounced = useCallback(debounce(setFormValue, 400), [setFormValue]);

  // populate internal state updates
  useDidUpdateEffect(() => {
    setFormValueDebounced(name, value);

    return () => {
      setFormValueDebounced.cancel();
    };
  }, [value, name, setFormValueDebounced]);

  // sync external state updates
  useDidUpdateEffect(() => {
    setValue(formValue);
  }, [setValue, formValue]);

  return (
    <div>
      <FormLabel id={name} className={classes.label}>
        {label}
      </FormLabel>
      <Slider
        min={min}
        max={max}
        value={value}
        step={step}
        aria-labelledby={name}
        onChange={(_, changeValue) => {
          setValue(changeValue as [number, number]);
        }}
        color="secondary"
      />
      <Grid container justifyContent="space-between">
        <Grid item>
          <AmountInput<TextFieldProps>
            className={classes.textField}
            customInput={TextField}
            inputProps={{
              step,
              min,
              max,
              'aria-labelledby': name,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
            }}
            margin="dense"
            value={value[0]}
            variant="outlined"
            onValueChange={({ value: unformattedValue }) => {
              setValue((prev: [number, number]) => [parseInt(unformattedValue, 10), prev[1]]);
            }}
            isAllowed={({ value: unformattedValue }) => !unformattedValue || parseInt(unformattedValue, 10) <= max}
            onBlur={(event) => {
              if (event.target.value === '') {
                setValue((prev) => [min, prev[1]]);
              }
            }}
          />
        </Grid>
        <Grid item>
          <AmountInput<TextFieldProps>
            className={classes.textField}
            customInput={TextField}
            inputProps={{
              step,
              min,
              max,
              'aria-labelledby': name,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
            }}
            margin="dense"
            variant="outlined"
            value={value[1]}
            onValueChange={({ value: unformattedValue }) => {
              setValue((prev: [number, number]) => [prev[0], parseInt(unformattedValue, 10)]);
            }}
            isAllowed={({ value: unformattedValue }) => !unformattedValue || parseInt(unformattedValue, 10) <= max}
            onBlur={(event) => {
              if (event.target.value === '') {
                setValue((prev) => [prev[0], max]);
              }
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(SliderInput);
