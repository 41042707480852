import { Chip, Container, Grid, Typography, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import ActiveBidsFilterChips from './filter/ActiveBidFilterChips';
import ActiveFilterChips from './filter/ActiveFilterChips';
import { useBidsFilter } from './filter/useBidsFilter';
import { TableHeader } from './table';
import { BidTypes, CarsTableNextInstance } from './types';

type HeaderProps = {
  // TODO: access via table ctx?
  isLoading?: boolean;
  table: CarsTableNextInstance;
  numbBids?: number;
  numbCars?: number;
  bidType?: BidTypes;
};

const useStyles = makeStyles((theme) => ({
  hideButton: {
    marginTop: theme.spacing(1.5),
    zIndex: 3,
    textTransform: 'none',
  },
  resetChip: {
    '& span': {
      marginBottom: '-3px',
    },
  },
  gridSpacing: {
    '& div': { padding: '2px' },
  },
}));

export const BidsTableHeader = ({ isLoading, table, numbBids, numbCars, bidType }: HeaderProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { watch, setFilter, defaultFilter, reset: resetFilter } = useBidsFilter();

  const getHeader = () => {
    switch (bidType) {
      case BidTypes.Pending:
        return t('bids.pendingBids_plural');
      case BidTypes.Past:
        return t('bids.sentBids_plural');
      case BidTypes.Orders:
        return t('bids.boughtCars');
      default:
        return t('carsTableNext.header.headline');
    }
  };

  return (
    <TableHeader
      style={{
        paddingInlineStart: 48, // add spacing for sidebar toggle
        paddingBlockStart: 24, // balance spacing from Carculator logo
        borderBottom: 'none',
      }}
      // @ts-expect-error
      title={
        <>
          {getHeader()}{' '}
          <Chip
            size="small"
            color="primary"
            label={
              isLoading
                ? t('carsTableNext.header.isLoading')
                : `${t('common.bidsWithCount', { count: numbBids })} (${t('common.carsWithCount', {
                    count: numbCars,
                  })})`
            }
          />
        </>
      }
      endAdornment={
        <Container disableGutters maxWidth="lg" style={{ margin: 0 }}>
          <Typography
            variant="caption"
            component="div"
            style={{
              marginBlockStart: 4,
              fontWeight: 'bold',
            }}
            gutterBottom
          >
            {t('carsTableNext.header.activeFilters')}
          </Typography>
          <Grid container className={classes.gridSpacing} alignItems="center">
            <ActiveBidsFilterChips
              watch={watch}
              defaultFilter={defaultFilter}
              setFilter={setFilter}
              bidType={bidType}
            />
            <ActiveFilterChips
              watch={watch}
              defaultFilter={defaultFilter.carsRequest}
              setFilter={setFilter}
              prefix="carsRequest."
            />
            <Grid item>
              <Chip
                onClick={resetFilter}
                avatar={<CloseIcon fontSize="small" />}
                label={t('carsTableNext.noDataFallback.actions.reset')}
                size="small"
                className={classes.resetChip}
              />
            </Grid>
          </Grid>
        </Container>
      }
    />
  );
};
