import { BidDTO, ListBidDTO } from '../modules/generated/api';
import BidSuccessStatusChip from './BidSuccessStatusChip';

type CarsTableBidsCellProps = {
  bid: BidDTO | ListBidDTO;
};

const CarsTableBidsCell = ({ bid }: CarsTableBidsCellProps) => {
  if (bid.successStatus)
    return <BidSuccessStatusChip successStatus={bid.successStatus} errorMessage={bid.errorMessage} />;
  return null;
};

export default CarsTableBidsCell;
