import React, { ReactNode } from 'react';
import { Stack } from '@components/ui';
import { makeStyles, Typography } from '@material-ui/core';
import { isString } from 'lodash';
import { useTranslation } from 'react-i18next';

type ServicePacketGroupProps = {
  children?: ReactNode;
  label: ReactNode;
};

const useStyles = makeStyles((theme) => ({
  label: {
    marginBlockEnd: theme.spacing(1),
  },
}));

export const ServicePacketGroup = ({ children, label }: ServicePacketGroupProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div>
      <Typography variant="overline" className={classes.label}>
        {isString(label) ? t(label as any) : label}
      </Typography>
      <Stack spacing={1}>{children}</Stack>
    </div>
  );
};
