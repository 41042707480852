import React, { ReactNode } from 'react';
import { Chip, makeStyles, ChipProps } from '@material-ui/core';
import type { Merge } from 'type-fest';
import clsx from 'clsx';

export type BadgeGroupProps = {
  primary: ReactNode;
  label: ReactNode;
  component?: keyof JSX.IntrinsicElements;
};

const useStyles = makeStyles({
  root: {
    background: '#FFF7F2',
    color: '#EE590D',
    fontWeight: 'bold',
  },
  badge: {
    background: '#EE590D',
    color: '#fff',
  },
  link: {
    cursor: 'pointer',
  },
});

export const BadgeGroup = ({ primary, label, component, ...rest }: Merge<ChipProps, BadgeGroupProps>): JSX.Element => {
  const classes = useStyles();

  return (
    <Chip
      className={clsx(classes.root, (component === 'a' || component === 'button') && classes.link)}
      icon={<Chip className={classes.badge} label={primary} size="small" />}
      label={label}
      // @ts-expect-error
      component={component}
      {...rest}
    />
  );
};
