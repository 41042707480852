import React from 'react';
import { PortableTextTypeComponent } from '@portabletext/react';
import { tryGetFileAsset } from '@sanity/asset-utils';
import { client } from '@iris/content';
import { Media } from './Media';

export const Video: PortableTextTypeComponent<any> = ({ value }) => {
  const asset = tryGetFileAsset(value.asset, client.config());

  if (!asset?.url && process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.warn(`Invalid video Asset!`, value);
    return null;
  }

  return (
    <Media>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video width="100%" muted controls src={asset.url} />
    </Media>
  );
};
