import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { QueryKeys } from 'src/constants';
import ApiService from 'src/modules/api-service';
import { DeliveryAddressDTO, SetTransportationDTO, SourceType } from 'src/modules/generated/api';
import useCustomSnackbarGlobal from './useSnackbarGlobal';

export const useGetTransportOptions = (
  source: SourceType,
  carId: string,
  targetDeliveryAdressId: string,
  kvps: string,
) => {
  const query = useQuery([QueryKeys.transportOptionsCar, source, carId, targetDeliveryAdressId, kvps], () =>
    ApiService.transportationController
      .transportationControllerGetTransportOptionsForCar(source, carId, targetDeliveryAdressId, kvps)
      .then((res) => res.data),
  );

  return {
    ...query,
    isLoading: query.isLoading,
  };
};

export const useGetSelectedTransportOption = (bidId: string) =>
  useQuery([QueryKeys.transportOptionBid, bidId], () =>
    ApiService.transportationController.transportationControllerGetAppliedTransportation(bidId).then((res) => res.data),
  );
export const useBidDeliveryAddress = (bidId: string) => {
  const { t } = useTranslation();
  const { showSuccess, showError } = useCustomSnackbarGlobal();
  const queryClient = useQueryClient();

  return useMutation(
    (deliveryAddressId: string) =>
      ApiService.newBids.bidNewControllerSaveDeliveryAddress(bidId, deliveryAddressId).then((res) => res.data),
    {
      onSuccess: () => showSuccess(t('alerts.successSaved')),
      onError: () => showError(t('alerts.errorRaised')),
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.carDetail]);
      },
    },
  );
};

export const useSetTransportInfo = (bidId: string) => {
  const { t } = useTranslation();
  const { showSuccess, showError } = useCustomSnackbarGlobal();
  const queryClient = useQueryClient();

  // write the correct mutation here
  return useMutation(
    (option: SetTransportationDTO) =>
      ApiService.transportationController
        .transportationControllerApplyTransportation(bidId, option)
        .then((res) => res.data),
    {
      onSuccess: () => showSuccess(t('alerts.successSaved')),
      onError: () => showError(t('alerts.errorRaised')),
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.carDetail]);
      },
    },
  );
};

export const useSetSourceAddress = (bidId: string) => {
  const { t } = useTranslation();
  const { showSuccess, showError } = useCustomSnackbarGlobal();
  const queryClient = useQueryClient();

  return useMutation(
    (address: DeliveryAddressDTO) =>
      ApiService.transportationController
        .transportationControllerApplyTransportationWithUpdatedSourceAddress(bidId, address)
        .then((res) => res.data),
    {
      onSuccess: () => showSuccess(t('alerts.successSaved')),
      onError: () => showError(t('alerts.errorRaised')),
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.carDetail, QueryKeys.transportOptionBid]);
      },
    },
  );
};
