import React, { ComponentProps } from 'react';
import { Avatar, makeStyles, AvatarProps, Tooltip } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import { grey } from '@material-ui/core/colors';
import { ContentCell } from '@components/cars-table-next/cells/ContentCell';
import type { Merge } from 'type-fest';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

type AvatarGroupCellProps = {
  items: AvatarItem[];
  max?: number;
  AvatarProps?: AvatarProps;
};

type AvatarItem = Merge<
  AvatarProps,
  {
    key: string;
    helpText?: string;
  }
>;

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  avatarGroupRoot: {
    zIndex: 0,
    flexWrap: 'wrap',
    gap: 2,
  },
  avatar: {
    width: 24,
    height: 24,
    background: '#fff',
    borderColor: grey[600],
    color: grey[600],
    borderWidth: 1,
    margin: 0,
    borderRadius: 4,
  },
  more: {
    fontSize: 11,
    color: grey[700],
    float: 'right',
    padding: 4,
    borderColor: 'transparent',
  },
});

export const getAvatarCount = (columnSize: number, rows = 1): number => {
  const paddingX = 2 * 12;
  const gap = 2;
  const finalColumnSize = columnSize - paddingX - 1;
  const itemSize = 24;
  const n = (finalColumnSize + gap) / (itemSize + gap); // based on: w = n * itemSize + gutter * (n-1)
  return Math.max(0, Math.floor(n) * rows);
};

/**
 * Display dense visual information such as avatars or icons.
 */
export const AvatarGroupCell = ({
  items,
  max = 10,
  AvatarProps: avatarProps,
  className,
  ...rest
}: Merge<ComponentProps<'div'>, AvatarGroupCellProps>): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();
  const placeholder = items.length > max ? 1 : 0;

  return (
    <ContentCell className={clsx(classes.root, className)} {...rest}>
      <AvatarGroup max={Infinity} classes={{ avatar: classes.avatar, root: classes.avatarGroupRoot }}>
        {items.slice(0, max - placeholder).map(({ key, alt, helpText, ...item }) => (
          <Tooltip arrow key={key} title={t(helpText as any) ?? ''}>
            <Avatar alt={alt} {...avatarProps} {...item} />
          </Tooltip>
        ))}
        {items.length > max && <span className={classes.more}>+{items.length - max + 1}</span>}
      </AvatarGroup>
    </ContentCell>
  );
};
