import React, { useRef, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { ButtonBase, Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Address } from '@components/ui/address/types';
import { AddressDrawer, FormInstance } from './AddressDrawer';
import { DELIVERY_ADDRESS_VALIDATION_MSG } from '../../../modules/data';

type NewAddressButtonProps = {
  onSubmit: (newAddress: Address) => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    placeItems: 'center',
    textAlign: 'center',
    border: '1px dashed',
    borderColor: theme.palette.grey[400],
    background: theme.palette.common.white,

    // button reset
    borderRadius: 4,
    textTransform: 'uppercase',
  },
}));

export const NewAddressButton = ({ onSubmit }: NewAddressButtonProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const form = useRef<FormInstance>();

  const handleSubmitDrawer = (newAddress: Address) => {
    onSubmit(newAddress);
    form.current?.reset();
    setDrawerOpen(false);
  };

  return (
    <>
      <Card component={ButtonBase} className={classes.root} onClick={() => setDrawerOpen(true)}>
        <CardContent>
          <AddIcon fontSize="large" />
          <Typography>{t('action.action_noun', { action: t('action.add'), noun: t('contact.address') })}</Typography>
        </CardContent>
      </Card>

      <AddressDrawer
        title={t('action.action_noun', {
          action: t('action.add'),
          noun: t('contact.address'),
        })}
        open={drawerOpen}
        onSubmit={handleSubmitDrawer}
        onClose={() => setDrawerOpen(false)}
        validationRules={DELIVERY_ADDRESS_VALIDATION_MSG}
        form={form}
      />
    </>
  );
};
