import { MenuItem, Select } from '@material-ui/core';
import { Public } from '@material-ui/icons';
import { get } from 'lodash';
import { Control, Controller, RegisterOptions, SetFieldValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DeliveryAddressDTO } from '../../modules/generated/api';
import { formatDeliveryAddress } from '../../modules/labels';

type DeliveryClassSelectProps = {
  control: Control<any>;
  name: string;
  disabled?: boolean;
  deliveryAddresses?: DeliveryAddressDTO[];
  className?: string;
  rules?: RegisterOptions;
  fullWidth?: boolean;
  setValue?: SetFieldValue<any>;
};
const DeliveryAddressSelect = ({
  control,
  name,
  deliveryAddresses = [],
  className,
  disabled,
  rules,
  fullWidth = false,
  setValue,
}: DeliveryClassSelectProps) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { ref, onChange, ...field }, formState: { errors } }) => (
        <Select
          fullWidth={fullWidth}
          className={className}
          label={null}
          error={get(errors, name)}
          variant="standard"
          disabled={disabled}
          inputRef={ref}
          {...field}
          value={field.value || 'empty'}
          onChange={(e) => {
            onChange(e.target.value);
            if (setValue) setValue('selectedTransportOption', '');
          }}
        >
          <MenuItem value="empty" disabled>
            {t('common.chooseOption', { option: t('navigation.delivery') })}
          </MenuItem>
          {deliveryAddresses.map((deliveryAddress) => (
            <MenuItem key={deliveryAddress.id} value={deliveryAddress.id}>
              <div style={{ display: 'flex', width: '100%', gap: 5, alignItems: 'center' }}>
                {formatDeliveryAddress(deliveryAddress)}
                {deliveryAddress.global && <Public color="primary" style={{ marginLeft: 'auto' }} fontSize="small" />}
              </div>
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};

export default DeliveryAddressSelect;
