import React from 'react';
import { InputAdornment, makeStyles } from '@material-ui/core';
import { Controller, useWatch } from 'react-hook-form';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import { DateFilter } from 'src/modules/generated/api';
import { DATE_TIME_FORMAT } from 'src/modules/date-helpers';
import { Select } from '@components/ui';
import { muiInputLabelProps } from '@components/cars-table-next/filter/constants';
import { DateTime } from 'luxon';
import { tr } from 'src/modules/i18n-helpers';

const useStyles = makeStyles(() => ({
  datePicker: {
    width: '100%',
  },
}));

type DateFilterRadiosProps = {
  dateType: string;
  control: any;
};

export const dateFilters = [
  { value: DateFilter.Last30Minutes, label: tr('carsTable.lastxOminutes', { count: 30 }) },
  { value: DateFilter.Last1Hour, label: tr('carsTable.lastxOhour', { count: 1 }) },
  { value: DateFilter.Last3Hour, label: tr('carsTable.lastxOhour', { count: 3 }) },
  { value: DateFilter.Last6Hour, label: tr('carsTable.lastxOhour', { count: 6 }) },
  { value: DateFilter.Individual, label: tr('configuration.individual') },
];

export const DateTimeFilterSelect = ({ dateType, control }: DateFilterRadiosProps) => {
  const classes = useStyles();
  const value = useWatch({ name: dateType === 'end' ? 'endDateFilter' : 'startDateFilter', control });
  const { t } = useTranslation();

  return (
    <>
      {dateType === 'end' ? (
        <Controller
          name="endDateFilter"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <Select
              inputRef={ref}
              {...field}
              label="carsTable.endingTime"
              clearable
              options={dateFilters}
              InputLabelProps={muiInputLabelProps}
            />
          )}
        />
      ) : (
        <Controller
          name="startDateFilter"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <Select
              inputRef={ref}
              {...field}
              label="carsTable.startingTime"
              clearable
              options={dateFilters}
              InputLabelProps={muiInputLabelProps}
            />
          )}
        />
      )}

      {value === DateFilter.Individual && (
        <>
          <Controller
            name={dateType === 'end' ? 'endDateFrom' : 'startDateFrom'}
            control={control}
            defaultValue={DateTime.now().startOf('day').toISO()}
            render={({ field: { onChange, ref, ...field } }) => (
              <KeyboardDateTimePicker
                {...field}
                ampm={false}
                className={classes.datePicker}
                format={DATE_TIME_FORMAT}
                invalidDateMessage={t('carsTable.formError.dateTimeError')}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{t('common.from')}</InputAdornment>,
                  fullWidth: true,
                }}
                inputRef={ref}
                inputVariant="outlined"
                margin="dense"
                onChange={onChange}
                openTo="date"
                placeholder="TT.MM.JJ SS:MM"
              />
            )}
          />
          <Controller
            name={dateType === 'end' ? 'endDateTo' : 'startDateTo'}
            control={control}
            defaultValue={
              dateType === 'end'
                ? DateTime.now().endOf('day').toISO()
                : DateTime.now().plus({ hour: 1 }).startOf('hour').toISO()
            }
            render={({ field: { onChange, ref, ...field } }) => (
              <KeyboardDateTimePicker
                {...field}
                ampm={false}
                className={classes.datePicker}
                format={DATE_TIME_FORMAT}
                invalidDateMessage={t('carsTable.formError.dateTimeError')}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{t('common.to')}</InputAdornment>,
                  fullWidth: true,
                }}
                inputRef={ref}
                inputVariant="outlined"
                margin="dense"
                onChange={onChange}
                openTo="date"
                placeholder="TT.MM.JJ SS:MM"
              />
            )}
          />
        </>
      )}
    </>
  );
};
