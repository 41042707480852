import { makeStyles, Tooltip } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import clsx from 'clsx';
import { isNil } from 'lodash';
import { Fragment, memo, ReactNode } from 'react';
import { useCurrency } from '../hooks/useCurrency';
import { isSupportedCurrencyCode } from '../modules/currency';
import { CurrencyCode } from '../modules/generated/api';
import { formatUnitValue } from '../modules/unit-helpers';

const useStyles = makeStyles({
  color: {
    '&--highlight': {
      color: green[200],
    },
    '&--green': {
      color: green[300],
    },
    '&--red': {
      color: red[400],
    },
  },
  root: {
    whiteSpace: 'nowrap',
  },
});

export type UnitValueProps = {
  alt?: ReactNode;
  className?: string;
  prefix?: string;
  tooltip?: string;
  unit?: string;
  value?: number;
  minFractionDigits?: number;
  maxFractionDigits?: number;
};

const UnitValue = ({ alt = "", className, prefix, tooltip = '', unit = '', value, minFractionDigits = 0,
  maxFractionDigits = 0, ...rest }: UnitValueProps) => {
  const classes = useStyles();
  const { format, convert, currency } = useCurrency();

  const formatOptions = {
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: maxFractionDigits,
  };

  return (
    <Fragment {...rest}>
      {!isNil(value) && isSupportedCurrencyCode(unit) && (
        <Tooltip title={currency !== CurrencyCode.Eur ? format(value, { to: CurrencyCode.Eur, ...formatOptions }) : ''}>
          <span>
            {prefix && `${prefix} `}
            {format(convert(value, { from: unit }), formatOptions)}
          </span>
        </Tooltip>
      )}

      {!isNil(value) && !isSupportedCurrencyCode(unit) && (
        <Tooltip title={tooltip}>
          <span className={clsx(className && `${classes.color}--${className}`, classes.root)}>
            {prefix && `${prefix} `}
            {formatUnitValue(value, unit)}
            {!['%', ''].includes(unit) && ` ${unit}`}
          </span>
        </Tooltip>
      )}
      {isNil(value) && alt}
    </Fragment>
  );
};

export default memo(UnitValue);