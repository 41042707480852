import React from 'react';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import DealerRow from '@components/dealer-table/DealerRow';
import NewDealerRow from '@components/dealer-table/NewDealerRow';
import { useTranslation } from 'react-i18next';
import useCustomSnackbarGlobal from '../../hooks/useSnackbarGlobal';
import { useDealerCreds } from '../../hooks/useDealerCreds';
import { PlatformCredentialsMetadataDTO, SourceType, WizardStep } from '../../modules/generated/api';
import { useWizard } from '../../hooks/useWizard';
import { useAuthenticationStore } from '../../stores/Authentication';
import { useMyDealer } from '../../hooks/useDealer';
import { setToArray } from '../../modules/util';

const useStyles = makeStyles({
  select: {
    width: 150,
    maxWidth: 150,

    '& .MuiOutlinedInput-notchedOutline legend': {
      width: 0,
    },
  },
});

type DealerTableProps = {
  showInWizard?: boolean;
};

export const DealerTable = ({ showInWizard }: DealerTableProps): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const snackbar = useCustomSnackbarGlobal();
  const { credentialList, add, remove, update } = useDealerCreds();
  const { data: myDealer } = useMyDealer();
  const { useTrackWizardConfirmationOnPageView } = useWizard();
  const { state: authState } = useAuthenticationStore();
  const displayPostalCode = authState.objectToken?.country === 'DE';

  const handleClickDelete = async (id: string) => {
    try {
      const removedCred = await remove(id);
      snackbar.showSuccess(t('dealerTable.success.delete', { username: removedCred?.username || 'UNKNOWN' }));
    } catch (error) {
      if (error instanceof Error) {
        snackbar.showError(error.message);
      }
    }
  };

  const handleClickSave = async (
    id: string,
    username: string,
    password: string,
    metadata: PlatformCredentialsMetadataDTO,
  ) => {
    try {
      await update(id, username, password, metadata);
      snackbar.showSuccess(t('dealerTable.success.edit', { username }));
    } catch (error) {
      if (error instanceof Error) {
        snackbar.showError(error.message);
      }
    }
  };

  const handleCreateNewDealer = async (
    username: string,
    password: string,
    metadata: PlatformCredentialsMetadataDTO,
    source: SourceType,
  ) => {
    try {
      await add(username, password, metadata, source);
      snackbar.showSuccess(t('dealerTable.success.create', { username }));
    } catch (error) {
      if (error instanceof Error) {
        snackbar.showError(error.message);
      }
    }
  };

  useTrackWizardConfirmationOnPageView(WizardStep.DealerAdministration, showInWizard);

  return (
    <TableContainer component={Paper}>
      <Table aria-label={t('dealerTable.title')}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="body2">{t('common.username')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{t('contact.displayName')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{t('common.password')}</Typography>
            </TableCell>
            {displayPostalCode && (
              <TableCell>
                <Typography variant="body2">{t('contact.postal')}</Typography>
              </TableCell>
            )}
            <TableCell>
              <Typography variant="body2">{t('common.source')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{t('navigation.delivery')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{t('common.status')}</Typography>
            </TableCell>

            <TableCell>{/* action placeholder */}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {credentialList.map((cred) => (
            <DealerRow
              key={cred.id}
              onDelete={handleClickDelete}
              onSave={handleClickSave}
              displayPostalCode={displayPostalCode}
              deliveryAddresses={setToArray(myDealer?.deliveryAddresses)}
              classes={{ select: classes.select }}
              deliveryAddress={setToArray(myDealer?.deliveryAddressesForNewCredentials).find(
                (address) => address.id === cred.metadata.defaultDeliveryAddressId,
              )}
              {...cred}
            />
          ))}

          <NewDealerRow
            onCreate={handleCreateNewDealer}
            displayPostalCode={displayPostalCode}
            deliveryAddresses={setToArray(myDealer?.deliveryAddressesForNewCredentials)}
            classes={{ select: classes.select }}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};
