import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { QueryKeys } from 'src/constants';
import useCustomSnackbarGlobal from 'src/hooks/useSnackbarGlobal';
import ApiService from 'src/modules/api-service';
import { createStrictPacket, patchPacket } from './lib';
import { ServicePacket } from './types';

const createPacket = (packet: ServicePacket): Promise<ServicePacket> =>
  ApiService.packet.packetControllerCreatePacket(patchPacket(packet)).then((res) => createStrictPacket(res.data));

export const usePacketCreate = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { showError, showSuccess } = useCustomSnackbarGlobal();

  const mutation = useMutation(createPacket, {
    onMutate: async (packet) => {
      await queryClient.cancelQueries([QueryKeys.servicePackets]);
      const prevList = queryClient.getQueryData<ServicePacket[] | undefined>([QueryKeys.servicePackets]);

      queryClient.setQueryData<ServicePacket[] | undefined>([QueryKeys.servicePackets], (prev = []) => [
        ...prev,
        packet,
      ]);

      return { prevList };
    },
    onError: (_error, _variables, snapshot) => {
      queryClient.setQueryData<ServicePacket[] | undefined>([QueryKeys.servicePackets], snapshot?.prevList ?? []);
      showError(t('servicePacketItem.actions.create.error'));
    },
    onSuccess: (packet) => {
      queryClient.setQueryData<ServicePacket>([QueryKeys.servicePacket, packet.id], packet);
      showSuccess(
        t('servicePacketItem.actions.create.success', {
          packetName: packet.name,
        }),
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.servicePackets]);
    },
  });

  return mutation;
};
