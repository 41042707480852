import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  makeStyles,
  Typography,
  Card,
} from '@material-ui/core';
import { ArrowDropDown, ExpandMore as ExpandMoreIcon, Save } from '@material-ui/icons';
import type { AxiosResponse } from 'axios';
import { RouteComponentProps } from '@reach/router';
import PageContainer from '@components/PageContainer';
import useApi from 'src/hooks/useApi';
import ApiService from 'src/modules/api-service';
import { DeliveryAddressDTO, DetailingAddressDTO, SourceType, ValuationCountryCode } from 'src/modules/generated/api';
import useCustomSnackbarGlobal from 'src/hooks/useSnackbarGlobal';
import { getLabel, sources } from 'src/modules/labels';
import { DetailingAddressLocalizedFields } from '@components/DetailingAddressFieldsLocalized';
import { AddressFields } from '@components/AddressFields';
import { PageMeta } from '@components/page-meta';

type DetailingAddressFormData = {
  [k in SourceType | 'default']?: k extends 'default'
    ? DeliveryAddressDTO
    : {
        [u in ValuationCountryCode | 'default']?: Partial<DeliveryAddressDTO>;
      };
};

const useStyles = makeStyles((theme) => ({
  headline: {
    marginBlockEnd: theme.spacing(4),
    hyphens: 'auto',
  },
  card: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
}));

const dtoToFormDataTranslator = (input: DetailingAddressDTO): DetailingAddressFormData => ({
  default: input.fallback,
  ...Object.fromEntries(
    Object.entries(input.perSource || {}).map(([source, detailingAddressPerSource]) => [
      source,
      {
        default: detailingAddressPerSource.fallback,
        ...detailingAddressPerSource.perCountry,
      },
    ]),
  ),
});

const formDataToDtoTranslator = ({ default: d, ...s }: DetailingAddressFormData): DetailingAddressDTO => ({
  fallback: d!,
  perSource: Object.fromEntries(
    Object.entries(s).map(([source, { default: d2, ...rest }]) => [source, { fallback: d2, perCountry: { ...rest } }]),
  ),
});

const DetailingAddressPage = (_props: RouteComponentProps) => {
  const classes = useStyles();
  const { fetch } = useApi<DetailingAddressDTO>();
  const form = useForm<DetailingAddressFormData>();
  const { handleSubmit, reset } = form;
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const snackbar = useCustomSnackbarGlobal();

  const handleFormSubmit = handleSubmit(async (data) => {
    const payload = formDataToDtoTranslator(data);

    try {
      await fetch(ApiService.config.configControllerSaveDetailingAddress(payload));
      snackbar.showSuccess(t('alerts.successSaved'));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      snackbar.showError(t('alerts.editError'));
    }
  });

  useEffect(() => {
    const getInitialData = async () => {
      let res: AxiosResponse<DetailingAddressDTO> | undefined;

      try {
        res = await fetch(ApiService.config.configControllerGetDetailingAddress());
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        throw Error(t('alerts.errorRaised'));
      }

      if (!res?.data) {
        throw Error(t('alerts.errorRaised'));
      }

      let defaultValues: DetailingAddressFormData = {};

      try {
        defaultValues = dtoToFormDataTranslator(res.data);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        throw Error(t('alerts.errorRaised'));
      }

      return defaultValues;
    };

    getInitialData()
      .then((initialData) => reset(initialData))
      .catch((e) => {
        if (e.message) {
          snackbar.showError(e.message);
        }
      });
  }, [fetch, reset, snackbar, t]);

  return (
    <>
      <PageMeta title="navigation.detailing" />
      <PageContainer>
        <Typography variant="h1" className={classes.headline}>
          {t('navigation.detailing')}
        </Typography>

        <FormProvider {...form}>
          <form onSubmit={handleFormSubmit}>
            <Grid container spacing={4}>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Card className={classes.card}>
                    <AddressFields source="default" />
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  {showMore ? (
                    Object.values(SourceType).map((source) => (
                      <Accordion key={source}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel-${source}-content`}
                          id={`panel-${source}-header`}
                        >
                          <Typography variant="h6">{getLabel(sources, source)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <DetailingAddressLocalizedFields source={source} />
                        </AccordionDetails>
                      </Accordion>
                    ))
                  ) : (
                    <Button
                      color="secondary"
                      variant="text"
                      type="button"
                      endIcon={<ArrowDropDown />}
                      onClick={() => setShowMore(true)}
                    >
                      {t('common.showMore')}
                    </Button>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Button color="primary" endIcon={<Save />} type="submit" variant="contained">
                  {t('common.save')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </PageContainer>
    </>
  );
};

export default DetailingAddressPage;
