import React, { useEffect } from 'react';
import { Button, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DialogWithClose from 'src/components/DialogWithClose';
import { useLocalStorage } from 'src/hooks/useLocalStorage';

type AutoRefreshDialogProps = {
  active: boolean;
  open: boolean;
  onClose: () => void;
  onSubmit: (data: TaskFormData) => void;
  onCancel: () => void;
};

export type TaskFormData = {
  interval: number; // seconds
};

const useStyles = makeStyles((theme) => ({
  inputs: {
    marginBlockEnd: theme.spacing(2),
  },
  controls: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  description: {
    marginBlockEnd: theme.spacing(6),
  },
}));

const MAX_DELAY_MS = Math.floor((2 ** 31 - 1) / 1000);

const validateInterval = (value: any) => {
  const isValid = Number.isFinite(parseInt(value, 10));
  return isValid;
};

export const AutoRefreshDialog = ({
  active,
  open,
  onClose,
  onSubmit,
  onCancel,
}: AutoRefreshDialogProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [defaultValues, setDefaultValues] = useLocalStorage<TaskFormData>('gwscout/AutoRefreshDialog.form', {
    interval: 5,
  });
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({ defaultValues });

  // persist form values
  useEffect(() => {
    const subscription = watch((data) => setDefaultValues(data));
    return () => {
      subscription.unsubscribe();
    };
  }, [setDefaultValues, watch]);

  return (
    <DialogWithClose open={open} onClose={onClose} title={t('autoRefresh.title')} maxWidth="sm">
      <Typography variant="body2" className={classes.description}>
        {t('autoRefresh.description')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.inputs}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="interval"
                rules={{
                  min: 1,
                  max: MAX_DELAY_MS,
                  required: true,
                  validate: validateInterval,
                }}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    label={t('autoRefresh.form.fields.interval.label')}
                    size="small"
                    variant="outlined"
                    error={!!errors.interval}
                    helperText={
                      errors.interval &&
                      t(`autoRefresh.form.fields.interval.errors.${errors.interval.type}` as any, {
                        min: 1,
                        max: MAX_DELAY_MS,
                      })
                    }
                    inputRef={ref}
                    disabled={active}
                    fullWidth
                    inputProps={{ type: 'number' }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption">{t('autoRefresh.form.fields.interval.description')}</Typography>
            </Grid>
          </Grid>
        </div>

        <div className={classes.controls}>
          {active ? (
            <Button variant="contained" size="small" color="primary" onClick={onCancel}>
              {t('autoRefresh.stopBtn')}
            </Button>
          ) : (
            <Button type="submit" variant="contained" size="small" disabled={active}>
              {t('autoRefresh.startBtn')}
            </Button>
          )}
        </div>
      </form>
    </DialogWithClose>
  );
};
