import { CalendarToday } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { formatDate, REGISTRATION_DATE_FORMAT } from '../modules/date-helpers';
import IconValue from './IconValue';

type CarDetailTileFirstRegProps = {
  firstRegistration?: string;
  constructionYear?: number;
};

const CarDetailTileFirstReg = ({ firstRegistration, constructionYear }: CarDetailTileFirstRegProps) => {
  const { t } = useTranslation();
  return (
    <IconValue
      icon={CalendarToday}
      label={t('car.firstRegistration')}
      padding
      hint={constructionYear && `${t('car.constructionYear')}: ${constructionYear}`}
    >
      {formatDate(firstRegistration || '', REGISTRATION_DATE_FORMAT)}
    </IconValue>
  );
};

export default CarDetailTileFirstReg;
