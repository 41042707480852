import PotentialAbsoluteFilterChip from './PotentialAbsoluteFilterChip';
import PotentialRelativeFilterChip from './PotentialRelativeFilterChip';

type PotentialChipProps = {
  value: any;
  name: string;
};

const PotentialFilterChip = ({ value, name }: PotentialChipProps) => (
  <>
    <PotentialAbsoluteFilterChip value={value} name={name} />
    <PotentialRelativeFilterChip value={value} name={name} />
  </>
);

export default PotentialFilterChip;
