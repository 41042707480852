import React, { memo } from 'react';
import { isSupportedCurrencyCode } from '../modules/currency';
import { ListCarDTO, PriceType, SourceRegisterPotentialDTO } from '../modules/generated/api';
import MultiLineValues, { LineValue } from './MultiLineValues';

type CarsPotentialProps = {
  fixedVariable: PriceType;
  targetKey: string;
  prefixes?: string[];
  car: ListCarDTO;
  unit?: string;
};

const CarsPotentialValues = ({
  car,
  fixedVariable,
  targetKey,
  unit,
  prefixes = ['', '', '', ''],
}: CarsPotentialProps) => {
  const { potential } = car;

  const values: LineValue[] = ['0', '1'].map((key, idx) => {
    const value = potential ? potential[targetKey as keyof SourceRegisterPotentialDTO] : undefined;
    const isCurrency = (value as any)?.currency && isSupportedCurrencyCode((value as any).currency);
    return {
      prefix: prefixes[idx],
      unit: isCurrency ? (value as any).currency : unit,
      key,
      // eslint-disable-next-line no-nested-ternary
      value: typeof value === 'object' ? (value.amount as number) : typeof value === 'number' ? value : undefined,
    };
  });

  return <MultiLineValues targetKey={targetKey} car={car} fixedVariable={fixedVariable} values={values} />;
};

export default memo(CarsPotentialValues);
