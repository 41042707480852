import React, { Fragment, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Dialog, DialogContent, DialogTitle, Grid, makeStyles, Typography } from '@material-ui/core';
import { Cancel, FiberManualRecord, Public, Save } from '@material-ui/icons';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { green } from '@material-ui/core/colors';
import { isEmpty } from 'lodash';
import useApi from '../hooks/useApi';
import ApiService from '../modules/api-service';
import { CostsDTO, SourceType, WizardStep } from '../modules/generated/api';
import SourceConfiguration from '../components/SourceConfiguration';
import { getLabel, sources } from '../modules/labels';
import useRole from '../hooks/useRole';
import UserRole from '../types/UserRoles';
import useCustomSnackbarGlobal from '../hooks/useSnackbarGlobal';
import { SUPPORTED_COUNTRIES } from '../modules/valuation-country-helpers';
import CountrySourceCosts from '../components/CountrySourceCosts';
import BooleanCheckbox from '../components/BooleanCheckbox';
import { useWizard } from '../hooks/useWizard';

const useStyles = makeStyles((theme) => ({
  btn: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  btnActive: {
    backgroundColor: green[500],
  },
  btnFlex: {
    display: 'flex',
  },
  btnGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      marginRight: theme.spacing(0.5),
      marginTop: theme.spacing(2),
    },
  },
  divider: {
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
  headline: {
    marginBottom: theme.spacing(2),
  },
  line: {
    display: 'flex',
    alignItems: 'center',
  },
  overlayCircle: {
    position: 'relative',
    top: '-8px',
    right: '15px',
    color: theme.palette.secondary.main,
  },
  overlayNumber: {
    position: 'relative',
    fontWeight: 'bold',
    color: 'white',
    top: '-4px',
    left: '-31px',
    width: 'fit-content',
    fontSize: '13px',
  },
  tos: {
    marginBlockStart: theme.spacing(3),
    marginBlockEnd: theme.spacing(2),
  },
}));
type ConfigurationCostsFormProps = {
  showInWizard?: boolean;
};

const ConfigurationCostsForm = ({ showInWizard }: ConfigurationCostsFormProps) => {
  const classes = useStyles();
  const { fetch, data: configurations } = useApi<CostsDTO>();
  const { hasRole } = useRole();
  const methods = useForm();
  const [open, setOpen] = useState<undefined | SourceType>();
  const snackbar = useCustomSnackbarGlobal();
  const { useTrackWizardConfirmationOnPageView } = useWizard();

  const {
    setValue,
    reset,
    formState: { errors },
  } = methods;
  const { t } = useTranslation();
  const isAdmin = hasRole(UserRole.ADMIN);

  useEffect(() => {
    fetch(ApiService.dealer.dealerControllerGetCosts());
  }, [fetch]);

  useEffect(() => {
    if (configurations) {
      reset(configurations);
    }
  }, [configurations, reset]);

  const getNumber = (source: SourceType) => {
    const costs = configurations?.global?.[source]?.costsPerCountry;
    let counter = 0;
    if (costs) {
      Object.keys(costs).forEach((key) => {
        if (
          !isEmpty(costs[key]) ||
          (costs[key].contributionMarginPercent !== undefined && costs[key].costs !== undefined)
        )
          counter += 1;
      });
    }
    if (counter !== 0) return counter;
    return undefined;
  };

  const handleOnSubmit = async (data: { [x: string]: any }) => {
    const result = await fetch(ApiService.dealer.dealerControllerSaveCosts(data));
    if (result && result.data) {
      snackbar.showSuccess(t('alerts.successSaved'));
      setValue('tos.overview', false);
      setValue('tos.individual', false);
    } else {
      snackbar.showError(t('alerts.errorRaised'));
    }
  };

  useTrackWizardConfirmationOnPageView(WizardStep.Costs, showInWizard);

  return (
    <FormProvider {...methods}>
      <form
        id="costs-form"
        onSubmit={methods.handleSubmit((data) => {
          if (open !== undefined) setOpen(undefined);
          handleOnSubmit(data);
        })}
      >
        <Typography className={classes.headline} variant="h1">
          {isAdmin && t('configuration.individualCosts')} {t('configuration.costConfig')}
        </Typography>
        <Grid alignItems="center" container spacing={4}>
          <SourceConfiguration />
        </Grid>

        {isAdmin && (
          <>
            <Typography className={clsx(classes.divider, classes.headline)} variant="h1">
              {t('configuration.global')} {t('configuration.costConfig')}
            </Typography>
            {Object.values(SourceType).map((source) => (
              <Fragment key={source}>
                <Grid className={classes.line} container spacing={3} xs={12}>
                  <SourceConfiguration key={source} label={getLabel(sources, source)} prefix={`global.${source}`} />
                  <Grid className={classes.btnFlex} item xs={2}>
                    <div className={classes.btnFlex}>
                      <Button
                        className={clsx(classes.btn, getNumber(source) && classes.btnActive)}
                        color="secondary"
                        endIcon={<Public />}
                        fullWidth
                        onClick={() => setOpen(source)}
                        variant="contained"
                      >
                        {t('configuration.individualize')}
                      </Button>
                      {getNumber(source) && (
                        <>
                          <FiberManualRecord className={classes.overlayCircle} />
                          <div className={classes.overlayNumber}>{getNumber(source)}</div>
                        </>
                      )}
                    </div>
                  </Grid>
                </Grid>

                {open === source && (
                  <Dialog
                    onClose={() => {
                      setOpen(undefined);
                      if (methods.formState.isDirty) {
                        snackbar.showInfo(t('alerts.plsSave'));
                      }
                    }}
                    maxWidth="xl"
                    open={!!open}
                  >
                    <DialogTitle>
                      <Typography variant="h2"> {`${t('configuration.changeGlobalCosts')} ${source}:`}</Typography>
                    </DialogTitle>
                    <DialogContent>
                      {SUPPORTED_COUNTRIES.map((countryCode) => (
                        <Grid item key={countryCode} xs={12}>
                          <CountrySourceCosts countryLabel={countryCode} key={countryCode} source={source} />
                        </Grid>
                      ))}
                      <Grid item xs={11} className={classes.tos}>
                        <BooleanCheckbox
                          name="tos.individual"
                          label={t('configuration.tos')}
                          required={!!open}
                          error={!!errors.tos?.individual}
                        />
                      </Grid>
                      <Grid className={classes.btnGrid} item xs={11}>
                        <Button
                          color="secondary"
                          endIcon={<Cancel />}
                          onClick={() => {
                            reset(configurations);
                            setOpen(undefined);
                          }}
                          variant="contained"
                        >
                          {t('common.cancel')}
                        </Button>
                        <Button color="primary" endIcon={<Save />} form="costs-form" type="submit" variant="contained">
                          {t('common.save')}
                        </Button>
                      </Grid>
                    </DialogContent>
                  </Dialog>
                )}
              </Fragment>
            ))}
          </>
        )}

        <Grid container spacing={2}>
          {isAdmin && (
            <Grid item xs={12} className={classes.tos}>
              <BooleanCheckbox
                name="tos.overview"
                label={t('configuration.tos')}
                required={!open}
                error={!!errors.tos?.overview}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Button color="primary" endIcon={<Save />} type="submit" variant="contained">
              {t('common.save')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
export default ConfigurationCostsForm;
