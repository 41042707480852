import React from 'react';
import { RouteComponentProps } from '@reach/router';
import ConfigurationCostsForm from 'src/containers/ConfigurationCostsForm';
import PageContainer from '@components/PageContainer';
import { PageMeta } from '@components/page-meta';

const CostsPage = (_props: RouteComponentProps) => (
  <>
    <PageMeta title="configuration.costConfig" />
    <PageContainer>
      <ConfigurationCostsForm />
    </PageContainer>
  </>
);

export default CostsPage;
