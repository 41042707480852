import React, { memo } from 'react';
import UnitValue from './UnitValue';

export type PriceRangePerUnitProps = {
  minValue?: number;
  maxValue?: number;
  unit: string;
  className?: string;
};

const PriceRangePerUnit = ({ minValue, maxValue, unit, className }: PriceRangePerUnitProps) => (
  <>
    <UnitValue value={minValue} unit={unit} className={className} />
    {minValue !== maxValue && (
      <>
        <span> - </span>
        <UnitValue value={maxValue} unit={unit} />
      </>
    )}
  </>
);

export default memo(PriceRangePerUnit);
