import { Button, Grid, makeStyles, TableRow, Tooltip } from '@material-ui/core';
import { ArrowDropDown, EditLocation } from '@material-ui/icons';
import clsx from 'clsx';
import { isNil } from 'lodash';
import { Fragment, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { formatDate } from 'src/modules/date-helpers';
import useRole from '../hooks/useRole';
import { DetailCarDTO, SourceRegisterPotentialDTO, ValuationCountryCode } from '../modules/generated/api';
import { MAX_NUMBER_CHECKABLE } from '../modules/global-vars';
import { countryCodeToOptions, getLabel } from '../modules/labels';
import { getAccountValuationCountry, SUPPORTED_COUNTRIES } from '../modules/valuation-country-helpers';
import i18n from '../setup/i18n';
import UserRole from '../types/UserRoles';
import ActionCarCulatorLink from './ActionCarCulatorLink';
import CarDetailTableHeadCell from './CarDetailTableHeadCell';
import CarDetailTableUnitValueCell from './CarDetailTableUnitValueCell';
import { PurchaseGrossFallback } from './PurchaseGrossFallback';
import ReferenceVehicleButton from './ReferenceVehicleButton';
import UnitValue from './UnitValue';
import ValuationCountryIcon from './ValuationCountryIcon';
import ValuationCountrySwitcher from './ValuationCountrySwitcher';

const useStyles = makeStyles((theme) => ({
  printHide: {
    '@media print': {
      display: 'none',
    },
  },
  tableCellEnd: {
    justifyContent: 'flex-end',
  },
  carculatorCell: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  valuationCountryWrapper: {
    float: 'right',
    display: 'flex',
    alignItems: 'center',
  },
  checkBoxRootBtn: {
    fontSize: '0.75rem',
    paddingRight: 0,
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  highlightGross: {
    backgroundColor: '#F0F0F0',
    '& span': {
      fontWeight: 'bold',
    },
  },
  tooltip: {
    whiteSpace: 'pre-line',
  },
}));

type CarDetailCarPotentialProps = {
  car: DetailCarDTO;
  comparisonCountries: Set<ValuationCountryCode>;
  setCheckedCountries?: (checkedCountries: Set<ValuationCountryCode>) => void;
  countryPotentials?: {
    [key: string]: SourceRegisterPotentialDTO;
  };
};

const CarDetailCarPotentials = ({
  car,
  comparisonCountries,
  setCheckedCountries,
  countryPotentials,
}: CarDetailCarPotentialProps) => {
  const classes = useStyles();
  const { hasRole } = useRole();
  const { t } = useTranslation();
  const isAdmin = hasRole(UserRole.ADMIN);
  const isATValuationCountry = getAccountValuationCountry(true) === 'AT';
  const showCarCalculatorLink = isATValuationCountry || isAdmin;

  const currentValuationCountry = car.potential?.country;
  const comparingValuationCountriesArray = Array.from(comparisonCountries);

  return (
    <>
      <TableRow className={classes.printHide}>
        <CarDetailTableHeadCell>
          {isAdmin && (
            <ValuationCountrySwitcher
              rootButton={
                <Button size="small" variant="outlined" className={classes.checkBoxRootBtn}>
                  <EditLocation fontSize="small" className={classes.marginRight} />
                  {t('navigation.changeValuationCountry_plural')} (
                  {`${comparisonCountries.size}/${MAX_NUMBER_CHECKABLE}`})
                  <ArrowDropDown fontSize="small" />
                </Button>
              }
              disableValuationTypeSubmenu
              checkboxMode
              checkedCountries={comparisonCountries}
              setCheckedCountries={setCheckedCountries}
              maxNumberCheckable={MAX_NUMBER_CHECKABLE}
            />
          )}
          {!isAdmin && <Trans t={t} i18nKey="navigation.changeValuationCountry" />}
        </CarDetailTableHeadCell>
        <CarDetailTableUnitValueCell>
          <Grid container className={classes.tableCellEnd} spacing={1}>
            {comparingValuationCountriesArray.map((country) => (
              <Fragment key={country}>
                {(isAdmin || country === currentValuationCountry || country === ValuationCountryCode.De) && (
                  <Grid item xs={4} key={`flag-${country}`}>
                    <Tooltip
                      title={
                        <div className={classes.tooltip}>
                          {`${getLabel(countryCodeToOptions([...SUPPORTED_COUNTRIES], i18n.language), country)}
                          ${t('referencePopup.valuationDate')}: 
                          ${formatDate(car.valuations?.[country]?.valuationDate || '')}`}
                        </div>
                      }
                      placement="top-end"
                    >
                      <div className={classes.valuationCountryWrapper}>
                        <ValuationCountryIcon valuationCountry={country} disableLocationIcon />
                      </div>
                    </Tooltip>
                  </Grid>
                )}
              </Fragment>
            ))}
          </Grid>
        </CarDetailTableUnitValueCell>
      </TableRow>
      <TableRow className={clsx(classes.printHide, classes.highlightGross)}>
        <CarDetailTableHeadCell>
          <Trans t={t} i18nKey="car.prices.purchaseGross_br" />
        </CarDetailTableHeadCell>
        <CarDetailTableUnitValueCell>
          <Grid container className={classes.tableCellEnd} spacing={1}>
            {comparingValuationCountriesArray.map((country) => (
              <Fragment key={country}>
                {(isAdmin || country === currentValuationCountry || country === ValuationCountryCode.De) && (
                  <Grid item xs={4} key={`purchaseGross-${country}`}>
                    {isNil(countryPotentials?.[country as string]?.purchaseGross?.amount) && <PurchaseGrossFallback />}
                    <UnitValue
                      value={countryPotentials?.[country as string]?.purchaseGross?.amount}
                      unit={countryPotentials?.[country as string]?.purchaseGross?.currency}
                    />
                  </Grid>
                )}
              </Fragment>
            ))}
          </Grid>
        </CarDetailTableUnitValueCell>
      </TableRow>
      <TableRow className={classes.printHide}>
        <CarDetailTableHeadCell>
          {`${t('common.CARculator')} ${t('car.prices.marketPrice')}`} <br />
          {showCarCalculatorLink && (
            <ActionCarCulatorLink firstRegistration={car.firstRegistration} mileage={car.mileage} vin={car.vin} />
          )}
        </CarDetailTableHeadCell>

        <CarDetailTableUnitValueCell>
          <Grid container className={classes.tableCellEnd} spacing={1}>
            {comparingValuationCountriesArray.map((country) => {
              const valuationPrice = car.valuations?.[country as string]?.price;
              const offerId = car.valuations?.[country as string]?.offerId;
              const numbIncludedCars = car.valuations?.[country as string]?.numberOfCars;
              const potential = car.potentials?.[country];
              return (
                <Fragment key={country}>
                  {(isAdmin || country === currentValuationCountry || country === ValuationCountryCode.De) && (
                    <Grid item xs={4} key={`carculator-${country}`} className={classes.carculatorCell}>
                      <div>
                        <UnitValue
                          value={valuationPrice?.mean?.amount}
                          unit={valuationPrice?.mean?.currency}
                          key={`vp-mean-${country}`}
                        />
                        <br />
                        <UnitValue
                          prefix="Min: "
                          value={valuationPrice?.min?.amount}
                          unit={valuationPrice?.min?.currency}
                          key={`vp-min-${country}`}
                        />
                        <br />
                        <UnitValue
                          prefix="Max: "
                          value={valuationPrice?.max?.amount}
                          unit={valuationPrice?.max?.currency}
                          key={`vp-max-${country}`}
                        />
                        <br />
                        {numbIncludedCars && numbIncludedCars > 0 && (
                          <ReferenceVehicleButton
                            refCar={car}
                            potential={potential}
                            offerId={offerId}
                            valuationCountry={country}
                          />
                        )}
                      </div>
                    </Grid>
                  )}
                </Fragment>
              );
            })}
          </Grid>
        </CarDetailTableUnitValueCell>
      </TableRow>
      <TableRow className={classes.printHide}>
        <CarDetailTableHeadCell>{t('car.prices.potentialPriceMin')}</CarDetailTableHeadCell>
        <CarDetailTableUnitValueCell>
          <Grid container className={classes.tableCellEnd} spacing={1}>
            {comparingValuationCountriesArray.map((country) => {
              const potentials = countryPotentials?.[country as string];
              return (
                <Fragment key={country}>
                  {(isAdmin || country === currentValuationCountry || country === ValuationCountryCode.De) && (
                    <Grid item xs={4} key={`potential-min-${country}`}>
                      <UnitValue
                        value={potentials?.potentialMinAbsolute?.amount}
                        unit={potentials?.potentialMinAbsolute?.currency}
                      />{' '}
                      {potentials?.potentialMinRelative && (
                        <>
                          (<UnitValue value={potentials?.potentialMinRelative} unit="%" />)
                        </>
                      )}
                    </Grid>
                  )}
                </Fragment>
              );
            })}
          </Grid>
        </CarDetailTableUnitValueCell>
      </TableRow>
      <TableRow className={classes.printHide}>
        <CarDetailTableHeadCell>{t('car.prices.potentialPriceMinAverage')}</CarDetailTableHeadCell>
        <CarDetailTableUnitValueCell>
          <Grid container className={classes.tableCellEnd} spacing={1}>
            {comparingValuationCountriesArray.map((country) => {
              const potentials = countryPotentials?.[country as string];
              return (
                <Fragment key={country}>
                  {(isAdmin || country === currentValuationCountry || country === ValuationCountryCode.De) && (
                    <Grid item xs={4} key={`potential-avg-${country}`}>
                      <UnitValue
                        value={potentials?.potentialMinMeanAbsolute?.amount}
                        unit={potentials?.potentialMinMeanAbsolute?.currency}
                      />{' '}
                      {potentials?.potentialMinMeanRelative && (
                        <>
                          (<UnitValue value={potentials?.potentialMinMeanRelative} unit="%" />)
                        </>
                      )}
                    </Grid>
                  )}
                </Fragment>
              );
            })}
          </Grid>
        </CarDetailTableUnitValueCell>
      </TableRow>
    </>
  );
};

export default memo(CarDetailCarPotentials);
