import React, { ReactNode, useRef, useState } from 'react';
import { Badge, Grow, IconButton, makeStyles, MenuItem, MenuList, Paper, Popper, withStyles } from '@material-ui/core';
import { Link } from '@reach/router';
import { tr } from '../modules/i18n-helpers';
import { WizardStep } from '../modules/generated/api';
import { useWizard } from '../hooks/useWizard';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontWeight: 'bold',
  },
  popper: {
    zIndex: 1000,
  },
  link: {
    fontSize: '0.875rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    textDecorationColor: theme.palette.primary.main,
    textDecorationThickness: `${theme.spacing(0.25)}px`,
    '&:hover': {
      textDecorationLine: 'underline',
      textDecorationColor: theme.palette.primary.main,
      textDecorationThickness: `${theme.spacing(0.25)}px`,
    },
  },
  badge: {
    marginTop: theme.spacing(1),
  },
}));

const StyledBadge = withStyles(() => ({
  badge: {
    right: -6,
    top: 5,
  },
}))(Badge);

type NavigationLinkDropdownProps = {
  title: string;
  icon: ReactNode;
  items: { title: string; to: string; wizardKey?: WizardStep }[];
};

const NavigationLinkDropdown = ({ title, icon, items }: NavigationLinkDropdownProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { showWizardBadge } = useWizard();

  return (
    <div
      key={title}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      onFocus={() => setOpen(true)}
      onBlur={() => setOpen(false)}
    >
      <IconButton
        color="inherit"
        ref={anchorRef}
        aria-controls={open ? `menu-list-grow-${title}` : undefined}
        aria-haspopup="true"
        className={classes.menuButton}
      >
        {icon}
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom"
        transition
        disablePortal
        className={classes.popper}
      >
        {({ TransitionProps }) => (
          <Grow
            in={TransitionProps?.in}
            onEnter={TransitionProps?.onEnter}
            onExited={TransitionProps?.onExited}
            style={{
              transformOrigin: 'left top',
            }}
          >
            <Paper>
              <MenuList
                id={`menu-list-grow-${title}`}
                onKeyDown={(event) => {
                  if (event.key === 'Tab') {
                    event.preventDefault();
                    setOpen(false);
                  }
                }}
              >
                {items.map(({ title: itemTitle, to, wizardKey }) => (
                  <MenuItem
                    key={itemTitle}
                    className={classes.link}
                    component={Link}
                    to={to}
                    getProps={({ isCurrent }: { isCurrent: boolean }) =>
                      isCurrent && {
                        style: {
                          textDecorationLine: 'underline',
                        },
                      }
                    }
                  >
                    <StyledBadge
                      color="primary"
                      variant="dot"
                      invisible={!(wizardKey && showWizardBadge(wizardKey))}
                      className={classes.badge}
                      overlap="rectangular"
                    >
                      {tr(itemTitle)}
                    </StyledBadge>
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default NavigationLinkDropdown;
