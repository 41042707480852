import React, { ComponentProps, useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import type { Merge } from 'type-fest';
import { useTranslation } from 'react-i18next';
import { DateLike, formatDiffToNow, diffToNow, getIsValidDate } from 'src/modules/date-helpers';
import { TextCell, TextCellProps } from './TextCell';

export type TimestampCellProps = {
  timestamp: string | number | Date | undefined;
  format?: string;
  displayDiff?: boolean;
};

type UseTimerOptions = {
  format?: (date: DateLike) => string | number;
  /**
   * @default true
   */
  enabled?: boolean;
};

/**
 * Get the difference to the current time
 * @example
 * diffToNow(future) // computes ms >0
 * diffToNow(past) // computes ms <0
 */

const getIsExpired = (date: DateLike): boolean => diffToNow(date) <= 0;

const useTimer = (timestamp: DateLike | undefined, { format, enabled = true }: UseTimerOptions = {}) => {
  const [timeLeft, setTimeLeft] = useState<string | number | null>(() =>
    getIsValidDate(timestamp) ? format?.(timestamp) ?? diffToNow(timestamp) : null,
  );
  const [isExpired, setIsExpired] = useState(() => (getIsValidDate(timestamp) ? getIsExpired(timestamp) : undefined));
  const formatRef = useRef(format);
  formatRef.current = format;

  useEffect(() => {
    let isRunning = enabled;

    const calcDiff = () => {
      if (isRunning && getIsValidDate(timestamp)) {
        setIsExpired(getIsExpired(timestamp));
        setTimeLeft(formatRef.current?.(timestamp) ?? diffToNow(timestamp));
        requestAnimationFrame(calcDiff);
      }
    };

    if (getIsValidDate(timestamp)) {
      requestAnimationFrame(calcDiff);
    } else {
      setTimeLeft(null);
    }

    return () => {
      isRunning = false;
    };
  }, [enabled, timestamp]);

  return {
    timeLeft,
    isExpired,
  };
};

/**
 * Humanize timestamps and provide optional relative time hints.
 */
export const TimestampCell = ({
  timestamp,
  format = 'dd.MM.yy HH:mm',
  displayDiff = true,
  ...rest
}: Merge<ComponentProps<'div'>, Merge<Partial<TextCellProps>, TimestampCellProps>>): JSX.Element => {
  const { t, i18n } = useTranslation();
  const parsed = timestamp && DateTime.fromJSDate(new Date(timestamp));
  const { timeLeft, isExpired } = useTimer(timestamp, {
    format: (date) => formatDiffToNow(date, i18n.language),
    enabled: displayDiff,
  });

  const getSupportText = () => {
    if (displayDiff) {
      if (timeLeft !== null) {
        return t(isExpired ? 'carsTableNext.cells.timestamp.past' : 'carsTableNext.cells.timestamp.future', {
          time: timeLeft,
        });
      }
      return '-';
    }
    return null;
  };

  return <TextCell text={parsed ? parsed.toFormat(format) : '-'} supportText={getSupportText()} {...rest} />;
};
