import jwtDecode from 'jwt-decode';
import { isNil } from 'lodash';
import { GwscoutJwtPayload, useAuthenticationStore } from '../stores/Authentication';
import { CountryCode } from './generated/api';

export const getAccountCountry = (): CountryCode => {
  const { state: authState } = useAuthenticationStore();
  const { accessToken } = authState;
  let country = authState.objectToken?.country as CountryCode;

  if (accessToken) {
    const token = jwtDecode<GwscoutJwtPayload>(accessToken);
    if (!isNil) country = token.country as CountryCode;
  }

  return country;
};
