import { Button, makeStyles, Tooltip } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { PriceType, ValuationCountryCode } from '../modules/generated/api';
import { countryCodeToOptions, getLabel, getPropertyLabel } from '../modules/labels';
import { uncapitalize } from '../modules/string-helpers';
import { getBaseCountry, PRICE_TYPE_INVERTED, SUPPORTED_COUNTRIES } from '../modules/valuation-country-helpers';
import ValuationCountryIcon from './ValuationCountryIcon';
import { getIcon, IconType } from '../modules/data';
import { CarProperty } from '../modules/table-data';

type CurrentValuationButtonProps = {
  valuationType?: PriceType | null;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  valuationCountry: ValuationCountryCode | null;
  disabled?: boolean;
};

const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: theme.spacing(1),
  },
}));

const CurrentValuationButton = ({
  valuationType,
  valuationCountry,
  onClick,
  disabled = false,
}: CurrentValuationButtonProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const options = countryCodeToOptions([...SUPPORTED_COUNTRIES], i18n.language);

  return (
    <Tooltip
      title={
        // @ts-ignore
        valuationType ? getPropertyLabel(uncapitalize(PRICE_TYPE_INVERTED.get(valuationType)) as CarProperty) : ''
      }
    >
      <Button onClick={onClick} size="small" disabled={disabled}>
        {valuationType && (
          <>
            <ValuationCountryIcon valuationCountry={valuationCountry} />
            {t('car.prices.bestValuationCountry')}
          </>
        )}
        {valuationCountry && (
          <>
            <img
              src={getIcon(IconType.COUNTRY, getBaseCountry(valuationCountry))}
              alt={getLabel(options, valuationCountry)}
              width={18}
              className={classes.marginRight}
            />
            {getLabel(options, valuationCountry)}
          </>
        )}
        <ArrowDropDown />
      </Button>
    </Tooltip>
  );
};

export default CurrentValuationButton;
