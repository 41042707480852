import { MUIDataTableOptions } from 'mui-datatables';
import React, { memo, useCallback, useRef } from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import TagManager from 'react-gtm-module';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { debounce } from 'lodash';
import useRole from '../hooks/useRole';
import ApiService from '../modules/api-service';
import { ExportProperty } from '../modules/generated/api';
import { SortDirection, useCarsTableStore } from '../stores/CarsTable';
import UserRole from '../types/UserRoles';
import CarDetailDialog from '../components/CarDetailDialog';
import { transformForSubmit } from '../modules/cars-filter';
import CarsMUIDataTable from '../components/CarsMUIDataTable';
import useCarsFileExport from '../hooks/useCarsFileExport';
import { calcPage } from '../modules/table-helpers';
import useCarDetailPopup from '../hooks/useCarDetailPopup';
import useConditionalFormattings from '../hooks/useConditionalFormattings';
import { useCarsFilter } from '../hooks/useCarsFilter';
import { useValuationSettings } from '../hooks/useValuationSettings';
import { useCurrency } from '../hooks/useCurrency';
import useCustomSnackbarGlobal from '../hooks/useSnackbarGlobal';
import { TableCalculatorProvider } from '../hooks/useTableCalculator';
import { TableCalculatorFormData } from '../types/TableCalculatorFormData';
import { CARS_BIDS_TABLE_COLUMNS, CarTableColumn } from '../modules/table-data';

const CarsMUIDataTableContainer = () => {
  const { t } = useTranslation();
  const {
    setRowsPerPage,
    rowsPerPage,
    page,
    setPage,
    totalCount = 0,
    cars,
    fetch: fetchCars,
    sortOn,
    setSortOn,
    packageMode,
    setAndValidatePackageMode,
  } = useCarsTableStore();
  const { carDetail, closeCarDetail, index, paginateCarDetail, onRowClick } = useCarDetailPopup();
  const conditionalFormattings = useConditionalFormattings();
  const { hasRole } = useRole();
  const { form, values } = useCarsFilter();
  const formPotentialCalculator = useForm<TableCalculatorFormData>();
  const { fetchCarsFileExport, carsFileExportLoading } = useCarsFileExport();
  const { valuationCountry, valuationType } = useValuationSettings();
  const fetchCarsRef = useRef(fetchCars);
  const { currency } = useCurrency();
  const { showError } = useCustomSnackbarGlobal();
  fetchCarsRef.current = fetchCars;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchCarsDebounced = useCallback(debounce(fetchCarsRef.current, 400), [
    // prevent hitting stale closures
    page,
    rowsPerPage,
    valuationCountry,
    valuationType,
    currency,
    sortOn,
  ]);

  const tableOptions: MUIDataTableOptions = {
    download: hasRole(UserRole.ADMIN),
    onRowClick: onRowClick(cars),
    rowsPerPage,
    serverSide: true,
    sortOrder: {
      name: sortOn.column,
      direction: sortOn.dir,
    },
  };

  const handleOnDownload = async (exportProperties: ExportProperty[]) => {
    if (carsFileExportLoading) return;

    await fetchCarsFileExport(
      ApiService.export.exportControllerListCars(
        {
          exportProperties,
          valuationCountry: valuationCountry || undefined,
          ...transformForSubmit(form.getValues()),
          order: [{ column: 0, dir: sortOn.dir }],
          columns: [{ data: CARS_BIDS_TABLE_COLUMNS[sortOn.column].sortOn } as any],
        },
        2,
        { responseType: 'blob' },
      ),
    );
  };

  const handleChangeRowsPerPage = (updatedRowsPerPage: number) => {
    setRowsPerPage(updatedRowsPerPage);
    setPage((prevPage) => calcPage(prevPage, rowsPerPage, updatedRowsPerPage));
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    TagManager.dataLayer({ dataLayer: { event: 'list-pagination', listPagination: newPage + 1 } });
  };

  const handleTogglePackageMode = () => {
    const changePackageModeSuccessful = setAndValidatePackageMode(!packageMode);
    if (!changePackageModeSuccessful) {
      showError(t('alerts.packageModeNotSupported'));
    }
  };

  const handleSort = (name: string, direction: string) => {
    setSortOn({ column: name as CarTableColumn, dir: direction as SortDirection });
  };

  useDeepCompareEffect(() => {
    fetchCarsDebounced(values);

    return () => {
      fetchCarsDebounced.cancel();
    };
  }, [values, sortOn, rowsPerPage, page, valuationCountry]);

  useDeepCompareEffect(() => {
    setPage(0);
    setAndValidatePackageMode(false);
  }, [values]);

  return (
    <FormProvider {...formPotentialCalculator}>
      <div>
        <TableCalculatorProvider>
          <CarsMUIDataTable
            cars={cars}
            tableOptions={tableOptions}
            totalCarsCount={totalCount}
            localStoragePrefix="CarsTable"
            conditionalFormattings={conditionalFormattings}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onSort={handleSort}
            onDownload={handleOnDownload}
            page={page}
            filterSettings={form.getValues()}
            title={
              <Typography variant="h1">
                {t('common.car_plural')} {totalCount !== undefined && `(${totalCount})`}
              </Typography>
            }
            packageMode={packageMode}
            handleTogglePackageMode={handleTogglePackageMode}
            autoRefresh
          />
          <CarDetailDialog
            car={carDetail}
            index={index}
            maxCars={cars.length}
            moveToNext={() => paginateCarDetail(cars, 1)}
            moveToPrev={() => paginateCarDetail(cars, -1)}
            onClose={closeCarDetail}
            onEdit={async () => {
              await fetchCars(form.getValues());
            }}
          />
        </TableCalculatorProvider>
      </div>
    </FormProvider>
  );
};
export default memo(CarsMUIDataTableContainer);
