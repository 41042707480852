import React from 'react';
import { Card, CardContent, Typography, styled, Grid, ButtonBase, withStyles } from '@material-ui/core';
import { Link } from '@reach/router';
import { Spinner } from '@components/spinner';
import { useQueryClient } from '@tanstack/react-query';
import { fetchCategoryBySlug } from '@components/help-center/useCategory';
import { fetchAllFaqByCategory } from '@components/help-center/useAllFaqByCategory';
import { QueryKeys } from 'src/constants';
import { useTranslation } from 'react-i18next';
import { BuildTwoTone, DirectionsCarTwoTone, SearchTwoTone, SettingsTwoTone, HelpTwoTone } from '@material-ui/icons';
import { useAllCategory } from './useAllCategory';

// IMPROVE: Add dynamic code-splitting
const iconsById = {
  '4d0a62d6-eaf6-4f21-8497-9fe716311b43': <SearchTwoTone fontSize="inherit" />,
  'ef7df16b-d7ff-4956-ae6b-e9378c502905': <SettingsTwoTone fontSize="inherit" />,
  '915c1eab-0253-47de-85df-cf28c3613f6e': <DirectionsCarTwoTone fontSize="inherit" />,
  '666a5d78-656d-4174-a71d-c8ba24a3863e': <BuildTwoTone fontSize="inherit" />,
};

const StyledCategory = withStyles((theme) => ({
  root: {
    textAlign: 'center',
    // anchor reset
    display: 'block',
    textDecoration: 'none',

    '&:hover': {
      outline: '1px solid',
      outlineColor: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ButtonBase);

const StyledCategoryIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  fontSize: '3rem',
  paddingInline: theme.spacing(2),
  paddingBlock: theme.spacing(3),
  paddingBlockEnd: 0,
}));

export const CategoryList = () => {
  const { i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { data: categoryList } = useAllCategory();

  const prefetchCategory = (slug: string) => {
    // prefetch category meta
    const categoryVariables = {
      slug,
      lang: i18n.language,
    };
    queryClient.prefetchQuery([QueryKeys.category, categoryVariables], () => fetchCategoryBySlug(categoryVariables));
    // prefetch faq by category
    const faqVariables = {
      category: slug,
      lang: i18n.language,
    };
    queryClient.prefetchQuery([QueryKeys.allFaq, faqVariables], () => fetchAllFaqByCategory(faqVariables));
  };

  return (
    <Grid spacing={2} container justifyContent="center">
      {categoryList!.map(({ _id, title, slug, iconId }) => (
        <Grid key={_id} item md={3}>
          <StyledCategory
            // @ts-expect-error: faulty MUI typings
            component={Link}
            to={`/help/${slug}`}
            onPointerEnter={() => prefetchCategory(slug)}
          >
            <Card elevation={2}>
              <StyledCategoryIcon>{iconsById[iconId as keyof typeof iconsById] ?? <HelpTwoTone />}</StyledCategoryIcon>
              <CardContent>
                <Typography variant="h6" dangerouslySetInnerHTML={{ __html: title }} />
              </CardContent>
            </Card>
          </StyledCategory>
        </Grid>
      ))}
    </Grid>
  );
};

export const CategoryListFallback = () => (
  <Grid container justifyContent="center">
    <Spinner isLoading busyDelayMs={200} size="large" />
  </Grid>
);
