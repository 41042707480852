import { useSnackbar } from 'material-ui-snackbar-provider';
import { useMemo } from 'react';

const useCustomSnackbarGlobal = () => {
  const snackbar = useSnackbar();
  return useMemo(() => {
    const showMessage =
      (
        type: string, // needs all parameters otherwise Alert color won't work
      ) =>
      (message: string, action?: string | undefined, handleAction?: (() => void) | undefined, customParameters?: any) =>
        snackbar.showMessage(message, action, handleAction, {
          autoHideDuration: 2000,
          ...customParameters,
          type,
        });
    return {
      ...snackbar,
      showMessage: showMessage('info'),
      showInfo: showMessage('info'),
      showWarning: showMessage('warning'),
      showError: showMessage('error'),
      showSuccess: showMessage('success'),
    };
  }, [snackbar]);
};

export default useCustomSnackbarGlobal;
