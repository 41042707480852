import { getTaxId } from '@components/cars-table-next/columns/lib';
import { ValuationCountryCode } from 'src/modules/generated/api';
import { Tax } from 'src/types/tax';

// prettier-ignore
export const relevantTaxes: Record<ValuationCountryCode, string[]> = {
  [ValuationCountryCode.At]: [
    getTaxId(ValuationCountryCode.At, Tax.VAT),
    getTaxId(ValuationCountryCode.At, Tax.NOVA),
  ],
  [ValuationCountryCode.AtLot]: [
    getTaxId(ValuationCountryCode.AtLot, Tax.VAT),
    getTaxId(ValuationCountryCode.AtLot, Tax.NOVA),
  ],
  [ValuationCountryCode.De]: [
    getTaxId(ValuationCountryCode.De, Tax.VAT)
  ],
  [ValuationCountryCode.Es]: [
    getTaxId(ValuationCountryCode.Es, Tax.REGISTRATION),
    getTaxId(ValuationCountryCode.Es, Tax.REGISTRATION_RELATIVE),
    getTaxId(ValuationCountryCode.Es, Tax.VAT),
  ],
  [ValuationCountryCode.It]: [
    getTaxId(ValuationCountryCode.It, Tax.ECOMALUS),
    getTaxId(ValuationCountryCode.It, Tax.TRANSCRIPTION),
    getTaxId(ValuationCountryCode.It, Tax.VAT),
  ],
  [ValuationCountryCode.Fr]: [
    getTaxId(ValuationCountryCode.Fr, Tax.ECO),
    getTaxId(ValuationCountryCode.Fr, Tax.VAT),
  ],
  [ValuationCountryCode.Cz]: [
    getTaxId(ValuationCountryCode.Cz, Tax.REGISTRATION),
    getTaxId(ValuationCountryCode.Cz, Tax.VAT),
  ],
  [ValuationCountryCode.Pl]: [
    getTaxId(ValuationCountryCode.Pl, Tax.CIVIL),
    getTaxId(ValuationCountryCode.Pl, Tax.EXCISE),
    getTaxId(ValuationCountryCode.Pl, Tax.VAT),
  ],
  [ValuationCountryCode.Hu]: [
    getTaxId(ValuationCountryCode.Hu, Tax.AUTHENTICATION),
    getTaxId(ValuationCountryCode.Hu, Tax.PROPERTY_ACQUISITION),
    getTaxId(ValuationCountryCode.Hu, Tax.REGISTRATION),
    getTaxId(ValuationCountryCode.Hu, Tax.VAT),
  ],
  [ValuationCountryCode.Ro]: [
    getTaxId(ValuationCountryCode.Ro, Tax.VAT)
  ],
  [ValuationCountryCode.Si]: [
    getTaxId(ValuationCountryCode.Si, Tax.VAT),
    getTaxId(ValuationCountryCode.Si, Tax.REGISTRATION),
  ],
  [ValuationCountryCode.Hr]: [
    getTaxId(ValuationCountryCode.Hr, Tax.VAT),
    getTaxId(ValuationCountryCode.Hr, Tax.REGISTRATION),
  ],
  [ValuationCountryCode.Sk]: [
    getTaxId(ValuationCountryCode.Sk, Tax.VAT)
  ],
  [ValuationCountryCode.Pt]: [
    getTaxId(ValuationCountryCode.Pt, Tax.VAT),
    getTaxId(ValuationCountryCode.Pt, Tax.REGISTRATION),
  ],
  [ValuationCountryCode.Se]: [
    getTaxId(ValuationCountryCode.Se, Tax.VAT)
  ],
  [ValuationCountryCode.Bg]: [
    getTaxId(ValuationCountryCode.Bg, Tax.VAT)
  ],
  [ValuationCountryCode.Mk]: [
    getTaxId(ValuationCountryCode.Mk, Tax.VAT)
  ],
  [ValuationCountryCode.Al]: [
    getTaxId(ValuationCountryCode.Al, Tax.VAT)
  ],
  [ValuationCountryCode.Xk]: [
    getTaxId(ValuationCountryCode.Xk, Tax.VAT)
  ],
};
