import { DateTime } from 'luxon';
import {
  createContext,
  createElement,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { merge, omit } from 'lodash';
import { getPersistedValue, persistValue } from 'src/lib/localStorage';
import { isValuationValid } from 'src/modules/valuation-country-helpers';
import { transformForForm } from 'src/modules/cars-filter';
import {
  BidFilterStatus,
  BuyableStatus,
  DamageType,
  DateFilter,
  OfferType,
  SearchAgentDTO,
  SourceType,
  SummaryDTO,
  TaxType,
  ValidityType,
} from 'src/modules/generated/api';
import ApiService from 'src/modules/api-service';
import { intersect, isSameArray } from 'src/modules/math-helpers';
import useApi from 'src/hooks/useApi';
import { CarsFilterSettings } from 'src/types/CarsFilterSettings';
import { DEFAULT_CURRENCY, makeMonetaryAmount } from 'src/modules/currency';
import useRole from 'src/hooks/useRole';
import UserRole from 'src/types/UserRoles';
import { obsoleteSearchAgentFields } from 'src/modules/data';
import { useValuationSettings } from 'src/hooks/useValuationSettings';
import { useSources } from 'src/hooks/useSources';

type CarsFilterProviderProps = {
  children?: ReactNode;
};

type CarsFilterCtx = {
  form: UseFormReturn<CarsFilterSettings>;
  summary: SummaryDTO | undefined;
  loadingSummary: boolean;
  restore: () => void;
  defaultValues: Readonly<CarsFilterSettings>;
  makePreset: (
    filter: Partial<CarsFilterSettings> | ((defaultValues: CarsFilterSettings) => CarsFilterSettings),
  ) => CarsFilterSettings;
  values: CarsFilterSettings;
  setSearchAgentAsFilter: (searchAgent: SearchAgentDTO) => void;
};

const carsFilterCtx = createContext<CarsFilterCtx>(undefined!);

const storageKey = 'gwscout/CarsFilter.values';

// Preselect source `VWFS` if available
const getDefaultSource = (availableSources: SourceType[]): SourceType[] =>
  availableSources.includes(SourceType.Vwfs) ? [SourceType.Vwfs] : [];

export const CarsFilterProvider = ({ children }: CarsFilterProviderProps): ReactElement => {
  const { data: sources = [] } = useSources();
  const { hasRole } = useRole();
  const isAdmin = hasRole(UserRole.ADMIN);
  const defaultValues = useMemo<CarsFilterSettings>(
    () => ({
      startDateFilter: DateFilter.All,
      startDateFrom: DateTime.now().startOf('day').toISO(),
      startDateTo: DateTime.now().plus({ hour: 1 }).startOf('hour').toISO(),
      endDateFilter: DateFilter.All,
      endDateFrom: DateTime.now().startOf('day').toISO(),
      endDateTo: DateTime.now().endOf('day').toISO(),
      search: { value: '', regex: false },
      searchExact: false,
      marketingChannel: [],
      taxType: [TaxType.Regelbesteuert],
      source: getDefaultSource(sources),
      originSource: [],
      promotions: [],
      countryOrigin: [],
      normalizedBrand: [],
      normalizedModel: [],
      equipmentCodes: [],
      equipments: [],
      normalizedEquipmentLine: [],
      normalizedFuel: [],
      normalizedVersion: [],
      normalizedTransmission: [],
      hasUserComment: false,
      potentialFilter: null,
      hasAnyOtherUserComment: false,
      colors: [],
      damageType: [DamageType.Hail, DamageType.Accident, DamageType.Dirt, DamageType.MissingWheelSet],
      powerKw: [0, 500],
      engineCo2: [0, 400],
      mileage: [0, 160_000],
      numAuctions: [0, 50],
      priceDamage: [makeMonetaryAmount(0, DEFAULT_CURRENCY), makeMonetaryAmount(25_000, DEFAULT_CURRENCY)],
      purchasePriceGross: [makeMonetaryAmount(0, DEFAULT_CURRENCY), makeMonetaryAmount(200_000, DEFAULT_CURRENCY)],
      registrationDate: ['1990-01-01', DateTime.now().endOf('month').toISODate()],
      vinSearch: '',
      ratingMin: 0,
      anyOtherUserRatingMin: 0,
      validity: ValidityType.Current,
      offerType: OfferType.Individual,
      currentBidState: BidFilterStatus.All,
      expiredBidState: BidFilterStatus.All,
      carIds: [],
      buyableStatus: isAdmin ? BuyableStatus.Buyable : null,
    }),
    [isAdmin, sources],
  );
  const form = useForm<CarsFilterSettings>({
    defaultValues: getPersistedValue(storageKey, { initialValue: defaultValues }),
  });
  const { watch, getValues, setValue, reset } = form;
  const values = watch();
  const { fetch: fetchSummary, data: summary, loading: loadingSummary } = useApi<SummaryDTO>();
  const [normalizedBrand, normalizedModel, normalizedEquipmentLine, normalizedVersion, equipmentCodes, promotions] =
    watch([
      'normalizedBrand',
      'normalizedModel',
      'normalizedEquipmentLine',
      'normalizedVersion',
      'equipmentCodes',
      'promotions',
    ]);
  // const prevValues = useRef<CarsFilterSettings>();
  const { setValuationSettings } = useValuationSettings();

  const restore: CarsFilterCtx['restore'] = () => {
    reset(defaultValues);
  };

  const makePreset = useCallback<CarsFilterCtx['makePreset']>(
    (filter) => (typeof filter === 'function' ? filter(defaultValues) : { ...defaultValues, ...filter }),
    [defaultValues],
  );

  const setSearchAgentAsFilter = (searchAgent: SearchAgentDTO) => {
    const { valuationCountry, valuationType, ...filter } = searchAgent;

    if (isValuationValid(valuationCountry, valuationType)) {
      setValuationSettings(valuationCountry || null, valuationType || null);
    }

    const newFilterValues = omit(
      merge({}, defaultValues, transformForForm(filter)),
      obsoleteSearchAgentFields,
    ) as CarsFilterSettings;

    reset(newFilterValues);
  };

  const value = {
    form,
    summary,
    loadingSummary,
    restore,
    defaultValues,
    makePreset,
    values,
    setSearchAgentAsFilter,
  };

  // Keep summary options in sync
  useDeepCompareEffect(() => {
    const syncSummary = (newSummary: SummaryDTO | undefined) => {
      if (!newSummary) {
        return;
      }

      Object.entries(newSummary).forEach(([field, fieldValue]) => {
        const curr = getValues(field as 'normalizedVersion') || [];
        const newValue = intersect(curr, fieldValue) as any[];
        if (!isSameArray(curr, newValue)) {
          setValue(field as 'normalizedVersion', newValue);
        }
      });
    };

    fetchSummary(
      ApiService.listCars.listCarsControllerSummaryPost({
        normalizedBrand,
        normalizedModel,
        normalizedEquipmentLine,
        equipmentCodes,
        normalizedVersion,
        promotions,
      }),
      true,
    ).then((res) => syncSummary(res?.data));
  }, [
    fetchSummary,
    setValue,
    getValues,
    normalizedBrand,
    normalizedEquipmentLine,
    equipmentCodes,
    normalizedModel,
    promotions,
    normalizedVersion,
  ]);

  // Persist filter form data
  useEffect(() => {
    persistValue(storageKey, values);
  });

  // track filter changes
  // useDeepCompareEffect(() => {
  //   const prev = prevValues.current;

  //   const omitExcludedFieldNames = (field: keyof CarsFilterSettings): boolean =>
  //     !['searchAgentSaveMode', 'searchAgentDisplayName', 'searchAgentEditId'].includes(field);

  //   if (prev) {
  //     const added = addedDiff(prev, values) as any;
  //     const updated = updatedDiff(prev, values) as Partial<CarsFilterSettings>;
  //     const deleted = deletedDiff(prev, values) as any;
  //     const addedFields = (Object.keys(added) as (keyof CarsFilterSettings)[]).filter(omitExcludedFieldNames);
  //     const deletedFields = (Object.keys(deleted) as (keyof CarsFilterSettings)[]).filter(omitExcludedFieldNames);
  //     const updatedFields = (Object.keys(updated) as (keyof CarsFilterSettings)[])
  //       .filter(omitExcludedFieldNames)
  //       .filter((field) => !addedFields.includes(field) && !deletedFields.includes(field));

  //     // on all field types
  //     addedFields.forEach((field) => {
  //       const addedValues = Object.values(added[field] || {});
  //       pushGTMFilterSettings(field, { [field]: addedValues } as any, false);
  //     });

  //     // on primitive field types, range types
  //     updatedFields.forEach((field) => {
  //       const updatedValue = updated[field];

  //       if (isNumber(updatedValue) || isString(updatedValue) || isBoolean(updatedValue)) {
  //         pushGTMFilterSettings(field, { [field]: updatedValue } as any, false);
  //       } else if (isObject(updatedValue) && isArray(prev[field])) {
  //         // range slider
  //         const range = [...(prev[field] as any[])];
  //         Object.entries(updatedValue).forEach(([k, v]) => {
  //           range[parseInt(k, 10)] = v;
  //         });

  //         pushGTMFilterSettings(field, { [field]: range } as any, false);
  //       }
  //     });

  //     // on array field types
  //     deletedFields.forEach((field) => {
  //       const prevValue = prev[field] as unknown;
  //       const nextValue = values[field] as unknown;

  //       if (Array.isArray(prevValue) && Array.isArray(nextValue)) {
  //         const diff = prevValue.filter((v: any) => !nextValue.includes(v));
  //         pushGTMFilterSettings(field, { [field]: diff } as any, true);
  //       }
  //     });
  //   }

  //   // skip initial render
  //   prevValues.current = values;
  // }, [values]);

  return createElement(carsFilterCtx.Provider, { value }, children);
};

export const useCarsFilter = () => useContext(carsFilterCtx);
