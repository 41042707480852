import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { engineFuels } from '../modules/labels';
import FilterAutocomplete from './FilterAutocomplete';

const EngineFuelSelect = () => {
  const { t } = useTranslation();
  return <FilterAutocomplete name="normalizedFuel" label={t('car.fuel')} multiple options={engineFuels} />;
};

export default memo(EngineFuelSelect);
