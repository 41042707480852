import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { getGlobalConfigTM, getUserDataTM } from 'src/modules/tag-manager-helpers';
import { useAuthenticationStore } from 'src/stores/Authentication';

export const useGTM = () => {
  const { i18n } = useTranslation();
  const {
    state: { objectToken },
  } = useAuthenticationStore();

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-N5X9X77',
      dataLayer: {
        ...getGlobalConfigTM(i18n.language),
        ...getUserDataTM(objectToken),
      },
    };

    if (process.env.REACT_APP_GTM_ENABLED !== 'false') {
      TagManager.initialize(tagManagerArgs);
    }
  }, [objectToken, i18n.language]);
};
