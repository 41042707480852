import React from 'react';
import { Typography } from '@material-ui/core';
import CountdownTimer from '@components/CountdownTimer';
import { isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { DATE_TIME_FORMAT_JUST_TIME, DATE_TIME_FORMAT_NO_TIME, formatDate } from '../../modules/date-helpers';
import { getTimerValue } from '../../modules/bid-buy-helpers';
import { DetailCarDTO } from '../../modules/generated/api';

type BidBuyTimerProps = {
  car: DetailCarDTO;
  refresh: () => Promise<void>;
};

const BidBuyTimer = ({ car: { dateEnd, dateStart, dateBuyOpening }, refresh }: BidBuyTimerProps) => {
  const { t } = useTranslation();
  const timerValue = getTimerValue(dateEnd, dateBuyOpening || dateStart);

  const getDateInfo = () => {
    if (!isArray(timerValue)) return '';
    if (timerValue[1] === 'until' || timerValue[1] === 'down') {
      // Starts in -> at StartDate OR  // ends in -> at Auction EndDate
      const date = timerValue[1] === 'down' ? dateEnd : dateBuyOpening || dateStart;
      if (date)
        return `${t('timer.on')} ${formatDate(date, DATE_TIME_FORMAT_NO_TIME)} ${t('timer.at')} ${formatDate(
          date,
          DATE_TIME_FORMAT_JUST_TIME,
        )}`;
      return '';
    }
    if (dateEnd && timerValue[1] === 'up') {
      // is running for -> Auction DateEnd
      return `${t('timer.auctionEnd')} ${formatDate(dateEnd)}`;
    }
    return '';
  };

  if (!(timerValue && timerValue[0])) {
    return <Typography variant="caption">{dateEnd && `${t('timer.auctionEnd')} ${formatDate(dateEnd)}`}</Typography>;
  }

  return (
    <>
      <Typography variant="caption">
        {timerValue[1] === 'down' && t('timer.auctionEnding')}
        {timerValue[1] === 'until' && t('timer.auctionStarting')}
        {timerValue[1] === 'up' && t('timer.auctionRunning')}
      </Typography>
      <CountdownTimer countType={timerValue[1]} date={timerValue[0]} refresh={refresh} />
      <Typography variant="caption">{getDateInfo()}</Typography>
    </>
  );
};

export default BidBuyTimer;
