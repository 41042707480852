import { makeStyles, Table, TableBody, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { CallMade as CallMadeIcon, Check as CheckIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import useRole from '../hooks/useRole';
import { BID_DATE_TIME_FORMAT, formatDate } from '../modules/date-helpers';
import { BidSuccessStatus, IndividualBidDTO } from '../modules/generated/api';
import UserRole from '../types/UserRoles';
import UnitValue from './UnitValue';

const useStyles = makeStyles((theme) => ({
  bidsTableHeadCell: {
    fontWeight: 'bold',
    borderBottom: `1px solid ${theme.palette.secondary.main} !important`,
  },
  bidsTableCell: {
    paddingLeft: theme.spacing(0.5),
    paddingTop: theme.spacing(0.25),
    paddingRight: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.25),
    borderBottom: 'none',
  },
  emphasizedBid: {
    paddingTop: theme.spacing(0.8),
    color: theme.palette.success.main,
  },
  submittedBidsRow: {
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      borderCollapse: 'collapse',
    },
  },
  submittedBidsTooltip: {
    backgroundColor: theme.palette.primary.light,
  },
  tableBorder: {
    borderRight: `2px solid ${theme.palette.secondary.main}`,
  },
}));

type IndividualBidTableProps = {
  bids?: IndividualBidDTO[];
};

const IndividualBidTable = ({ bids = [] }: IndividualBidTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { hasRole } = useRole();

  const showStatus = bids.find(
    (bidSubmitted) =>
      bidSubmitted.status === BidSuccessStatus.Highest || bidSubmitted.status === BidSuccessStatus.Bought,
  );
  const showDealer = hasRole(UserRole.ADMIN) || hasRole(UserRole.COUNTRYMANAGER);
  const moreThanThreeBids = bids.length > 3;

  return (
    <Table size="small" className={clsx(moreThanThreeBids && classes.tableBorder)}>
      <TableBody>
        {bids.map((bidSubmitted, index) => (
          <Tooltip
            placement="left"
            classes={{ tooltip: classes.submittedBidsTooltip }}
            /* eslint-disable-next-line react/no-array-index-key */
            key={index}
            PopperProps={{
              popperOptions: {
                keepMounted: true,
                modifiers: {
                  flip: { enabled: false },
                  offset: {
                    enabled: true,
                    offset: '0px, -14px, 0px ',
                  },
                },
              },
            }}
            title={bidSubmitted.created ? formatDate(bidSubmitted.created, BID_DATE_TIME_FORMAT) : ''}
          >
            <TableRow
              key={`${bidSubmitted.user?.id}-${bidSubmitted.created}-${bidSubmitted.bid?.value}`}
              className={classes.submittedBidsRow}
            >
              <TableCell align="left" className={classes.bidsTableCell} width="10px">
                {index + 1}.
              </TableCell>
              <TableCell align="right" className={classes.bidsTableCell} width="70px">
                <UnitValue value={bidSubmitted.bid?.value} unit={bidSubmitted.bid?.unit} />
              </TableCell>
              <TableCell className={classes.bidsTableCell}>{bidSubmitted.user?.name}</TableCell>
              {showDealer && (
                <TableCell className={classes.bidsTableCell} width="90px">
                  {bidSubmitted?.dealer?.id}
                </TableCell>
              )}
              {showStatus && (
                <TableCell className={classes.bidsTableCell}>
                  {bidSubmitted.status === BidSuccessStatus.Highest && (
                    <Tooltip title={t('bids.status.highest') || ''}>
                      <CallMadeIcon className={classes.emphasizedBid} />
                    </Tooltip>
                  )}
                  {bidSubmitted.status === BidSuccessStatus.Bought && (
                    <Tooltip title={t('bids.status.bought') || ''}>
                      <CheckIcon className={classes.emphasizedBid} />
                    </Tooltip>
                  )}
                </TableCell>
              )}
            </TableRow>
          </Tooltip>
        ))}
      </TableBody>
    </Table>
  );
};

export default IndividualBidTable;
