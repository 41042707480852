import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { CircularProgress, CircularProgressProps, Fade, SvgIconProps } from '@material-ui/core';
import type { Merge } from 'type-fest';

export type SpinnerProps = {
  /**
   * Show/Hide the spinner
   */
  isLoading: boolean;
  /**
   * Threshold in milliseconds until spinner becomes visible.
   * Prevents unnecessary content flashes.
   * @default 0
   */
  busyDelayMs?: number;
  /**
   * The min duration the spinner stays visible.
   * Improves visual appearance.
   * @default 0
   */
  busyMinDurationMs?: number;
  // Manual declaration as sizes from CircularProgress do not match SVGIconProps['fontSize']
  size?: SvgIconProps['fontSize'];
};

const sizes: {
  [k in Exclude<SvgIconProps['fontSize'], undefined>]: string | number;
} = {
  small: 16,
  medium: 24,
  large: 32,
  default: 16,
  inherit: '1em',
};

export const Spinner = ({
  busyDelayMs = 0,
  busyMinDurationMs = 0,
  isLoading,
  className,
  size = 'default',
  ...rest
}: Merge<CircularProgressProps, SpinnerProps>): ReactElement | null => {
  const revealTimeMsRef = useRef<number>();
  const [show, setShow] = useState(false);

  useEffect(() => {
    let t: number | undefined;

    if (isLoading) {
      if (busyDelayMs === 0) {
        revealTimeMsRef.current = Date.now();
        setShow(true);
        return undefined;
      }

      t = window.setTimeout(() => {
        revealTimeMsRef.current = Date.now();
        setShow(true);
      }, busyDelayMs);
    } else {
      const revealTimeMs = revealTimeMsRef.current;
      if (!revealTimeMs || busyMinDurationMs === 0) {
        setShow(false);
        return undefined;
      }

      const timeSpent = Date.now() - revealTimeMs;

      t = window.setTimeout(
        () => {
          setShow(false);
        },
        Math.max(0, busyMinDurationMs - timeSpent),
      );
    }

    return () => {
      if (t !== undefined) {
        window.clearTimeout(t);
      }
    };
  }, [isLoading, busyDelayMs, busyMinDurationMs]);

  return show ? (
    <Fade in>
      <CircularProgress className={className} size={sizes[size]} {...rest} />
    </Fade>
  ) : null;
};
