import { Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UnitValue from './UnitValue';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    whiteSpace: 'pre-wrap',
    '& p': {
      fontSize: 'inherit',
    },
  },
}));

type BuyProps = {
  base: number | undefined;
  onClick: () => Promise<void>;
  disabled: boolean;
};

const Buy = ({ base, onClick, disabled }: BuyProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button
      color="secondary"
      disabled={disabled}
      fullWidth
      onClick={onClick}
      variant="contained"
      className={classes.root}
    >
      <Typography noWrap>
        {t('common.toBuy')} {t('bids.buyFor')}{' '}
        <strong>
          <UnitValue value={base} unit="EUR" alt={t('common.unavailable')} />
        </strong>
      </Typography>
    </Button>
  );
};

export default Buy;
