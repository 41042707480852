import { Button, Chip, Container, Grid, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import NotificationsActiveActive from '@material-ui/icons/NotificationsActiveOutlined';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOffOutlined';
import { Link } from '@reach/router';
import { find } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { useSearchAgentUpdate } from 'src/hooks/useSearchAgentUpdate';
import { SearchAgentDTOStrict, useSearchAgents } from 'src/hooks/useSearchAgents';
import { SearchAgentDTO } from 'src/modules/generated/api';
import { SidebarRoutePaths } from './constants';
import ActiveFilterChips from './filter/ActiveFilterChips';
import { transformForForm } from './filter/lib';
import { useFilter } from './filter/useFilter';
import { headerOpenState } from './state';
import { TableHeader } from './table';
import { CarsTableNextInstance } from './types';

type HeaderProps = {
  // TODO: access via table ctx?
  isLoading?: boolean;
  table: CarsTableNextInstance;
};

const useStyles = makeStyles((theme) => ({
  hideButton: {
    marginTop: theme.spacing(1.5),
    zIndex: 3,
    textTransform: 'none',
  },
  resetChip: {
    '& span': {
      marginBottom: '-3px',
    },
  },
  gridSpacing: {
    '& div': { padding: '2px' },
  },
}));

export const Header = ({ isLoading, table }: HeaderProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data: searchAgents } = useSearchAgents();
  const { mutate: updateSearchAgent } = useSearchAgentUpdate();
  const [headerIsOpen, setHeaderIsOpen] = useRecoilState(headerOpenState);

  const { setFilterSafe, watch, setFilter, defaultFilter, reset: resetFilter } = useFilter();
  const filterState = watch();

  const handleToggleNotification = (id: string) => {
    const prevSearchAgent = find(searchAgents, { id });

    if (!prevSearchAgent) {
      return;
    }

    updateSearchAgent({ ...prevSearchAgent, active: !prevSearchAgent.active });
  };

  const activateSearchAgent = (searchAgent: SearchAgentDTO) => {
    setFilterSafe(transformForForm(searchAgent));
  };

  return (
    <TableHeader
      style={{
        paddingInlineStart: 48, // add spacing for sidebar toggle
        paddingBlockStart: 24, // balance spacing from Carculator logo
        borderBottom: 'none',
      }}
      // @ts-expect-error
      title={
        <>
          {t('carsTableNext.header.headline')}{' '}
          <Chip
            size="small"
            color="primary"
            label={
              isLoading
                ? t('carsTableNext.header.isLoading')
                : t('carsTableNext.header.result', { count: table.getPageCount() })
            }
          />
        </>
      }
      endAdornment={
        <Container disableGutters maxWidth="lg" style={{ margin: 0 }}>
          {headerIsOpen ? (
            <>
              <Typography
                variant="caption"
                component="div"
                style={{
                  marginBlockStart: 4,
                  fontWeight: 'bold',
                }}
                gutterBottom
              >
                {t('carsTableNext.header.searchagentsFavorites')}
              </Typography>
              <Grid container className={classes.gridSpacing} alignItems="center">
                {searchAgents
                  .filter((searchAgent) => searchAgent.favorite)
                  .map((searchAgent: SearchAgentDTOStrict) => (
                    <Grid item key={searchAgent.id}>
                      <Chip
                        size="small"
                        variant={searchAgent.id === filterState?.id ? 'default' : 'outlined'}
                        label={searchAgent.searchAgentDisplayName}
                        color="primary"
                        onClick={() => activateSearchAgent(searchAgent)}
                        onDelete={() => handleToggleNotification(searchAgent.id)}
                        deleteIcon={searchAgent.active ? <NotificationsActiveActive /> : <NotificationsOffIcon />}
                      />
                    </Grid>
                  ))}
                <Grid item>
                  <Button
                    component={Link}
                    to={SidebarRoutePaths.views}
                    variant="text"
                    size="small"
                    style={{ textTransform: 'none' }}
                    color="primary"
                  >
                    {t('carsTableNext.header.buttonDisplaySearchAgent.label')}
                  </Button>
                </Grid>
              </Grid>
              <Typography
                variant="caption"
                component="div"
                style={{
                  marginBlockStart: 4,
                  fontWeight: 'bold',
                }}
                gutterBottom
              >
                {t('carsTableNext.header.activeFilters')}
              </Typography>
              <Grid container className={classes.gridSpacing} alignItems="center">
                <ActiveFilterChips watch={watch} defaultFilter={defaultFilter} setFilter={setFilter} />
                <Grid item>
                  <Chip
                    onClick={resetFilter}
                    avatar={<CloseIcon fontSize="small" />}
                    label={t('carsTableNext.noDataFallback.actions.reset')}
                    size="small"
                    className={classes.resetChip}
                  />
                </Grid>
              </Grid>
            </>
          ) : null}
          <Tooltip title={t('carsTableNext.header.showHidePreferences')} placement="right" arrow>
            <Button
              className={classes.hideButton}
              color="secondary"
              size="small"
              endIcon={headerIsOpen ? <ExpandLess /> : <ExpandMore />}
              onClick={() => setHeaderIsOpen((prev) => !prev)}
            >
              {headerIsOpen ? t('common.showLess') : t('common.showMore')}
            </Button>
          </Tooltip>
        </Container>
      }
    />
  );
};
