import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import FilterAutocomplete from './FilterAutocomplete';
import { equipments } from '../modules/labels';
import { IconType } from '../modules/data';

const EquipmentHighlightsSelect = () => {
  const { t } = useTranslation();

  return (
    <FilterAutocomplete
      name="equipments"
      label={`${t('car.equipment')}-${t('car.highlights')}`}
      multiple
      options={equipments}
      iconType={IconType.EQUIPMENT}
    />
  );
};

export default memo(EquipmentHighlightsSelect);
