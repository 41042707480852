import { Button } from '@material-ui/core';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

type SidbarButtonProps = {
  expanded: boolean;
  onClick: () => void;
  colIdx?: number;
  label: string;
};

const SidebarButton = ({ expanded, onClick, colIdx = 2, label }: SidbarButtonProps) => (
  <Button
    style={{
      position: 'absolute',
      zIndex: 3,
      paddingInline: 14,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transform: 'rotateZ(90deg) translate(0, -100%)',
      transformOrigin: '0 0',
      gridColumn: colIdx,
      marginBlockStart: 18,
    }}
    variant="contained"
    color="secondary"
    size="small"
    onClick={onClick}
  >
    <span
      style={{
        marginInlineEnd: 6,
      }}
    >
      {label}
    </span>{' '}
    {expanded ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
  </Button>
);

export default SidebarButton;
