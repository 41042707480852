/* eslint-disable no-nested-ternary */
import { Avatar, Chip, CircularProgress, InputAdornment, TextField, Tooltip, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { PriorityHigh } from '@material-ui/icons';
import { FilterOptionsState } from '@material-ui/lab';
import MuiAutocomplete, {
  AutocompleteGetTagProps,
  AutocompleteProps as MuiAutocompleteProps,
  AutocompleteRenderInputParams as MuiAutocompleteRenderInputParams,
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import { isFunction } from 'lodash';
import { KeyboardEventHandler, Ref, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Option, isOptionObj } from 'src/modules/i18n-helpers';
import type { Merge } from 'type-fest';

// TODO: virtualizing

export type AutocompleteProps = {
  required?: boolean;
  label: string;
  options: Readonly<(Option | string)[]>;
  inputRef?: Ref<HTMLInputElement>;
  error?: boolean;
  helperText?: string;
  virtual?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  onChange?: (value: string | string[] | null) => void;
  multiple?: boolean;
  name?: string;
  optionIcon?: string | ((optionValue: string) => string);
  zoomAvatarImage?: number;
  disableClearable?: boolean;
};

const useStyles = makeStyles((theme) => ({
  option: {
    display: 'flex',
    flexGrow: 1,
    marginBlock: theme.spacing(-0.75),
    marginInline: theme.spacing(-2),
    paddingBlock: theme.spacing(0.75),
    paddingInline: theme.spacing(2),
  },
  // applied via select-all shortcut
  optionFocused: {
    background: theme.palette.grey[200],
  },
  optionImg: {
    width: 20,
    marginRight: theme.spacing(1),
    alignSelf: 'flex-start',
  },
}));

export const Autocomplete = ({
  inputRef,
  error,
  helperText,
  loading,
  label,
  virtual,
  name,
  renderInput,
  renderTags,
  variant = 'outlined',
  ChipProps,
  size = 'small',
  disableCloseOnSelect = true,
  onChange,
  multiple,
  options,
  value,
  optionIcon,
  zoomAvatarImage,
  renderOption,
  getOptionLabel: getOptionLabelCustom,
  ...props
}: Merge<Partial<MuiAutocompleteProps<any, false, false, false>>, AutocompleteProps>): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  // Destructure options so the final emitted value is the option.value instead the whole option
  const optionValues = options?.map((option) => (isOptionObj(option) ? option.value : option));

  const [highlightAll, setHighlightAll] = useState(false);
  // Track suggested options in order to expose the filterOptionsState to the keyboard shortcut event listener
  const suggestedOptions = useRef<any[]>([]);

  const getOptionLabel =
    getOptionLabelCustom ||
    ((optionValue: unknown) => {
      const option = options?.find((o) => (isOptionObj(o) ? o.value === optionValue : o === optionValue));

      if (option === undefined) {
        return 'UNKNOWN';
      }

      return (isOptionObj(option) ? t(option.label as any) : t(option as any)) ?? 'UNKNOWN';
    });

  const getOptionSelected = (optionValue: unknown) => {
    if (Array.isArray(value) && multiple) {
      return value.includes(optionValue);
    }

    return value === optionValue;
  };

  const handleKeyDown: KeyboardEventHandler<any> = (event) => {
    switch (true) {
      // select all
      case multiple && (event.ctrlKey || event.metaKey) && event.key === 'a': {
        event.preventDefault();
        setHighlightAll(true);
        break;
      }
      // apply all
      case highlightAll && event.key === 'Enter': {
        event.stopPropagation();
        setHighlightAll(false);
        onChange?.(suggestedOptions.current);
        setOpen(false);
        break;
      }
      // revert highlight selection
      default: {
        setHighlightAll(false);
        break;
      }
    }
  };

  const renderInputDefault = (params: MuiAutocompleteRenderInputParams): JSX.Element => (
    <TextField
      {...params}
      inputRef={inputRef}
      error={error}
      helperText={helperText}
      variant={variant}
      label={t(label as any)}
      InputLabelProps={{
        shrink: true,
        style: {
          color: grey[600],
        },
      }}
      InputProps={{
        ...params.InputProps,
        style: {
          // fix missing `MuiAutocomplete-hasPopupIcon` class
          paddingRight: loading ? '39px' : undefined,
        },
        endAdornment: (
          <InputAdornment position="end">
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </InputAdornment>
        ),
        inputProps: {
          ...params.inputProps,
          onKeyDown: handleKeyDown,
          onBlur: (event) => {
            // assert `inputProps` to any as CRA ts-compiler throws an error
            if ('onBlur' in params.inputProps && typeof (params.inputProps as any).onBlur === 'function') {
              (params.inputProps as any).onBlur(event);
            }
            setHighlightAll(false);
          },
        },
      }}
    />
  );

  const renderTagsDefault = (tagValues: Array<string>, getTagProps: AutocompleteGetTagProps) =>
    tagValues.map((option, index) => (
      <Tooltip key={option} title={option === 'AUDI_GWS' ? t('alerts.audi') : ''}>
        <Chip
          {...getTagProps({ index })}
          size="small"
          label={getOptionLabel(option)}
          avatar={
            option === 'AUDI_GWS' ? (
              <PriorityHigh color="secondary" />
            ) : optionIcon ? (
              <Avatar
                src={isFunction(optionIcon) ? optionIcon(option) : optionIcon}
                imgProps={{
                  style: {
                    width: `calc(100% + ${zoomAvatarImage}px)`,
                    height: `calc(100% + ${zoomAvatarImage}px)`,
                  },
                }}
              />
            ) : undefined
          }
        />
      </Tooltip>
    ));

  const defaultRenderOption = (option: string) => (
    <div className={clsx(classes.option, { [classes.optionFocused]: highlightAll })}>
      {optionIcon && (
        <img src={isFunction(optionIcon) ? optionIcon(option) : optionIcon} alt="" className={classes.optionImg} />
      )}
      {getOptionLabel(option)}
    </div>
  );

  const handleFilterOptions = (nextOptions: any[], state: FilterOptionsState<any>) => {
    const filter = createFilterOptions();
    const filteredOptions = filter(nextOptions, state);
    suggestedOptions.current = filteredOptions;
    return filteredOptions;
  };

  return (
    <MuiAutocomplete
      open={open}
      onClose={() => setOpen(false)}
      onOpen={(event) => {
        // @ts-ignore
        const exactMatch = options.find((option) => getOptionLabel(option) === event.target?.value);
        if (!exactMatch) {
          setOpen(true);
        }
      }}
      id={name}
      disableClearable
      options={optionValues}
      renderInput={renderInput || renderInputDefault}
      renderTags={renderTags || renderTagsDefault}
      renderOption={renderOption || defaultRenderOption}
      loading={loading}
      // ListboxComponent={virtual ? VirtualListboxComponent : undefined}
      disableListWrap={virtual === true}
      ChipProps={{
        size,
        ...ChipProps,
      }}
      size={size}
      disableCloseOnSelect={disableCloseOnSelect}
      multiple={multiple}
      onChange={(_event, newValue) => onChange?.(newValue)}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      limitTags={3}
      value={value}
      {...props}
      noOptionsText={t('carsTable.noEntriesFound')}
      onInputChange={(event, inputValue) => {
        const exactMatch = options.find((option) => getOptionLabel(option) === inputValue);
        if (exactMatch) {
          onChange?.(isOptionObj(exactMatch) ? exactMatch.value : exactMatch);
        }
      }}
      filterOptions={(o, s) => handleFilterOptions(o, s)}
    />
  );
};
