import React, { Reducer, useReducer, useState } from 'react';
import { MUIDataTableColumn } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  InputLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import clsx from 'clsx';
import { ArrowDropDown, ArrowRight } from '@material-ui/icons';
import i18n from 'i18next';
import { countryCodeToOptions, damageTypes, equipments, getPropertyLabel, promotions } from '../modules/labels';
import { ExportCarProperty, ExportProperty, ExportPropertyKeyValue } from '../modules/generated/api';
import { Option, tr } from '../modules/i18n-helpers';
import { COUNTRY_ORIGIN_FILTER_COUNTRIES, EXPORT_PRESETS, valuationCountryToTaxCol } from '../modules/data';
import { getAccountValuationCountry } from '../modules/valuation-country-helpers';
import { CARS_BIDS_TABLE_COLUMNS, CarTableColumn } from '../modules/table-data';

type ExportPromptProps = {
  columns: MUIDataTableColumn[];
  columnOrder?: number[];
  open: boolean;
  onDownload: (exportProperty: ExportProperty[]) => Promise<void>;
  onClose: () => void;
};

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: 0,
    height: '1.6rem',
  },
  allSelector: { marginTop: theme.spacing(2) },
  presetSelector: {
    marginLeft: 'auto',
  },
  listIconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  subListItem: {
    height: '1.2rem',
  },
  subListItemSelect: {
    height: '1.9rem',
  },
  bigCheckBox: {
    paddingRight: 0,

    verticalAlign: 'middle',
    justify: 'center',
  },
  smallCheckBox: {
    transform: 'scale(0.8)',
    marginLeft: theme.spacing(0.8),
  },
  dialog: {
    maxHeight: theme.spacing(100),
  },
  select: {
    fontSize: '0.75rem',
  },
  selectItem: {
    cursor: 'pointer',
  },
  allWrapper: {
    display: 'flex',
  },
  columnItemsWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  selectLabel: {
    paddingRight: theme.spacing(0.8),
  },
}));

function setAllExport(exportArray: Column[] | Column['includes'][number][], value: boolean) {
  return exportArray.map((exportable) => ({
    ...exportable,
    export: value,
  }));
}

type Column = {
  name: CarTableColumn;
  label: string;
  export: boolean;
  excluded: boolean;
  includes: (ExportProperty & { export: boolean })[];
};

type StateType = {
  columnSelect: Column[];
  selectionPreset: string;
};

type ActionType =
  | { state: StateType; type: 'all' }
  | {
      state: StateType;
      type: 'table';
      columns: MUIDataTableColumn[];
      columnOrder?: number[];
      colsTemplate: typeof CARS_BIDS_TABLE_COLUMNS;
    }
  | { state: StateType; type: 'toggleColumnCheck'; column: Column }
  | { state: StateType; type: 'configurationPreset'; configurationId: number }
  | { state: StateType; type: 'togglePropertyCheck'; column: Column; exportProperty: Column['includes'][number] }
  | {
      state: StateType;
      type: 'toggleExportOptions';
      column: Column;
      exportProperty: Column['includes'][number];

      exportOptions: string;
    };

const getValueLabels = (name: ExportCarProperty): ExportPropertyKeyValue[] | undefined => {
  const optionsToExportProperty = (option: Option) => ({ name: option.value, label: tr(option.label) });

  switch (name) {
    case ExportCarProperty.DamageType:
      return damageTypes.map((damageType) => optionsToExportProperty(damageType));
    case ExportCarProperty.EquipmentKeys:
      return equipments.map((equipment) => optionsToExportProperty(equipment));
    case ExportCarProperty.CountryOrigin:
      return countryCodeToOptions(COUNTRY_ORIGIN_FILTER_COUNTRIES, i18n.language).map((country) =>
        optionsToExportProperty(country),
      );
    case ExportCarProperty.Promotions:
      return promotions.map((promotion) => optionsToExportProperty(promotion));
    default:
      return undefined;
  }
};

const getDisplayedTableColumns = (
  columns: MUIDataTableColumn[],
  colsTemplate: typeof CARS_BIDS_TABLE_COLUMNS,
  columnOrder?: number[],
) => {
  const possiblyOrderedColumns = columnOrder?.map((colIdx) => columns[colIdx]) || columns;
  const mappedColumns: Column[] = possiblyOrderedColumns
    ?.filter((column) => colsTemplate[column.name as CarTableColumn].exports.length > 0)
    .map((column) => {
      const shouldExport = ['true', true, undefined].includes(column.options?.display);
      const name = column.name as CarTableColumn;
      const { exports: includes } = colsTemplate[name];
      const isHighlight = column.name === CarTableColumn.Highlights;
      const isPromotion = column.name === CarTableColumn.promotions;

      return {
        name,
        label: column.label || '',
        excluded: column.options?.display === 'excluded',
        includes: includes.map((property) => {
          const valueLabels = getValueLabels(property as ExportCarProperty);
          const columnName = property as ExportCarProperty;
          return {
            name: columnName,
            label: getPropertyLabel(property),
            export: shouldExport,
            ...((isHighlight || isPromotion) && { exportIcon: true, exportText: true }),
            ...(valueLabels && {
              valueLabels,
            }),
          };
        }),
        export: shouldExport,
      };
    });

  return mappedColumns;
};

const reducer: Reducer<StateType, ActionType> = (state, action) => {
  switch (action.type) {
    case 'all': {
      const allTrue = state.columnSelect.every((column) => column.export);
      const newColumnSelect = state.columnSelect.map((column) => ({
        ...column,
        export: !allTrue,
        includes: setAllExport(column.includes, !allTrue) as Column['includes'],
      }));

      return {
        ...state,
        selectionPreset: 'individual',
        columnSelect: newColumnSelect,
      };
    }
    case 'toggleColumnCheck': {
      const modifiedColumn = {
        ...action.column,
        export: !action.column.export,
        includes: setAllExport(action.column.includes, !action.column.export) as Column['includes'],
      };
      const modifiedColumnSelect = [...state.columnSelect];
      modifiedColumnSelect[modifiedColumnSelect.indexOf(action.column)] = modifiedColumn;
      return { ...state, columnSelect: modifiedColumnSelect, selectionPreset: 'individual' };
    }
    case 'togglePropertyCheck': {
      const modifiedProp = { ...action.exportProperty, export: !action.exportProperty.export };
      const modifiedIncludes = [...action.column.includes];
      modifiedIncludes[modifiedIncludes.indexOf(action.exportProperty)] = modifiedProp;

      const allPropsChecked = modifiedIncludes.every((property) => property.export);
      const modifiedColumn = { ...action.column, includes: modifiedIncludes, export: allPropsChecked };
      const modifiedColumnSelect = [...state.columnSelect];
      modifiedColumnSelect[modifiedColumnSelect.indexOf(action.column)] = modifiedColumn;
      return { ...state, columnSelect: modifiedColumnSelect, selectionPreset: 'individual' };
    }
    case 'toggleExportOptions': {
      const modifiedProp = {
        ...action.exportProperty,
        exportText: action.exportOptions !== 'iconsOnly',
        exportIcon: action.exportOptions !== 'textOnly',
      };
      const modifiedIncludes = [...action.column.includes];
      modifiedIncludes[modifiedIncludes.indexOf(action.exportProperty)] = modifiedProp;

      const modifiedColumn = { ...action.column, includes: modifiedIncludes };
      const modifiedColumnSelect = [...state.columnSelect];
      modifiedColumnSelect[modifiedColumnSelect.indexOf(action.column)] = modifiedColumn;
      return { ...state, columnSelect: modifiedColumnSelect, selectionPreset: state.selectionPreset };
    }
    case 'table': {
      const displayedTableColumns =
        action.columns && getDisplayedTableColumns(action.columns, action.colsTemplate, action.columnOrder);
      return {
        ...state,
        selectionPreset: 'table',
        columnSelect: displayedTableColumns || state.columnSelect,
      };
    }
    case 'configurationPreset': {
      const modifiedColumnSelect = state.columnSelect.map((columnSelect) => ({
        ...columnSelect,
        includes: setAllExport(columnSelect.includes, false) as Column['includes'],
        export: false,
      }));

      EXPORT_PRESETS[action.configurationId].forEach((presetColumnName) => {
        const idx = state.columnSelect.findIndex((selectColumn) => presetColumnName === selectColumn.name);
        modifiedColumnSelect[idx] = {
          ...modifiedColumnSelect[idx],
          includes: setAllExport(modifiedColumnSelect[idx].includes, true) as Column['includes'],
          export: true,
        };
      });

      return {
        ...state,
        selectionPreset: `configurationPreset_${action.configurationId}`,
        columnSelect: modifiedColumnSelect,
      };
    }
    default:
      return { ...state };
  }
};

const ExportPrompt = ({ columns, columnOrder, open, onDownload, onClose }: ExportPromptProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const colsTemplate = CARS_BIDS_TABLE_COLUMNS;
  colsTemplate[CarTableColumn.Taxes].exports =
    CARS_BIDS_TABLE_COLUMNS[valuationCountryToTaxCol[getAccountValuationCountry()]].exports;

  const [state, dispatch] = useReducer(reducer, {
    columnSelect: getDisplayedTableColumns(columns, colsTemplate, columnOrder) || [],
    selectionPreset: 'table',
  });

  const [openItems, setOpenItems] = useState<CarTableColumn[]>([]);

  const getExportOptionName = (exportProperty: Column['includes'][number]) => {
    switch (true) {
      case !exportProperty.exportIcon && exportProperty.exportText:
        return 'textOnly';
      case exportProperty.exportIcon && !exportProperty.exportText:
        return 'iconsOnly';
      case exportProperty.exportIcon && exportProperty.exportText:
      default:
        return 'iconsAndData';
    }
  };

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog}>
      <DialogTitle>{t('carsTable.downloadXLSXDialog')}</DialogTitle>
      <DialogContent>
        <div className={classes.allWrapper}>
          <ListItem className={clsx(classes.listItem, classes.allSelector)}>
            <ListItemIcon>
              <Checkbox
                checked={state.columnSelect.every((column) => column.export)}
                onClick={() => dispatch({ state, type: 'all' })}
                size="small"
              />
            </ListItemIcon>
            <ListItemText>{t('common.all')}</ListItemText>
          </ListItem>
          <div className={classes.presetSelector}>
            <InputLabel>{t('carsTable.columnPreset_plural')}</InputLabel>
            <Select
              value={state.selectionPreset}
              displayEmpty
              onChange={(event) => {
                const selectValue = event.target.value as string;
                if (selectValue === 'table') {
                  dispatch({
                    state,
                    type: 'table',
                    columns,
                    columnOrder,
                    colsTemplate,
                  });
                }
                if (selectValue.startsWith('configurationPreset')) {
                  dispatch({
                    state,
                    type: 'configurationPreset',
                    configurationId: parseInt(selectValue.split('_')[1], 10),
                  });
                }
              }}
            >
              <MenuItem value="table">{t('carsTable.presetLikeTable')}</MenuItem>
              <MenuItem value="configurationPreset_0">{t('carsTable.columnPreset')} 1</MenuItem>
              <MenuItem value="individual">{t('configuration.individual')}</MenuItem>
            </Select>
          </div>
        </div>
        <Divider variant="fullWidth" />
        {state.columnSelect
          .filter((column) => !column.excluded)
          .map((column) => {
            const showArrow = column.includes.length > 1 || column.name === CarTableColumn.promotions;
            const isItemOpen = openItems.find((item) => item === column.name);
            return (
              <div key={column.name}>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listIconContainer}>
                    <Checkbox
                      checked={column.export}
                      onChange={() => dispatch({ state, type: 'toggleColumnCheck', column })}
                      size="small"
                      className={classes.bigCheckBox}
                    />
                    <div className={classes.columnItemsWrapper}>
                      {showArrow &&
                        (isItemOpen ? (
                          <ArrowDropDown
                            onClick={() => setOpenItems([...openItems.filter((item) => item !== column.name)])}
                          />
                        ) : (
                          <ArrowRight onClick={() => setOpenItems([...openItems, column.name])} />
                        ))}
                    </div>
                  </ListItemIcon>
                  <ListItemText>{column.label}</ListItemText>
                </ListItem>
                {isItemOpen &&
                  column?.includes?.map((exportProperty) => {
                    const provideExportOptionSelector =
                      exportProperty.exportIcon !== undefined && exportProperty.exportText !== undefined;
                    return (
                      <ListItem
                        key={exportProperty.name}
                        className={provideExportOptionSelector ? classes.subListItemSelect : classes.subListItem}
                      >
                        <ListItemIcon>
                          <Checkbox
                            checked={exportProperty.export}
                            onChange={() => dispatch({ state, type: 'togglePropertyCheck', column, exportProperty })}
                            size="small"
                            className={classes.smallCheckBox}
                          />
                        </ListItemIcon>
                        <ListItemText>{exportProperty.label && t(exportProperty.label as any)}</ListItemText>
                        {provideExportOptionSelector && (
                          <Select
                            value={getExportOptionName(exportProperty)}
                            onChange={(event) =>
                              dispatch({
                                state,
                                type: 'toggleExportOptions',
                                column,
                                exportProperty,
                                exportOptions: event.target.value as string,
                              })
                            }
                            className={classes.select}
                          >
                            <ListItem className={classes.selectItem} value="iconsAndData">
                              {t('carsTable.exportIconsAndText')}
                            </ListItem>
                            <ListItem className={classes.selectItem} value="iconsOnly">
                              {t('carsTable.exportIconsOnly')}
                            </ListItem>
                            <ListItem className={classes.selectItem} value="textOnly">
                              {t('carsTable.exportTextOnly')}
                            </ListItem>
                          </Select>
                        )}
                      </ListItem>
                    );
                  })}
              </div>
            );
          })}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            const exportProperties = state.columnSelect
              .filter(
                (column) => (column.export || column.includes.some((property) => property.export)) && !column.excluded,
              )
              .map((column) => column.includes.filter((property) => property.export))
              .flat();

            let withIcons = false;
            (exportProperties as any[]).forEach((exportProperty: ExportProperty) => {
              if (exportProperty.exportIcon) withIcons = true;
            });

            if (withIcons)
              exportProperties.push({ name: ExportCarProperty.Icons, label: t('common.icons'), export: true });

            (exportProperties as any[]).forEach(
              // eslint-disable-next-line no-param-reassign
              (exportProperty) => delete exportProperty.export,
            );

            onDownload(exportProperties as ExportProperty[]);
            onClose();
          }}
        >
          {t('common.download')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ExportPrompt;
