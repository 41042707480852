import React, { memo, useState } from 'react';
import { Button } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import ApiService from '../modules/api-service';
import useApi from '../hooks/useApi';
import { SearchAgentDTO } from '../modules/generated/api';
import SearchAgentSaveDialog from '../components/SearchAgentSaveDialog';

type SearchAgentSaveProps = {
  searchAgents: SearchAgentDTO[];
  refetch: () => void;
};

const SearchAgentSave = ({ refetch, searchAgents }: SearchAgentSaveProps) => {
  const [open, setOpen] = useState(false);

  const { fetch: fetchCreate } = useApi();
  const { fetch: fetchUpdate } = useApi();
  const { t } = useTranslation();

  return (
    <>
      <Button onClick={() => setOpen(true)} endIcon={<Save />} color="primary" variant="contained" type="button">
        {t('filterSearch.saveAsSearchAgent')}
      </Button>
      <SearchAgentSaveDialog
        open={open}
        searchAgents={searchAgents}
        onClose={() => setOpen(false)}
        onCreate={async (searchAgent) => {
          TagManager.dataLayer({ dataLayer: { event: 'searchagent-save' } });
          await fetchCreate(
            ApiService.searchAgent.searchAgentControllerCreateSearchAgent({
              ...searchAgent,
              version: '2',
            }),
          );
        }}
        onUpdate={async (id, searchAgent) => {
          await fetchUpdate(ApiService.searchAgent.searchAgentControllerUpdateSearchAgent(id, searchAgent));
        }}
        refetch={refetch}
      />
    </>
  );
};

export default memo(SearchAgentSave);
