import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { tr } from 'src/modules/i18n-helpers';
import { findConditionalFormattingFieldName } from 'src/modules/conditional-formatting';
import { ConditionalFormattingDetailDTO, ConditionalFormattingType } from '../modules/generated/api';
import ConditionalFormattingColor from './ConditionalFormattingColor';
import { damageTypes, getLabel } from '../modules/labels';
import UnitValue from './UnitValue';
import { GlobalUnitMap } from '../modules/data';

type ConditionalFormattingColumnProps = {
  condition: ConditionalFormattingDetailDTO;
  field?: ConditionalFormattingType;
  fieldName?: string;
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  colorWrapper: {
    flexBasis: 0,
  },
}));

const f = (field?: ConditionalFormattingType, value?: number) => {
  if (!field) return value;
  const unit = GlobalUnitMap[field];
  return <UnitValue unit={GlobalUnitMap[field]} value={unit === '%' && value ? value / 100 : value} />;
};

const ConditionalFormattingColumnInfo = ({ condition, field, fieldName }: ConditionalFormattingColumnProps) => {
  const { backgroundColor, eq, gt, gte, lt, lte, contains } = condition;
  const classes = useStyles();
  const { t } = useTranslation();

  let formatting;

  if (eq || contains) {
    formatting = (
      <>
        {eq ? t('conditionalFormatting.equal').toLowerCase() : t('conditionalFormatting.contains').toLowerCase()}
        <em> {`"${eq || contains}"`}</em>
      </>
    );
  }
  if (eq && contains) {
    formatting = (
      <>
        {formatting} <br /> {t('conditionalFormatting.contains').toLowerCase()} <em>{`"${contains}"`}</em>
      </>
    );
  }

  if (field === ConditionalFormattingType.DamageType && contains) {
    formatting = `${t('conditionalFormatting.type')}: ${getLabel(damageTypes, contains)}`;
  }

  if (gt !== undefined || gte !== undefined || lt !== undefined || lte !== undefined) {
    const hasLowerBound = gt || gte;
    const hasUpperBound = lt || lte;
    const hasLowerAndUpperBound = hasLowerBound && hasUpperBound;

    formatting =
      (hasLowerAndUpperBound && (
        <>
          {t('conditionalFormatting.gt_abbr')} {f(field, gt || gte)}
          <br />
          &amp; {t('conditionalFormatting.st_abbr').toLowerCase()} {f(field, lt || lte)}
        </>
      )) ||
      (hasLowerBound && (
        <>
          {t('conditionalFormatting.gt_abbr')} {f(field, gt || gte)}
        </>
      )) ||
      (hasUpperBound && (
        <>
          {t('conditionalFormatting.st_abbr')} {f(field, lt || lte)}
        </>
      ));
  }

  return formatting ? (
    <div className={classes.root}>
      <div className={classes.colorWrapper}>
        <ConditionalFormattingColor size={18} value={backgroundColor} />
      </div>
      {fieldName && (
        <Typography variant="caption" style={{ paddingRight: '2px' }}>
          {tr(`carsTableNext.filter.${findConditionalFormattingFieldName(fieldName)}`)}:
        </Typography>
      )}
      <Typography variant="caption">{formatting}</Typography>
    </div>
  ) : null;
};
export default ConditionalFormattingColumnInfo;
