import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import countries from 'i18n-iso-countries';
import { MenuItem, Select, Tooltip } from '@material-ui/core';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { KvpsDTO, KvpsType } from '../../modules/generated/api';

export const KVPSSelect = ({
  kvps = [],
  disabled,
  className,
  rules,
}: {
  kvps?: KvpsDTO[];
  disabled?: boolean;
  className?: string;
  rules?: RegisterOptions;
}): ReactElement => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { control } = useFormContext();

  const getKvpsAlias = (kvpsDTO: KvpsDTO): string => {
    const alias: Record<string, string> = {
      [KvpsType.Central]: t('bids.headquaters', { country: countries.getName(kvpsDTO.kvps || '', language) }),
      [KvpsType.Dealer]: t('bids.dealer'),
    };
    const kvpsNumber = kvpsDTO.kvps ?? 'UNKNOWN';

    return alias[kvpsDTO.type!] || kvpsNumber;
  };

  return (
    <Controller
      name="kvps"
      rules={rules}
      control={control}
      render={({ field }) => (
        <Select
          {...field}
          variant="standard"
          value={field.value || 'empty'}
          displayEmpty
          disabled={disabled}
          className={className}
        >
          <MenuItem value="empty" disabled>
            {t('bids.kvps')}
          </MenuItem>
          {kvps.map((kvpsDTO) => (
            <MenuItem key={kvpsDTO.kvps} value={kvpsDTO.kvps}>
              <Tooltip title={kvpsDTO.displayName || ''} placement="right">
                <div>{getKvpsAlias(kvpsDTO)}</div>
              </Tooltip>
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};
