import { useConditionalFormats } from '@components/cars-table-next/useConditionalFormats';
import ConditionalFormattingColumnInfo from '@components/ConditionalFormattingColumnInfo';
import InfoTooltip from '@components/InfoTooltip';
import { IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { grey, orange } from '@material-ui/core/colors';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import clsx from 'clsx';
import { isNil, isString } from 'lodash';
import { ComponentProps, CSSProperties, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConditionalFormattingDTO } from 'src/modules/generated/api';
import type { Merge } from 'type-fest';
import { findConditionalFormattingDetail, findConditionalFormattingRow } from '../../../modules/conditional-formatting';
import { getFilter } from '../filter/filter';
import { FilterDef } from '../filter/types';
import { getConditionalFormatKey } from '../lib';
import { ConditionalFormatDef } from '../types';
import { SupportText } from './common/SupportText';
import { ContentCell } from './ContentCell';
import HeaderFilterCell from './HeaderFilterCell';

export type HeaderCellProps = {
  text: ReactNode;
  supportText?: ReactNode;
  conditionalFormatDef?: ConditionalFormatDef | ConditionalFormatDef[];
  hint?: ReactNode;
  align?: CSSProperties['textAlign'];
  enableSorting?: boolean;
  onSortClick?: (event: unknown) => void;
  sortDir?: 'asc' | 'desc' | false;
  helperText?: ReactNode;
  filterValueName?: Array<string>;
};

const useStyles = makeStyles((theme) => ({
  text: {
    display: 'flex',
    alignItems: 'center',
    color: grey[700],
    fontWeight: 'bold',
  },
  text__content: {
    minWidth: '3ch',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  text___with_sorting: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  text___reversed: {
    flexDirection: 'row-reverse',
  },
  text___with_helper: {
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    cursor: 'help',
  },
  button_sort: {
    display: 'none',
    flex: '0 0',
    margin: -3, // small button padding
  },
  button_sort___active: {
    display: 'inline-flex',
    color: orange[900],
  },
  button_sort__icon___asc: {
    transform: 'rotate(180deg)',
  },
  button_sort__icon___desc: {
    transform: 'rotate(0)',
  },
  popover: {
    margin: theme.spacing(2),
    width: '15vw',
  },
}));

/**
 * Render multifunctional Column Headers
 */
export const HeaderCell = ({
  text,
  conditionalFormatDef,
  supportText,
  hint,
  align = 'left',
  style,
  enableSorting,
  onSortClick,
  sortDir,
  helperText,
  filterValueName,
  id,
  ...rest
}: Merge<ComponentProps<'div'>, HeaderCellProps>): JSX.Element => {
  const { t } = useTranslation();
  // check if url includes the term /cars
  const isCarsTable = window.location.href.includes('/cars');
  const classes = useStyles();
  const filter = useMemo(() => getFilter(), []);

  const { data: allConditionalFormats = [] } = useConditionalFormats();
  const conditionalFormatDefs =
    Array.isArray(conditionalFormatDef) || isNil(conditionalFormatDef) ? conditionalFormatDef : [conditionalFormatDef];
  const conditionsDetails = conditionalFormatDefs
    ?.map((def) => findConditionalFormattingDetail(getConditionalFormatKey(def), allConditionalFormats))
    .filter((def): def is ConditionalFormattingDTO => def !== undefined);
  const showConditionalFormats = conditionsDetails?.some((conditionDetails) =>
    conditionDetails.conditions?.some((condition) => condition !== undefined),
  );

  const filterDefs = filter.map(({ filter: filterObj }) => filterObj);
  const flattenedFilterDef = filterDefs.flat(1).map((filterObj) => filterObj);
  const filterOptions: FilterDef[] = [];
  if (filterValueName) {
    filterValueName.forEach((name) => {
      const filterObjects = flattenedFilterDef.find((filterValue) => name === filterValue.name);
      if (filterObjects) filterOptions.push(filterObjects);
    });
  }

  const rowFormattings = findConditionalFormattingRow(allConditionalFormats);

  return (
    <ContentCell
      style={
        {
          textAlign: align,
          ...style,
        } as CSSProperties
      }
      {...rest}
    >
      <div
        className={clsx(classes.text, {
          [classes.text___reversed]: align === 'right',
          [classes.text___with_sorting]: enableSorting,
          [classes.text___with_helper]: helperText,
        })}
      >
        {(hint || showConditionalFormats) && (
          <InfoTooltip
            title={hint ? t('common.information') : undefined}
            hint={hint}
            xsIcon
            divider={!!(hint && showConditionalFormats)}
            moreInfo={
              showConditionalFormats && (
                <>
                  <Typography variant="caption">
                    <strong>{t('conditionalFormatting.conditionalFormatting')}</strong>
                  </Typography>
                  {conditionsDetails?.map((conditionalFormatting) =>
                    conditionalFormatting?.conditions?.map((condition) => (
                      <ConditionalFormattingColumnInfo
                        key={`${condition.backgroundColor},${condition.gt},${condition.lt},${condition.gte}, ${condition.lte}, ${condition.eq}, ${condition.contains}`}
                        condition={condition}
                        field={conditionalFormatting.field}
                      />
                    )),
                  )}
                </>
              )
            }
          />
        )}
        <div className={classes.text__content} title={isString(text) ? text : undefined}>
          <Tooltip title={isString(helperText) ? t(helperText as any) : helperText || ''} arrow>
            <span>{isString(text) ? t(text as any) : text}</span>
          </Tooltip>
        </div>
        {id === 'car' && rowFormattings && (
          <InfoTooltip
            xsIcon
            moreInfo={
              <>
                <Typography variant="caption">
                  <strong>{t('conditionalFormatting.conditionalFormatting_row')}</strong>
                </Typography>
                {rowFormattings?.map((conditionalFormatting) =>
                  conditionalFormatting?.conditions?.map((condition) => (
                    <ConditionalFormattingColumnInfo
                      key={`${condition.backgroundColor},${condition.gt},${condition.lt},${condition.gte}, ${condition.lte}, ${condition.eq}, ${condition.contains}`}
                      condition={condition}
                      field={conditionalFormatting.field}
                      fieldName={conditionalFormatting.field as string}
                    />
                  )),
                )}
              </>
            }
          />
        )}
        {enableSorting ? (
          <IconButton
            size="small"
            className={clsx('button_sort', classes.button_sort, {
              [classes.button_sort___active]: sortDir === 'asc' || sortDir === 'desc',
            })}
            onClick={onSortClick}
          >
            <ArrowDownwardIcon
              className={clsx({
                [classes.button_sort__icon___asc]: sortDir === 'asc',
                [classes.button_sort__icon___desc]: sortDir === 'desc',
              })}
              fontSize="small"
            />
          </IconButton>
        ) : null}
      </div>
      {supportText ? <SupportText>{isString(supportText) ? t(supportText as any) : supportText}</SupportText> : null}
      {filterOptions.length >= 1 && isCarsTable && <HeaderFilterCell filterDefs={filterOptions} />}
    </ContentCell>
  );
};
