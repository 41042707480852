import { useBidsFilter } from './useBidsFilter';
import { useFilter } from './useFilter';

export const useWatchSummary = () => {
  const { watch } = useFilter();

  const [
    normalizedBrand,
    normalizedModel,
    normalizedEquipmentLine,
    normalizedVersion,
    equipmentCodes,
    promotions,
    normalizedFuel,
  ] = watch([
    `normalizedBrand`,
    `normalizedModel`,
    'normalizedEquipmentLine',
    'normalizedVersion',
    'equipmentCodes',
    'promotions',
    'normalizedFuel',
  ]);

  return {
    normalizedBrand,
    normalizedModel,
    normalizedEquipmentLine,
    normalizedVersion,
    equipmentCodes,
    promotions,
    normalizedFuel,
  };
};

export const useBidsWatchSummary = () => {
  const { watch } = useBidsFilter();

  const [
    normalizedBrand,
    normalizedModel,
    normalizedEquipmentLine,
    normalizedVersion,
    equipmentCodes,
    promotions,
    normalizedFuel,
    bidCreatorDealerCountry,
    bidCreatorDealerId,
    bidCreatorUserId,
  ] = watch([
    'carsRequest.normalizedBrand',
    'carsRequest.normalizedModel',
    'carsRequest.normalizedEquipmentLine',
    'carsRequest.normalizedVersion',
    'carsRequest.equipmentCodes',
    'carsRequest.promotions',
    'carsRequest.normalizedFuel',
    'bidCreatorDealerCountry',
    'bidCreatorDealerId',
    'bidCreatorUserId',
  ]);

  return {
    normalizedBrand,
    normalizedModel,
    normalizedEquipmentLine,
    normalizedVersion,
    equipmentCodes,
    promotions,
    normalizedFuel,
    bidCreatorDealerCountry,
    bidCreatorDealerId,
    bidCreatorUserId,
  };
};
