import React from 'react';
import { styled, Typography, TypographyProps } from '@material-ui/core';
import type { Merge } from 'type-fest';

type InputLabelProps = {
  required?: boolean;
};

const Label = styled(Typography)(({ theme }) => ({
  display: 'block',
  fontWeight: 'bold',
  marginBlockEnd: theme.spacing(1),
}));

export function InputLabel({
  required,
  children,
  ...typographyProps
}: Merge<TypographyProps<'label'>, InputLabelProps>): JSX.Element {
  return (
    // @ts-expect-error
    <Label variant="body2" component="label" {...typographyProps}>
      {children}
      {required ? (
        <Typography component="span" color="error" variant="inherit">
          *
        </Typography>
      ) : null}
    </Label>
  );
}
