import { BidBuyStatus } from '@components/BidBuyAnimation';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import ApiService from '../modules/api-service';
import { SetBidRequestDTO } from '../modules/generated/api';

export const usePurchase = () => {
  const { t } = useTranslation();
  const [bidBuyAnimationOpen, setBidBuyAnimationOpen] = useState(false);
  const [bidBuyStatus, setBidBuyStatus] = useState<BidBuyStatus>({
    success: null,
    message: null,
  });
  const toggleBidBuyAnimationOpen = () => setBidBuyAnimationOpen((prevState) => !prevState);

  useEffect(() => {
    if (bidBuyAnimationOpen) setBidBuyStatus((prevState) => ({ ...prevState, message: null, success: null }));
  }, [bidBuyAnimationOpen]);

  const mutation = useMutation(
    async (bid: SetBidRequestDTO) => {
      toggleBidBuyAnimationOpen();
      const result = await ApiService.newBids.bidNewControllerUpsertBid(bid);
      if (!result) throw new Error('Bid or Buy denied by the server');
      return result;
    },
    {
      onSuccess: (data, variables) => {
        setBidBuyStatus((prevState) => ({
          ...prevState,
          message: t(variables.fixedPrice ? 'alerts.buySuccessful' : 'alerts.bidsSuccessfullyTransmitted'),
          success: true,
        }));
        TagManager.dataLayer({
          dataLayer: {
            event: 'car-bid',
          },
        });
      },
      onError: (error, variables) => {
        setBidBuyStatus((prevState) => ({
          ...prevState,
          message: t(variables.fixedPrice ? 'alerts.buyNotExecuted' : 'alerts.bidsNotTransmitted'),
          success: false,
        }));
      },
    },
  );

  return { ...mutation, bidBuyFeedbackOpen: bidBuyAnimationOpen, bidBuyStatus, toggleBidBuyAnimationOpen };
};
