import { isEmpty } from 'lodash';
import { tr } from 'src/modules/i18n-helpers';
import ArrayFilterChip from './ArrayFilterChip';
import FilterChip from './FilterChip';
import { SearchFilter } from './types';
import { useBidsFilter } from './useBidsFilter';
import { useFilter } from './useFilter';

type SearchFilterChipProps = {
  searchObj: SearchFilter;
  name: string;
  prefix?: string;
};

const SearchFilterChip = ({ searchObj: { vins, value }, name, prefix }: SearchFilterChipProps) => {
  const { watch: watchBidsFilter, setFilter: setBidsFilter } = useBidsFilter();
  const { watch: watchCarsFilter, setFilter: setCarsFilter } = useFilter();
  const bidsFilterState = watchBidsFilter();
  const carsFilterState = watchCarsFilter();

  const handleOnDelete = () => {
    if (prefix) {
      setBidsFilter({
        ...bidsFilterState,
        carsRequest: { ...bidsFilterState.carsRequest, search: { ...bidsFilterState.carsRequest.search, value: '' } },
      });
    } else {
      setCarsFilter({
        ...carsFilterState,
        search: { ...carsFilterState.search, value: '' },
      });
    }
  };

  return (
    <>
      {!isEmpty(vins) && (
        <ArrayFilterChip value={vins} name="search.vins" tooltip={tr(`carsTableNext.filter.search.vin`)} />
      )}
      {value && (
        <FilterChip
          key={`${name}-${value}`}
          tooltip={tr(`carsTableNext.filter.search.label`)}
          label={value}
          name="search.value"
          resetEffect="undefined"
          value={value}
          onDelete={() => handleOnDelete()}
        />
      )}
    </>
  );
};

export default SearchFilterChip;
