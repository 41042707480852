import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { ListCarDTO } from '../modules/generated/api';
import DialogWithClose from './DialogWithClose';
import CarDetail from '../containers/CarDetail';
import ActionCarDetailLink from './ActionCarDetailLink';

type CarDetailDialogProps = {
  car?: ListCarDTO;
  index?: number;
  maxCars: number;
  moveToNext?: () => void;
  moveToPrev?: () => void;
  onClose: () => void;
  onEdit: () => Promise<unknown>;
};

const CarDetailDialog = ({ car, index, maxCars, moveToNext, moveToPrev, onClose, onEdit }: CarDetailDialogProps) => {
  const { t } = useTranslation();

  const { carId, source } = car || {};

  const disablePagination = index !== undefined && index + 1 >= maxCars;
  return (
    <DialogWithClose
      open={!!car}
      onClose={onClose}
      maxWidth="xl"
      actionsTopLeft={
        <>
          <Tooltip title={t('carsTable.prevPage')} placement="right">
            <span>
              <IconButton disabled={index === 0} onClick={moveToPrev}>
                <NavigateBefore />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={t('carsTable.nextPage')} placement="right">
            <span>
              <IconButton disabled={disablePagination} onClick={moveToNext}>
                <NavigateNext />
              </IconButton>
            </span>
          </Tooltip>
        </>
      }
      actionsTopRight={<ActionCarDetailLink carId={carId} source={source} tooltipPosition="left" />}
    >
      {source && carId && (
        <CarDetail source={source} carId={carId} onRefresh={onEdit} valuationCountry={car?.potential?.country} />
      )}
    </DialogWithClose>
  );
};

export default CarDetailDialog;
