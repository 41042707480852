import React from 'react';
import { Button, Collapse, makeStyles, Paper } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';
import WithChildren from '../types/WithChildren';
import { useLocalStorage } from '../hooks/useLocalStorage';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(6),
  },
  btnMiddle: {
    left: '50%',
    right: '50%',
  },
  paper: {
    padding: theme.spacing(4),
    borderRadius: 0,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  expandButton: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
}));

type TableControlWrapperProps = {
  expandLabel: string;
  storageKey: string;
  onToggle?: (open: boolean) => void;
};

const TableControlWrapper = ({
  children,
  expandLabel,
  storageKey,
  onToggle,
}: WithChildren<TableControlWrapperProps>) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useLocalStorage(`gwscout/${storageKey}.expanded`, true);

  function handleClickToggle() {
    setExpanded((prev) => !prev);
    onToggle?.(expanded);

    if (expanded) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }

  return (
    <div className={clsx(classes.root, !expanded && classes.btnMiddle)}>
      <Collapse in={expanded}>
        <Paper className={classes.paper} elevation={6}>
          {expanded && children}
        </Paper>
      </Collapse>

      <div className={classes.buttonWrapper}>
        <Button
          className={classes.expandButton}
          onClick={handleClickToggle}
          endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
          variant="contained"
          color="secondary"
          size="small"
        >
          {expandLabel}
        </Button>
      </div>
    </div>
  );
};

export default TableControlWrapper;
