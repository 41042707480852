export enum Potential {
  base = 'base',
  potentialMeanAbsolute = 'potentialMeanAbsolute',
  potentialMeanRelative = 'potentialMeanRelative',
  potentialMinAbsolute = 'potentialMinAbsolute',
  potentialMinRelative = 'potentialMinRelative',
  potentialMinMeanAbsolute = 'potentialMinMeanAbsolute',
  potentialMinMeanRelative = 'potentialMinMeanRelative',
  purchaseGross = 'purchaseGross',
  purchaseNetWholesaleUnrepaired = 'purchaseNetWholesaleUnrepaired',
  purchaseNetRetailUnrepaired = 'purchaseNetRetailUnrepaired',
  purchaseNetRetailRepaired = 'purchaseNetRetailRepaired',
}
