import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Chip,
  CircularProgress,
  IconButton,
  makeStyles,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { Delete as DeleteIcon, Edit as EditIcon, Save as SaveIcon } from '@material-ui/icons';
import DeliveryAddressSelect from '@components/dealer-table/DeliveryAddressSelect';
import {
  DeliveryAddressDTO,
  PlatformCredentialsMetadataDTO,
  SourceType,
  UsernameStatusBasic,
} from '../../modules/generated/api';
import { formatDeliveryAddress, getLabel, sources, USERNAME_STATUS_LABELS } from '../../modules/labels';
import theme from '../../setup/theme';

const useStyles = makeStyles({
  actions: {
    width: 220,
    '& > * + *': {
      marginInlineStart: theme.spacing(2),
    },
  },

  spinner: {
    display: 'inline-block',
    width: '1em !important',
    height: '1em !important',
    marginInlineStart: theme.spacing(1),
  },
  tooltipTrigger: {
    textDecoration: 'underline dotted',
    cursor: 'help',
  },
  statusCell: {
    paddingRight: 0,
  },
  kvps: {
    padding: 0,
    paddingLeft: '1em',
    margin: 0,
  },
  detailHeadline: {
    fontWeight: theme.typography.fontWeightBold as number,
    marginBlockEnd: theme.spacing(1),
  },
});

const getStatusColor = (status: UsernameStatusBasic): string =>
  ({
    [UsernameStatusBasic.Disabled]: theme.palette.error.main,
    [UsernameStatusBasic.Enabled]: theme.palette.success.main,
    [UsernameStatusBasic.Pending]: theme.palette.grey[300],
  })[status];
type DealerInfoProps = {
  kvps: string[];
};

const DealerInfos = ({ kvps }: DealerInfoProps): React.ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Typography variant="body2" className={classes.detailHeadline}>
        {t('dealerTable.infosHeadline')}
      </Typography>
      <ul className={classes.kvps}>
        {kvps.map((id) => (
          <li key={id}>
            <Typography variant="body2">{id}</Typography>
          </li>
        ))}
      </ul>
    </div>
  );
};

type DealerRowProps = {
  username: string;
  metadata: PlatformCredentialsMetadataDTO;
  onDelete: (id: string) => void;
  onSave: (id: string, username: string, password: string, metadata: PlatformCredentialsMetadataDTO) => void;
  validated?: boolean;
  status: UsernameStatusBasic;
  kvps: string[];
  displayPostalCode: boolean;
  source: SourceType;
  id: string;
  deliveryAddresses?: DeliveryAddressDTO[];
  classes: { select: string };
  // Address that should be displayed when not edited
  deliveryAddress?: DeliveryAddressDTO;
};

const DealerRow = ({
  username,
  metadata,
  onDelete,
  onSave,
  validated,
  status,
  kvps,
  displayPostalCode,
  source,
  id,
  deliveryAddresses,
  deliveryAddress,
  classes: externalClasses,
}: DealerRowProps): React.ReactElement => {
  const { t } = useTranslation();
  const classes = { ...useStyles(), ...externalClasses };
  const [editMode, setEditMode] = useState(false);
  const loading = validated === false;
  const renderInfos = kvps.length > 0;
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      newPassword: '',
      metadata,
    },
  });
  const selectedSourceOption = sources.find((s) => s.value === source);

  const handleClickEdit = () => {
    setEditMode(true);
  };

  const handleClickCancel = () => {
    setValue('newPassword', '');
    setEditMode(false);
  };

  const handleClickSave = handleSubmit(({ newPassword, metadata: newMetdata }) => {
    onSave(id, username, newPassword, newMetdata);
    setValue('newPassword', '');
    setEditMode(false);
  });

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Typography variant="body2" component="div">
          <Tooltip title={renderInfos ? <DealerInfos kvps={kvps} /> : ''} placement="bottom-start">
            <span className={clsx(kvps.length > 0 && classes.tooltipTrigger)}>{username}</span>
          </Tooltip>
          {loading && <CircularProgress className={classes.spinner} />}
        </Typography>
      </TableCell>
      <TableCell>
        {editMode ? (
          <Controller
            control={control}
            name="metadata.displayName"
            render={({ field }) => (
              <TextField label={t('contact.displayName')} type="text" size="small" variant="outlined" {...field} />
            )}
          />
        ) : (
          <TextField disabled size="small" variant="outlined" value={metadata?.displayName} />
        )}
      </TableCell>
      <TableCell>
        {editMode ? (
          <Controller
            control={control}
            name="newPassword"
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                label={t('common.password')}
                type="text"
                size="small"
                variant="outlined"
                error={!!errors.newPassword}
                {...field}
              />
            )}
          />
        ) : (
          <TextField type="password" disabled size="small" variant="outlined" value="password-hidden" />
        )}
      </TableCell>
      {displayPostalCode && (
        <TableCell>
          {editMode ? (
            <Controller
              control={control}
              name="metadata.postalCode"
              render={({ field }) => (
                <TextField label={t('contact.postal')} type="text" size="small" variant="outlined" {...field} />
              )}
            />
          ) : (
            <TextField disabled size="small" variant="outlined" value={metadata?.postalCode || t('contact.postal')} />
          )}
        </TableCell>
      )}

      <TableCell>{selectedSourceOption ? t(selectedSourceOption.label as any) : 'UNKNOWN'}</TableCell>

      <TableCell>
        {editMode && (
          <DeliveryAddressSelect
            name="metadata.defaultDeliveryAddressId"
            deliveryAddresses={deliveryAddresses}
            control={control}
            className={classes.select}
          />
        )}
        {!editMode && deliveryAddress && formatDeliveryAddress(deliveryAddress)}
        {!editMode && !deliveryAddress && deliveryAddresses !== undefined && 'N/A'}
      </TableCell>

      <TableCell className={classes.statusCell}>
        <Chip
          label={getLabel(USERNAME_STATUS_LABELS, status)}
          size="small"
          style={{ background: getStatusColor(status) }}
        />
      </TableCell>

      <TableCell align="right" className={classes.actions}>
        {editMode ? (
          <Button variant="text" color="secondary" onClick={handleClickCancel}>
            {t('common.cancel')}
          </Button>
        ) : (
          <IconButton
            size="small"
            color="secondary"
            aria-label={t('common.delete')}
            onClick={() => onDelete(id)}
            disabled={loading}
          >
            <DeleteIcon />
          </IconButton>
        )}
        {editMode ? (
          <Button variant="contained" startIcon={<SaveIcon />} color="primary" onClick={handleClickSave} size="small">
            {t('common.save')}
          </Button>
        ) : (
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            color="secondary"
            onClick={handleClickEdit}
            disabled={loading}
            size="small"
          >
            {t('common.edit')}
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default DealerRow;
