import React, { CSSProperties, ReactNode } from 'react';
import { styled, Typography, TypographyProps } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';
import type { Merge } from 'type-fest';
import theme from '../../setup/theme';

type TextAlign = 'left' | 'right' | 'center';

export type TextCellProps = {
  text: ReactNode;
  supportText?: ReactNode;
  textAlign?: TextAlign;
  startAdornment?: ReactNode;
};

type TextProps = {
  textAlign?: TextAlign;
  fontWeight?: CSSProperties['fontWeight'];
  color?: string;
};

type SupportTextProps = {
  textAlign?: TextAlign;
};

const Text = styled(
  ({ fontWeight: _fontWeight, color: _color, textAlign: align, ...rest }: Merge<TypographyProps, TextProps>) => (
    <Typography component={'div' as any} align={align} {...rest} />
  ),
)({
  // @ts-expect-error: fixed in v5
  fontWeight: (props: TextProps) => props.fontWeight,
  color: (props: TextProps) => props.color,
});

const SupportText = styled(({ textAlign: align, ...rest }: Merge<TypographyProps, SupportTextProps>) => (
  <Typography component={'div' as any} align={align} {...rest} />
))({
  color: grey[700],

  '& svg': {
    verticalAlign: 'middle',
  },

  '& .MuiCircularProgress-root': {
    marginInline: theme.spacing(0.5),
  },
});

const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
});

export const TextCell = ({ text, supportText, textAlign, startAdornment }: TextCellProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {startAdornment}
      <div>
        <Text
          variant="body2"
          fontWeight={supportText ? 600 : 'normal'}
          textAlign={textAlign}
          color={supportText ? grey[900] : grey[700]}
        >
          {/* @ts-expect-error: dynamic translation key */}
          {typeof text === 'string' ? t(text) : text}
        </Text>
        {supportText ? (
          <SupportText variant="body2" textAlign={textAlign}>
            {/* @ts-expect-error: dynamic translation key */}
            {typeof supportText === 'string' ? t(supportText) : supportText}
          </SupportText>
        ) : null}
      </div>
    </Wrapper>
  );
};
