import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { Controller } from 'react-hook-form';

const useStyles = makeStyles(() => ({
  rating: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type UserRatingFilterProps = {
  name: string;
  type: 'own' | 'any';
};

const UserRatingFilter = ({ name, type }: UserRatingFilterProps) => {
  const classes = useStyles();

  return (
    <Controller
      name={name}
      render={({ field: { value, onChange } }) => (
        <Rating
          className={classes.rating}
          name={type === 'own' ? 'ratingFilter' : 'anyRatingFilter'}
          onChange={(_, changeValue) => onChange(changeValue ?? 0)}
          value={value}
        />
      )}
    />
  );
};

export default UserRatingFilter;
