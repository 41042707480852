import { useLocation } from '@reach/router';

const useQueryParam = (param: string) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParam = queryParams.get(param);
  const urlWithoutQueryParams = `${location.protocol}//${location.host}${location.pathname}`;

  return { queryParam, urlWithoutQueryParams };
};

export default useQueryParam;
