import { useEffect } from 'react';
import ApiService from '../modules/api-service';
import useApi from './useApi';
import { ConditionalFormattingDTO } from '../modules/generated/api';

// Avoid triggering re-render due to new array instantiation
const INIT_CONDITIONAL_FORMATTING: any = [];

const useConditionalFormattings = () => {
  const { fetch: fetchConditionalFormattings, data: conditionalFormattings = INIT_CONDITIONAL_FORMATTING } = useApi<
    ConditionalFormattingDTO[]
  >('/api/config/conditional-formatting');

  useEffect(() => {
    fetchConditionalFormattings(ApiService.config.configControllerGetConditionalFormatting());
  }, [fetchConditionalFormattings]);

  return conditionalFormattings;
};

export default useConditionalFormattings;
