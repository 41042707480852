import { Chip, IconButton, makeStyles, TableCell, Tooltip } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import RepeatIcon from '@material-ui/icons/Repeat';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import { useTranslation } from 'react-i18next';
import useApi from 'src/hooks/useApi';
import useRole from 'src/hooks/useRole';
import useCustomSnackbarGlobal from 'src/hooks/useSnackbarGlobal';
import ApiService from 'src/modules/api-service';
import UserRole from 'src/types/UserRoles';
import { BID_DATE_TIME_FORMAT, formatDate } from '../modules/date-helpers';
import { BidAutomationType, BidSuccessStatus, IndividualBidDTO, SourceType } from '../modules/generated/api';
import BidSuccessStatusChip from './BidSuccessStatusChip';
import UnitValue from './UnitValue';

type BidTableCellsProps = {
  bid: IndividualBidDTO;
  source?: SourceType;
  refresh: () => void;
  dateEnd?: string;
  bidId?: string;
};

const useStyles = makeStyles((theme) => ({
  green: {
    backgroundColor: green[300],
  },
  statusChip: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(0.5),
    '&--primary': {
      backgroundColor: theme.palette.primary.main,
    },
    '&--secondary': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&--error': {
      backgroundColor: theme.palette.error.main,
    },
    '&--success': {
      backgroundColor: theme.palette.success.main,
    },
  },
  statusChipIcon: {
    color: theme.palette.common.white,
  },
  buyButton: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
  },
  cellAlign: {
    display: 'flex',
    alignItems: 'center',
  },
  padRight: {
    paddingRight: theme.spacing(1),
  },
  valueWithIcon: {
    marginBottom: '5px',
  },
}));

const BidTableCells = ({ bid, source, refresh, dateEnd, bidId }: BidTableCellsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { hasRole } = useRole();
  const isAdmin = hasRole(UserRole.ADMIN);
  const snackbar = useCustomSnackbarGlobal();
  const { fetch } = useApi();

  const auctionHasEnded = dateEnd && new Date(dateEnd) < new Date();

  const handleSetToBought = async (dealerId?: string) => {
    if (!bidId || !source) return;
    const response = await fetch(
      ApiService.newBids.bidNewControllerSetBidStatusToBought(source, bidId, dealerId || ''),
    );
    if (response && response.status === 200) {
      snackbar.showSuccess(t('snackBar.success.stateToBuy'));
      refresh();
    } else {
      snackbar.showError(t('snackBar.error.stateToBuy'));
    }
  };

  return (
    <>
      <TableCell>{`${bid.user?.name} (${bid.dealer?.id})`}</TableCell>
      <TableCell>
        <div className={classes.cellAlign}>
          {bid.automatedBy === BidAutomationType.Repeat && (
            <div className={classes.padRight}>
              <Tooltip placement="left" title={t('bids.bidWasSentAutomatically')}>
                <AutorenewIcon color="primary" fontSize="small" />
              </Tooltip>
            </div>
          )}
          {bid.autoRepeatBidOnSubsequentOffer && (
            <div className={classes.padRight}>
              <Tooltip placement="left" title={t('bids.bidAutomationStarted')}>
                <RepeatIcon color="secondary" fontSize="small" />
              </Tooltip>
            </div>
          )}
          <UnitValue
            className={bid.autoRepeatBidOnSubsequentOffer || bid.automatedBy ? classes.valueWithIcon : ''}
            alt={t('common.unavailable')}
            value={bid.bid?.value}
            unit={bid.bid?.unit}
          />
        </div>
      </TableCell>
      <TableCell>{formatDate(bid.sentTime || '', BID_DATE_TIME_FORMAT)}</TableCell>
      {bid.status && (
        <TableCell>
          <BidSuccessStatusChip errorMessage={bid.errorMessage} successStatus={bid.status} />
          {bid.status === BidSuccessStatus.Allocate &&
            auctionHasEnded &&
            isAdmin &&
            source === SourceType.SpotdealsAllmobil && (
              <Tooltip title={t('bids.setToBought')} arrow placement="left">
                <IconButton
                  size="small"
                  className={classes.buyButton}
                  onClick={() => handleSetToBought(bid.dealer?.id)}
                >
                  <ShoppingBasketIcon />
                </IconButton>
              </Tooltip>
            )}
        </TableCell>
      )}
      {!bid.status && !bid.sentTime && (
        <TableCell>
          <Chip label={t('common.pending')} className={classes.statusChip} size="small" color="secondary" />
        </TableCell>
      )}
    </>
  );
};

export default BidTableCells;
