import DeliveryAddressSelect from '@components/dealer-table/DeliveryAddressSelect';
import { Button, CircularProgress, Grid, IconButton, makeStyles } from '@material-ui/core';
import { Cancel, OpenInNew, Save } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { navigate } from '@reach/router';
import { useEffect, useState } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useApi from 'src/hooks/useApi';
import ApiService from 'src/modules/api-service';
import { RoutePaths } from '../constants';
import { DeliveryAddressDTO, TransportOptionsDTO } from '../modules/generated/api';
import { BoughtCarDeliveryAddressForm } from './BoughtCarDeliveryAddressTransportation';
import { TransportSelectBoughtCar } from './bid-buy/TransportSelect';

type DeliveryAddressFormProps = {
  deliveryAddresses: DeliveryAddressDTO[];
  isLoading?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  methods: UseFormReturn<BoughtCarDeliveryAddressForm>;
  showLink?: boolean;
  onlyDelivery?: boolean;
  bidId?: string;
  originTransportationId?: string
};

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: theme.spacing(2),
  },
}));

const DeliveryAddressForm = ({
  onCancel,
  onSubmit,
  isLoading,
  methods,
  deliveryAddresses = [],
  showLink,
  onlyDelivery = false,
  bidId,
  originTransportationId
}: DeliveryAddressFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { fetch, loading: transportOptionsLoading } = useApi<TransportOptionsDTO>();
  const [transportOptions, setTransportOptions] = useState<TransportOptionsDTO>();
  const { watch, setValue } = methods;
  const transportId = watch('transportOptionId');
  const deliveryId = watch('deliveryAddressId');
  const optionsAvailable = transportOptions?.options && transportOptions.options.length > 0;

  useEffect(() => {
    if (transportId !== undefined && transportOptions?.options?.find((option) => option.id === transportId) === undefined)
      setValue('transportOptionId', undefined, { shouldValidate: true });
  }, [deliveryId, setValue, transportId, transportOptions?.options]);

  useEffect(() => {
    if (!bidId) return;
    fetch(
      ApiService.transportationController.transportationControllerGetTransportOptionsForBid(
        bidId!,
        deliveryId || '',
      ),
    ).then((response) => {
      if (response?.data) setTransportOptions(response.data);
    });
  }, [bidId, deliveryId, fetch]);

  return (
    <FormProvider {...methods}>
      <Grid item container alignItems="center">
        <Grid container spacing={1}>
          <Grid item xs={showLink ? 10 : 12}>
            <DeliveryAddressSelect
              fullWidth
              name="deliveryAddressId"
              deliveryAddresses={deliveryAddresses}
              control={methods.control}
            />
          </Grid>
          {showLink && (
            <Grid item xs={2}>
              <IconButton onClick={() => navigate(RoutePaths.deliveryAddress)}>
                <OpenInNew />
              </IconButton>
            </Grid>
          )}
          {!onlyDelivery &&
            <>
              {!transportOptionsLoading && <Alert severity={optionsAvailable ? "info" : "warning"}>{optionsAvailable ? t('configuration.resetTransportOption') : t("configuration.noTransportOptionsAvailable")}</Alert>}
              <Grid item xs={10}>
                <TransportSelectBoughtCar
                  options={transportOptions?.options || []}
                  loading={transportOptionsLoading}
                  disabled={!deliveryId || !optionsAvailable}
                  fullWidth
                  preSelectedId={originTransportationId}
                />
              </Grid>
            </>
          }
        </Grid>
        <Grid container item spacing={1} className={classes.buttons}>
          <Grid item>
            <Button color="secondary" disabled={isLoading} endIcon={<Cancel />} onClick={onCancel} variant="contained">
              {t('common.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              disabled={isLoading || (!onlyDelivery && !transportId) || (!onlyDelivery && !optionsAvailable)}
              endIcon={isLoading ? <CircularProgress size={20} /> : <Save />}
              type="submit"
              variant="contained"
              onClick={onSubmit}
            >
              {t('common.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default DeliveryAddressForm;
