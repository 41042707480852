import React from 'react';
import { Chip as MuiChip, ChipProps as MuiChipProps, createStyles, withStyles } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';

export type BadgeCellProps = {
  color: BadgeColors;
  label: string;
};

type BadgeProps = {
  color: BadgeColors;
};

type BadgeColors = keyof typeof colors;

const colors = {
  success: {
    background: green[50],
    color: green[700],
  },
  error: {
    background: red[50],
    color: red[700],
  },
};

const Badge = withStyles(
  createStyles<string, Omit<MuiChipProps, keyof BadgeProps> & BadgeProps>({
    root: (props) => ({
      fontWeight: 'bold',
      ...colors[props.color],
    }),
  }),
  {
    name: 'Badge',
  },
)(({ color: _color, ...props }: Omit<MuiChipProps, keyof BadgeProps> & BadgeProps) => <MuiChip {...props} />);

export const BadgeCell = ({ color, label, ...rest }: BadgeCellProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Badge
      color={color}
      size="small"
      // @ts-expect-error: dynamic translation key
      label={t(label)}
      {...rest}
    />
  );
};
