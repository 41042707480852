import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { getLabel, promotions } from '../modules/labels';
import FilterAutocomplete from './FilterAutocomplete';
import { SummaryDTO } from '../modules/generated/api';
import { IconType } from '../modules/data';

type PromotionsSelectProps = {
  filterSummary?: SummaryDTO;
};
const PromotionsSelect = ({ filterSummary }: PromotionsSelectProps) => {
  const { t } = useTranslation();

  return (
    <FilterAutocomplete
      name="promotions"
      label={t('filterSearch.promotions')}
      multiple
      options={
        filterSummary?.promotions?.map((promotion) => ({
          value: promotion,
          label: getLabel(promotions, promotion),
        })) || []
      }
      iconType={IconType.PROMOTION}
    />
  );
};

export default memo(PromotionsSelect);
