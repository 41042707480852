import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { formatDate } from '../modules/date-helpers';
import carCulatorLogoImageVertical from '../assets/images/carculator-vertical.png';
import carCulatorLogoImage from '../assets/images/carculator.png';

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 0,
    padding: `${theme.spacing(0.75)}px ${theme.spacing(0.5)}px`,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[4],
    border: `2px solid ${theme.palette.primary.main}`,
  },
  image: {
    display: 'block',
  },
  image___vertical: {
    width: theme.spacing(5),
  },
  image___horizontal: {
    width: theme.spacing(10),
  },
}));

type ActionCarCulatorLinkProps = {
  firstRegistration?: string;
  mileage?: number;
  vin?: string;
  layout?: 'vertical' | 'horizontal';
};

const ActionCarCulatorLink = ({ firstRegistration, mileage, vin, layout = 'vertical' }: ActionCarCulatorLinkProps) => {
  const classes = useStyles();
  const disabled = !firstRegistration || mileage === undefined || !vin;

  return (
    <Button
      onClick={(event) => event.stopPropagation()}
      disabled={disabled}
      href={`https://carculator.auto-partner.net/app?mileage=${mileage}&modelvariant=${vin}&registrationDate=${formatDate(
        firstRegistration || '',
        'MM/yyyy',
      )}`}
      size="small"
      rel="noreferrer"
      target="_blank"
      className={classes.button}
      variant="contained"
    >
      <img
        className={clsx(classes.image, {
          [classes.image___vertical]: layout === 'vertical',
          [classes.image___horizontal]: layout === 'horizontal',
        })}
        src={layout === 'vertical' ? carCulatorLogoImageVertical : carCulatorLogoImage}
        alt="CARculator"
      />
    </Button>
  );
};

export default ActionCarCulatorLink;
