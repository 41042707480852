import { isFunction } from 'lodash';
import { useCallback, useState } from 'react';

export const useLocalStorage = <T>(key: string, initialValue: T | (() => T), isValid?: (value: any) => void) => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key) as string | null;
      if (item) isValid?.(JSON.parse(item));

      if (item) {
        return JSON.parse(item);
      }
      return isFunction(initialValue) ? initialValue() : initialValue;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(key, error);
      return isFunction(initialValue) ? initialValue() : initialValue;
    }
  });

  const setValue = useCallback(
    (value: T | ((val: T) => T), skipPersist = false) => {
      setStoredValue((prevValue) => {
        try {
          const valueToStore = value instanceof Function ? value(prevValue) : value;

          if (typeof window !== 'undefined' && !skipPersist) {
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
          }

          return valueToStore;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }

        return prevValue;
      });
    },
    [key],
  );

  return [storedValue, setValue] as const;
};
