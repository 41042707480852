import React, { useEffect, useMemo } from 'react';
import {
  Button,
  DrawerProps as MuiDrawerProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  OutlinedInput,
  Switch,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import type { Merge } from 'type-fest';
import { Controller, useForm } from 'react-hook-form';
import { Drawer, DrawerHeader, DrawerContent, DrawerFooter, InputLabel, Stack, Select } from '@components/ui';
import { NotificationChannel, SearchAgentRole } from 'src/modules/generated/api';
import { ExternalSearchAgent } from '@components/ExternalSearchAgentTable';
import { searchAgentRoleOptions } from 'src/modules/labels';
import { isNil } from 'lodash';
import { Row } from '@tanstack/react-table';
import { ExternalSearchAgentNotificationDTO } from '../modules/generated/api/api';

type ExternalSearchAgentTableDrawerProps = {
  title?: string;
  onClose: () => void;
  onSubmit: (data: ExternalSearchAgentFormData) => void;
  row: Row<ExternalSearchAgent>;
};

export type ExternalSearchAgentFormData = {
  notification: ExternalSearchAgentNotificationDTO;
  role: SearchAgentRole;
  active: boolean;
};

export const ExternalSearchAgentTableDrawer = ({
  onClose,
  onSubmit,
  row,
  ...drawerProps
}: Merge<MuiDrawerProps, ExternalSearchAgentTableDrawerProps>) => {
  const { t } = useTranslation();
  const originalValues = useMemo(
    () => ({
      notification: row.original?.notification ?? {},
      role: row.original?.role,
      active: row.original?.active,
    }),
    [row.original],
  );
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<ExternalSearchAgentFormData>({
    defaultValues: originalValues,
  });
  const selectedActive = watch('active');

  const handleClose = () => {
    onClose();
    reset(originalValues);
  };

  useEffect(() => {
    reset(originalValues);
  }, [originalValues, reset]);

  return (
    <Drawer anchor="right" onClose={handleClose} {...drawerProps}>
      <DrawerHeader>
        <Typography>{t('externalSearchAgentTableDrawer.title')}</Typography>
      </DrawerHeader>
      <DrawerContent>
        <form id="search-agent-edit-form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <div>
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name="active"
                    render={({ field: { ref, value, ...field } }) => (
                      <Switch inputRef={ref} {...field} checked={value} size="small" color="primary" />
                    )}
                  />
                }
                label={selectedActive ? t('common.active') : t('common.inactive')}
              />
            </div>

            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel>
                {t('common.name')} ({t('common.readonly')})
              </InputLabel>
              <OutlinedInput readOnly value={row.original?.name} />
            </FormControl>

            <div>
              <InputLabel>{t('externalSearchAgentTable.role')}</InputLabel>
              <Controller
                control={control}
                name="role"
                render={({ field: { ref, ...field } }) => (
                  <Select
                    inputRef={ref}
                    {...field}
                    options={[
                      ...(isNil(row.original?.role)
                        ? [{ value: '', label: t('searchAgentRoles.EMPTY'), options: { disabled: true } }]
                        : []),
                      ...searchAgentRoleOptions,
                    ]}
                    displayEmpty
                  />
                )}
              />
            </div>

            <div>
              <InputLabel>{t('common.notification_plural')}</InputLabel>
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name="notification.active"
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Switch
                        inputRef={ref}
                        onChange={(e) => {
                          setValue('notification.channel', NotificationChannel.EMail);
                          onChange(e.target.checked);
                        }}
                        {...field}
                        checked={Boolean(value)}
                        size="small"
                        color="primary"
                      />
                    )}
                  />
                }
                label={t('notification.E_MAIL')}
              />
              {errors.notification?.channel ? (
                <FormHelperText error>{errors.notification.channel?.type}</FormHelperText>
              ) : null}
            </div>
          </Stack>
        </form>
      </DrawerContent>
      <DrawerFooter>
        <Grid container justifyContent="space-between">
          <Button size="small" variant="outlined" color="secondary" onClick={handleClose}>
            {t('common.cancel')}
          </Button>
          <Button type="submit" form="search-agent-edit-form" size="small" variant="contained" color="primary">
            {t('common.save')}
          </Button>
        </Grid>
      </DrawerFooter>
    </Drawer>
  );
};
