import React, { useEffect, useMemo } from 'react';
import {
  Button,
  DrawerProps as MuiDrawerProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  OutlinedInput,
  Switch,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import type { Merge } from 'type-fest';
import { Controller, useForm } from 'react-hook-form';
import { Drawer, DrawerHeader, DrawerContent, DrawerFooter, InputLabel, Stack } from '@components/ui';
import { NotificationChannel } from 'src/modules/generated/api';

type SearchAgentDrawerProps = {
  title?: string;
  onClose: () => void;
  onSubmit: (data: SearchAgentFormData) => void;
  searchAgent: {
    id: string;
    searchAgentDisplayName: string;
    active?: boolean;
    favorite?: boolean;
  };
};

enum DisplayChannel {
  favorites = 'favorites',
}

export type SearchAgentFormData = {
  searchAgentDisplayName: string;
  notifications: Record<NotificationChannel, boolean>;
  display: Record<DisplayChannel, boolean>;
};

export const SearchAgentDrawer = ({
  onClose,
  onSubmit,
  searchAgent: originalSearchAgent,
  ...drawerProps
}: Merge<MuiDrawerProps, SearchAgentDrawerProps>) => {
  const { t } = useTranslation();
  const originalValues = useMemo(
    () => ({
      searchAgentDisplayName: originalSearchAgent.searchAgentDisplayName,
      notifications: {
        [NotificationChannel.EMail]: originalSearchAgent.active,
      },
      display: {
        [DisplayChannel.favorites]: originalSearchAgent.favorite,
      },
    }),
    [originalSearchAgent.active, originalSearchAgent.favorite, originalSearchAgent.searchAgentDisplayName],
  );
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<SearchAgentFormData>({
    defaultValues: originalValues,
  });

  const handleClose = () => {
    onClose();
    reset(originalValues);
  };

  useEffect(() => {
    reset(originalValues);
  }, [originalValues, reset]);

  return (
    <Drawer anchor="right" onClose={handleClose} {...drawerProps}>
      <DrawerHeader>
        <Typography>{t('searchAgentTableDrawer.title')}</Typography>
      </DrawerHeader>
      <DrawerContent>
        <form id="search-agent-edit-form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel required>{t('common.name')}</InputLabel>
              <Controller
                name="searchAgentDisplayName"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => <OutlinedInput inputRef={ref} {...field} />}
              />
              {errors.searchAgentDisplayName ? (
                <FormHelperText error>{errors.searchAgentDisplayName.type}</FormHelperText>
              ) : null}
            </FormControl>

            <div>
              <InputLabel>{t('common.display')}</InputLabel>
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name={`display.${DisplayChannel.favorites}`}
                    render={({ field: { ref, value, ...field } }) => (
                      <Switch inputRef={ref} {...field} checked={value} size="small" color="primary" />
                    )}
                  />
                }
                label={t('searchAgentTableDrawer.display.favorites')}
              />
              {errors.display?.[DisplayChannel.favorites] ? (
                <FormHelperText error>{errors.display?.[DisplayChannel.favorites]?.type}</FormHelperText>
              ) : null}
            </div>

            <div>
              <InputLabel>{t('common.notification_plural')}</InputLabel>
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name={`notifications.${NotificationChannel.EMail}`}
                    render={({ field: { ref, value, ...field } }) => (
                      <Switch inputRef={ref} {...field} checked={value} size="small" color="primary" />
                    )}
                  />
                }
                label={t('notification.E_MAIL')}
              />
              {errors.notifications?.[NotificationChannel.EMail] ? (
                <FormHelperText error>{errors.notifications[NotificationChannel.EMail]?.type}</FormHelperText>
              ) : null}
            </div>
          </Stack>
        </form>
      </DrawerContent>
      <DrawerFooter>
        <Grid container justifyContent="space-between">
          <Button size="small" variant="outlined" color="secondary" onClick={handleClose}>
            {t('common.cancel')}
          </Button>
          <Button type="submit" form="search-agent-edit-form" size="small" variant="contained" color="primary">
            {t('common.save')}
          </Button>
        </Grid>
      </DrawerFooter>
    </Drawer>
  );
};
