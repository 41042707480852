import { client } from '@iris/content';
import { useQuery } from '@tanstack/react-query';
import groq from 'groq';
import { useTranslation } from 'react-i18next';
import { QueryKeys } from 'src/constants';

type Category = {
  _id: string;
  title: string;
  slug: string;
  iconId: string;
};

const allCategory = groq`*[
  _type == 'category' &&
  __i18n_lang == $lang
]|order(coalesce(__i18n_base->orderRank, orderRank)) {
  _id,
  title,
  "slug": slug.current,
  "iconId": coalesce(__i18n_base->_id, _id)
}
`;

export const useAllCategory = () => {
  const { i18n } = useTranslation();

  return useQuery(
    [QueryKeys.allCategory, i18n.language],
    () => client.fetch<Category[]>(allCategory, { lang: i18n.language }),
    {
      staleTime: Infinity,
      suspense: true,
      useErrorBoundary: true,
    },
  );
};
