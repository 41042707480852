import React, { CSSProperties, ReactNode } from 'react';
import { makeStyles, styled, Typography } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import clsx from 'clsx';

type EmptyMessageProps = {
  headline: ReactNode;
  body?: ReactNode;
  actions?: JSX.Element;
  className?: string;
  icon?: JSX.Element;
  style?: CSSProperties;
};

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    paddingBlock: theme.spacing(6),
    paddingInline: theme.spacing(4),
  },
  reason: {
    marginBlockEnd: theme.spacing(2),
  },
}));

export const EmptyMessageIcon = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  borderRadius: '99rem',
  background: orange[100],
  borderWidth: theme.spacing(1),
  borderColor: orange[50],
  borderStyle: 'solid',
  color: theme.palette.primary.main,
  padding: theme.spacing(1),
}));

export const EmptyMessage = ({ headline, body, actions, className, icon, style }: EmptyMessageProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} style={style}>
      {icon}
      <Typography variant="h6" gutterBottom>
        {headline}
      </Typography>
      {body ? (
        <Typography variant="body2" className={classes.reason}>
          {body}
        </Typography>
      ) : null}
      {actions}
    </div>
  );
};
