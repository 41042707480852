import React, { useState } from 'react';
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Stack } from '@components/ui';
import clsx from 'clsx';
import { useCurrency } from 'src/hooks/useCurrency';
import { CurrencyCode } from 'src/modules/generated/api';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'src/constants';
import { getLabel, sources } from 'src/modules/labels';
import { useTranslation } from 'react-i18next';
import { createStrictPacket, getPositionsTotal } from './lib';
import { ServicePacket } from './types';
import { fetchPacket } from './usePacket';

type ServicePacketItemProps = {
  packet: ServicePacket;
  onClickEdit: () => void;
  onClickDelete: () => void;
};

type DeleteDialogProps = {
  onSubmit: () => void;
  onCancel: () => void;
  open: boolean;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    gap: theme.spacing(6),
  },
  title: {
    wordBreak: 'break-all',
  },
  iconButton: {
    marginBlock: theme.spacing(-1.5),
    marginInline: theme.spacing(-0.5),
  },
  buttonDelete: {
    color: theme.palette.error.main,
  },
  meta: {
    color: theme.palette.grey[500],
  },
  total: {
    textAlign: 'right',
    fontVariantNumeric: 'tabular-nums',
    marginInlineEnd: theme.spacing(4),
  },
}));

const DeleteDialog = ({ open, onSubmit, onCancel }: DeleteDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-delete-dialog-title"
      aria-describedby="alert-delete-dialog-description"
    >
      <DialogTitle id="alert-delete-dialog-title">{t('servicePacketItem.deleteDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-delete-dialog-description">
          {t('servicePacketItem.deleteDialog.content')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {t('action.cancel')}
        </Button>
        <Button onClick={onSubmit} color="primary" autoFocus>
          {t('action.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ServicePacketItem = ({
  packet: { id, name, country, source, positions },
  onClickEdit,
  onClickDelete,
}: ServicePacketItemProps) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { format } = useCurrency();
  const total = getPositionsTotal(positions);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const preloadPacket = () => {
    queryClient.prefetchQuery([QueryKeys.servicePacket, id], () => fetchPacket(id).then(createStrictPacket));
  };

  const handleClickDelete = () => {
    setDeleteDialogOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = () => {
    onClickDelete();
  };

  return (
    <>
      <Card className={classes.root}>
        <Typography className={classes.title}>
          {name}{' '}
          <span className={classes.meta}>
            • {country} • {getLabel(sources, source)}
          </span>
        </Typography>
        <Stack direction="row" spacing={1}>
          <Typography className={classes.total}>
            {format(total, { maximumFractionDigits: 2, to: CurrencyCode.Eur })}
          </Typography>
          <IconButton
            color="secondary"
            className={classes.iconButton}
            onClick={onClickEdit}
            onMouseEnter={preloadPacket}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="secondary"
            className={clsx(classes.iconButton, classes.buttonDelete)}
            onClick={handleClickDelete}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      </Card>

      <DeleteDialog open={deleteDialogOpen} onSubmit={handleDelete} onCancel={handleCancelDelete} />
    </>
  );
};
