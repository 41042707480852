import React, { memo } from 'react';
import { IconButton } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { getExternalDetailLink } from '../modules/external-detail-link';
import { SourceType } from '../modules/generated/api';
import ActionTooltip from './ActionTooltip';
import { getLabel, sources } from '../modules/labels';
import UserRole from '../types/UserRoles';
import useRole from '../hooks/useRole';

type ActionExternalDetailLinkProps = {
  source?: SourceType;
  carId?: string;
  size?: 'small' | 'medium';
  offerId?: string;
};

const ActionExternalDetailLink = ({ source, carId, size = 'small', offerId }: ActionExternalDetailLinkProps) => {
  const { hasRole } = useRole();
  const { t } = useTranslation();
  const link = getExternalDetailLink(source, carId, offerId);

  return hasRole(UserRole.ADMIN) ? (
    <ActionTooltip title={`${t('carsTable.directLink')} ${getLabel(sources, source as string)}`} disabled={!link}>
      <IconButton disabled={!link} size={size} href={link || ''} rel="noreferrer" target="_blank">
        <OpenInNew />
      </IconButton>
    </ActionTooltip>
  ) : null;
};

export default memo(ActionExternalDetailLink);
