import ImprintContent from '@components/ImprintContent';
import { Button, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DialogWithClose from '../components/DialogWithClose';

type ImprintPopupProps = {
  className?: string;
};

const useStyles = makeStyles((theme) => ({
  hover: {
    '&:hover, &.Mui-focusVisible': {
      color: theme.palette.primary.light,
    },
  },
  helpIcon: {
    fontSize: '1.3rem',
  },
}));

const ImprintPopup = ({ className }: ImprintPopupProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)} className={className} size="small">
        {t('navigation.imprint')}
      </Button>

      <DialogWithClose open={open} onClose={() => setOpen(false)} paddingType="noTop">
        <ImprintContent />
      </DialogWithClose>
    </>
  );
};

export default ImprintPopup;
