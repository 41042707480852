import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { useCarsFilter } from '../../hooks/useCarsFilter';
import { useCurrency } from '../../hooks/useCurrency';
import { useDidUpdateEffect } from '../../hooks/useDidUpdateEffect';
import useCustomSnackbarGlobal from '../../hooks/useSnackbarGlobal';
import { useValuationSettings } from '../../hooks/useValuationSettings';
import { useCarsTableStore } from '../../stores/CarsTable';
import type { Action, Message } from './lib';

const worker = new Worker(new URL('./lib.ts', import.meta.url));

type Registration = {
  intervalId: string;
  intervalMs: number;
};

export const useAutoRefresh = () => {
  const { t } = useTranslation();
  const [registration, setRegistration] = useLocalStorage<Registration | null>(
    'gwscout/AutoRefresh.registration',
    null,
  );
  const [isLoading, setIsLoading] = useState(false);
  const lastRegistrationRef = useRef<typeof registration>();
  const { showInfo, showError, showSuccess } = useCustomSnackbarGlobal();
  lastRegistrationRef.current = registration;
  const { mutate } = useCarsTableStore();
  const { values: filter } = useCarsFilter();
  const { rowsPerPage, sortOn, page } = useCarsTableStore();
  const { valuationCountry, valuationType } = useValuationSettings();
  const { currency } = useCurrency();

  useEffect(() => {
    const handleMessage = (event: MessageEvent<Message>) => {
      const msg = event.data;

      switch (msg.type) {
        case 'register':
          setRegistration(msg.payload);
          showSuccess(t('autoRefresh.alters.register'));
          break;

        case 'remove':
          setRegistration(null);
          setIsLoading(false);
          showSuccess(t('autoRefresh.alters.remove'));
          break;

        case 'update': {
          mutate(msg.payload);
          setIsLoading(false);
          break;
        }

        case 'loading': {
          setIsLoading(true);
          break;
        }

        case 'error': {
          setRegistration(null);
          setIsLoading(false);
          worker.postMessage({ type: 'cancel', payload: { intervalId: lastRegistrationRef.current } });
          showError(t('autoRefresh.alters.error'));
          break;
        }

        default:
        // ignore message
      }
    };

    worker.addEventListener('message', handleMessage);

    // unsubscribe polling and prevent memory leaks
    return () => {
      worker.removeEventListener('message', handleMessage);

      if (!lastRegistrationRef.current) {
        return;
      }

      const cancelAction: Action = { type: 'cancel', payload: { intervalId: lastRegistrationRef.current.intervalId } };
      worker.postMessage(cancelAction);
    };
  }, [mutate, setRegistration, showError, showInfo, showSuccess, t]);

  useDidUpdateEffect(() => {
    if (lastRegistrationRef.current) {
      const removeAction: Action = { type: 'remove', payload: { intervalId: lastRegistrationRef.current.intervalId } };
      worker.postMessage(removeAction);
    }
  }, [currency, filter, rowsPerPage, page, sortOn, valuationCountry, valuationType]);

  return {
    worker,
    ...registration,
    active: !!registration,
    isLoading,
  };
};
