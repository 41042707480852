import React, { useMemo, useState } from 'react';
import { getCoreRowModel, useReactTable, ColumnDef } from '@tanstack/react-table';
import { Box, CircularProgress, Fade, IconButton, Tooltip } from '@material-ui/core';
import { HeadCell, TextCell, Table, TimestampCell, Menu, MenuAction, useMenu, TextFallback } from '@components/ui';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { LeadToggleCell } from '@components/ui/LeadToggleCell';
import { useSearchAgentDelete } from 'src/hooks/useSearchAgentDelete';
import { useSearchAgentUpdate } from 'src/hooks/useSearchAgentUpdate';
import { useQueryClient } from '@tanstack/react-query';
import { useSearchAgents } from 'src/hooks/useSearchAgents';
import { QueryKeys, RoutePaths } from 'src/constants';
import { NotificationChannel, SearchAgentDTO } from 'src/modules/generated/api';
import { navigate } from '@reach/router';
import { SearchAgentFormData, SearchAgentDrawer } from '@components/SearchAgentDrawer';
import StarIcon from '@material-ui/icons/Star';
import { useTranslation } from 'react-i18next';
import { useSearchAgentPreview } from 'src/hooks/useSearchAgentPreview';
import { useSources } from 'src/hooks/useSources';
import { useUser } from 'src/hooks/useUser';
import { merge } from 'lodash';
import { getDefaultFilter, persistFilter, transformForForm } from './cars-table-next/filter/lib';

type SearchAgent = {
  id: string;
  searchAgentDisplayName: string;
  notification: NotificationChannel;
  lastUpdatedAt?: string;
  active?: boolean;
  favorite?: boolean;
  preset: SearchAgentDTO;
};

export type RowProps = any;

export type SearchAgentTableProps = {
  className?: string;
};

enum SearchAgentAction {
  edit,
  load,
  delete,
}

const columns: ColumnDef<SearchAgent>[] = [
  {
    id: 'name',
    header: () => <HeadCell text="common.name" />,
    cell: ({ row }) => {
      const { t } = useTranslation();
      const queryClient = useQueryClient();
      const { id, active, searchAgentDisplayName, favorite } = row.original ?? {};
      const { mutate: updateSearchAgent } = useSearchAgentUpdate();
      const { data: preview, isLoading: previewIsLoading, isFetching: previewIsFetching } = useSearchAgentPreview(id);

      const handleChangeActive = () => {
        const searchAgents = queryClient.getQueryData<SearchAgentDTO[]>([QueryKeys.searchAgents]);
        const target = searchAgents?.find((searchAgent) => searchAgent.id === id);

        if (target) {
          updateSearchAgent({ id: id!, ...target, active: !active });
        }
      };

      return (
        <LeadToggleCell
          text={
            <>
              {/* eslint-disable-next-line no-restricted-syntax */}
              <Box marginRight={0.5} component="span">
                {searchAgentDisplayName}
              </Box>
              {favorite ? (
                <Tooltip title={t('searchAgentTable.pinned')} placement="right" arrow>
                  <StarIcon fontSize="inherit" />
                </Tooltip>
              ) : null}
            </>
          }
          supportText={
            previewIsLoading ? (
              <TextFallback width="12ch" />
            ) : (
              <>
                {t('searchAgentTable.preview', { count: preview?.recordsTotal })}
                <Fade
                  in={previewIsFetching}
                  style={{
                    transitionDelay: previewIsFetching ? '300ms' : '800ms',
                  }}
                >
                  <CircularProgress aria-label={t('common.isLoading')} size="12px" color="secondary" />
                </Fade>
              </>
            )
          }
          checked={active ?? false}
          onChange={handleChangeActive}
        />
      );
    },
  },
  {
    accessorKey: 'notification',
    header: () => <HeadCell text="common.notification" />,
    cell: (props) => {
      const { t } = useTranslation();
      const value = props.getValue();

      return <TextCell text={value && t(`notification.${value}` as any)} />;
    },
  },
  {
    accessorKey: 'lastUpdatedAt',
    header: () => <HeadCell text="common.lastUpdatedAt" />,
    cell: (props) => <TimestampCell timestamp={props.getValue<string | undefined>()} />,
  },
  {
    id: 'actions',
    header: () => null,
    cell: ({ row }) => {
      const { t } = useTranslation();
      const { mutate: deleteSearchAgent } = useSearchAgentDelete();
      const { isOpen: isMenuOpen, open: openMenu, close: closeMenu } = useMenu();
      const queryClient = useQueryClient();
      const [drawerOpen, setDrawerOpen] = useState(false);
      const { id, preset } = row.original ?? {};
      const { mutate: updateSearchAgent } = useSearchAgentUpdate();
      const { data: sources } = useSources();
      const { data: user } = useUser();

      const menuReducer = (action: MenuAction) => {
        switch (action.id) {
          case SearchAgentAction.delete: {
            if (id) {
              deleteSearchAgent(id);
            }

            break;
          }
          case SearchAgentAction.load: {
            if (preset) {
              persistFilter(merge({}, getDefaultFilter({ sources, user }), transformForForm(preset)));
              navigate(RoutePaths.cars);
            } else {
              // TODO: emit error snackbar
              // eslint-disable-next-line no-console
              console.error('Unable to rehydrate SearchAgent.');
            }

            break;
          }
          case SearchAgentAction.edit: {
            setDrawerOpen(true);

            break;
          }

          // no default
        }
      };

      const handleCloseDrawer = () => {
        setDrawerOpen(false);
      };

      const handleSubmitDrawer = ({ searchAgentDisplayName, notifications, display }: SearchAgentFormData) => {
        const searchAgents = queryClient.getQueryData<SearchAgentDTO[]>([QueryKeys.searchAgents]);
        const target = searchAgents?.find((searchAgent) => searchAgent.id === id);
        const active = Object.values(notifications).some(Boolean);

        if (target) {
          updateSearchAgent({
            id: id!,
            ...target,
            searchAgentDisplayName,
            active,
            favorite: display.favorites,
          });
        }

        setDrawerOpen(false);
      };

      return (
        <>
          {/* eslint-disable-next-line no-restricted-syntax */}
          <Box textAlign="right">
            <Menu
              actions={[
                { id: SearchAgentAction.load, title: t('action.load'), icon: FlashOnIcon },
                { id: SearchAgentAction.edit, title: t('action.edit'), icon: EditIcon },
                { id: SearchAgentAction.delete, title: t('action.delete'), icon: DeleteIcon },
              ]}
              open={isMenuOpen}
              onAction={menuReducer}
              onOpen={openMenu}
              onClose={closeMenu}
            >
              {(triggerProps) => (
                <IconButton {...triggerProps} size="small">
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              )}
            </Menu>
          </Box>

          <SearchAgentDrawer
            open={drawerOpen}
            onSubmit={handleSubmitDrawer}
            onClose={handleCloseDrawer}
            searchAgent={row.original}
          />
        </>
      );
    },
  },
];

export const SearchAgentTable = ({ className }: SearchAgentTableProps): JSX.Element => {
  const { data: searchAgents } = useSearchAgents();
  const data = useMemo(
    () =>
      searchAgents.map<SearchAgent>((searchAgent, index) => ({
        id: searchAgent.id || String(index),
        lastUpdatedAt: searchAgent.lastModified,
        searchAgentDisplayName: searchAgent.searchAgentDisplayName ?? 'UNKNOWN',
        notification: searchAgent.active ? NotificationChannel.EMail : ('NONE' as NotificationChannel),
        active: searchAgent.active,
        favorite: searchAgent.favorite,
        preset: searchAgent,
      })),
    [searchAgents],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (searchAgent, index) => searchAgent.id ?? index,
  });

  return <Table table={table} className={className} />;
};
