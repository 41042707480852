import React, { cloneElement, isValidElement, memo, MouseEvent, ReactNode, useState } from 'react';
import i18n from 'i18next';
import { makeStyles, Menu } from '@material-ui/core';
import { countryCodeToOptions, getLabel } from '../modules/labels';
import { PriceType, ValuationCountryCode } from '../modules/generated/api';
import ValuationTypeSubmenu from './ValuationTypeSubmenu';
import ValuationCountrySubmenu from './ValuationCountrySubmenu';
import { REGIONS, SUPPORTED_COUNTRIES, VALUATION_COUNTRIES_PURE } from '../modules/valuation-country-helpers';
import useRole from '../hooks/useRole';
import UserRole from '../types/UserRoles';

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: theme.spacing(4),
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
}));

type ValuationCountrySwitcherProps = {
  rootButton: ReactNode;
  disableValuationTypeSubmenu?: boolean;
  checkboxMode?: boolean;
  checkedCountries?: Set<ValuationCountryCode>;
  setCheckedCountries?: (checkedCountries: Set<ValuationCountryCode>) => void;
  onOptionSelect?: (
    updatedValuationCountry: ValuationCountryCode | null,
    updatedValuationType?: PriceType | null,
  ) => void;
  maxNumberCheckable?: number;
  onClose?: () => void;
  highlightSelectedValuation?: boolean;
};

const ValuationCountrySwitcher = ({
  disableValuationTypeSubmenu = false,
  checkboxMode = false,
  checkedCountries,
  setCheckedCountries,
  onOptionSelect,
  rootButton,
  maxNumberCheckable = 3,
  onClose,
  highlightSelectedValuation,
}: ValuationCountrySwitcherProps) => {
  const [mainListAnchorEl, setMainListAnchorEl] = useState<Element | null>(null);
  const classes = useStyles();
  const { hasRole } = useRole();
  const options = countryCodeToOptions([...SUPPORTED_COUNTRIES], i18n.language);

  const handleChangeValuation = (
    updatedValuationCountry: ValuationCountryCode | null,
    updatedValuationType: PriceType | null = null,
  ) => {
    if (onOptionSelect) onOptionSelect(updatedValuationCountry, updatedValuationType);
    setMainListAnchorEl(null);
  };

  const toggleCountryCheck = (countryCodeToCheck: ValuationCountryCode) => {
    if (!checkedCountries || !setCheckedCountries)
      throw RangeError("Checkbox mode requires 'checkedCountries' and 'setCheckedCountries' as Props.");
    const modifiedValuationCountry = new Set(checkedCountries);

    if (checkedCountries.has(countryCodeToCheck)) {
      modifiedValuationCountry.delete(countryCodeToCheck);
    } else {
      if (modifiedValuationCountry.size >= maxNumberCheckable) return;
      modifiedValuationCountry.add(countryCodeToCheck);
    }
    setCheckedCountries(modifiedValuationCountry);
  };

  const handleOpenMenu = (event: MouseEvent) => setMainListAnchorEl(event.currentTarget);

  const handleOnClose = () => {
    if (onClose) onClose();
    setMainListAnchorEl(null);
  };

  return (
    <>
      {isValidElement(rootButton) && cloneElement(rootButton, { onClick: handleOpenMenu })}
      <Menu
        anchorEl={mainListAnchorEl}
        getContentAnchorEl={null}
        open={Boolean(mainListAnchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleOnClose}
      >
        {VALUATION_COUNTRIES_PURE().map((countryCode) => (
          <ValuationCountrySubmenu
            sourceItemClass={classes.listItemIcon}
            countryCode={countryCode}
            regions={REGIONS[countryCode]}
            onClose={handleOnClose}
            handleChangeValuation={handleChangeValuation}
            getCountryCodeLabel={(searchCountryCode) => getLabel(options, searchCountryCode)}
            checkboxMode={checkboxMode}
            checkedCountries={checkedCountries}
            toggleCountryCheck={toggleCountryCheck}
            key={countryCode}
            maxNumberCheckable={maxNumberCheckable}
            highlightSelectedValuation={highlightSelectedValuation}
          />
        ))}
        {!disableValuationTypeSubmenu && hasRole(UserRole.ADMIN) && (
          <ValuationTypeSubmenu
            sourceItemClass={classes.listItemIcon}
            onClose={handleOnClose}
            handleChangeValuation={handleChangeValuation}
            highlightSelectedValuation={highlightSelectedValuation}
          />
        )}
      </Menu>
    </>
  );
};
export default memo(ValuationCountrySwitcher);
