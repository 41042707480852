import React from 'react';
import { Avatar, styled, Theme, Typography } from '@material-ui/core';
import { Stack, StackProps } from '@components/ui';
import { useTranslation } from 'react-i18next';
import { isNumber, isString } from 'lodash';
import { Rating } from '@material-ui/lab';
import { DATE_TIME_FORMAT_NO_TIME, formatDate, getIsValidDate } from 'src/modules/date-helpers';
import { ActivityType } from 'src/modules/activity';

type ActivityProps = {
  type: ActivityType;
  userName: string;
  value: unknown;
  createdAt?: string;
};

const getInitials = (name: string): string =>
  name
    .split(' ')
    .map((part) => part[0])
    .join('');

export const ActivityList = styled(({ spacing = 2, ...rest }: StackProps) => <Stack spacing={spacing} {...rest} />)<
  Theme,
  StackProps
>(({ theme, spacing }) => ({
  '& > * + *': {
    paddingBlockStart: theme.spacing(spacing),
    borderTop: '1px solid',
    borderColor: theme.palette.grey[300],
  },
}));

export const Activity = ({ type, userName, value, createdAt }: ActivityProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div>
      <Stack
        spacing={1}
        direction="row"
        style={{
          marginBlockEnd: 8,
          alignItems: 'center',
        }}
      >
        <Avatar
          style={{
            width: 24,
            height: 24,
          }}
        >
          <Typography variant="caption">{getInitials(userName)}</Typography>
        </Avatar>
        <Typography variant="body2">
          {userName}{' '}
          <Typography variant="inherit" component="span" color="primary">
            {t(`activity.types.${type}`)}
          </Typography>
        </Typography>
        {getIsValidDate(createdAt) && (
          <Typography
            style={{
              marginInlineStart: 'auto',
            }}
            color="textSecondary"
            variant="caption"
          >
            {formatDate(createdAt, DATE_TIME_FORMAT_NO_TIME)}
          </Typography>
        )}
      </Stack>
      {/* eslint-disable-next-line no-nested-ternary */}
      {type === 'comment' && isString(value) ? (
        <Typography
          variant="body2"
          style={{
            maxWidth: 400,
          }}
        >
          &quot;{value}&quot;
        </Typography>
      ) : type === 'rating' && isNumber(value) ? (
        <Rating size="small" readOnly value={value} />
      ) : null}
    </div>
  );
};
