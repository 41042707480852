import { sortBy, uniqBy } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'src/constants';
import ApiService from 'src/modules/api-service';
import type { SourceType } from 'src/modules/generated/api';

type UseExternalSearchAgentsProps = {
  source: SourceType;
};

export const useExternalSearchAgents = ({ source }: UseExternalSearchAgentsProps) =>
  useQuery([QueryKeys.externalSearchAgents, source], () =>
    ApiService.searchAgent.searchAgentControllerExternalList(source).then((res) =>
      sortBy(
        uniqBy(
          res.data.map((searchAgent, index, self) => ({
            ...searchAgent,
            isDuplicated: self.some((sA, i) => sA.id === searchAgent.id && i !== index),
          })),
          'id',
        ),
        (searchAgent) => searchAgent.name,
      ),
    ),
  );
