import { makeStyles } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import WithChildren from '../types/WithChildren';

type ImageLabelProps = {
  size: 'small' | 'medium' | 'big';
  text: string;
  disable?: boolean;
};

const useStyles = makeStyles(() => ({
  textWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    position: 'absolute',
    transform: 'rotate(-35deg)',
    textTransform: 'uppercase',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: '#dc0404',
    fontWeight: 'bold',
    zIndex: 2,
    pointerEvents: 'none',
    '&--small': {
      WebkitTextStroke: '0.1px white',
      '@media screen and (min-width: 1280px)': {
        fontSize: '0.8rem',
      },
      '@media screen and (min-width: 960px) and (max-width: 1279px)': {
        fontSize: '1vw',
      },
      '@media screen and (max-width: 959px)': {
        fontSize: '1.2vw',
      },
    },
    '&--medium': {
      fontSize: '1.7rem',
      WebkitTextStroke: '0.1px white',
    },
    '&--big': {
      WebkitTextStroke: '0.4px white',
      marginBottom: '16px',
      '@media screen and (min-width: 1280px)': {
        fontSize: '4.5vw',
      },
      '@media screen and (min-width: 960px) and (max-width: 1279px)': {
        fontSize: '6.8vw',
      },
      '@media screen and (max-width: 959px)': {
        fontSize: '11vw',
      },
    },
  },
}));

const ImageLabel = ({ size, text, children, disable }: WithChildren<ImageLabelProps>) => {
  const classes = useStyles({ textLength: text.length });

  if (disable && children) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <div className={classes.textWrapper}>
      <span className={clsx(`${classes.text}--${size}`, classes.text)}>{text}</span>
      {children}
    </div>
  );
};

export default ImageLabel;
