import React, { memo } from 'react';
import { List, ListItem } from '@material-ui/core';
import { DetailCarDTO } from '../modules/generated/api';

type PackageCarsListProps = {
  cars?: DetailCarDTO[];
};

const PackageCarsList = ({ cars = [] }: PackageCarsListProps) => (
  <List component="ol">
    {cars.map(({ carId, model }, index) => (
      <ListItem key={carId} dense>
        <strong>
          {index + 1}. {model}
        </strong>
      </ListItem>
    ))}
  </List>
);

export default memo(PackageCarsList);
