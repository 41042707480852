import React, { ReactElement } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { useCurrency } from '../hooks/useCurrency';
import { CurrencyCode } from '../modules/generated/api';
import { MonetaryAmountStrict, SUPPORTED_CURRENCIES } from '../modules/currency';

type ConfirmFluctuationDialogProps = {
  handleClose: (confirmed: boolean) => void;
  open: boolean;
  bids: MonetaryAmountStrict[];
};

const ConfirmFluctuationDialog = ({ handleClose, open, bids }: ConfirmFluctuationDialogProps): ReactElement => {
  const { t } = useTranslation();
  const { convert, currency, format } = useCurrency();

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle>{t('alerts.confirmBidTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Trans
            t={t}
            i18nKey="bids.fluctuationDialog.body"
            values={{
              currency: t(SUPPORTED_CURRENCIES[currency].label as 'currency.EUR'),
              baseCurrency: t('currency.EUR'),
            }}
          />
        </DialogContentText>
        <ol>
          {bids.map((bid, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>
              <strong>{format(convert(bid, { to: CurrencyCode.Eur }), {})}</strong> ({format(bid, {})})
            </li>
          ))}
        </ol>
        <DialogContentText>{t('bids.fluctuationDialog.hint')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary" size="small">
          {t('common.cancel')}
        </Button>
        <Button onClick={() => handleClose(true)} autoFocus variant="contained" color="primary" size="small">
          {t('common.continue')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmFluctuationDialog;
