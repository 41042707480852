import { createElement, Fragment, KeyboardEvent, MouseEvent, ReactNode } from 'react';

const handleStopPropagation = (event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => {
  event.stopPropagation();
};

export const joinJSX = (items: JSX.Element[], separator: ReactNode): JSX.Element[] =>
  items.reduce<JSX.Element[]>(
    (res, span, index, self) => [
      ...res,
      createElement(Fragment, { key: span.key }, [span, index < self.length - 1 ? separator : '']),
    ],
    [],
  );

export default handleStopPropagation;
