import { InputAdornment, makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import { get, isEmpty, isNil, isNumber, isString, pickBy } from 'lodash';
import { Controller, useFormContext, Validate } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import AmountInput from 'src/components/AmountInput';
import { BidBuyFormType } from 'src/hooks/useBidBuyForm';
import { useCurrency } from 'src/hooks/useCurrency';
import { isUcs, isVwfs } from 'src/modules/car-source-helper';
import { DEFAULT_CURRENCY, SUPPORTED_CURRENCIES } from 'src/modules/currency';
import { SourceType } from 'src/modules/generated/api';
import { currentTabIndex } from 'src/stores/state';
import { BidInputNameType } from 'src/types/BidInputNameType';

type TableNumberInputProps = {
  type: 'gross' | 'base';
  activateForm: boolean;
  bidEditMode: boolean;
  bidNumber: 1 | 2 | 3;
  onValueChange: (value: number | undefined) => void;
  source: SourceType;
  minBid?: number;
};

const useStyles = makeStyles((theme) => ({
  textField: {
    '&--grey-out': {
      '& .MuiInputBase-input': {
        color: theme.palette.grey[600],
      },
    },
  },
  calculation: {
    fontSize: '1rem',
    textAlign: 'right',
  },
}));

const isNilAfterIndex = (list: number[], index: number): boolean => list.slice(index + 1).every((item) => isNil(item));

const isAscending = (index: number, value: number, list: number[]) => {
  if (isNil(value) && isNilAfterIndex(list, index)) {
    return true;
  }

  const prevBids = list.slice(0, index);
  return prevBids.every((prev) => prev < value);
};

const hasMinDistance = (index: number, value: number, list: number[], minDistance: number = 100) => {
  const prevBid = list[index - 1];
  return isNil(prevBid) || isNil(value) || Math.abs(value - prevBid) >= minDistance;
};

const minAmount = (minValue: number, value: number) => isNil(value) || value >= minValue;

const TableNumberInput = ({
  type,
  activateForm,
  bidEditMode,
  bidNumber,
  onValueChange,
  source,
  minBid,
}: TableNumberInputProps) => {
  const classes = useStyles();
  const {
    formState: { errors },
    watch,
  } = useFormContext<BidBuyFormType>();
  const { t } = useTranslation();
  const { currency, convert, format } = useCurrency();
  const [, setCurrentTabindex] = useRecoilState(currentTabIndex);

  const basePrefix = 'bid';
  const grossPrefix = 'bid-gross-';
  const prefix = type === 'base' ? basePrefix : grossPrefix;
  const name: BidInputNameType = `bid.${prefix}${bidNumber}`;

  const tabIndex = type === 'gross' ? bidNumber + 5 : bidNumber;
  const typePattern = new RegExp(`^${prefix}\\d+$`);
  const formBidData = watch('bid');
  const bids = Object.values(pickBy(formBidData, (_val, key) => typePattern.test(key))).map((m) =>
    isString(m) ? parseInt(m, 10) || 0 : m?.amount,
  );

  const hasError = !!get(errors, name);
  const validations: Record<string, Validate<any>> = {
    ...(bidNumber === 1 && type === 'base'
      ? {
          isRequiredBid: (val) => !isNil(val?.amount),
        }
      : undefined),
    ...(type === 'base' && source && isVwfs(source)
      ? {
          hasMinDistanceBid: (val) =>
            hasMinDistance(bidNumber - 1, val?.amount, bids, convert(100, { from: DEFAULT_CURRENCY.code })),
          isAscendingBid: (val) => isAscending(bidNumber - 1, val?.amount, bids),
        }
      : undefined),
    ...(type === 'base' && !isNil(minBid)
      ? {
          minBid: (val) => minAmount(convert(minBid, { from: DEFAULT_CURRENCY.code }), val?.amount),
        }
      : undefined),
  };

  return (
    <Controller
      name={name}
      rules={{
        validate: validations,
      }}
      render={({ field: { onChange, value: inputValue, ref, ...field } }) => (
        <AmountInput<TextFieldProps>
          {...field}
          value={isNumber(inputValue?.amount) ? Math.round(inputValue.amount) : ''}
          className={clsx(classes.textField, !bidEditMode && `${classes.textField}--grey-out`)}
          fullWidth
          error={hasError}
          helperText={
            hasError
              ? t(`form.errors.${get(errors, name)?.type}` as 'form.errors.isAscendingBid', {
                  minBid: format(convert(minBid || 0, { from: DEFAULT_CURRENCY.code }), {}),
                  minDistance: format(convert(100, { from: DEFAULT_CURRENCY.code }), {}),
                })
              : undefined
          }
          customInput={TextField}
          InputLabelProps={{ required: false }}
          InputProps={{
            endAdornment: <InputAdornment position="end">{SUPPORTED_CURRENCIES[currency].symbol}</InputAdornment>,
            startAdornment:
              !activateForm && isEmpty(errors) ? (
                <InputAdornment position="start">
                  <CheckIcon fontSize="inherit" />
                </InputAdornment>
              ) : undefined,
          }}
          inputProps={{
            tabIndex,
            min: 0,
            'aria-labelledby': name,
            readOnly: !activateForm,
          }}
          label={`${t('common.bid')} ${bidNumber} ${type === 'gross' ? t('common.gross') : ''}`}
          onValueChange={({ floatValue }, { source: valueChangeSource }) => {
            if (valueChangeSource === 'event' && (floatValue === undefined || floatValue > 0)) {
              onChange({ amount: floatValue, currency });
              onValueChange(floatValue);
            }
          }}
          emptyInputAllowed
          inputMode="numeric"
          margin="dense"
          variant="outlined"
          onFocus={() => setCurrentTabindex(type === 'gross' ? 9 : 3)}
          getInputRef={ref}
          disabled={bidNumber !== 1 && isUcs(source)} // special handling for UCS, Bids 2 and 3 are disabled
        />
      )}
    />
  );
};

export default TableNumberInput;
