import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import useApi from 'src/hooks/useApi';
import useCustomSnackbarGlobal from 'src/hooks/useSnackbarGlobal';
import ApiService from 'src/modules/api-service';
import { SourceType } from 'src/modules/generated/api';

type ConfirmDeclineBidsDialogProps = {
  handleClose: (confirmed: boolean) => void;
  open: boolean;
  source?: SourceType;
  carId?: string;
  refresh: () => void;
};

const ConfirmDeclineBidsDialog = ({
  handleClose,
  open,
  carId,
  source,
  refresh,
}: ConfirmDeclineBidsDialogProps): ReactElement => {
  const { t } = useTranslation();
  const snackbar = useCustomSnackbarGlobal();
  const { fetch } = useApi();

  const handleSetToOutbid = async () => {
    if (!source || !carId) return;
    const response = await fetch(ApiService.newBids.bidNewControllerSetBidStatusToOutBid(source, carId));
    if (response && response.status === 200) {
      snackbar.showSuccess(t('snackBar.success.setToDeclined'));
      refresh();
      handleClose(false);
    } else {
      snackbar.showError(t('snackBar.error.setToDeclined'));
    }
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle>{t('bids.declineBid')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('bids.declineBidHint')}</DialogContentText>
        <DialogContentText style={{ fontWeight: 'bold' }}>{t('common.noActionRevoke')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary" size="small">
          {t('common.cancel')}
        </Button>
        <Button onClick={() => handleSetToOutbid()} autoFocus variant="contained" color="primary" size="small">
          {t('common.continue')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeclineBidsDialog;
