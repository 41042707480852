import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'src/constants';
import ApiService from 'src/modules/api-service';
import { ListCarDTO, DataTablesOutputListCarDTO } from 'src/modules/generated/api';
import { useTranslation } from 'react-i18next';
import useCustomSnackbarGlobal from '../../hooks/useSnackbarGlobal';

export const useCarUpdate = () => {
  const queryClient = useQueryClient();
  const { showSuccess, showError } = useCustomSnackbarGlobal();
  const { t } = useTranslation();

  return useMutation(
    (updatedCar: ListCarDTO) =>
      ApiService.listCars.listCarsControllerUpdateCar(
        updatedCar.source!,
        updatedCar.carId!,
        updatedCar,
        2,
        updatedCar.potential?.country,
      ),
    {
      onMutate: async (updatedCar) => {
        await queryClient.cancelQueries([QueryKeys.carsList]);

        const prevCarListQueries = queryClient.getQueriesData<DataTablesOutputListCarDTO>([QueryKeys.carsList]);

        // Walk caches and update target car references
        prevCarListQueries.forEach(([queryKey, queryData]) => {
          queryClient.setQueryData<DataTablesOutputListCarDTO>(queryKey, () => ({
            ...queryData,
            data: queryData.data?.map((car) => (car.carId === updatedCar.carId ? updatedCar : car)),
          }));
        });

        return { prevCarListQueries };
      },
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.carsList]);
      },
      onSuccess: () => {
        showSuccess(t('alerts.successSaved'));
      },
      onError: (_error, _variables, snapshot) => {
        snapshot?.prevCarListQueries.forEach(([queryKey, queryData]) => {
          queryClient.setQueryData<DataTablesOutputListCarDTO>(queryKey, queryData);
        });
        showError(t('alerts.errorRaised'));
      },
    },
  );
};
