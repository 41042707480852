import { Button, ListItemText, makeStyles, Menu, MenuItem, Typography, Link } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import React, { MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrency } from '../hooks/useCurrency';
import { SupportedCurrencyCode, SUPPORTED_CURRENCIES } from '../modules/currency';

const useStyles = makeStyles((theme) => ({
  source: {
    paddingInline: theme.spacing(2),
    paddingBlock: theme.spacing(1),
    marginBlockEnd: -theme.spacing(1), // cancel menu bottom spacing
    whiteSpace: 'normal',
    backgroundColor: theme.palette.grey[50],
    maxWidth: 'fit-content',
    lineHeight: 1.24,
    fontSize: '0.725rem',
    minWidth: '100%',
  },
}));

export const CurrencySwitcher = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { currency, setCurrency: setActiveCurrency } = useCurrency();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeCurrency = (currencyCode: SupportedCurrencyCode) => {
    setActiveCurrency(currencyCode);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button size="small" onClick={handleClick}>
        {currency} <ArrowDropDown />
      </Button>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
      >
        {Object.values(SUPPORTED_CURRENCIES).map((supportedCurrency) => (
          <MenuItem onClick={() => handleChangeCurrency(supportedCurrency.code)} key={supportedCurrency.code}>
            <ListItemText>
              {t(supportedCurrency.label as any)} ({supportedCurrency.symbol})
            </ListItemText>
          </MenuItem>
        ))}
        <Typography variant="subtitle2" className={classes.source}>
          {t('currencySwitcher.sourcePrefix')}{' '}
          <Link href="https://www.finanzen.net/waehrungsrechner" target="blank">
            finanzen.net
          </Link>
        </Typography>
      </Menu>
    </div>
  );
};
