import React, { forwardRef, Ref } from 'react';
import { Checkbox, ListItemIcon, ListItemText, makeStyles, MenuItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import NestedMenuItem from 'material-ui-nested-menu-item';
import { getIcon, IconType } from '../modules/data';
import { getLabel, REGIONS_LABELS } from '../modules/labels';
import { PriceType, ValuationCountryCode } from '../modules/generated/api';
import { getBaseCountry } from '../modules/valuation-country-helpers';
import { useValuationSettings } from '../hooks/useValuationSettings';

const useStyles = makeStyles((theme) => ({
  regions: {
    top: '200px',
  },
  title: {
    marginLeft: theme.spacing(2),
  },
  selected: {
    backgroundColor: theme.palette.grey[400],
    '&:hover': {
      backgroundColor: theme.palette.grey[400],
    },
  },
}));

type ValuationCountrySubmenuProps = {
  onClose: () => void;
  countryCode: string;
  regions?: ValuationCountryCode[];
  sourceItemClass: string;
  handleChangeValuation?: (
    updatedValuationCountry: ValuationCountryCode | null,
    updatedValuationType?: PriceType,
  ) => void;
  getCountryCodeLabel: (countryCode: string) => string;
  checkboxMode?: boolean;
  checkedCountries?: Set<ValuationCountryCode>;
  toggleCountryCheck: (countryCodeToCheck: ValuationCountryCode) => void;
  maxNumberCheckable?: number;
  highlightSelectedValuation?: boolean;
};

const ValuationCountrySubmenu = forwardRef(
  (
    {
      onClose,
      countryCode,
      regions,
      sourceItemClass,
      handleChangeValuation,
      getCountryCodeLabel,
      checkboxMode,
      checkedCountries,
      toggleCountryCheck,
      maxNumberCheckable,
      highlightSelectedValuation,
    }: ValuationCountrySubmenuProps,
    submenuRef: Ref<HTMLLIElement> | null | undefined,
  ) => {
    const { valuationCountry } = useValuationSettings();
    const classes = useStyles();
    const { t } = useTranslation();

    const isCountryChecked = (countryCodeToCheck: string) =>
      checkedCountries?.has(countryCodeToCheck as ValuationCountryCode);

    const handleItemClick = (valuationCountryCodeWithRegion: ValuationCountryCode) => {
      if (checkboxMode) {
        toggleCountryCheck(valuationCountryCodeWithRegion);
      } else if (handleChangeValuation) {
        handleChangeValuation(valuationCountryCodeWithRegion);
        onClose();
      } else {
        throw RangeError("Either provide 'handleChangeValuation' or 'valuationCountryCodeWithRegion'.");
      }
    };

    return regions ? (
      <NestedMenuItem
        label={
          <>
            <ListItemIcon className={sourceItemClass}>
              <img src={getIcon(IconType.COUNTRY, countryCode)} alt={getCountryCodeLabel(countryCode)} width={18} />
            </ListItemIcon>
            <ListItemText>{getCountryCodeLabel(countryCode)}</ListItemText>
          </>
        }
        title={t('regions.region_plural')}
        parentMenuOpen
        ref={submenuRef}
        className={
          highlightSelectedValuation && valuationCountry && getBaseCountry(valuationCountry) === countryCode
            ? classes.selected
            : undefined
        }
      >
        <Typography variant="subtitle2" className={classes.title}>
          <strong>{t('regions.region_plural')}</strong>
        </Typography>
        {[countryCode as ValuationCountryCode, ...regions].map((valuationCountryCodeWithRegion) => (
          <MenuItem
            key={valuationCountryCodeWithRegion}
            onClick={() => handleItemClick(valuationCountryCodeWithRegion)}
            ref={submenuRef}
            disabled={
              !isCountryChecked(valuationCountryCodeWithRegion) && checkedCountries?.size === maxNumberCheckable
            }
            className={
              highlightSelectedValuation && valuationCountry === valuationCountryCodeWithRegion
                ? classes.selected
                : undefined
            }
          >
            <ListItemIcon className={sourceItemClass}>
              <img
                src={getIcon(IconType.COUNTRY, getBaseCountry(valuationCountryCodeWithRegion))}
                alt={getCountryCodeLabel(valuationCountryCodeWithRegion)}
                width={18}
              />
            </ListItemIcon>
            <ListItemText>{getLabel(REGIONS_LABELS, valuationCountryCodeWithRegion)}</ListItemText>
            {checkboxMode && (
              <Checkbox
                size="small"
                checked={isCountryChecked(valuationCountryCodeWithRegion)}
                onChange={() => handleItemClick(valuationCountryCodeWithRegion)}
              />
            )}
          </MenuItem>
        ))}
      </NestedMenuItem>
    ) : (
      <MenuItem
        onClick={() => handleItemClick(countryCode as ValuationCountryCode)}
        ref={submenuRef}
        disabled={!isCountryChecked(countryCode) && checkedCountries?.size === maxNumberCheckable}
        className={highlightSelectedValuation && valuationCountry === countryCode ? classes.selected : undefined}
      >
        <ListItemIcon className={sourceItemClass}>
          <img src={getIcon(IconType.COUNTRY, countryCode)} alt={getCountryCodeLabel(countryCode)} width={18} />
        </ListItemIcon>
        <ListItemText>{getCountryCodeLabel(countryCode)}</ListItemText>
        {checkboxMode && (
          <Checkbox
            size="small"
            checked={isCountryChecked(countryCode)}
            onChange={() => handleItemClick(countryCode as ValuationCountryCode)}
          />
        )}
      </MenuItem>
    );
  },
);
export default ValuationCountrySubmenu;
