import { Grid, makeStyles } from '@material-ui/core';
import { memo } from 'react';
import useRole from '../hooks/useRole';
import { ListCarDTO } from '../modules/generated/api';
import { getAccountValuationCountry } from '../modules/valuation-country-helpers';
import UserRole from '../types/UserRoles';
import ActionCarCulatorLink from './ActionCarCulatorLink';
import ReferenceVehicleButton from './ReferenceVehicleButton';

const useStyles = makeStyles(() => ({
  tableCell: {
    width: '10rem',
  },
  linkWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

type CarsTableCarCulatorLinkCellProps = {
  car: ListCarDTO;
};

const CarsTableCarCulatorLinkCell = ({
  car: { firstRegistration, mileage, vin, valuation },
  car,
}: CarsTableCarCulatorLinkCellProps) => {
  const classes = useStyles();
  const { hasRole } = useRole();
  const isAdmin = hasRole(UserRole.ADMIN);
  const isATValuationCountry = getAccountValuationCountry(true) === 'AT';
  const showCarCalculatorLink = isATValuationCountry || isAdmin;

  return (
    <Grid className={classes.tableCell} container>
      {showCarCalculatorLink && (
        <Grid item xs={6}>
          <div className={classes.linkWrapper}>
            <ActionCarCulatorLink firstRegistration={firstRegistration} mileage={mileage} vin={vin} />
          </div>
        </Grid>
      )}
      <Grid item xs={showCarCalculatorLink ? 6 : 12} className={classes.linkWrapper}>
        {valuation?.numberOfCars && valuation.numberOfCars > 0 && (
          <ReferenceVehicleButton
            potential={car.potential}
            refCar={car}
            offerId={valuation.offerId}
            valuationCountry={valuation.valuationCountry}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default memo(CarsTableCarCulatorLinkCell);
