import React from 'react';
import humanizeDurationInstance from '../modules/humanize-duration';

type CarsTableOnlineTimeCellProps = {
  onlineTimeInMinutes?: number;
};

function CarsTableOnlineTimeCell({ onlineTimeInMinutes }: CarsTableOnlineTimeCellProps) {
  if (!onlineTimeInMinutes) return <>N/A</>;
  const onlineTimeInMilliseconds = onlineTimeInMinutes * 60 * 1000;
  return <>{humanizeDurationInstance(onlineTimeInMilliseconds)}</>;
}

export default CarsTableOnlineTimeCell;
