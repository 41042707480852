export enum ColumnSize {
  xxs = 100,
  xs = 115,
  sm = 150,
  md = 250,
  lg = 390,
}

export const rowSize = 72;

export const PrimaryKey = 'car';

export enum ExportType {
  textOnly = 'textOnly',
  iconsOnly = 'iconsOnly',
  textAndIcons = 'textAndIcons',
}

export enum CarsTableNextColumnAction {
  showColumns,
  hide,
}

export enum CarsTableNextAction {
  showViews,
  showExport,
  showAutoRefresh,
  showColumns,
  showConditionalFormats,
  showFilter,
  showUpload,
}

export enum SidebarRoutePaths {
  root = '/cars',
  filter = '/cars',
  columnSettings = '/cars/column-settings',
  views = '/cars/views',
  export = '/cars/export',
  upload = '/cars/upload',
  autoRefresh = '/cars/auto-refresh',
  pendingFilter = '/bids/pending',
  pendingColumnSettings = '/bids/pending/column-settings',
  pendingExport = '/bids/pending/export',
  pendingUpload = '/bids/pending/upload',
  pastFilter = '/bids/past',
  pastColumnSettings = '/bids/past/column-settings',
  pastExport = '/bids/past/export',
  ordersFilter = '/orders',
  ordersColumnSettings = '/orders/column-settings',
  ordersExport = '/orders/export',
}
