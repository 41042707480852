import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { Button, makeStyles, TableCell, TableRow, TextField } from '@material-ui/core';
import { Select } from '@components/ui';
import DeliveryAddressSelect from '@components/dealer-table/DeliveryAddressSelect';
import { Add as AddIcon } from '@material-ui/icons';
import { PaletteColor } from '@material-ui/core/styles/createPalette';
import { useAuthenticationStore } from 'src/stores/Authentication';
import theme from '../../setup/theme';
import { getSourceOptions, useSources } from '../../hooks/useSources';
import {
  DeliveryAddressDTO,
  PlatformCredentialsMetadataDTO,
  SourceType,
  CountryCode,
} from '../../modules/generated/api';

const useStyles = makeStyles({
  newLine: {
    background: theme.palette.primary['accent' as keyof PaletteColor],
  },
  input: {
    background: theme.palette.common.white,
  },
  deliveryCell: {
    width: 160,
  },
});

type NewDealerRowProps = {
  onCreate: (username: string, password: string, metadata: PlatformCredentialsMetadataDTO, source: SourceType) => void;
  displayPostalCode: boolean;
  deliveryAddresses?: DeliveryAddressDTO[];
  classes: { select: string };
};

type NewDealerForm = {
  username: string;
  password: string;
  metadata: PlatformCredentialsMetadataDTO;
  source: SourceType;
};

const sourceBlacklist: SourceType[] = [SourceType.RealFirstCallOther, SourceType.RealFirstCallOwn];

const NewDealerRow = ({
  onCreate,
  displayPostalCode,
  deliveryAddresses,
  classes: externalClasses,
}: NewDealerRowProps): React.ReactElement => {
  const { t } = useTranslation();
  const classes = { ...useStyles(), ...externalClasses };
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<NewDealerForm>({
    defaultValues: {
      username: '',
      password: '',
      metadata: { displayName: '', postalCode: '', defaultDeliveryAddressId: '' },
      source: '' as SourceType,
    },
  });

  const { data: sources = [] } = useSources();
  const sourceOptions = getSourceOptions(sources);
  const { state: authState } = useAuthenticationStore();
  const dealerCountry = authState.objectToken?.country;
  // IT dealers are not allowewd to add credentials for VWFS
  if (dealerCountry === CountryCode.It) sourceBlacklist.push(SourceType.Vwfs);

  const onSubmit = handleSubmit(({ username, password, metadata, source }) => {
    onCreate(username, password, metadata, source);
    reset();
  });

  return (
    <TableRow className={classes.newLine}>
      <TableCell>
        <Controller
          control={control}
          name="username"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              className={classes.input}
              type="text"
              size="small"
              variant="outlined"
              label={t('common.username')}
              error={!!errors.username}
              {...field}
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          control={control}
          name="metadata.displayName"
          render={({ field }) => (
            <TextField
              className={classes.input}
              type="text"
              size="small"
              variant="outlined"
              label={t('contact.displayName')}
              {...field}
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          control={control}
          name="password"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              className={classes.input}
              type="text"
              size="small"
              variant="outlined"
              label={t('common.password')}
              error={!!errors.password}
              {...field}
            />
          )}
        />
      </TableCell>
      {displayPostalCode && (
        <TableCell>
          <Controller
            control={control}
            name="metadata.postalCode"
            render={({ field }) => (
              <TextField
                className={classes.input}
                type="text"
                size="small"
                variant="outlined"
                label={t('contact.postal')}
                {...field}
              />
            )}
          />
        </TableCell>
      )}
      <TableCell>
        <Controller
          control={control}
          name="source"
          render={({ field: { ref, ...field } }) => (
            <Select
              className={classes.select}
              label={null}
              options={[
                {
                  value: 'empty',
                  label: t('common.chooseOption', { option: t('common.source') }),
                  options: { disabled: true },
                },
                ...sourceOptions.filter((option) => !sourceBlacklist.includes(option.value)),
              ]}
              inputRef={ref}
              {...field}
              value={field.value || 'empty'}
            />
          )}
        />
      </TableCell>
      <TableCell className={classes.deliveryCell}>
        <DeliveryAddressSelect
          name="metadata.defaultDeliveryAddressId"
          rules={{ required: true }}
          deliveryAddresses={deliveryAddresses}
          control={control}
          className={classes.select}
        />
      </TableCell>
      <TableCell>{/* status placeholder */}</TableCell>
      <TableCell align="right">
        <Button variant="contained" startIcon={<AddIcon />} color="primary" onClick={onSubmit}>
          {t('common.add')}
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default NewDealerRow;
