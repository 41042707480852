import React, { memo } from 'react';
import { makeStyles, TableCell, TableRow, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { cloneDeep, range } from 'lodash';
import CarDetailTable from './CarDetailTable';
import { EquipmentCodeText } from '../modules/generated/api';

function splitToChunks<Type>(array: Type[] | undefined, parts: number) {
  const result: Type[][] = [];
  if (!array) return result;

  for (let i = parts; i > 0; i -= 1) {
    result.push(array.splice(0, Math.ceil(array.length / i)));
  }
  return result;
}

const useStyles = makeStyles((theme) => ({
  codeCell: {
    float: 'right',
    fontWeight: 'bold',
    height: '100%',
  },
  textCell: {
    width: `calc(50% - ${theme.spacing(20)}px)`,
    display: 'flex',
    alignContent: 'space-between',
  },
  text: {
    float: 'left',
    flex: '1',
    maxWidth: '80%',
  },
  textRow: {
    display: 'flex',
    alignContent: 'space-between',
  },
}));

type CarDetailEquipmentProps = {
  equipmentCodeText?: EquipmentCodeText[];
};
const CarDetailEquipment = ({ equipmentCodeText }: CarDetailEquipmentProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery('(max-width: 1200px)');

  const equipmentCodeTextSided: EquipmentCodeText[][] = splitToChunks<EquipmentCodeText>(
    cloneDeep(equipmentCodeText),
    isSmallScreen ? 2 : 3,
  );
  const maxRowNr = equipmentCodeTextSided[0]?.length;

  return (
    <CarDetailTable title={t('car.equipment')} layoutFixed>
      {range(maxRowNr).map((rowNr) => {
        const left = equipmentCodeTextSided[0]?.[rowNr];
        const middle = equipmentCodeTextSided.length === 3 && equipmentCodeTextSided[1]?.[rowNr];
        const right =
          equipmentCodeTextSided.length === 3 ? equipmentCodeTextSided[2]?.[rowNr] : equipmentCodeTextSided[1]?.[rowNr];

        return (
          <TableRow key={`${left?.text};${middle && middle?.text};${right?.text}`} className={classes.textRow}>
            <TableCell className={classes.textCell}>
              <span className={classes.text}> {left?.text} </span>{' '}
              <span className={classes.codeCell}>{left?.code && `[${left.code}]`}</span>
            </TableCell>
            {equipmentCodeTextSided.length === 3 && (
              <TableCell className={classes.textCell}>
                {middle && (
                  <>
                    <span className={classes.text}>{middle?.text}</span>{' '}
                    <span className={classes.codeCell}>{middle?.code && `[${middle.code}]`}</span>
                  </>
                )}
              </TableCell>
            )}
            <TableCell className={classes.textCell}>
              {right && (
                <>
                  <span className={classes.text}>{right?.text}</span>{' '}
                  <span className={classes.codeCell}>{right?.code && `[${right.code}]`}</span>
                </>
              )}
            </TableCell>
          </TableRow>
        );
      })}
    </CarDetailTable>
  );
};

export default memo(CarDetailEquipment);
