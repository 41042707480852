// forked from @material-ui
import { SetStateAction, useCallback, useRef, useState } from 'react';

export type UseControlledProps<T = unknown> = {
  /**
   * This prop contains the component value when it's controlled.
   */
  controlled: T | undefined;
  /**
   * The default value when uncontrolled.
   */
  default: T | undefined;
};

export const useControlled = <T = unknown>({ controlled, default: defaultProp }: UseControlledProps<T>) => {
  const { current: isControlled } = useRef(controlled !== undefined);
  const [valueState, setValue] = useState(defaultProp);
  const value = isControlled ? controlled : valueState;

  const setValueIfUncontrolled = useCallback((newValue: SetStateAction<T | undefined>) => {
    if (!isControlled) {
      setValue(newValue);
    }
    // isControlled should not change!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [value as T, setValueIfUncontrolled] as const;
};
