import { Chip, Grid, Tooltip, makeStyles } from '@material-ui/core';
import { amber } from '@material-ui/core/colors';
import React from 'react';
import {
  removeItemFromBidsTableNextFilter,
  removeItemFromCarsTableNextFilter,
  resetBidsTableNextFilterValue,
  resetCarsTableNextFilterValue,
} from 'src/modules/filter-chips-helper';
import { useBidsFilter } from './useBidsFilter';
import { useFilter } from './useFilter';

type FilterChipProps = {
  label: string;
  tooltip: string;
  name: string;
  onDelete?: () => void;
  resetEffect?: 'remove' | 'undefined';
  value?: any;
  avatar?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
};

const useStyles = makeStyles(() => ({
  chip: {
    '&:hover': {
      backgroundColor: amber[100],
    },
    '& span': {
      alignSelf: 'baseline',
    },
    '& svg': {
      alignSelf: 'center',
    },
  },
  tooltip: {
    fontSize: 20,
  },
}));

const FilterChip = ({ label, tooltip, name, onDelete, resetEffect, value, avatar }: FilterChipProps) => {
  const classes = useStyles();
  const { watch: watchBidsFilter, setFilter: setBidsFilter } = useBidsFilter();
  const { watch: watchCarsFilter, setFilter: setCarsFilter } = useFilter();
  const bidsFilterState = watchBidsFilter();
  const carsFilterState = watchCarsFilter();

  // check if the url contains bids
  const url = window.location.href;
  const isBidsPage = url.includes('bids') || url.includes('orders');

  return (
    <Grid item>
      <Tooltip title={tooltip}>
        <Chip
          key={`${name}-${value}`}
          size="small"
          variant="outlined"
          label={label}
          className={classes.chip}
          onDelete={() => {
            if (onDelete) {
              onDelete();
              return;
            }
            if (resetEffect === 'remove') {
              if (isBidsPage) {
                removeItemFromBidsTableNextFilter(name, value, bidsFilterState, setBidsFilter);
                return;
              }
              removeItemFromCarsTableNextFilter(name, value, carsFilterState, setCarsFilter);
              return;
            }
            if (isBidsPage) {
              resetBidsTableNextFilterValue(name, bidsFilterState, setBidsFilter);
              return;
            }
            resetCarsTableNextFilterValue(name, carsFilterState, setCarsFilter);
          }}
          avatar={avatar}
        />
      </Tooltip>
    </Grid>
  );
};

export default FilterChip;
