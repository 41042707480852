import { makeStyles } from '@material-ui/core';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'src/setup/theme';
import { SourceType } from '../../../modules/generated/api/api';
import { useFilter } from '../filter/useFilter';

const useStyles = makeStyles({
  alert: {
    color: theme.palette.error.main,
  },
});

type TableFooterProps = {
  children?: ReactNode;
};

export const TableFooter = ({ children }: TableFooterProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { watch } = useFilter();
  const sources = watch('source');

  const audi = sources?.includes('AUDI_GWS' as SourceType);

  return (
    <div
      style={{
        position: 'sticky',
        bottom: 0,
        left: 0,
        background: '#fff',
        paddingBlock: 1,
        paddingInline: 12,
      }}
    >
      {audi && <p className={classes.alert}>{t('alerts.audi')}</p>}
      {children}
    </div>
  );
};
