import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { ListCarDTO } from '../modules/generated/api';
import { PromotionIcon } from './PromotionIcon';

type PromotionsProps = {
  car: ListCarDTO;
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 2,
  },
});

const Promotions = ({ car: { promotions } }: PromotionsProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* Thermo is listed as a promotion but its actually an equipment. So we filter it out here. */}
      {(promotions || [])
        .filter((promo) => promo !== 'THERMO')
        .map((promotion) => (
          <PromotionIcon key={promotion} promotion={promotion} />
        ))}
    </div>
  );
};

export default memo(Promotions);
