import OriginSourceSelect from '@components/OriginSourceSelect';
import PurchaseGrossSliderInput from '@components/PurchaseGrossSliderInput';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import clsx from 'clsx';
import { memo } from 'react';
import TagManager from 'react-gtm-module';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getSourceOptions, useSources } from 'src/hooks/useSources';
import AddVinFilter from '../components/AddVinFilter';
import BidStateFilterCurrent from '../components/BidStateFilterCurrent';
import BidStateFilterExpired from '../components/BidStateFilterExpired';
import BrandsModelGroupsSelect from '../components/BrandsModelGroupsSelect';
import BuyableStatusSelect from '../components/BuyableStatusSelect';
import ColorsSelect from '../components/ColorsSelect';
import CountryOriginSelect from '../components/CountryOriginSelect';
import CurrencySliderInput from '../components/CurrencySliderInput';
import DamageTypeSelect from '../components/DamageTypeSelect';
import DateTimeFilterSelect from '../components/DateTimeFilterSelect';
import EngineFuelSelect from '../components/EngineFuelSelect';
import EngineTransmissionSelect from '../components/EngineTransmissionSelect';
import EquipmentHighlightsSelect from '../components/EquipmentHighlightsSelect';
import EquipmentsSelect from '../components/EquipmentsSelect';
import FilterHeading from '../components/FilterHeading';
import MarketingChannelCheckboxes from '../components/MarketingChannelCheckboxes';
import OfferTypeSelect from '../components/OfferTypeSelect';
import PotentialFilter from '../components/PotentialFilter';
import PromotionsSelect from '../components/PromotionsSelect';
import RegistrationDatePicker from '../components/RegistrationDatePicker';
import SearchAgentActionBar from '../components/SearchAgentActionBar';
import SearchInput from '../components/SearchInput';
import SliderInput from '../components/SliderInput';
import SourceSelect from '../components/SourceSelect';
import TableControlWrapper from '../components/TableControlWrapper';
import TaxTypeCheckboxes from '../components/TaxTypeCheckboxes';
import UserInformationCheckboxes from '../components/UserInformationCheckboxes';
import ValiditySelect from '../components/ValiditySelect';
import { useCarsFilter } from '../hooks/useCarsFilter';
import useRole from '../hooks/useRole';
import UserRole from '../types/UserRoles';

const useStyles = makeStyles((theme) => ({
  filterSection: {
    paddingTop: '0 !important',
  },
  filterSectionDivider: {
    '@media (min-width: 1280px)': {
      borderRight: `1px solid ${theme.palette.secondary.main}`,
    },
  },
  leftFilter: {
    paddingRight: theme.spacing(1),
    '@media screen and (max-width: 959px)': {
      paddingRight: '0',
    },
  },
}));

const CarsFilter = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { hasRole } = useRole();
  const { form, summary, loadingSummary, restore } = useCarsFilter();

  const { data: sources = [] } = useSources();
  const sourceOptions = getSourceOptions(sources);

  const handleToggleExpanded = (newValue: boolean) => {
    TagManager.dataLayer({ dataLayer: { event: `filter-${newValue ? 'open' : 'close'}` } });
  };

  const handleClickReset = () => {
    restore();

    TagManager.dataLayer({
      dataLayer: {
        event: 'filter-reset',
      },
    });
  };

  return (
    <TableControlWrapper expandLabel={t('common.filter')} storageKey="CarsFilter" onToggle={handleToggleExpanded}>
      <FormProvider {...form}>
        <form>
          <Grid container spacing={4}>
            <Grid item lg={4} xs={12} className={clsx(classes.filterSection, classes.filterSectionDivider)}>
              <FilterHeading>{t('common.general')}</FilterHeading>

              <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={11}>
                  <SearchInput />
                </Grid>
                <Grid item xs={1}>
                  <AddVinFilter />
                </Grid>
              </Grid>
              {!hasRole(UserRole.ADMIN) ? (
                <UserInformationCheckboxes formLabel={t('carsTable.userInfo')} type="own" />
              ) : (
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item md={6} xs={12}>
                      <UserInformationCheckboxes formLabel={t('carsTable.userInfo')} type="own" />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <UserInformationCheckboxes formLabel={t('carsTable.otherUserInfo')} type="any" />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {hasRole(UserRole.ADMIN) && (
                <Grid container spacing={2}>
                  <Grid item lg={6} xs={12}>
                    <DateTimeFilterSelect dateType="start" />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <DateTimeFilterSelect dateType="end" />
                  </Grid>
                </Grid>
              )}
              <Grid container>
                <Grid item lg={6} xs={12}>
                  <MarketingChannelCheckboxes />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TaxTypeCheckboxes />
                </Grid>
              </Grid>

              {hasRole(UserRole.ADMIN) && <BuyableStatusSelect />}

              {sourceOptions.length > 0 && <SourceSelect />}
              <PromotionsSelect filterSummary={summary} />
              {hasRole(UserRole.ADMIN) && (
                <Grid container item>
                  <Grid className={classes.leftFilter} item md={6} xs={12}>
                    <ValiditySelect />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <OfferTypeSelect />
                  </Grid>
                </Grid>
              )}
              <Grid container item>
                <Grid className={classes.leftFilter} item md={6} xs={12}>
                  <BidStateFilterCurrent />
                </Grid>
                <Grid item md={6} xs={12}>
                  <BidStateFilterExpired />
                </Grid>
              </Grid>
              <SliderInput
                name="numAuctions"
                label={t('car.numAuctions')}
                unit="x"
                step={1}
                min={0}
                max={50}
                marginTop
              />
            </Grid>

            <Grid item lg={4} xs={12} className={classes.filterSection}>
              <FilterHeading>{t('common.car')}</FilterHeading>
              <CountryOriginSelect />
              {hasRole(UserRole.ADMIN) && <OriginSourceSelect />}

              <BrandsModelGroupsSelect
                brands={summary?.normalizedBrand}
                models={summary?.normalizedModel}
                versions={summary?.normalizedVersion}
                equipmentLines={summary?.normalizedEquipmentLine}
                loading={loadingSummary}
              />
              <EquipmentsSelect equipments={summary?.equipmentCodeText} loading={loadingSummary} />
              <ColorsSelect />
              <EquipmentHighlightsSelect />
              <DamageTypeSelect />
              <Grid container item spacing={1}>
                <Grid item xs={12} md={6}>
                  <EngineFuelSelect />
                </Grid>
                <Grid item xs={12} md={6}>
                  <EngineTransmissionSelect />
                </Grid>
              </Grid>
              <SliderInput marginTop name="powerKw" label={t('car.power')} unit="kW" step={5} min={0} max={500} />
              <SliderInput name="engineCo2" label={t('car.co2')} unit="g/km" step={5} min={0} max={400} />
            </Grid>

            <Grid item lg={4} xs={12} className={classes.filterSection}>
              <SliderInput name="mileage" label={t('car.mileage')} unit="km" step={250} min={0} max={250000} />
              <RegistrationDatePicker />
              <CurrencySliderInput name="priceDamage" label={t('car.damage')} step={100} min={0} max={25_000} />
              <PurchaseGrossSliderInput />

              <PotentialFilter />
            </Grid>

            <SearchAgentActionBar />

            <Grid item lg={2} xs={12}>
              <Button
                endIcon={<Clear />}
                color="secondary"
                variant="contained"
                type="button"
                onClick={handleClickReset}
              >
                {t('filterSearch.resetFilters')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </TableControlWrapper>
  );
};

export default memo(CarsFilter);
