import { client } from '@iris/content/lib';
import { useQuery } from '@tanstack/react-query';
import groq from 'groq';
import { useTranslation } from 'react-i18next';
import { QueryKeys } from 'src/constants';

type UseAllFaqByCategoryProps = {
  category: string;
};

export type AllFaqByCategory = {
  _id: string;
  slug: string;
  title: string;
  subTitle: string | null;
  description: string | null;
  subCategory: {
    _id: string;
    title: string;
  } | null;
  countries: string[];
}[];

const allFaqByCategory = groq`*[
  _type == 'faq' &&
  __i18n_lang == $lang &&
  $category in categories[]->slug.current
]|order(subCategories[0]->orderRank, coalesce(__i18n_base->orderRank, orderRank)) {
  _id,
  "slug": slug.current,
  title,
  subTitle,
  "description": pt::text(description),
  "subCategory": subCategories[0]->{
    _id,
    title,
  },
  countries
}`;

export const fetchAllFaqByCategory = (variables: { category: string; lang: string }) =>
  client.fetch<AllFaqByCategory>(allFaqByCategory, variables).then((faqs) =>
    faqs.map((faq) => ({
      ...faq,
      countries: (faq.countries as unknown as string | null)?.split(',').map((country) => country.trim()) ?? [],
    })),
  );

export const useAllFaqByCategory = ({ category }: UseAllFaqByCategoryProps) => {
  const { i18n } = useTranslation();
  const variables = { category, lang: i18n.language };

  return useQuery([QueryKeys.allFaq, variables], () => fetchAllFaqByCategory(variables), {
    staleTime: Infinity,
    suspense: true,
    useErrorBoundary: true,
  });
};
