import {
  FormLabel,
  Grid,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import flattenErrors from '../hook-form-helper';
import i18n from '../setup/i18n';
import { getIcon, IconType } from '../modules/data';
import { countryCodeToOption, countryCodeToRegion } from '../modules/labels';
import CurrencyInput from './CurrencyInput';

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: theme.spacing(4),
  },
  titleFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
  },
}));

type SourceConfigurationProps = {
  label?: string;
  prefix?: string;
  countryLabel?: string;
};

const SourceConfiguration = ({ label, prefix, countryLabel }: SourceConfigurationProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const flatErrors = flattenErrors(errors);
  const { t } = useTranslation();
  const classes = useStyles();

  const getName = (name: string) => `${prefix ? `${prefix}.` : ''}${name}`;
  const { ref: contributionMarginPercentRef, ...contributionMarginPercentField } = register(
    getName('contributionMarginPercent'),
    { valueAsNumber: true },
  );

  return (
    <>
      {label && (
        <Grid item xs={2}>
          <FormLabel>{label}</FormLabel>
        </Grid>
      )}
      {countryLabel && (
        <Grid item xs={3} className={classes.titleFlex}>
          <ListItemIcon className={classes.listItemIcon}>
            <img
              src={getIcon(IconType.COUNTRY, countryLabel)}
              alt={countryCodeToOption(countryLabel, i18n.language).label}
              width={18}
            />
          </ListItemIcon>
          <ListItemText>{countryCodeToRegion(countryLabel, i18n.language).label}</ListItemText>
        </Grid>
      )}

      {!label &&
        !countryLabel &&
        (() => {
          const { ref: repairQuotePercentRef, ...repairQuotePercentField } = register(getName('repairQuotePercent'), {
            valueAsNumber: true,
            required: true,
            min: 0,
            max: 100,
          });

          return (
            <Grid item xs={4}>
              <TextField
                inputRef={repairQuotePercentRef}
                {...repairQuotePercentField}
                label={t('configuration.repairQuote')}
                type="number"
                inputProps={{
                  min: 0,
                  max: 100,
                }}
                variant="outlined"
                margin="dense"
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Tooltip title={t('configuration.repairQuoteHint') as string}>
                        <InfoOutlined fontSize="small" />
                      </Tooltip>
                    </InputAdornment>
                  ),
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                error={!!flatErrors[getName('repairQuotePercent.type')]}
              />
            </Grid>
          );
        })()}

      <Grid item xs={4}>
        <CurrencyInput
          error={!!flatErrors[getName('costs.type')]}
          name={getName('costs')}
          label={t('configuration.fixedCosts')}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          inputRef={contributionMarginPercentRef}
          {...contributionMarginPercentField}
          label={t('configuration.contribMargin')}
          type="number"
          variant="outlined"
          margin="dense"
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          error={!!flatErrors[getName('contributionMarginPercent.type')]}
        />
      </Grid>
    </>
  );
};

export default SourceConfiguration;
