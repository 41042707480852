import { max, min } from 'lodash';
import { PriceType } from './generated/api';

export const getMinPotential = (
  countries: string[],
  potentials: { [key: string]: number | { amount: number; currency: string } },
) => {
  const tempArr: number[] = [];
  countries.forEach((country) => {
    const number = potentials[country];
    if (typeof number === 'object') {
      tempArr.push(number.amount);
    } else {
      tempArr.push(number);
    }
  });
  return min(tempArr);
};

export const getMaxPotential = (
  countries: string[],
  potentials: { [key: string]: number | { amount: number; currency: string } },
) => {
  const tempArr: number[] = [];
  countries.forEach((country) => {
    const number = potentials[country];
    if (typeof number === 'object') {
      tempArr.push(number.amount);
    } else {
      tempArr.push(number);
    }
  });
  return max(tempArr);
};

export const getMinRegion = (
  country: string,
  countries: string[],
  potentials: { [key: string]: number | { amount: number; currency: string } },
) => {
  const tempArr: number[] = [];
  countries
    .filter((potentialCountry) => potentialCountry.substring(0, 2) === country)
    .forEach((selectedCountry) => {
      const number = potentials[selectedCountry];
      if (typeof number === 'object') {
        tempArr.push(number.amount);
      } else if (number) {
        tempArr.push(number);
      }
    });
  return min(tempArr);
};

export const getMaxRegion = (
  country: string,
  countries: string[],
  potentials: { [key: string]: number | { amount: number; currency: string } },
) => {
  const tempArr: number[] = [];
  countries
    .filter((potentialCountry: string) => potentialCountry.substring(0, 2) === country)
    .forEach((selectedCountry: string | number) => {
      const number = potentials[selectedCountry];
      if (!number) return null;
      if (typeof number === 'object') {
        tempArr.push(number.amount);
      } else {
        tempArr.push(number);
      }
      return null;
    });
  return max(tempArr);
};

export const fixedVariablesToCalculationResponseKeys: { [key in PriceType]?: string } = {
  [PriceType.OfferPrice]: 'base',
  [PriceType.PurchaseNetWholesaleUnrepaired]: 'purchaseNetRetailUnrepaired',
  [PriceType.PurchaseNetRetailRepaired]: 'purchaseNetRetailRepaired',
  [PriceType.PurchaseGross]: 'purchaseGross',
  [PriceType.PotentialMinAbsolute]: 'potentialMinAbsolute',
  [PriceType.PotentialMinRelative]: 'potentialMinRelative',
  [PriceType.PotentialMinMeanAbsolute]: 'potentialMinMeanAbsolute',
  [PriceType.PotentialMinMeanRelative]: 'potentialMinMeanRelative',
  [PriceType.PotentialMeanRelative]: 'potentialMeanRelative',
  [PriceType.PotentialMeanAbsolute]: 'potentialMeanAbsolute',
};
export const getInputUri = (carId: string, fixedVariable: PriceType, index: number) =>
  `${fixedVariable}.${carId}-${index}`;
