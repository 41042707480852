import React from 'react';
import { makeStyles } from '@material-ui/core';
import WithChildren from '../types/WithChildren';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: `calc(100vh - ${theme.spacing(24)}px)`,
  },
}));

const FullPageCenterContainer = ({ children }: WithChildren) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default FullPageCenterContainer;
