import { Button, makeStyles, TableContainer } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useApi from '../hooks/useApi';
import ApiService from '../modules/api-service';
import { BidDTO, OfferType } from '../modules/generated/api';
import { ListCarDTO } from '../modules/generated/api/api';
import DialogWithClose from './DialogWithClose';
import IndividualBidTable from './IndividualBidTable';
import PackageCarsList from './PackageCarsList';

const useStyles = makeStyles((theme) => ({
  bidsTable: {
    marginTop: theme.spacing(2),
  },
}));

type DeleteBidDialogProps = {
  bidForDeletion?: BidDTO | (BidDTO & ListCarDTO);
  onClose: () => void;
  reload?: () => void;
};

const DeleteBidDialog = ({ bidForDeletion, onClose, reload }: DeleteBidDialogProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { fetch } = useApi();

  return (
    <DialogWithClose
      title={t('bids.delete')}
      open={!!bidForDeletion}
      onClose={onClose}
      stopProp
      actionsBottom={
        <>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
            color="primary"
          >
            {t('common.cancel')}
          </Button>
          <Button
            type="button"
            variant="contained"
            onClick={async (e) => {
              e.stopPropagation();
              await fetch(ApiService.newBids.bidNewControllerCancelBid(bidForDeletion?.id as string));
              if (reload) reload();
              onClose();
            }}
            color="primary"
          >
            {t('common.delete')}
          </Button>
        </>
      }
    >
      {bidForDeletion && (
        <>
          {`${t('bids.delete_for')} `}
          {bidForDeletion.offerType === OfferType.Package ? (
            <>
              <strong>
                {t('common.package')} {t('common.carsWithCount', { count: bidForDeletion.cars?.length })}
              </strong>
              <PackageCarsList cars={bidForDeletion.cars} />
            </>
          ) : (
            bidForDeletion.cars && <strong>{bidForDeletion.cars[0].model}</strong>
          )}
          <TableContainer className={classes.bidsTable}>
            <IndividualBidTable bids={bidForDeletion.bids} />
          </TableContainer>
        </>
      )}
    </DialogWithClose>
  );
};

export default DeleteBidDialog;
