import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const ErrorScreen = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Typography>{t('helpCenter.search.errorScreen.headline')}</Typography>
    </div>
  );
};
