import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { ReactElement } from 'react';
import { Controller, RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type ProfileAutocompleteProps = {
  name: string;
  error: boolean;
  helperText?: string;
  label: string;
  options: string[];
  getOptionLabel?: (option: string) => string;
  defaultValue?: string | null;
  control?: any;
  customOnChange?: (value: string | null) => void;
  rules?: RegisterOptions;
};

const ProfileAutocomplete = ({
  name,
  error,
  helperText,
  label,
  options,
  getOptionLabel,
  defaultValue,
  control,
  customOnChange,
  rules,
  ...rest
}: ProfileAutocompleteProps) => {
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={rules}
      render={({ field: { onChange, ...field } }) => (
        <Autocomplete
          {...field}
          noOptionsText={t('carsTable.noEntriesFound')}
          onChange={(_, value) => {
            if (customOnChange) customOnChange(value);
            onChange(value);
          }}
          options={options}
          getOptionLabel={getOptionLabel}
          renderInput={(params): ReactElement => (
            <TextField
              {...params}
              error={error}
              fullWidth
              helperText={helperText}
              label={label}
              margin="dense"
              size="small"
              variant="outlined"
            />
          )}
        />
      )}
      {...rest}
    />
  );
};

export default ProfileAutocomplete;
