import React from 'react';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core';
import IconValue from './IconValue';
import UnitValue from './UnitValue';

type CarDetailTileUnitProps = {
  hint?: string;
  icon: OverridableComponent<SvgIconTypeMap>;
  label: string;
  unit: string;
  value?: number;
};

const CarDetailTileUnit = ({ hint, icon, label, unit, value }: CarDetailTileUnitProps) => (
  <IconValue hint={hint} icon={icon} label={label} padding>
    <UnitValue unit={unit} value={value} />
  </IconValue>
);

export default CarDetailTileUnit;
