import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import FilterAutocomplete from './FilterAutocomplete';
import { COUNTRY_ORIGIN_FILTER_COUNTRIES, IconType } from '../modules/data';
import { countryCodeToOptions } from '../modules/labels';

const CountryOriginSelect = () => {
  const { t, i18n } = useTranslation();

  return (
    <FilterAutocomplete
      name="countryOrigin"
      label={t('car.countryOrigin')}
      multiple
      options={countryCodeToOptions(COUNTRY_ORIGIN_FILTER_COUNTRIES, i18n.language).sort((a, b) =>
        a.label.localeCompare(b.label, i18n.language),
      )}
      iconType={IconType.COUNTRY}
    />
  );
};

export default memo(CountryOriginSelect);
