import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import FilterAutocomplete from './FilterAutocomplete';
import { capitalizeThreeOrLessChars, capitalizeWords, formatModelNames } from '../modules/string-helpers';
import i18n from '../setup/i18n';
import { homeBrands } from '../modules/data';
import { useCarsFilter } from '../hooks/useCarsFilter';

const useStyles = makeStyles((theme) => ({
  leftFilter: {
    paddingRight: theme.spacing(1),
    '@media screen and (max-width: 959px)': {
      paddingRight: '0',
    },
  },
}));

const brandsFieldName = 'normalizedBrand';
const modelsFieldName = 'normalizedModel';
const equipmentFieldName = 'normalizedEquipmentLine';
const versionFieldName = 'normalizedVersion';

type BrandsModelGroupsSelectProps = {
  loading: boolean;
  brands?: string[];
  models?: string[];
  versions?: string[];
  equipmentLines?: string[];
};

const sortBrands = (brands: string[]): string[] => {
  const externalBrands = brands.filter((brand) => !homeBrands.includes(brand));
  externalBrands.sort((a, b) => a.localeCompare(b));
  const availableHomeBrands = homeBrands.filter((brand) => brands.includes(brand));
  return [...availableHomeBrands, ...externalBrands];
};

const BrandsModelGroupsSelect = ({
  brands = [],
  models = [],
  versions = [],
  equipmentLines = [],
  loading,
}: BrandsModelGroupsSelectProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    form: { getValues, setValue },
    defaultValues,
  } = useCarsFilter();
  const sortedBrands = sortBrands(brands);
  const brandOptions = sortedBrands.map((value) => ({
    label: i18n.exists(`brands.${value}`)
      ? t(`brands.${value}` as any)
      : capitalizeThreeOrLessChars(capitalizeWords(value).split(' ')),
    value,
  }));
  const equipmentLineOptions = equipmentLines.map((value) => ({
    label: capitalizeThreeOrLessChars(capitalizeWords(value).split(' ')),
    value,
  }));
  const modelOptions = models.map((value) => ({
    label: formatModelNames(value),
    value,
  }));
  const versionOptions = versions.map((value) => ({ label: value, value }));

  return (
    <Grid container justifyContent="space-between">
      <Grid className={classes.leftFilter} item md={6} xs={12}>
        <FilterAutocomplete
          name={brandsFieldName}
          label={t('car.manufacturer')}
          multiple
          options={brandOptions}
          isLoading={loading}
          onChipDelete={(value) => {
            if (getValues(brandsFieldName).length === 1) {
              setValue(modelsFieldName, defaultValues.normalizedModel);
            }
            setValue(
              brandsFieldName,
              getValues(brandsFieldName).filter((item: string) => item !== value),
            );
          }}
          groupBy={(option) => (homeBrands.includes(option) ? t('car.homebrand') : t('car.foreignBrand'))}
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <FilterAutocomplete
          name={modelsFieldName}
          label={t('car.modelGroup')}
          multiple
          options={modelOptions}
          isLoading={loading}
        />
      </Grid>

      <Grid className={classes.leftFilter} item md={6} xs={12}>
        <FilterAutocomplete
          name={equipmentFieldName}
          label={t('car.equipmentLine')}
          multiple
          options={equipmentLineOptions}
          isLoading={loading}
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <FilterAutocomplete
          name={versionFieldName}
          label={t('car.version')}
          multiple
          options={versionOptions}
          isLoading={loading}
        />
      </Grid>
    </Grid>
  );
};

export default memo(BrandsModelGroupsSelect);
