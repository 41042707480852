import React from 'react';
import PageContainer from '@components/PageContainer';
import ConfigurationFormattingForm from 'src/containers/ConfigurationFormattingForm';
import { RouteComponentProps } from '@reach/router';
import { PageMeta } from '@components/page-meta';

const FormatsPage = (_props: RouteComponentProps) => (
  <>
    <PageMeta title="conditionalFormatting.formattingConfigHeader" />
    <PageContainer>
      <ConfigurationFormattingForm />
    </PageContainer>
  </>
);

export default FormatsPage;
