import React, { ReactNode } from 'react';
import { makeStyles, TableCell } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'right',
  },
}));

type CarDetailTableHeadCellProps = {
  children: ReactNode;
};

const CarDetailTableHeadCell = ({ children }: CarDetailTableHeadCellProps) => {
  const classes = useStyles();

  return <TableCell className={classes.root}>{children}</TableCell>;
};

export default CarDetailTableHeadCell;
