export enum Tax {
  VAT = 'VAT',
  NOVA = 'NOVA',
  REGISTRATION = 'REGISTRATION',
  REGISTRATION_RELATIVE = 'REGISTRATION_RELATIVE',
  ECOMALUS = 'ECOMALUS',
  TRANSCRIPTION = 'TRANSCRIPTION',
  ECO = 'ECO',
  CIVIL = 'CIVIL',
  EXCISE = 'EXCISE',
  AUTHENTICATION = 'AUTHENTICATION',
  PROPERTY_ACQUISITION = 'PROPERTY_ACQUISITION',
}
