import { Grid } from '@material-ui/core';
import React from 'react';
import { SourceType } from '../modules/generated/api';
import SourceConfiguration from './SourceConfiguration';

type CountrySourceCostsProps = {
  countryLabel?: string;
  source: SourceType;
};

const CountrySourceCosts = ({ countryLabel, source }: CountrySourceCostsProps) => (
  <Grid container spacing={4} alignItems="center">
    <SourceConfiguration
      key={countryLabel}
      countryLabel={countryLabel}
      prefix={`global.${source}.costsPerCountry.${countryLabel}`}
    />
  </Grid>
);

export default CountrySourceCosts;
