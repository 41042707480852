import React from 'react';
import { tr } from 'src/modules/i18n-helpers';
import { isArray } from 'lodash';
import { useCurrency } from '../../../hooks/useCurrency';
import FilterChip from './FilterChip';
import { CarsTableNextFilterData } from './types';
import { useFilter } from './useFilter';

type AmountSilderFilterChipProps = {
  value: any;
  name: string;
  label: string;
};

export const AmountSliderChip = ({ value, name, label }: AmountSilderFilterChipProps) => {
  const { format } = useCurrency();
  const { setValue, defaultFilter } = useFilter();

  if (isArray(value))
    return (
      <FilterChip
        name={name}
        key={`${name}-${value}`}
        tooltip={tr(`${label}`)}
        label={`${format(value[0], {})} - ${format(value[1], {})}`}
        onDelete={() =>
          setValue(name as keyof CarsTableNextFilterData, defaultFilter[name as keyof CarsTableNextFilterData])
        }
      />
    );
  return null;
};
