import { useTranslation } from 'react-i18next';
import React from 'react';
import { TextField } from '@material-ui/core';
import { getSourceOptions, useSources } from 'src/hooks/useSources';
import { Autocomplete } from '@material-ui/lab';
import { sortBy } from 'lodash';
import { getLabel } from 'src/modules/labels';
import { SourceType } from '../modules/generated/api';
import useCustomSnackbarGlobal from '../hooks/useSnackbarGlobal';
import ApiService from '../modules/api-service';
import { DetailedDealerDTO } from '../modules/generated/api/api';

export type DealerSourcesPermissionProps = {
  dealer: DetailedDealerDTO;
  dealerId: string;
  edit: boolean;
  refetch: () => void;
};

export const DealerSourcesPermission = ({ dealerId, refetch, dealer }: DealerSourcesPermissionProps) => {
  const { t } = useTranslation();
  const { showError, showSuccess } = useCustomSnackbarGlobal();

  const { data: source = [] } = useSources();
  const sourceOptions = getSourceOptions(source);
  const options = sortBy(
    sourceOptions.map((src) => src),
    (option) => option.label.toLowerCase(),
    ['asc'],
  );
  const optionValues = options.map(({ value }) => value);

  const saveSources = async (newAvailableSources: SourceType[]) => {
    const response = await ApiService.dealer.dealerControllerSaveAllowedSourceTypes(dealerId, newAvailableSources);
    if (response.status !== 200) {
      showError(t('alerts.errorRaised'));
      return;
    }
    showSuccess(t('alerts.successSaved'));
    refetch();
  };

  const handleSelect = (newOption: never[]) => {
    saveSources(newOption);
  };

  return (
    <Autocomplete
      fullWidth
      size="small"
      multiple
      value={dealer?.allowedSource}
      options={optionValues}
      getOptionLabel={(option: string) => getLabel(options, option)}
      onChange={(value, newOption) => handleSelect(newOption as never[])}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
    />
  );
};
