import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { merge, omit } from 'lodash';
import { useSearchAgents } from 'src/hooks/useSearchAgents';
import { Link } from '@reach/router';
import ListIcon from '@material-ui/icons/List';
import { useTranslation } from 'react-i18next';
import { RoutePaths } from 'src/constants';
import SearchAgentSave from '../containers/SearchAgentSave';
import SearchAgentFavorites from './SearchAgentFavorites';
import ApiService from '../modules/api-service';
import useApi from '../hooks/useApi';
import { SearchAgentDTO } from '../modules/generated/api';
import { transformForForm } from '../modules/cars-filter';
import { isValuationValid } from '../modules/valuation-country-helpers';
import { obsoleteSearchAgentFields } from '../modules/data';
import { useValuationSettings } from '../hooks/useValuationSettings';
import { useCarsFilter } from '../hooks/useCarsFilter';
import { CarsFilterSettings } from '../types/CarsFilterSettings';

const SearchAgentActionBar = () => {
  const { t } = useTranslation();
  const { data: searchAgents, refetch } = useSearchAgents();
  const { fetch: fetchUpdate } = useApi();
  const {
    form: { reset },
    defaultValues,
  } = useCarsFilter();
  const { setValuationSettings } = useValuationSettings();

  const updateSearchAgent = async (searchAgentDto: SearchAgentDTO) => {
    await fetchUpdate(
      ApiService.searchAgent.searchAgentControllerUpdateSearchAgent(searchAgentDto.id as string, searchAgentDto),
    );
    refetch();
  };

  const setSearchAgentAsFilter = (searchAgent: SearchAgentDTO) => {
    const { valuationCountry, valuationType, ...filter } = searchAgent;

    if (isValuationValid(valuationCountry, valuationType)) {
      setValuationSettings(valuationCountry || null, valuationType || null);
    }

    const newFilterValues = omit(
      merge({}, defaultValues, transformForForm(filter)),
      obsoleteSearchAgentFields,
    ) as CarsFilterSettings;

    reset(newFilterValues);
  };

  return (
    <>
      <Grid item lg={4} xs={12}>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item>
            <Button
              component={Link}
              to={RoutePaths.searchAgents}
              endIcon={<ListIcon />}
              color="secondary"
              variant="contained"
            >
              {t('filterSearch.manageSearchAgent')}
            </Button>
          </Grid>
          <Grid item>
            <SearchAgentSave searchAgents={searchAgents} refetch={refetch} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={6} xs={12}>
        <SearchAgentFavorites
          searchAgents={searchAgents.filter((searchAgent) => searchAgent.favorite)}
          updateSearchAgent={updateSearchAgent}
          viewSearchAgent={setSearchAgentAsFilter}
        />
      </Grid>
    </>
  );
};

export default SearchAgentActionBar;
