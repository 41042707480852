import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../modules/labels';
import FilterAutocomplete from './FilterAutocomplete';
import { IconType } from '../modules/data';

const EquipmentsSelect = () => {
  const { t } = useTranslation();

  return (
    <FilterAutocomplete name="colors" label={t('colors.color')} multiple options={colors} iconType={IconType.COLOR} />
  );
};

export default memo(EquipmentsSelect);
