import React, { ChangeEventHandler, ReactElement } from 'react';
import {
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Experiment, ExperimentId, useExperiments } from '../hooks/useExperiments';

type ExperimentTableRowProps = Experiment & {
  onChange: ChangeEventHandler<HTMLInputElement>;
};

const ExperimentTableRow = ({ title, description, enabled, id, onChange }: ExperimentTableRowProps): ReactElement => (
  <TableRow>
    <TableCell component="th" scope="row">
      <Typography variant="body2" component="div">
        {title}
      </Typography>
    </TableCell>
    <TableCell component="th" scope="row">
      <Typography variant="body2" component="div">
        {description}
      </Typography>
    </TableCell>
    <TableCell align="right" component="th" scope="row">
      <Switch checked={enabled} onChange={onChange} color="primary" name={id} />
    </TableCell>
  </TableRow>
);

export const ExperimentsTable = (): ReactElement => {
  const { t } = useTranslation();
  const { experiments, enableExperiment, disableExperiment } = useExperiments();

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const id = event.target.name as ExperimentId;
    const enabled = event.target.checked;

    if (enabled) {
      enableExperiment(id);
    } else {
      disableExperiment(id);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="body2">{t('common.title')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{t('common.description')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" />
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {experiments.map(({ id, title, description, enabled }) => (
            <ExperimentTableRow
              key={id}
              id={id}
              title={t(title as any)}
              description={t(description as any)}
              enabled={enabled}
              onChange={handleChange}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
