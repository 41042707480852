import React from 'react';
import { BidSuccessStatus, IndividualBidDTO } from '../modules/generated/api';

type CarsTableBidsBuyerProps = {
  bids?: IndividualBidDTO[];
};

const CarsTableBidsBuyer = ({ bids }: CarsTableBidsBuyerProps) => {
  const user = bids?.find(
    (bidSubmitted) =>
      bidSubmitted.status === BidSuccessStatus.Highest || bidSubmitted.status === BidSuccessStatus.Bought,
  )?.user;

  return <span>{user?.name}</span>;
};

export default CarsTableBidsBuyer;
