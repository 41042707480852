import React, { ChangeEventHandler } from 'react';
import { inputLabelProps } from '@components/cars-table-next/filter/constants';
import { InputLabel } from '@components/ui';
import { Checkbox, FormControl, FormControlLabel, Grid, GridSize } from '@material-ui/core';
import { Option } from 'src/modules/i18n-helpers';
import { useTranslation } from 'react-i18next';
import { useControlled } from '../../useControlled';

type CheckboxGroupProps = {
  label: string;
  options: Readonly<Option[]>;
  value?: string[];
  onChange?: (value: string[]) => void;
  columns?: number;
};

const RenderOption = ({
  option,
  onChange,
  checked,
}: {
  option: Option;
  checked: boolean;
  onChange: (event: any) => void;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <FormControlLabel
      key={option.value}
      control={
        <Checkbox
          {...option.options}
          style={{
            marginBlock: -8,
            ...option.options?.style,
          }}
          value={option.value}
          onChange={onChange}
          checked={checked}
        />
      }
      label={t(option.label as any)}
    />
  );
};

export const CheckboxGroup = ({ label, options, value: initialValue, onChange, columns = 1 }: CheckboxGroupProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useControlled({ controlled: initialValue, default: [] });

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { checked, value: checkboxValue } = event.target;

    if (checked) {
      onChange?.([...value, checkboxValue]);
      setValue((prev = []) => [...prev, checkboxValue]);
    } else {
      onChange?.(value!.filter((v) => v !== checkboxValue));
      setValue((prev = []) => prev.filter((v) => v !== checkboxValue));
    }
  };

  return (
    <FormControl component="fieldset">
      <InputLabel style={inputLabelProps.style}>{t(label as any)}</InputLabel>
      <Grid container>
        {options.map((option) => (
          <Grid item key={option.value} xs={(12 / columns) as GridSize}>
            <RenderOption option={option} onChange={handleChange} checked={value.includes(option.value)} />
          </Grid>
        ))}
      </Grid>
    </FormControl>
  );
};
