import { RouteComponentProps } from '@reach/router';
import { ComponentType } from 'react';
import { RoutePaths } from 'src/constants';

import BidsPastPage from 'src/pages/bids/past';
import BidsPendingPage from 'src/pages/bids/pending';
import CarsPage from 'src/pages/cars';
import CarPage from 'src/pages/cars/[source]/[carId]';
import ForbiddenPage from 'src/pages/forbidden';
import ImprintPage from 'src/pages/imprint';
import LoginPage from 'src/pages/login';
import LogoutPage from 'src/pages/logout';
import OrdersPage from 'src/pages/orders';
import SearchAgentsPage from 'src/pages/searchagents';
import ImageExportPage from 'src/pages/services/image-export';
import CostsPage from 'src/pages/settings/costs';
import CredentialsPage from 'src/pages/settings/credentials';
import DealersPage from 'src/pages/settings/dealers';
import DeliveryAddressPage from 'src/pages/settings/delivery-address';
import DetailingAddressPage from 'src/pages/settings/detailing-address';
import ExperimentsPage from 'src/pages/settings/experiments';
import FormatsPage from 'src/pages/settings/formats';
import TermsAndConditionsPage from 'src/pages/terms-and-conditions';

enum GTMPage {
  configuration = 'Configuration',
  bids = 'Bids',
  cars = 'Car list',
  car = 'Car details',
}

export type RouteDef = {
  path: string;
  element: ComponentType<RouteComponentProps>;
  /**
   * Display route to authenticated users only?
   * @default false
   */
  protected?: boolean;
  /**
   * External analytics meta
   */
  gtmPageType?: string;
};

export const routes: RouteDef[] = [
  {
    path: RoutePaths.login,
    element: LoginPage,
  },
  {
    path: RoutePaths.logout,
    element: LogoutPage,
  },
  {
    path: RoutePaths.forbidden,
    element: ForbiddenPage,
  },
  {
    path: `${RoutePaths.cars}/*`,
    element: CarsPage,
    protected: true,
    gtmPageType: GTMPage.cars,
  },
  {
    path: RoutePaths.car,
    element: CarPage,
    protected: true,
    gtmPageType: GTMPage.car,
  },
  {
    path: RoutePaths.experiments,
    element: ExperimentsPage,
    protected: true,
  },
  {
    path: RoutePaths.searchAgents,
    element: SearchAgentsPage,
    protected: true,
  },
  {
    path: RoutePaths.termsAndConditions,
    element: TermsAndConditionsPage,
    protected: true,
    gtmPageType: GTMPage.configuration,
  },
  {
    path: RoutePaths.imprint,
    element: ImprintPage,
    protected: true,
    gtmPageType: GTMPage.configuration,
  },
  {
    path: `${RoutePaths.bidsPending}/*`,
    element: BidsPendingPage,
    protected: true,
    gtmPageType: GTMPage.bids,
  },
  {
    path: `${RoutePaths.bidsPast}/*`,
    element: BidsPastPage,
    protected: true,
    gtmPageType: GTMPage.bids,
  },
  {
    path: `${RoutePaths.orders}/*`,
    element: OrdersPage,
    protected: true,
    gtmPageType: GTMPage.bids,
  },
  {
    path: RoutePaths.dealers,
    element: DealersPage,
    protected: true,
    gtmPageType: GTMPage.configuration,
  },
  {
    path: RoutePaths.credentials,
    element: CredentialsPage,
    protected: true,
    gtmPageType: GTMPage.configuration,
  },
  {
    path: RoutePaths.costs,
    element: CostsPage,
    protected: true,
    gtmPageType: GTMPage.configuration,
  },
  {
    path: RoutePaths.formats,
    element: FormatsPage,
    protected: true,
    gtmPageType: GTMPage.configuration,
  },
  {
    path: RoutePaths.deliveryAddress,
    element: DeliveryAddressPage,
    protected: true,
    gtmPageType: GTMPage.configuration,
  },
  {
    path: RoutePaths.detailingAddress,
    element: DetailingAddressPage,
    protected: true,
    gtmPageType: GTMPage.configuration,
  },
  {
    path: RoutePaths.imageExport,
    element: ImageExportPage,
  },
];
