import { SourceType } from './generated/api';

export const getExternalDetailLink = (source?: SourceType, carId?: string, offerId?: string) => {
  switch (source) {
    case SourceType.Autobid:
      return `https://autobid.de/?action=car&id=${carId}&show=car`;
    case SourceType.Vwfs:
      return `https://usedcars.vwfs.com/vehicles/detail/${carId}`;
    case SourceType.Arval:
      return `https://remktg.arval.com/desktop2/#/item-details/${carId}?prov=se&salesEventId=${offerId}`;
    case SourceType.Okcars:
      return 'https://app.logicauto.es/';
    case SourceType.Autorola:
      return `https://www.autorola.de/dealer/bid.do?zz=1&eid=${carId}&aid=${offerId}`;
    case SourceType.Auto1:
      return `https://www.auto1.com/de/app/merchant/car/${carId}`;
    default:
      return null;
  }
};

export const getExternalPackageLink = (source?: SourceType, packageId?: string) => {
  switch (source) {
    case SourceType.Vwfs:
      return `https://usedcars.vwfs.com/vehicles/vehicle-list/stockList?packageId=${packageId}`;
    default:
      return null;
  }
};
