import { Grid, GridProps, makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import clsx from 'clsx';
import { isString, pickBy } from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import countries from 'i18n-iso-countries';
import { SHIPPING_COUNTRIES_COUNTRY_CODES } from '../modules/data';
import i18n from '../setup/i18n';
import { getLabel, getRegionOptions } from '../modules/labels';
import ProfileAutocomplete from './ProfileAutocomplete';

type AddressFieldsProps = {
  source?: string;
  country?: string;
  className?: string;
};

const useStyles = makeStyles({
  root: {
    border: 'none',
  },
});

const FormRow = ({ children, ...rest }: GridProps) => (
  <Grid item xs={12} {...rest}>
    <Grid container spacing={3}>
      {children}
    </Grid>
  </Grid>
);

const FormItem = (props: GridProps) => <Grid item xs={12} sm={4} {...props} />;

const FormTextField = ({
  name,
  defaultValue,
  required,
  ...textFieldProps
}: TextFieldProps & Pick<ControllerProps, 'name' | 'rules'>) => (
  <Controller
    key={name}
    name={name}
    rules={{ required }}
    shouldUnregister={false}
    defaultValue={defaultValue}
    render={({ field }) => (
      <TextField
        fullWidth
        InputLabelProps={{ shrink: true }}
        size="small"
        variant="outlined"
        {...field}
        {...textFieldProps}
      />
    )}
  />
);

export const AddressFields = ({ source, country, className }: AddressFieldsProps): ReactElement => {
  const classes = useStyles();
  const { getValues, watch, register, unregister } = useFormContext();
  const { t } = useTranslation();
  const rootValues = getValues('default');
  const sourceRootValues = pickBy(getValues(`${source}.default`), (value) => !!value);
  const placeholderValues = { ...rootValues, ...sourceRootValues };
  const countryCode = watch(prefixName('countryCode'));

  function prefixName(name: string) {
    return [source, country, name].filter(isString).join('.');
  }

  useEffect(() => {
    const fieldName = prefixName('country');
    register(fieldName, { value: countries.getName(countryCode, i18n.language) });

    return () => {
      unregister(fieldName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode, source, country]);

  return (
    <fieldset className={clsx(classes.root, className)}>
      {source !== 'default' ? (
        <legend>
          {source} {country && getLabel(getRegionOptions(), country)}
        </legend>
      ) : null}
      <Grid container spacing={3}>
        <FormRow>
          <FormItem>
            <FormTextField
              name={prefixName('companyName')}
              defaultValue=""
              placeholder={placeholderValues.companyName}
              error={false}
              label={t('configuration.companyName')}
            />
          </FormItem>
          <FormItem>
            <FormTextField
              name={prefixName('contactPersonName')}
              defaultValue=""
              placeholder={placeholderValues.contactPersonName}
              error={false}
              label={t('configuration.contactPerson')}
            />
          </FormItem>
        </FormRow>

        <FormRow>
          <FormItem>
            <FormTextField
              name={prefixName('email')}
              defaultValue=""
              placeholder={placeholderValues.email}
              error={false}
              label={t('contact.E-Mail')}
            />
          </FormItem>
          <FormItem>
            <FormTextField
              name={prefixName('phoneNumber')}
              defaultValue=""
              placeholder={placeholderValues.phoneNumber}
              error={false}
              label={t('contact.phone')}
            />
          </FormItem>
          <FormItem>
            <FormTextField
              name={prefixName('faxNumber')}
              defaultValue=""
              placeholder={placeholderValues.faxNumber}
              error={false}
              label={t('contact.fax')}
            />
          </FormItem>
          <FormItem>
            <FormTextField
              name={prefixName('street')}
              defaultValue=""
              placeholder={placeholderValues.street}
              error={false}
              label={t('contact.street')}
            />
          </FormItem>
          <FormItem>
            <FormTextField
              name={prefixName('zip')}
              defaultValue=""
              placeholder={placeholderValues.zip}
              error={false}
              label={t('contact.postal')}
            />
          </FormItem>
          <FormItem>
            <FormTextField
              name={prefixName('city')}
              defaultValue=""
              placeholder={placeholderValues.city}
              error={false}
              label={t('contact.city')}
            />
          </FormItem>
          <FormItem>
            <ProfileAutocomplete
              name={prefixName('countryCode')}
              key={prefixName('countryCode')}
              error={false}
              defaultValue={null}
              label={t('contact.country')}
              options={SHIPPING_COUNTRIES_COUNTRY_CODES}
              getOptionLabel={(option: string) => (countries.getName(option, i18n.language) as string) || option}
            />
          </FormItem>
        </FormRow>
      </Grid>
    </fieldset>
  );
};
