import React, { ChangeEventHandler, ComponentProps } from 'react';
import { Rating } from '@material-ui/lab';
import type { Merge } from 'type-fest';
import { makeStyles, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { TextCell, TextCellProps } from './TextCell';

export type RatingCellProps = {
  name: string;
  value?: number;
  onChange?: ChangeEventHandler<{}>;
  comparisonRating?: number | false;
  comparisonRatingCount?: number;
};

const useStyles = makeStyles({
  rating: {
    // fix vertical spacing
    display: 'flex',
    width: 'max-content',
    padding: 1,

    '& .MuiRating-icon': {
      marginInline: -1,
    },
  },
  ratingOther: {
    color: '#2196f3',
    position: 'relative',
    marginTop: '-7px',
    right: '-9px',
  },
});

/**
 * Ratings provide insight regarding others' opinions and experiences, and can allow the user to submit a rating of their own.
 */
export const RatingCell = ({
  value = 0,
  onChange,
  name,
  comparisonRating,
  comparisonRatingCount,
  ...rest
}: Merge<ComponentProps<'div'>, Merge<Partial<TextCellProps>, RatingCellProps>>): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TextCell
      text={
        <>
          <Rating size="small" name={name} className={classes.rating} value={value} onChange={onChange} />
          {!!comparisonRating && (
            <Tooltip
              title={
                comparisonRatingCount !== undefined &&
                t('carsTableNext.cells.rating.avgRatingOther', { count: comparisonRatingCount })
              }
            >
              <span>
                <Rating
                  size="small"
                  name="meanRatingOthers"
                  className={clsx(classes.rating, classes.ratingOther)}
                  value={comparisonRating}
                  readOnly
                />
              </span>
            </Tooltip>
          )}
        </>
      }
      {...rest}
    />
  );
};
