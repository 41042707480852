import React, { ChangeEventHandler, useMemo, useState } from 'react';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Box, IconButton, Link, styled, Tooltip } from '@material-ui/core';
import { HeadCell, TextCell, Table, TimestampCell, MenuAction, Menu, useMenu } from '@components/ui';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import { LeadToggleCell } from '@components/ui/LeadToggleCell';
import LaunchIcon from '@material-ui/icons/Launch';
import { BadgeCell } from '@components/ui/BadgeCell';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useExternalSearchAgents } from 'src/hooks/useExternalSearchAgents';
import {
  ExternalSearchAgentWithNotificationDTO,
  NotificationChannel,
  SearchAgentRole,
  SourceType,
} from 'src/modules/generated/api';
import { useExternalSearchAgentUpdate } from 'src/hooks/useExternalSearchAgentUpdate';
import { QueryKeys } from 'src/constants';
import { useQueryClient } from '@tanstack/react-query';
import {
  ExternalSearchAgentFormData,
  ExternalSearchAgentTableDrawer,
} from '@components/ExternalSearchAgentTableDrawer';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { yellow } from '@material-ui/core/colors';
import { tr } from 'src/modules/i18n-helpers';

type ExternalSearchAgentTableProps = {
  id?: string;
};

export type ExternalSearchAgent = ExternalSearchAgentWithNotificationDTO & {
  isDuplicated?: boolean;
};

enum ExternalSearchAgentAction {
  edit,
  display,
}

const StyledErrorIconWrapper = styled('span')(({ theme }) => ({
  display: 'inline-flex',
  background: yellow[50],
  color: yellow[900],
  borderRadius: '99rem',
  padding: 2,
  marginInlineStart: theme.spacing(0.5),
  verticalAlign: 'top',
}));

const columns: ColumnDef<ExternalSearchAgent>[] = [
  {
    id: 'name',
    header: () => <HeadCell text="common.name" />,
    cell: ({ row }) => {
      const { t } = useTranslation();
      const queryClient = useQueryClient();
      const { mutate: updateSearchAgent } = useExternalSearchAgentUpdate();
      const { active, id, name, kvps, source, notification, isDuplicated } = row.original ?? {};
      const linkIsValid = notification?.link !== undefined && notification.link !== '';

      const handleChangeActive: ChangeEventHandler<HTMLInputElement> = (event) => {
        const newActiveValue = event.target.checked;

        const searchAgents = queryClient.getQueryData<ExternalSearchAgentWithNotificationDTO[]>([
          QueryKeys.externalSearchAgents,
          source,
        ]);
        const target = searchAgents?.find((searchAgent) => searchAgent.id === id);

        if (!target) {
          return;
        }

        // If the agent gets activated, we want to make sure that the role
        // and notifications are active as well in order to prevent confusion.
        if (newActiveValue) {
          updateSearchAgent({
            ...target,
            active: newActiveValue,
            role:
              target.role !== undefined && target.role !== SearchAgentRole.None
                ? target.role
                : SearchAgentRole.ShoppingCart,
            notification: {
              active: true,
              channel: target.notification?.channel ?? NotificationChannel.EMail,
            },
          });
        } else {
          updateSearchAgent({ ...target, active: newActiveValue });
        }
      };

      return (
        <LeadToggleCell
          text={
            linkIsValid ? (
              <Link href={notification.link} target="_blank" rel="noopener noreferrer">
                {/* eslint-disable-next-line no-restricted-syntax */}
                <Box marginRight={0.5} component="span">
                  {name}
                </Box>
                <LaunchIcon fontSize="inherit" />
              </Link>
            ) : (
              <span>{name}</span>
            )
          }
          supportText={
            <>
              {kvps}
              {isDuplicated && (
                <Tooltip title={t('externalSearchAgentTable.isDuplicated')} arrow placement="right">
                  <StyledErrorIconWrapper>
                    <ErrorOutlineIcon fontSize="inherit" color="inherit" />
                  </StyledErrorIconWrapper>
                </Tooltip>
              )}
            </>
          }
          checked={!!active}
          onChange={handleChangeActive}
        />
      );
    },
  },
  {
    accessorKey: 'userName',
    header: () => <HeadCell text="common.username" />,
    cell: (props) => <TextCell text={(props.getValue() as string) || '?'} />,
  },
  {
    accessorKey: 'expires',
    header: () => <HeadCell text="externalSearchAgentTable.gwPlusStatus" />,
    cell: (props) => {
      const { t } = useTranslation();
      const value = props.getValue() as Date | undefined;
      const expired = value !== undefined && new Date(value).getTime() < Date.now();

      return (
        <Tooltip
          title={value ? t('common.validTo', { date: DateTime.fromJSDate(value).toFormat('dd.MM.yyyy') }) : ''}
          arrow
          placement="right"
        >
          {/* eslint-disable-next-line no-restricted-syntax */}
          <Box display="inline-flex">
            <BadgeCell
              label={expired ? 'common.expired' : 'common.valid'}
              color={expired ? 'error' : 'success'}
              // @ts-expect-error
              style={{ cursor: 'help' }}
            />
          </Box>
        </Tooltip>
      );
    },
  },
  {
    accessorKey: 'role',
    header: () => <HeadCell text="externalSearchAgentTable.role" />,
    cell: (props) => <TextCell text={props.getValue() ? `searchAgentRoles.${props.getValue()}` : '-'} />,
  },
  {
    accessorKey: 'notification',
    header: () => <HeadCell text="common.notification" />,
    cell: (props) => {
      const data = props.getValue<any>();
      return <TextCell text={data.active ? tr(`notification.${data.channel || 'UNKNOWN'}`) || '' : '-'} />;
    },
  },
  {
    accessorKey: 'createdAt',
    header: () => <HeadCell text="common.created" />,
    cell: (props) => <TimestampCell timestamp={props.getValue<string | undefined>()} />,
  },
  {
    id: 'actions',
    header: () => null,
    cell: ({ row }) => {
      const { t } = useTranslation();
      const { source, id, notification } = row.original ?? {};
      const { isOpen: isMenuOpen, open: openMenu, close: closeMenu } = useMenu();
      const [drawerOpen, setDrawerOpen] = useState(false);
      const queryClient = useQueryClient();
      const { mutate: updateExternalSearchAgent } = useExternalSearchAgentUpdate();
      const linkIsValid = notification?.link !== undefined && notification.link !== '';

      const menuReducer = (action: MenuAction) => {
        switch (action.id) {
          case ExternalSearchAgentAction.display: {
            if (linkIsValid) window.open(notification.link, 'blank');
            break;
          }

          case ExternalSearchAgentAction.edit: {
            setDrawerOpen(true);
            break;
          }

          // no default
        }
      };

      const handleSubmitDrawer = (data: ExternalSearchAgentFormData) => {
        const searchAgents = queryClient.getQueryData<ExternalSearchAgentWithNotificationDTO[]>([
          QueryKeys.externalSearchAgents,
          source,
        ]);
        const target = searchAgents?.find((searchAgent) => searchAgent.id === id);

        if (target) {
          updateExternalSearchAgent({
            ...target,
            active: data.active,
            role: data.role,
            notification: {
              channel: data.notification.channel,
              active: data.notification.active,
            },
          });
        }

        setDrawerOpen(false);
      };

      const handleCloseDrawer = () => {
        setDrawerOpen(false);
      };

      return (
        <>
          {/* eslint-disable-next-line no-restricted-syntax */}
          <Box textAlign="right">
            <Menu
              open={isMenuOpen}
              onOpen={openMenu}
              onClose={closeMenu}
              actions={[
                // prettier-ignore
                ...(linkIsValid ? [{ id: ExternalSearchAgentAction.display, title: t('action.display'), icon: VisibilityIcon }] : []),
                { id: ExternalSearchAgentAction.edit, title: t('action.edit'), icon: EditIcon },
              ].filter(Boolean)}
              onAction={menuReducer}
            >
              {(triggerProps) => (
                <IconButton {...triggerProps} size="small">
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              )}
            </Menu>
          </Box>

          <ExternalSearchAgentTableDrawer
            row={row}
            open={drawerOpen}
            onClose={handleCloseDrawer}
            onSubmit={handleSubmitDrawer}
          />
        </>
      );
    },
  },
];

export const ExternalSearchAgentTable = ({ id }: ExternalSearchAgentTableProps): JSX.Element => {
  const { t } = useTranslation();
  const { data: externalSearchAgents } = useExternalSearchAgents({ source: SourceType.AudiGws });
  const data = useMemo(
    () =>
      externalSearchAgents?.map<ExternalSearchAgent>((searchAgent) => ({
        id: searchAgent.id!,
        name: searchAgent.name ?? 'UNKNOWN',
        active: searchAgent.active,
        createdAt: searchAgent.created,
        role: searchAgent.role,
        kvps: searchAgent.kvps,
        notification: searchAgent.notification,
        expires:
          searchAgent.expires !== undefined && searchAgent.expires !== ''
            ? new Date(searchAgent.expires).toISOString()
            : undefined,
        source: searchAgent.source,
        // @ts-expect-error: TODO: remove after BE implementation
        link: searchAgent.link,
        isDuplicated: searchAgent.isDuplicated,
        userName: searchAgent.userName,
      })) ?? [],
    [externalSearchAgents],
  );
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (searchAgent) => searchAgent.id!,
  });

  return (
    <Table
      id={id}
      title={t('externalSearchAgentTable.title')}
      subheader={t('externalSearchAgentTable.subheader')}
      table={table}
    />
  );
};
