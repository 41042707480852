import { grey } from '@material-ui/core/colors';

export const inputLabelProps = {
  shrink: true,
  style: {
    fontWeight: 'normal',
    fontSize: 12,
    color: grey[600],
  },
};

export const muiInputLabelProps = {
  shrink: true,
  style: {
    color: grey[600],
  },
};
