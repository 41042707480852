import React from 'react';
import { useCurrency } from 'src/hooks/useCurrency';
import { tr } from 'src/modules/i18n-helpers';
import { useFilter } from './useFilter';
import FilterChip from './FilterChip';

type PotentialChipProps = {
  value: any;
  name: string;
};

const PotentialAbsoluteFilterChip = ({ value, name }: PotentialChipProps) => {
  const { setFilter, watch } = useFilter();
  const filterState = watch();
  const { format } = useCurrency();

  const min = filterState?.potentialFilterAbsoluteMin;
  const max = filterState?.potentialFilterAbsoluteMax;

  const getTooltip = (): string => {
    if (min && max) {
      return tr(`carsTableNext.filter.potential.${value}`);
    }
    const range = min ? 'Min' : 'Max';

    return `${tr(`carsTableNext.filter.potential.${value}`)} ${range}`;
  };

  if (min || max)
    return (
      <FilterChip
        name={name}
        key={`${name}-${value}-absolute`}
        tooltip={getTooltip()}
        label={
          min && max ? `${format(min, {})} - ${format(max, {})}` : `${format((min as object) || (max as object), {})}`
        }
        onDelete={() =>
          filterState &&
          setFilter({
            ...filterState,
            potentialFilterAbsoluteMin: undefined,
            potentialFilterAbsoluteMax: undefined,
          })
        }
      />
    );
  return null;
};

export default PotentialAbsoluteFilterChip;
