import React, { memo } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, makeStyles } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { Option } from '../modules/i18n-helpers';

const useStyles = makeStyles(() => ({
  formGroup: {
    flexDirection: 'row',
  },
}));

type OptionCheckboxesProps = {
  name: string;
  label: string;
  options: Option[];
};

const OptionCheckboxes = ({ name, label, options }: OptionCheckboxesProps) => {
  const classes = useStyles();
  const { getValues } = useFormContext();

  const handleCheck = (value: string) => {
    const values = getValues(name);

    return values?.includes(value) ? values?.filter((id: string) => id !== value) : [...(values ?? []), value];
  };

  return (
    <FormControl component="fieldset" margin="dense" fullWidth>
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup className={classes.formGroup}>
        <Controller
          name={name}
          render={({ field: { value, onChange } }) => (
            <>
              {options.map(({ value: itemValue, label: optionLabel }) => (
                <FormControlLabel
                  key={itemValue}
                  value={itemValue}
                  control={
                    <Checkbox checked={value?.includes(itemValue)} onChange={() => onChange(handleCheck(itemValue))} />
                  }
                  label={optionLabel}
                />
              ))}
            </>
          )}
        />
      </FormGroup>
    </FormControl>
  );
};

export default memo(OptionCheckboxes);
