export const uncapitalize = (s: string) => s[0].toLowerCase() + s.slice(1);

export const splitOnDelimiter = (s: string) => s.trim().split(/[, \n]+/);

export const capitalizeWords = (s: string) => s.toLowerCase().replace(/(?:^|\s|-|\/)\S/g, (l) => l.toUpperCase());

export const uppercaseRoman = (s: string) =>
  s
    .replace(/\s\w(?=[mdclxvi])m*([md]|d?c*)(x[cl]|l?x*)(i[xv]|v?i*)$/g, (l) => l.toUpperCase())
    .replace(/\s\w(?=[mdclxvi])m*([md]|d?c*)(x[cl]|l?x*)(i[xv]|v?i*)\s/g, (l) => l.toUpperCase());

export const capitalizeThreeOrLessChars = (words: string[], exceptions: string[] = []) => {
  words.forEach((word, index) => {
    // eslint-disable-next-line no-param-reassign
    if (word.length <= 3 && !exceptions.includes(word)) words[index] = word.toUpperCase();
  }, words);
  return words.join(' ');
};

export const formatModelNames = (model: string) => {
  const exceptions = ['Van', 'Suv', 'Fé', 'Bus'];
  const preset = uppercaseRoman(capitalizeWords(model)).split(' ');
  return capitalizeThreeOrLessChars(preset, exceptions);
};
