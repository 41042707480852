import DialogWithClose from '@components/DialogWithClose';
import ReferenceVehicle from '@components/ReferenceVehicle';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import useApi from 'src/hooks/useApi';
import ApiService from 'src/modules/api-service';
import { ValuationWithIncludedCarsDTO } from 'src/modules/generated/api';
import { refCarDialogState } from '../stores/state';

const RefCarDialog = () => {
  const [referenceVehicleState, setReferenceVehicleState] = useRecoilState(refCarDialogState);
  const { fetch, data: valuation } = useApi<ValuationWithIncludedCarsDTO>();
  const { refCar, potential, offerId, valuationCountry } = referenceVehicleState || {};
  const { source, carId } = refCar || {};

  useEffect(() => {
    if (source && carId && valuationCountry && offerId) {
      fetch(ApiService.detail.carDetailControllerGetOneValuationIncludedCars(source, valuationCountry, offerId, carId));
    }
  }, [source, carId, valuationCountry, offerId, fetch]);

  return (
    <DialogWithClose
      open={!!referenceVehicleState}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onClose={() => setReferenceVehicleState(null)}
      maxWidth={false}
    >
      {!!referenceVehicleState && refCar && valuation && (
        <ReferenceVehicle valuation={valuation} refCar={refCar} potential={potential} />
      )}
    </DialogWithClose>
  );
};

export default RefCarDialog;
