import { MenuItem, Select } from '@material-ui/core';
import { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getAccountDealerId } from 'src/modules/bid-buy-helpers';
import { IndividualBidDTO } from '../../modules/generated/api';

type FirstCallOverrideSelectProps = {
  bids?: IndividualBidDTO[];
  className?: string;
};

export const FirstCallOverrideSelect = ({ bids, className }: FirstCallOverrideSelectProps): ReactElement => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const self = getAccountDealerId();

  return (
    <Controller
      name="overridingFirstCallCascadeDealer"
      control={control}
      render={({ field }) => (
        <Select {...field} variant="standard" value={field.value} defaultValue={self} className={className}>
          <MenuItem value="empty" disabled>
            {t('bids.firstCallBuyer')}
          </MenuItem>
          <MenuItem value={self} key="self">
            {t('bids.yourself')}
          </MenuItem>
          {bids?.map((bid) => {
            const dealer = bid?.dealer;
            if (dealer) {
              return (
                <MenuItem key={dealer.id} value={dealer.id}>
                  {bid.user?.name || dealer.id}
                </MenuItem>
              );
            }
            return null;
          })}
        </Select>
      )}
    />
  );
};
