import { makeStyles, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import InfoTooltip from './InfoTooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'right',
  },
  reasons: {
    paddingLeft: theme.spacing(2),
  },
}));

export const PurchaseGrossFallback = (): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {t('common.unavailable')}
      <InfoTooltip
        title={t('car.hints.purchaseGrossFallback.title')}
        hint={
          <ul className={classes.reasons}>
            {[t('car.hints.purchaseGrossFallback.reason1'), t('car.hints.purchaseGrossFallback.reason2')].map(
              (reason) => (
                <li key={reason}>
                  <Typography variant="caption">{reason}</Typography>
                </li>
              ),
            )}
          </ul>
        }
      />
    </div>
  );
};
