import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'src/constants';
import ApiService from 'src/modules/api-service';
import { SearchAgentDTO, SearchAgentRequestDTO } from 'src/modules/generated/api';

export const useSearchAgentUpdate = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    ({ id, ...searchAgent }: SearchAgentRequestDTO & { id: string }) =>
      ApiService.searchAgent.searchAgentControllerUpdateSearchAgent(id, searchAgent),
    {
      onMutate: async (updatedSearchAgent) => {
        await queryClient.cancelQueries([QueryKeys.searchAgents]);

        const snapshot = queryClient.getQueryData<SearchAgentDTO[]>([QueryKeys.searchAgents]);

        queryClient.setQueryData<SearchAgentDTO[]>([QueryKeys.searchAgents], (prev = []) =>
          prev.map((searchAgent) => {
            if (searchAgent.id === updatedSearchAgent.id) {
              return updatedSearchAgent;
            }
            return searchAgent;
          }),
        );

        return snapshot;
      },
      onError: (_err, _updateSearchAgent, snapshot) => {
        queryClient.setQueryData<SearchAgentDTO[] | undefined>([QueryKeys.searchAgents], snapshot);
      },
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.searchAgents]);
      },
    },
  );

  return mutation;
};
