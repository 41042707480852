import React, { useEffect, useState } from 'react';
import { Badge, IconButton, makeStyles } from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';
import clsx from 'clsx';
import { useAuthenticationStore } from 'src/stores/Authentication';
import { useCarsFilter } from 'src/hooks/useCarsFilter';
import { useCarsTableStore } from 'src/stores/CarsTable';
import { useValuationSettings } from 'src/hooks/useValuationSettings';
import { useCurrency } from 'src/hooks/useCurrency';
import { transformForSubmit } from 'src/modules/cars-filter';
import { CARS_BIDS_TABLE_COLUMNS } from 'src/modules/table-data';
import { AutoRefreshDialog, TaskFormData } from './AutoRefreshDialog';
import { useAutoRefresh } from './useAutoRefresh';
import { Action } from './lib';

const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
    verticalAlign: 'middle', // center around inline contexts
  },
  icon: {
    animation: '$spin 1s linear infinite',
    animationPlayState: 'paused',
  },
  icon___loading: {
    animationPlayState: 'running',
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(-360deg)',
    },
  },
});

export const AutoRefresh = (): JSX.Element => {
  const classes = useStyles();
  const { intervalId, intervalMs, worker, active, isLoading } = useAutoRefresh();
  const [isOpen, setOpen] = useState(false);
  const { values: filter } = useCarsFilter();
  const { rowsPerPage, sortOn, page } = useCarsTableStore();
  const { valuationCountry, valuationType } = useValuationSettings();
  const [isLoadingDelayed, setIsLoadingDelayed] = useState(isLoading);
  const { currency } = useCurrency();
  const {
    state: { accessToken },
  } = useAuthenticationStore();
  const requestInit: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      length: rowsPerPage,
      start: page * rowsPerPage,
      order: [{ column: 0, dir: sortOn.dir }],
      columns: [{ data: CARS_BIDS_TABLE_COLUMNS[sortOn.column].sortOn } as any],
      draw: 1,
      valuationCountry: valuationCountry || undefined,
      valuationType: valuationType || undefined,
      targetCurrency: currency,
      ...(filter && transformForSubmit(filter)),
    }),
  };

  const handleToggleDialog = () => {
    setOpen((prev) => !prev);
  };

  const handleSubmit = (data: TaskFormData) => {
    if (intervalId) {
      return;
    }

    const registerAction: Action = { type: 'register', payload: { intervalMs: data.interval * 1000, requestInit } };
    worker.postMessage(registerAction);
    setOpen(false);
  };

  const handleClickCancel = () => {
    if (!intervalId) {
      return;
    }

    const removeAction: Action = { type: 'remove', payload: { intervalId } };
    worker.postMessage(removeAction);
  };

  // register previous intervalId
  useEffect(() => {
    if (intervalId && Number.isFinite(intervalMs)) {
      const registerAction: Action = {
        type: 'register',
        payload: { intervalId, intervalMs: intervalMs!, requestInit },
      };
      worker.postMessage(registerAction);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let t: number;

    if (isLoading || !intervalId) {
      setIsLoadingDelayed(isLoading);
    }

    // virtually delay isLoading changes in order to prevent animation flicker
    if (!isLoading) {
      t = window.setTimeout(() => {
        setIsLoadingDelayed(isLoading);
      }, 2000);
    }

    return () => {
      if (t) {
        window.clearTimeout(t);
      }
    };
  }, [intervalId, isLoading]);

  return (
    <div className={classes.root}>
      <IconButton onClick={handleToggleDialog}>
        <Badge color="primary" variant="dot" invisible={!active}>
          <SyncIcon className={clsx(classes.icon, isLoadingDelayed && classes.icon___loading)} />
        </Badge>
      </IconButton>

      <AutoRefreshDialog
        open={isOpen}
        onSubmit={handleSubmit}
        onClose={handleToggleDialog}
        onCancel={handleClickCancel}
        active={active}
      />
    </div>
  );
};
