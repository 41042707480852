import { makeStyles } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ExperimentsTable } from '@components/ExperimentsTable';
import PageContainer from '@components/PageContainer';
import { PageHeader } from '@components/page-header';

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    marginBlockEnd: theme.spacing(4),
  },
  card: {
    padding: theme.spacing(2),
  },
}));

const ExperimentsPage = (_props: RouteComponentProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <PageContainer>
      <PageHeader
        className={classes.pageHeader}
        headline={t('navigation.experiments')}
        body={t('experimentsPage.subHeadline')}
      />
      <ExperimentsTable />
    </PageContainer>
  );
};

export default ExperimentsPage;
