import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { EditLocation } from '@material-ui/icons';
import { getIcon, IconType } from '../modules/data';
import { ValuationCountryCode } from '../modules/generated/api';
import { getBaseCountry } from '../modules/valuation-country-helpers';
import { getLabel, SHORT_REGIONS_LABELS } from '../modules/labels';

type ValuationCountryIconProps = {
  valuationCountry: ValuationCountryCode | null;
  disableCaption?: boolean;
  disableLocationIcon?: boolean;
};

const useStyles = makeStyles((theme) => ({
  marginLeft: { marginLeft: theme.spacing(1) },
}));

const ValuationCountryIcon = ({
  valuationCountry,
  disableCaption = false,
  disableLocationIcon = false,
}: ValuationCountryIconProps) => {
  const classes = useStyles();
  const valuationCountrySubscript = valuationCountry !== null ? getBaseCountry(valuationCountry) : null;
  return (
    <>
      {!disableCaption && (
        <Typography variant="caption">{getLabel(SHORT_REGIONS_LABELS, valuationCountry || '')}</Typography>
      )}
      <img
        src={getIcon(IconType.COUNTRY, valuationCountrySubscript || 'AT_BE_SE_CZ')}
        alt={valuationCountry || 'VALUATION_BEST'}
        width={15}
        className={classes.marginLeft}
      />
      {!disableLocationIcon && <EditLocation />}
    </>
  );
};
export default ValuationCountryIcon;
