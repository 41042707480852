import React, { memo, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import NotFoundPage from 'src/pages/404';
import axios from 'axios';
import CarDetailContent from '../components/CarDetailContent';
import { SourceType, ValuationCountryCode } from '../modules/generated/api';
import { DEFAULT_VALUATION_COUNTRY } from '../modules/data';
import { getCarDetailsTM, getPageViewTM, mergeDL } from '../modules/tag-manager-helpers';
import { useExtendUsersnap } from '../hooks/useExtendUsersnap';
import { useValuationSettings } from '../hooks/useValuationSettings';
import { useCarDetail } from '../hooks/useCarDetail';

type CarsDetailProps = {
  source: SourceType;
  carId: string;
  isDetailPage?: boolean;
  onRefresh?: () => Promise<unknown>;
  valuationCountry?: ValuationCountryCode;
};

const CarDetail = ({ source, carId, isDetailPage, onRefresh, valuationCountry }: CarsDetailProps) => {
  const { valuationCountry: globalValuationCountry } = useValuationSettings();
  const finalValuationCountry = valuationCountry || globalValuationCountry || DEFAULT_VALUATION_COUNTRY;
  const { data: carDetail, error, refetch } = useCarDetail(carId, source, finalValuationCountry);
  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    if (isDetailPage && carDetail) {
      document.title = `ALLUC - ${carDetail.vin}`;
      TagManager.dataLayer(mergeDL(getCarDetailsTM(carDetail), getPageViewTM(language, 'Car details')));
    }
  }, [isDetailPage, carDetail, language]);

  useExtendUsersnap(
    {
      carDetail: pick(carDetail, 'carId', 'source', 'vin'),
    },
    [carDetail],
  );

  return (
    <>
      {axios.isAxiosError(error) && (error?.response?.status === 400 || error?.response?.status === 404) && (
        <NotFoundPage />
      )}
      {carDetail && (
        <CarDetailContent
          car={carDetail}
          refresh={async () => {
            await refetch();

            if (onRefresh) {
              await onRefresh();
            }
          }}
        />
      )}
    </>
  );
};

export default memo(CarDetail);
