import { FirstCallIcon } from '@components/FirstCallIcon';
import { makeStyles } from '@material-ui/core/styles';
import { FirstCallType, SourceType } from '../../../modules/generated/api/api';
import { VirtualSourceIcon } from './VirtualSourceIcon';

const useStyles = makeStyles((theme) => ({
  iconGroup: {
    position: 'absolute',
    display: 'flex',
    columnGap: theme.spacing(0.5),
    right: theme.spacing(0.5),
    bottom: theme.spacing(0.5),
  },
}));

export type IconGroupProps = {
  call?: FirstCallType;
  virtual: SourceType[];
};

export const IconGroup = ({ call, virtual }: IconGroupProps) => {
  const classes = useStyles();

  return (
    <div className={classes.iconGroup}>
      {virtual?.includes(SourceType.Vvj) && <VirtualSourceIcon title="SQ1" src="/images/icons/sq_1.svg" />}
      {virtual?.includes(SourceType.SpotdealsAllmobil) && (
        <VirtualSourceIcon title="Spotdeals Allmobil" src="/images/icons/sq_2.svg" />
      )}
      {call && <FirstCallIcon call={call} />}
    </div>
  );
};
