import React from 'react';
import { TextCell } from '@components/ui/TextCell';
import humanizeDuration from 'humanize-duration';

export type TimestampCellProps = {
  timestamp: string | number | Date | undefined;
};

const diffToNow = (date: number | Date | string): string => {
  const parsedDate = +new Date(date);
  return humanizeDuration(Date.now() - parsedDate, { round: true, language: 'de', largest: 1 });
};

export const TimestampCell = ({ timestamp }: TimestampCellProps): JSX.Element => (
  <TextCell text={timestamp ? diffToNow(timestamp) : null} />
);
