import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { memo, MouseEvent, useEffect, useState } from 'react';
import { ListCarDTO, PriceType } from '../modules/generated/api';
import TableInput from './TableInput';
import UnitValueCell from './UnitValueCell';
import CarTableEditButton from './CarTableEditButton';
import { ListCarDtoSafe, useTableCalculator } from '../hooks/useTableCalculator';

export type LineValue = {
  prefix?: string;
  unit: string;
  key?: string;
  value: number | undefined;
};

const useStyles = makeStyles(() => ({
  bidContainer: {
    height: '1rem',
    lineHeight: '1rem',
    margin: '2px 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
}));

type MultiLineValuesProps = {
  car: ListCarDTO;
  fixedVariable: PriceType;
  targetKey: string;
  values: (LineValue | undefined)[];
};

const MultiLineValues = ({ car, fixedVariable, targetKey, values: lineValues }: MultiLineValuesProps) => {
  const { handleOnEdit, isEdit, toCalcCar } = useTableCalculator();
  const [focusIdx, setFocusIdx] = useState<number | null>(null);
  const classes = useStyles();
  const { carId, source } = car;
  const calcCar = carId && source ? toCalcCar(car as ListCarDtoSafe) : null;
  const isCurrentyEdited = calcCar && isEdit(calcCar);

  useEffect(() => {
    setFocusIdx(null);
  }, [carId]);

  const handleOnClick = (event: MouseEvent<HTMLButtonElement>, idx: number) => {
    setFocusIdx(idx + 1);
    event.stopPropagation();
    if (calcCar) handleOnEdit(calcCar);
  };

  return (
    <>
      {lineValues.map((lineValue, idx) => {
        // eslint-disable-next-line react/no-array-index-key
        if (!lineValue) return <div key={idx} />;

        const { prefix, unit, key, value } = lineValue;

        if (idx === 1) {
          return (
            isCurrentyEdited && (
              <TableInput
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                car={car}
                idx={idx}
                fixedVariable={fixedVariable}
                targetKey={targetKey}
                autoFocus={focusIdx === idx}
              />
            )
          );
        }

        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={idx} className={classes.bidContainer}>
            {value !== undefined && idx !== 3 && (
              <CarTableEditButton hide={!!isCurrentyEdited} onClick={(e) => handleOnClick(e, idx)} />
            )}
            {value !== undefined && (
              <span className={clsx(key === 'potentialBid' && 'bid')}>
                <UnitValueCell unit={unit} prefix={prefix} value={value} />
              </span>
            )}
          </div>
        );
      })}
    </>
  );
};

export default memo(MultiLineValues);
