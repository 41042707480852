import React, { memo, useEffect, useState } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { green, red, yellow } from '@material-ui/core/colors';
import {
  colors,
  countryCodeToOption,
  damageTypes,
  equipments,
  getLabel,
  highlightLabels,
  promotions,
  sources,
  specialHighlights,
} from '../modules/labels';
import { COUNTRY_ORIGIN_FILTER_COUNTRIES, getIcon, IconType, fallbackIcon } from '../modules/data';

const options = {
  COLOR: colors,
  COUNTRY: COUNTRY_ORIGIN_FILTER_COUNTRIES,
  EQUIPMENT: equipments,
  DAMAGE_TYPE: damageTypes,
  VIRTUAL_SOURCE: sources,
  SPECIAL: specialHighlights,
  PROMOTION: promotions,
} as const;

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: spacing(3),
    height: 'auto',
    display: 'block',
    marginRight: 1,
    marginBottom: 1,
  },
  damage: {
    backgroundColor: '#f5c6cb',
  },
  green: {
    backgroundColor: green[200],
  },
  red: {
    backgroundColor: red[200],
  },
  warranty: {
    backgroundColor: green[200],
  },
  yellow: {
    backgroundColor: yellow[200],
  },
}));

type HighlightIconProps = {
  type: Exclude<IconType, 'LANGUAGE'>;
  value?: string;
  label?: string;
  color?: 'red' | 'green' | undefined;
  titleSuffix?: string;
};

const HighlightIcon = ({ type, value = '', label = '', color, titleSuffix = '' }: HighlightIconProps) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [imgSrc, setImgSrc] = useState<string | undefined>();
  const onError = () => setImgSrc(fallbackIcon);

  let renderLabel;
  const typeLabel = getLabel(highlightLabels, type);

  switch (type) {
    case IconType.ASSESSMENT:
      renderLabel = value === '2' ? t('common.statusReport') : typeLabel;
      break;
    case IconType.EXTERNAL_PORTAL:
      renderLabel = `${typeLabel}: ${value}`;
      break;
    case IconType.COUNTRY:
      renderLabel = `${typeLabel}: ${countryCodeToOption(value, i18n.language).label}`;
      break;
    case IconType.SPECIAL:
      renderLabel = `${getLabel(options[type], value)}`;
      break;
    case IconType.PROMOTION:
      renderLabel = `${getLabel(options[type], value)}`;
      break;
    default:
      renderLabel = `${typeLabel}: ${label || getLabel(options[type], value)}`;
  }

  useEffect(() => {
    setImgSrc(getIcon(type, value));
  }, [type, value]);

  return (
    <Tooltip title={`${renderLabel} ${titleSuffix}`}>
      <img
        className={clsx(
          classes.root,
          type === IconType.DAMAGE_TYPE && classes.damage,
          value === 'REFURBISHED' && classes.green,
          value === 'REPAIRED_DAMAGE' && classes.yellow,
          value === 'WARRANTY_EXTENSION' && color !== 'red' && classes.warranty,
          color === 'red' && classes.red,
          color === 'green' && classes.green,
        )}
        src={imgSrc}
        alt={renderLabel}
        onError={onError}
      />
    </Tooltip>
  );
};

export default memo(HighlightIcon);
