export const removeItemAtIndex = <T>(arr: T[], index: number): T[] => {
  const newArr = arr.slice();
  newArr.splice(index, 1);
  return newArr;
};

export const replaceItemAtIndex = <T>(arr: T[], index: number, item: T): T[] => {
  const newArr = arr.slice();
  newArr.splice(index, 1, item);
  return newArr;
};

export const addItemAtIndex = <T>(arr: T[], index: number, item: T): T[] => {
  const newArr = arr.slice();
  newArr.splice(index, 0, item);
  return newArr;
};

export const addItemAtIndexSafe = <T>(arr: T[], index: number, item: T): T[] => {
  if (index > arr.length) {
    return [...arr, item];
  }

  if (index < 0) {
    return [item, ...arr];
  }

  return addItemAtIndex(arr, index, item);
};

export const arrayToObject = <T>(array: T[], key: keyof T): Record<string, T> =>
  Object.assign({}, ...array.map((item) => ({ [item[key] as any]: item })));

export const setToArray = <T>(set?: Set<T>) => [...(set || [])];
