import { MutableRefObject, useEffect, useState } from 'react';

export const useInView = (ref: MutableRefObject<HTMLElement | null>): boolean => {
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const rootEl = ref.current;

    const obs = new IntersectionObserver(([entry]) => {
      setInView(entry.isIntersecting);
    });

    if (rootEl) {
      obs.observe(rootEl);
    }

    return () => {
      obs.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return inView;
};
