import BidBuyAnimation from '@components/BidBuyAnimation';
import Buy from '@components/Buy';
import ConfirmBidDialog from '@components/ConfirmBidDialog';
import ConfirmFluctuationDialog from '@components/ConfirmFluctuationDialog';
import InfoTooltip from '@components/InfoTooltip';
import { Button, Checkbox, FormControlLabel, FormHelperText, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { isEmpty, isNil, isUndefined } from 'lodash';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { useFeatureFlags } from 'src/hooks/useFeatureFlags';
import useRole from 'src/hooks/useRole';
import UserRole from 'src/types/UserRoles';
import { BidBuyFormType } from '../../hooks/useBidBuyForm';
import { useCurrency } from '../../hooks/useCurrency';
import { usePurchase } from '../../hooks/usePurchase';
import {
  BidBuyErrors,
  getAccountDealerId,
  isBuyingAllowedForUCS,
  transformDealerIdToDealerIdDTO,
} from '../../modules/bid-buy-helpers';
import { MonetaryAmountStrict } from '../../modules/currency';
import { BidSuccessStatus, CurrencyCode, DetailCarDTO, SourceType } from '../../modules/generated/api';
import { chosenValuationCountryState, currentTabIndex } from '../../stores/state';
import BidFormData from '../../types/BidFormData';

const useStyles = makeStyles((theme) => ({
  noMouseEvents: {
    pointerEvents: 'none',
  },
  checkboxPadding: {
    paddingTop: theme.spacing(1),
  },
  textAlign: {
    verticalAlign: 'text-bottom',
  },
}));

type BidBuySubmitProps = {
  buyPrice: number | undefined;
  bidEditMode: boolean;
  car: DetailCarDTO;
  supportsBuying: boolean;
  supportsBidding: boolean;
  actualBidSuccessState?: BidSuccessStatus;
  refresh: () => void;
  errors: BidBuyErrors;
};

export const BidBuySubmit = ({
  car,
  buyPrice,
  actualBidSuccessState,
  supportsBuying,
  supportsBidding,
  bidEditMode,
  refresh,
  errors,
}: BidBuySubmitProps) => {
  const [acceptValue, setAcceptValue] = useState(false);
  const { currency, convert } = useCurrency();
  const [chosenValuationCountry] = useRecoilState(chosenValuationCountryState);
  const [confirmBidData, setConfirmBidData] = useState<BidFormData | null>(null);
  const [fluctuationHintData, setFluctuationHintData] = useState<BidFormData | null>(null);
  const [tabIndex] = useRecoilState(currentTabIndex);
  const { mutate: upsertBid, isLoading, bidBuyFeedbackOpen, bidBuyStatus, toggleBidBuyAnimationOpen } = usePurchase();
  const {
    getValues,
    trigger,
    formState: { errors: formErrors },
    watch,
    control,
  } = useFormContext<BidBuyFormType>();
  const { t } = useTranslation();
  const classes = useStyles();
  const { hasRole } = useRole();
  const isAdmin = hasRole(UserRole.ADMIN);

  const isFeatureFlagEnabled = useFeatureFlags('setAutoBidOnSubsequentOffers');

  const isUCSCar = car.source === SourceType.Ucs;
  const startDateInRange = isUCSCar ? isBuyingAllowedForUCS(car.dateStart, car.dateBuyOpening) : true;
  const overrideFirstCallDealer = watch('overridingFirstCallCascadeDealer');
  const overrideFirstCallDealerOther =
    overrideFirstCallDealer !== undefined && !getAccountDealerId()?.includes(overrideFirstCallDealer);

  const illegalBidSuccessState =
    actualBidSuccessState === BidSuccessStatus.Sent || actualBidSuccessState === BidSuccessStatus.Bought;

  // when bidding and buying is allowed, bid form errors would block buying
  const formErrorsWithoutBidErrors = Object.keys(formErrors).filter((key) => key !== 'bid');
  const submitDisabledBuy =
    isLoading ||
    illegalBidSuccessState ||
    !acceptValue ||
    !isEmpty({ ...errors, ...formErrorsWithoutBidErrors }) ||
    !startDateInRange;

  const submitDisabledBid = isLoading || !bidEditMode || !acceptValue || !isEmpty({ ...errors, ...formErrors });
  const selectedTransportOption = getValues('selectedTransportOption');

  const handleSubmitBuy = async () => {
    if (!(await trigger())) return;
    const cascade = getValues('overrideFirstCallCascade');
    const overrideCascadeDealer = getValues('overridingFirstCallCascadeDealer');
    upsertBid(
      {
        biddingTime: car.dateBuyOpening ?? car.dateStart ?? DateTime.now().toISO(), // should be the earliest possible date
        bid1: car.potential?.base?.amount,
        carId: car.carId,
        fixedPrice: true,
        packageId: car.packageId,
        source: car.source as SourceType,
        kvps: getValues('kvps'),
        deliveryAddress: getValues('deliveryAddress'),
        overrideFirstCallCascade: cascade === 'yes',
        overridingFirstCallCascadeDealer:
          cascade === 'yes' ? transformDealerIdToDealerIdDTO(overrideCascadeDealer) : undefined,
        selectedTransportOption: car.source === SourceType.Vwfs && !car.packageId ? selectedTransportOption : undefined,
      },
      { onSettled: refresh },
    );
  };

  const postBid = async (data: BidFormData) => {
    const autoSetBids = getValues('autoSetBids');
    upsertBid(
      {
        bid1: data.bid1 && convert(data.bid1.amount, { from: data.bid1.currency, to: CurrencyCode.Eur }),
        bid2: data.bid2 && convert(data.bid2.amount, { from: data.bid2.currency, to: CurrencyCode.Eur }),
        bid3: data.bid3 && convert(data.bid3.amount, { from: data.bid3.currency, to: CurrencyCode.Eur }),
        carId: car.carId,
        packageId: car.packageId,
        secondsBeforeEnd: parseInt(data.biddingTime, 10),
        source: car.source as SourceType,
        kvps: getValues('kvps'),
        deliveryAddress: getValues('deliveryAddress'),
        fixedPrice: false,
        autoRepeatBidOnSubsequentOffer: isAdmin ? autoSetBids : false,
        selectedTransportOption: car.source === SourceType.Vwfs && !car.packageId ? selectedTransportOption : undefined,
      },
      { onSettled: refresh },
    );
  };

  const isHighBid = (data: BidFormData) => {
    const marketPrice = car.valuations?.[chosenValuationCountry]?.price?.mean?.amount;
    const marketCurrency = car.valuations?.[chosenValuationCountry]?.price?.mean?.currency;

    if (isUndefined(marketPrice) || isUndefined(marketCurrency)) return false;

    return [data.bid1, data.bid2, data.bid3].some(
      (bidToPost) => bidToPost && marketPrice * 1.2 < convert(bidToPost, { to: marketCurrency }).amount,
    );
  };

  const handleConfirm = (confirmed: boolean) => {
    if (confirmed && (confirmBidData || fluctuationHintData)) {
      postBid(confirmBidData || fluctuationHintData!);
    }

    setConfirmBidData(null);
    setFluctuationHintData(null);
  };

  const handleSubmitBid = async () => {
    if (!(await trigger())) return;
    const data = getValues('bid');
    if (currency !== CurrencyCode.Eur) {
      setFluctuationHintData(data);
      return;
    }

    if (isHighBid(data)) {
      setConfirmBidData(data);
      return;
    }
    postBid(data);
  };

  const toggleCheckbox = () => {
    setAcceptValue(!acceptValue);
    trigger();
  };

  return (
    <>
      {isFeatureFlagEnabled && supportsBidding && isAdmin && (
        <div className={clsx(classes.checkboxPadding, !bidEditMode && classes.noMouseEvents)}>
          <FormControlLabel
            control={
              <Controller
                name="autoSetBids"
                control={control}
                render={({ field: { onChange, value, ...field } }) => (
                  <Checkbox
                    {...field}
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                    disabled={!bidEditMode || !isEmpty(errors) || !supportsBidding}
                    inputProps={{
                      tabIndex: tabIndex + 2,
                    }}
                  />
                )}
              />
            }
            label={
              <div>
                {t('bids.activateBidsAutomation')}
                <span className={classes.textAlign}>
                  <InfoTooltip xsIcon title={t('bids.activateBidsAutomationHint')} />
                </span>
              </div>
            }
          />
        </div>
      )}
      {isFeatureFlagEnabled && supportsBidding && bidEditMode && (
        <hr style={{ width: '100%', marginTop: 12, marginBottom: 12 }} />
      )}
      <div>
        {bidEditMode && (
          <FormControlLabel
            control={
              <Checkbox
                checked={acceptValue}
                color={acceptValue ? 'primary' : 'secondary'}
                onChange={toggleCheckbox}
                disabled={!isEmpty(errors) || !(supportsBuying || supportsBidding)}
                inputProps={{
                  tabIndex: tabIndex + 1,
                }}
              />
            }
            label={t('bids.validateBids')}
          />
        )}

        {/* Form errors are placed here. Global BidBuy errors in parent component */}
        {(formErrors.kvps || formErrors.deliveryAddress) && !overrideFirstCallDealerOther && (
          <FormHelperText error>{t('bids.plsSelectKVPSAndAddressFirst')}</FormHelperText>
        )}
        {formErrors.selectedTransportOption && !overrideFirstCallDealerOther && (
          <FormHelperText error>{t('configuration.selectTransportOption')}</FormHelperText>
        )}

        {supportsBidding && (
          <Button
            color="primary"
            disabled={submitDisabledBid}
            fullWidth
            variant="contained"
            style={{ marginTop: 16 }}
            onClick={handleSubmitBid}
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex={tabIndex + 2}
          >
            {t('bids.submitBids')}
          </Button>
        )}

        {supportsBidding && supportsBuying && <hr style={{ width: '100%', marginTop: 24, marginBottom: 24 }} />}

        {supportsBuying && (
          <>
            <Buy base={buyPrice} disabled={submitDisabledBuy} onClick={handleSubmitBuy} />
            {isUCSCar && !startDateInRange && (
              <Typography variant="caption">{t('bids.hintNoBuyBecauseStartTime')}</Typography>
            )}
          </>
        )}
      </div>

      <ConfirmBidDialog open={!!confirmBidData} handleClose={handleConfirm} />
      <ConfirmFluctuationDialog
        open={!!fluctuationHintData}
        handleClose={handleConfirm}
        bids={
          [fluctuationHintData?.bid1, fluctuationHintData?.bid2, fluctuationHintData?.bid3].filter(
            (b) => !isNil(b),
          ) as MonetaryAmountStrict[]
        }
      />
      <BidBuyAnimation
        bidBuyStatus={bidBuyStatus}
        isLoading={isLoading}
        open={bidBuyFeedbackOpen}
        toggleOpen={toggleBidBuyAnimationOpen}
      />
    </>
  );
};
