import React, { memo } from 'react';
import { LinearProgress, makeStyles, Slide } from '@material-ui/core';

const useStyles = makeStyles({
  linearProgress: {
    left: 0,
    right: 0,
    bottom: 0,
    height: 16,
    position: 'fixed',
    width: '100vw',
    zIndex: 2000,
  },
});

const ProgressBar = () => {
  const classes = useStyles();

  return (
    <Slide direction="up" in mountOnEnter unmountOnExit>
      <LinearProgress className={classes.linearProgress} color="primary" />
    </Slide>
  );
};

export default memo(ProgressBar);
