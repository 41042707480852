import { Select } from '@components/ui';
import {
  FormLabel,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { InfoOutlined } from '@material-ui/icons';
import { isString } from 'lodash';
import { useEffect, useState } from 'react';
import { Control, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import CurrentValuationButton from '@components/CurrentValuationButton';
import TableCellWithoutBorder from '@components/TableCellWithoutBorder';
import { potentialFilters, PotentialFilterType } from 'src/modules/labels';

import BiddingTimeRadios from '@components/bid-buy/BiddingTimeRadios';
import ValuationCountrySwitcher from 'src/components/ValuationCountrySwitcher';
import { BidBuyFormType } from 'src/hooks/useBidBuyForm';
import { useCurrency } from 'src/hooks/useCurrency';
import useRole from 'src/hooks/useRole';
import { DetailCarDTO, SourceRegisterPotentialDTO, SourceType } from 'src/modules/generated/api';
import { chosenValuationCountryState } from 'src/stores/state';
import UserRole from 'src/types/UserRoles';
import { FetchCalculationParams } from '../../hooks/useCalculation';
import BidInput from './BidInput';

const useStyles = makeStyles(({ spacing }) => ({
  tableHeadCell: {
    fontWeight: 'bold',
  },
  button: {
    marginTop: spacing(2),
  },
  tableContainer: {
    marginLeft: -16,
    marginRight: -16,
    width: 'calc(100% + 32px)',
  },
  infoIcon: {
    verticalAlign: 'middle',
    fontSize: '1.2rem',
  },
  red: {
    color: red[500],
    textAlign: 'center',
    paddingTop: spacing(1),
    display: 'block',
  },

  table: {
    tableLayout: 'fixed',

    '& .MuiTableCell-sizeSmall': {
      paddingInline: spacing(1),
    },
  },
}));

type BidFormProps = {
  car: DetailCarDTO;
  bidEditMode: boolean;
  fetchCalculation: (params: FetchCalculationParams) => Promise<undefined | null | SourceRegisterPotentialDTO>;
};

function retrieveDefaultFromLocalStorage() {
  const defaultPotentialRaw = localStorage.getItem('gwscout/CarsDetailPage.defaultPotential');
  if (!defaultPotentialRaw) return undefined;
  const defaultPotential: PotentialFilterType = JSON.parse(defaultPotentialRaw);
  return defaultPotential;
}

const BidForm = ({ car: { source, carId, potential }, bidEditMode, fetchCalculation }: BidFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { currency, convert } = useCurrency();
  const [chosenValuationCountry, setChosenValuationCountry] = useRecoilState(chosenValuationCountryState);
  const [comparisonPotential, setComparisonPotential] = useState<PotentialFilterType>(
    retrieveDefaultFromLocalStorage() || (potentialFilters[0].value as PotentialFilterType),
  );
  const { hasRole } = useRole();
  const { reset, getValues, control } = useFormContext<BidBuyFormType>();

  const activateBidForm = bidEditMode;

  useEffect(() => {
    const prev = getValues('bid');
    const exchanged = Object.fromEntries(
      Object.entries(prev).map(([key, money]) => [key, isString(money) ? money : money && convert(money, {})]),
    );
    reset({ ...getValues(), bid: exchanged });
  }, [currency, convert, getValues, reset]);

  useEffect(() => {
    if (potential?.country) setChosenValuationCountry(potential.country);
  }, [setChosenValuationCountry, potential?.country]);

  function handleChangeComparisonPotential(newComparisonPotential: PotentialFilterType) {
    localStorage.setItem('gwscout/CarsDetailPage.defaultPotential', JSON.stringify(newComparisonPotential));
    setComparisonPotential(newComparisonPotential as PotentialFilterType);
  }

  return (
    <>
      {hasRole(UserRole.ADMIN) && (
        <div>
          <BiddingTimeRadios
            name="bid.biddingTime"
            control={control as unknown as Control}
            activateForm={activateBidForm}
          />
          <FormLabel>{t('carsTable.chosenValuationCountry')}</FormLabel>
          <br />

          <ValuationCountrySwitcher
            rootButton={
              <CurrentValuationButton valuationCountry={chosenValuationCountry} disabled={!activateBidForm} />
            }
            onOptionSelect={(updatedValuationCountry) =>
              updatedValuationCountry && setChosenValuationCountry(updatedValuationCountry)
            }
            disableValuationTypeSubmenu
          />
        </div>
      )}

      <TableContainer>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCellWithoutBorder className={classes.tableHeadCell}>
                {t('common.bid_plural')}
              </TableCellWithoutBorder>
              <TableCellWithoutBorder className={classes.tableHeadCell}>
                {t('common.gross')}{' '}
                <Tooltip title={t('car.prices.purchaseGross')}>
                  <InfoOutlined className={classes.infoIcon} />
                </Tooltip>
              </TableCellWithoutBorder>
              <TableCellWithoutBorder className={classes.tableHeadCell}>
                <Select
                  name="bid.comparisonPotential"
                  value={comparisonPotential}
                  onChange={handleChangeComparisonPotential}
                  options={potentialFilters}
                  variant="standard"
                />
              </TableCellWithoutBorder>
            </TableRow>
          </TableHead>
          <TableBody>
            {[1, 2, 3].map((idx) => (
              <BidInput
                fetchCalculation={fetchCalculation}
                source={source as SourceType} // check in parent
                carId={carId as string}
                bidNumber={idx as 1 | 2 | 3}
                key={idx}
                activateForm={activateBidForm}
                bidEditMode={bidEditMode}
                comparisonPotential={comparisonPotential}
                // quickfix for ticket IRIS-3947 set minBud to 0 for now
                // minBid={bidStateInfo?.highestHistoricBid?.amount && bidStateInfo.highestHistoricBid.amount + 100}
                minBid={0 as number}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default BidForm;
