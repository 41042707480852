import flatten from 'flat';
import TagManager from 'react-gtm-module';
import { ListCarDTO } from './generated/api';
import { filterSettingsTemporaryKeys } from './cars-filter';
import { GwscoutJwtPayload } from '../stores/Authentication';
import type { SortOn } from '../stores/CarsTable';
import { getPropertyLabel } from './labels';
import { CarsFilterSettings } from '../types/CarsFilterSettings';
import { CarProperty } from './table-data';

export const getGlobalConfigTM = (language: string) => ({
  platform: 'gw-scout',
  country: 'AT',
  language: language.toUpperCase(),
  environment: process.env.REACT_APP_ACCESS_TOKEN ? 'dev' : 'live',
  currency: 'EUR',
});

export const getUserDataTM = (jwt?: GwscoutJwtPayload) => {
  if (!jwt) return { user: null };
  return {
    user: {
      userId: jwt.user_id,
      userDatabase: jwt.authentication_provider,
      dealerId: jwt.dealer_id,
      dealerCountry: jwt.country,
    },
  };
};

export const getPageViewTM = (language: string, pageType: string) => ({
  dataLayer: {
    event: 'pageview',
    pagePath: window.location.pathname,
    pageUrl: window.location.href,
    pageTitle: document.title,
    pageType,
    ...getGlobalConfigTM(language),
  },
});

export const getCarDetailsTM = (car: ListCarDTO) => ({
  dataLayer: {
    car: {
      // Model details
      carCode6: car?.modelcode,
      carBrand: car?.normalized?.brand,
      carModel: car?.normalized?.fullModelName,
      carModelGroup: car?.normalized?.model,
      carModelLine: car?.normalized?.equipmentLine,
      carCategory: car?.normalized?.bodyType,
      // Car Offers
      carOfferId: car?.carId,
      carOfferPrice: car.potential?.base?.amount,
      carMileage: car?.mileage,
      carRegistration: car?.firstRegistration,
    },
  },
});

export const getCarDetailsOpenTM = (car: ListCarDTO) => ({
  dataLayer: {
    event: 'car-details-open',
    ...getCarDetailsTM(car).dataLayer,
  },
});

export const mergeDL = (a: { dataLayer: {} }, b: { dataLayer: {} }) => ({
  dataLayer: { ...a.dataLayer, ...b.dataLayer },
});

export const getCalculationCarDetailsTM = (inputField: string, inputValue: number, car: ListCarDTO) => ({
  dataLayer: {
    event: 'car-calculate',
    input: {
      inputField,
      inputValue,
    },
    ...getCarDetailsTM(car).dataLayer,
  },
});

export const GTM_FILTER_NAMES: Record<string, string> = {
  brand: 'Car brand',
  model: 'Car model',
  purchasePriceGross: 'Car price',
  powerKw: 'Car power',
  firstRegistration: 'Car registration',
  mileage: 'Car mileage',
  colors: 'Car colour',
  equipments: 'Car equipment',
  promotions: 'Car promotion',
};

export const getGTMfilterMappings = (fieldName: string, data: Partial<CarsFilterSettings>) => {
  let filterType: string;
  let inputValue: any;

  switch (fieldName) {
    case 'potentialFilterAbsoluteMin':
    case 'potentialFilterAbsoluteMax':
      if (!data.potentialFilter) return null;
      filterType = `Car ${data.potentialFilter}`;
      inputValue = [data.potentialFilterAbsoluteMin, data.potentialFilterAbsoluteMax];
      break;
    case 'potentialFilterRelativeMin':
    case 'potentialFilterRelativeMax':
      if (!data.potentialFilter) return null;
      filterType = `Car ${data.potentialFilter}`;
      inputValue = [data.potentialFilterRelativeMin, data.potentialFilterRelativeMax];
      break;
    default: {
      const castedFieldName = fieldName as keyof CarsFilterSettings;
      inputValue = !fieldName.includes('.') ? data[castedFieldName] : (flatten(data) as any)[castedFieldName];
      filterType = GTM_FILTER_NAMES[fieldName] || `Car ${fieldName}`;
    }
  }
  if (fieldName in filterSettingsTemporaryKeys && fieldName !== 'potentialFilter') {
    return {
      filterType,
      filterRangeMin: inputValue[0],
      filterRangeMax: inputValue[1],
    };
  }
  return {
    filterType,
    filterValue: inputValue,
  };
};

export function pushGTMFilterSettings<T extends keyof CarsFilterSettings>(
  fieldName: T,
  data: Pick<CarsFilterSettings, T>,
  isFilterRemoved: boolean,
) {
  const mappedFilter = getGTMfilterMappings(fieldName, data);
  if (mappedFilter === null) return;

  TagManager.dataLayer({
    dataLayer: {
      event: isFilterRemoved ? 'filter-remove' : 'filter',
      ...mappedFilter,
    },
  });
}

export const getGlobalFilterAndCarDataGTM = (
  event: string,
  data: ListCarDTO[],
  page: number,
  rowsPerPage: number,
  sortOn: SortOn,
  filter?: CarsFilterSettings,
) => ({
  dataLayer: {
    event,
    listItems: rowsPerPage,
    listOrder: getPropertyLabel(sortOn.column as CarProperty, 'en'),
    listOrderDirection: sortOn.dir?.toUpperCase(),
    listPagination: page,
    listItemData: data.map((car) => ({
      itemType: 'car',
      ...getCarDetailsTM(car).dataLayer.car,
    })),
    filters: filter && Object.keys(filter).map((key) => ({ ...getGTMfilterMappings(key, filter) })),
  },
});
