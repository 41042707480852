import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import clsx from 'clsx';
import countries from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';
import useRole from 'src/hooks/useRole';
import UserRole from 'src/types/UserRoles';
import { wasAuctionAlreadyVisible } from '../modules/bid-buy-helpers';
import { getIcon, IconType } from '../modules/data';
import { formatDate } from '../modules/date-helpers';
import { DetailCarDTO, MarketingChannel, OfferType } from '../modules/generated/api';
import { getChannel } from '../modules/unit-helpers';
import i18n from '../setup/i18n';
import AuctionsHintHover from './AuctionsHintHover';
import CarDetailHeadline from './CarDetailHeadline';
import CarDetailTableHeadCell from './CarDetailTableHeadCell';
import CarDetailTableUnitValueCell from './CarDetailTableUnitValueCell';
import InfoTooltip from './InfoTooltip';
import UnitValue from './UnitValue';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  disableElevation: {
    borderRadius: 0,
  },
  headingBox: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    '& h2': {
      paddingRight: '5px',
    },
  },
  layoutFixed: {
    tableLayout: 'fixed',
  },
}));

type PrevAuctionsDetailTableProps = {
  title: string;
  layoutFixed?: boolean;
  disableHeader?: boolean;
  disableElevation?: boolean;
  className?: string;
  car: DetailCarDTO;
};

const PrevAuctionsDetailTable = ({
  title,
  layoutFixed,
  disableHeader = false,
  disableElevation = false,
  className,
  car,
}: PrevAuctionsDetailTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { hasRole } = useRole();

  return (
    <TableContainer
      component={Paper}
      elevation={disableElevation ? 0 : 3}
      className={clsx(classes.root, disableElevation && classes.disableElevation, className)}
    >
      {!disableHeader && (
        <div className={classes.headingBox}>
          <CarDetailHeadline title={title} />
          {car && (
            <span>
              <AuctionsHintHover car={car} />
            </span>
          )}
        </div>
      )}
      <Table size="small" className={layoutFixed ? classes.layoutFixed : ''}>
        <TableBody>
          <TableRow>
            <CarDetailTableHeadCell className="bigger">{t('filterSearch.offerType')}</CarDetailTableHeadCell>
            {car.previousAuctions?.map((auction) => {
              let offer: string | undefined;
              switch (auction.offerType) {
                case OfferType.Individual:
                  offer = t('filterSearch.offerTypeIndiv_abbr');
                  break;
                case OfferType.Package:
                  offer = t('common.package');
                  break;
                default:
                  offer = undefined;
              }
              return (
                <TableCell key={`${auction.dateStart}-${auction.offerType}`} align="right">
                  {offer}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow>
            <CarDetailTableHeadCell>{t('bids.start')}</CarDetailTableHeadCell>
            {car.previousAuctions?.map((auction) => (
              <TableCell key={auction.dateStart} align="right">
                {formatDate(auction.dateStart || '')}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <CarDetailTableHeadCell>
              {t('car.prices.basePrice')}
              <InfoTooltip
                hint={`${t('car.prices.priceOrientation_abbr')}: ${t('car.prices.priceOrientation')}
                    ${t('car.prices.fixedPrice_abbr')}: ${t('car.prices.fixedPrice')}`}
                xsIcon
              />
            </CarDetailTableHeadCell>
            {car.previousAuctions?.map((auction) => {
              const { marketingChannel = [] } = auction;
              const channel = getChannel(marketingChannel as MarketingChannel[]);
              return (
                <CarDetailTableUnitValueCell key={`${auction.dateStart}-${auction.priceFixed?.amount}`}>
                  {(auction.priceFixed?.amount || auction.priceOrientation?.amount) && (
                    <UnitValue
                      unit={auction.priceFixed?.currency || auction.priceOrientation?.currency}
                      prefix={channel && `(${channel})`}
                      value={auction.priceFixed?.amount || auction.priceOrientation?.amount}
                    />
                  )}
                </CarDetailTableUnitValueCell>
              );
            })}
          </TableRow>
          <TableRow>
            <CarDetailTableHeadCell>{t('car.damage')}</CarDetailTableHeadCell>
            {car.previousAuctions?.map((auction) => (
              <CarDetailTableUnitValueCell key={`${auction.dateStart}-${auction.priceDamage?.amount}`}>
                <UnitValue value={auction.priceDamage?.amount} unit={auction.priceDamage?.currency} />
              </CarDetailTableUnitValueCell>
            ))}
          </TableRow>
          <TableRow>
            <CarDetailTableHeadCell>{`${t('common.CARculator')} ${t(
              'car.prices.marketPrice',
            )}`}</CarDetailTableHeadCell>
            {car.previousAuctions?.map((auction) => (
              <CarDetailTableUnitValueCell key={`${auction.dateStart}-${auction.marketPrice?.amount}`}>
                <UnitValue value={auction.marketPrice?.amount} unit={auction.marketPrice?.currency} />
              </CarDetailTableUnitValueCell>
            ))}
          </TableRow>
          <TableRow>
            <CarDetailTableHeadCell>{t('car.prices.potentialPriceAverage')}</CarDetailTableHeadCell>
            {car.previousAuctions?.map((auction) => (
              <CarDetailTableUnitValueCell key={`${auction.dateStart}-${auction.potentialMeanAbsolute?.amount}`}>
                <UnitValue
                  value={auction.potentialMeanAbsolute?.amount}
                  unit={auction.potentialMeanAbsolute?.currency}
                />
                {auction.potentialMeanRelative && (
                  <>
                    (<UnitValue value={auction.potentialMeanRelative} unit="%" />)
                  </>
                )}
              </CarDetailTableUnitValueCell>
            ))}
          </TableRow>
          <TableRow>
            <CarDetailTableHeadCell>{t('navigation.changeValuationCountry_plural')}</CarDetailTableHeadCell>
            {car.previousAuctions?.map((auction) => (
              <CarDetailTableUnitValueCell key={`${auction.dateStart}-country`}>
                {auction.visibleForCountry?.map((countryCode) => (
                  <Tooltip title={countries.getName(countryCode, i18n.language)} key={countryCode}>
                    <img
                      style={{ marginLeft: 2, marginRight: 2 }}
                      width={14}
                      src={getIcon(IconType.COUNTRY, countryCode)}
                      alt=""
                    />
                  </Tooltip>
                ))}
              </CarDetailTableUnitValueCell>
            ))}
          </TableRow>
          <TableRow>
            <CarDetailTableHeadCell>
              {t('bids.auctionVisible')}
              <InfoTooltip hint={t('bids.auctionVisibleInfo')} xsIcon />
            </CarDetailTableHeadCell>
            {car.previousAuctions?.map((auction) => {
              const wasVisible = wasAuctionAlreadyVisible(auction.visibleForKvps);
              return (
                <CarDetailTableUnitValueCell key={`${auction.dateStart}-visibility`}>
                  {wasVisible ? <Visibility color="primary" fontSize="small" /> : <VisibilityOff fontSize="small" />}
                </CarDetailTableUnitValueCell>
              );
            })}
          </TableRow>
          {hasRole(UserRole.ADMIN) && (
            <TableRow>
              <CarDetailTableHeadCell>{t('bids.auctionsId')}</CarDetailTableHeadCell>
              <CarDetailTableUnitValueCell key={car.offerId}>{car.offerId || '-'}</CarDetailTableUnitValueCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PrevAuctionsDetailTable;
function useAuth(): { user: any } {
  throw new Error('Function not implemented.');
}
