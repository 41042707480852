import {
  ColumnOrderState,
  ColumnPinningState,
  ColumnSizingState,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import { difference, merge } from 'lodash';
import { DefaultValue, atom, selector } from 'recoil';
import { CacheKeys } from 'src/constants';
import { localStorageEffect } from 'src/lib/recoil';
import { addItemAtIndexSafe } from 'src/modules/util';
import { columns, columnsWithBids } from './columns';
import { getInitialColumnVisibility } from './lib';
import { BidsTableNextItem, CarsTableNextItem } from './types';

export const refetchIntervalState = atom<number | null>({
  key: 'carsTableNext/refetchInterval',
  default: 10_000,
  effects: [localStorageEffect(CacheKeys.carsTableNextRefetchInterval)],
});

export const sidebarOpenState = atom<boolean>({
  key: 'tableNext/sidebarOpen',
  default: true,
  effects: [localStorageEffect(CacheKeys.tableNextSidebarOpen)],
});

export const headerOpenState = atom<boolean>({
  key: 'header/headerOpen',
  default: true,
  effects: [localStorageEffect(CacheKeys.headerState)],
});

export const columnSizingState = atom<ColumnSizingState>({
  key: 'carsTableNext/columnSizing',
  default: {},
  effects: [localStorageEffect(CacheKeys.carsTableNextColumnSizing)],
});

export const sortingState = atom<SortingState>({
  key: 'carsTableNext/sorting',
  default: [
    {
      id: 'dateEnd',
      desc: false,
    },
  ],
  effects: [localStorageEffect(CacheKeys.carsTableNextSorting)],
});

export const columnPinningState = atom<ColumnPinningState>({
  key: 'carsTableNext/columnPinning',
  default: {
    left: ['car'],
  },
});

export const columnOrderState = atom<ColumnOrderState>({
  key: 'carsTableNext/columnOrder',
  default: columns().map((column: CarsTableNextItem | BidsTableNextItem) => column.id as string),
  effects: [
    localStorageEffect(CacheKeys.carsTableNextColumnOrder, {
      transform: (savedOrder: ColumnOrderState): ColumnOrderState => {
        const columnsArray = columns();
        const savedOrderValidated = savedOrder.filter((columnId) =>
          columnsArray.some((col: CarsTableNextItem | BidsTableNextItem) => col.id === columnId),
        );

        if (savedOrderValidated.length === columnsArray.length) {
          return savedOrder;
        }

        // if the saved order is missing columns, add them to the end
        const fullOrder = columnsArray.map((column) => column.id as string);
        const missingColumns = difference(fullOrder, savedOrderValidated);
        const missingColumnsWithIndex = missingColumns.map((columnId) => ({
          columnId,
          index: columnsArray.findIndex((col) => col.id === columnId),
        }));
        const sortedMissingColumns = missingColumnsWithIndex.sort((a, b) => a.index - b.index);
        const newOrder = sortedMissingColumns.reduce(
          (acc, { columnId, index }) => addItemAtIndexSafe(acc, index, columnId),
          savedOrderValidated,
        );
        return newOrder;
      },
    }),
  ],
});

export const bidsSortingState = atom<SortingState>({
  key: 'carsTableNext/bidsSorting',
  default: [
    {
      id: 'biddingTime',
      desc: true,
    },
  ],
  effects: [localStorageEffect(CacheKeys.bidsTableNextSorting)],
});

export const bidPendingColumnOrderState = atom<ColumnOrderState>({
  key: 'bidsPendingTableNext/columnOrder',
  default: columnsWithBids.map((column) => column.id as string), // must start out with populated columnOrder so we can splice
  effects: [localStorageEffect(CacheKeys.bidsPendingTableNextColumnOrder)],
});

export const bidPastColumnOrderState = atom<ColumnOrderState>({
  key: 'bidsPastTableNext/columnOrder',
  default: columnsWithBids.map((column) => column.id as string), // must start out with populated columnOrder so we can splice
  effects: [localStorageEffect(CacheKeys.bidsPastTableNextColumnOrder)],
});

export const bidOrderedColumnOrderState = atom<ColumnOrderState>({
  key: 'bidsOrderedTableNext/columnOrder',
  default: columnsWithBids.map((column) => column.id as string), // must start out with populated columnOrder so we can splice
  effects: [localStorageEffect(CacheKeys.bidsOrderedTableNextColumnOrder)],
});

export const columnCarsVisibilityState = atom({
  key: 'carsTableNext/columnVisibility',
  default: getInitialColumnVisibility(columns()),
  effects: [
    localStorageEffect(CacheKeys.carsTableNextColumnVisibility, {
      transform: (savedVisibility) => merge({}, getInitialColumnVisibility(columns()), savedVisibility),
    }),
  ],
});

export const columnPendingBidsVisibilityState = atom({
  key: 'bidsPendingTableNext/columnVisibility',
  default: getInitialColumnVisibility(columnsWithBids),
  effects: [
    localStorageEffect(CacheKeys.bidsPendingTableNextColumnVisibility, {
      transform: (savedVisibility) => merge({}, getInitialColumnVisibility(columns()), savedVisibility),
    }),
  ],
});

export const columnPastBidsVisibilityState = atom({
  key: 'bidsPastTableNext/columnVisibility',
  default: getInitialColumnVisibility(columnsWithBids),
  effects: [
    localStorageEffect(CacheKeys.bidsPastTableNextColumnVisibility, {
      transform: (savedVisibility) => merge({}, getInitialColumnVisibility(columns()), savedVisibility),
    }),
  ],
});

export const columnOrderedBidsVisibilityState = atom({
  key: 'bidsOrderedTableNext/columnVisibility',
  default: getInitialColumnVisibility(columnsWithBids),
  effects: [
    localStorageEffect(CacheKeys.bidsOrderedTableNextColumnVisibility, {
      transform: (savedVisibility) => merge({}, getInitialColumnVisibility(columns()), savedVisibility),
    }),
  ],
});

const pageSizeState = atom({
  key: 'carsTableNext/pageSize',
  default: 25,
  effects: [localStorageEffect(CacheKeys.carsTableNextPageSize)],
});

export const carsTablePageIndexState = atom({
  key: 'carsTableNext/pageIndex',
  default: 0,
});

export const bidsPendingTablePageIndexState = atom({
  key: 'bidsPendingTableNext/pageIndex',
  default: 0,
});

export const bidsPastTablePageIndexState = atom({
  key: 'bidsPastTableNext/pageIndex',
  default: 0,
});

export const bidsOrderedTablePageIndexState = atom({
  key: 'bidsOrderedTableNext/pageIndex',
  default: 0,
});

export const carsTablePaginationState = selector<PaginationState>({
  key: 'carsTableNext/pagination',
  get: ({ get }) => ({
    pageSize: get(pageSizeState),
    pageIndex: get(carsTablePageIndexState),
  }),
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(pageSizeState, newValue);
      set(carsTablePageIndexState, newValue);
    } else {
      const { pageSize, pageIndex } = newValue;
      set(carsTablePageIndexState, pageIndex);
      set(pageSizeState, pageSize);
    }
  },
});

export const bidsPendingTablePaginationState = selector<PaginationState>({
  key: 'bidsPendingTableNext/pagination',
  get: ({ get }) => ({
    pageSize: get(pageSizeState),
    pageIndex: get(bidsPendingTablePageIndexState),
  }),
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(pageSizeState, newValue);
      set(bidsPendingTablePageIndexState, newValue);
    } else {
      const { pageSize, pageIndex } = newValue;
      set(bidsPendingTablePageIndexState, pageIndex);
      set(pageSizeState, pageSize);
    }
  },
});

export const bidsPastTablePaginationState = selector<PaginationState>({
  key: 'bidsPastTableNext/pagination',
  get: ({ get }) => ({
    pageSize: get(pageSizeState),
    pageIndex: get(bidsPastTablePageIndexState),
  }),
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(pageSizeState, newValue);
      set(bidsPastTablePageIndexState, newValue);
    } else {
      const { pageSize, pageIndex } = newValue;
      set(bidsPastTablePageIndexState, pageIndex);
      set(pageSizeState, pageSize);
    }
  },
});

export const bidsOrderedTablePaginationState = selector<PaginationState>({
  key: 'bidsOrderedTableNext/pagination',
  get: ({ get }) => ({
    pageSize: get(pageSizeState),
    pageIndex: get(bidsOrderedTablePageIndexState),
  }),
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(pageSizeState, newValue);
      set(bidsOrderedTablePageIndexState, newValue);
    } else {
      const { pageSize, pageIndex } = newValue;
      set(bidsOrderedTablePageIndexState, pageIndex);
      set(pageSizeState, pageSize);
    }
  },
});
