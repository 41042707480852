import { omit, pick } from 'lodash';
import { ApiCarsFilterSettings, FilterSettingsTemporary, PotentialFilters } from '../types/FilterSettings';
import { potentialFilters, PotentialFilterType } from './labels';
import { splitOnDelimiter } from './string-helpers';
import { CarsFilterSettings } from '../types/CarsFilterSettings';
import { makeMonetaryAmount } from './currency';
import { BuyableStatus } from './generated/api';

export const filterSettingsTemporaryKeys: (keyof FilterSettingsTemporary)[] = [
  'powerKw',
  'engineCo2',
  'mileage',
  'priceDamage',
  'purchasePriceGross',
  'registrationDate',
  'potentialFilter',
  'potentialFilterAbsoluteMin',
  'potentialFilterAbsoluteMax',
  'potentialFilterRelativeMin',
  'potentialFilterRelativeMax',
  'numAuctions',
];

export const transformForSubmit = (data: CarsFilterSettings): ApiCarsFilterSettings => {
  const { potentialFilter } = data;

  // omit select fields and potential fields
  const primitiveFilters = omit(
    data,
    // select fields
    'powerKw',
    'engineCo2',
    'mileage',
    'priceDamage',
    'purchasePrice',
    'registrationDate',
    'numAuctions',
    'purchasePriceGross',
    'originSource',
    // potential fields
    'potentialFilterAbsoluteMin',
    'potentialFilterAbsoluteMax',
    'potentialFilterRelativeMin',
    'potentialFilterRelativeMax',
  );

  let transformed = {
    ...primitiveFilters,
    search: data?.search && {
      ...data.search,
      value: data?.search?.value.trim(),
    },
    powerKwMin: data.powerKw[0],
    powerKwMax: data.powerKw[1],
    engineCo2Min: data.engineCo2[0],
    engineCo2Max: data.engineCo2[1],
    mileageMin: data.mileage[0],
    mileageMax: data.mileage[1],
    priceDamageMin: data.priceDamage[0],
    priceDamageMax: data.priceDamage[1],
    purchasePriceGrossMin: data.purchasePriceGross[0],
    purchasePriceGrossMax: data.purchasePriceGross[1],
    registrationDateMin: data.registrationDate[0],
    registrationDateMax: data.registrationDate[1],
    numAuctionsMin: data.numAuctions[0],
    numAuctionsMax: data.numAuctions[1],
    carIds: data.vinSearch?.toString() ? splitOnDelimiter(data.vinSearch.toString()).map((v) => v.trim()) : [],
    buyableStatus: data.buyableStatus ?? BuyableStatus.Buyable,
    originSource: data.originSource.toString().split(','),
  };

  if (potentialFilter) {
    transformed = {
      ...transformed,
      [`${potentialFilter}AbsoluteMin`]: data.potentialFilterAbsoluteMin,
      [`${potentialFilter}AbsoluteMax`]: data.potentialFilterAbsoluteMax,
      [`${potentialFilter}RelativeMin`]: data.potentialFilterRelativeMin,
      [`${potentialFilter}RelativeMax`]: data.potentialFilterRelativeMax,
    };
  }

  return transformed;
};

const potentialInternetPriceKeys = potentialFilters.flatMap(({ value: base }) => [
  { base, key: `${base}AbsoluteMin` as keyof ApiCarsFilterSettings },
  { base, key: `${base}AbsoluteMax` as keyof ApiCarsFilterSettings },
  { base, key: `${base}RelativeMin` as keyof ApiCarsFilterSettings },
  { base, key: `${base}RelativeMax` as keyof ApiCarsFilterSettings },
]);

export const transformForForm = (data: ApiCarsFilterSettings): Partial<CarsFilterSettings> => {
  let potentialFilter: Partial<
    Pick<
      CarsFilterSettings,
      | 'potentialFilter'
      | 'potentialFilterAbsoluteMin'
      | 'potentialFilterAbsoluteMax'
      | 'potentialFilterRelativeMin'
      | 'potentialFilterRelativeMax'
    >
  > = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const potentialInternetPriceKey of potentialInternetPriceKeys) {
    if (data[potentialInternetPriceKey.key] !== undefined) {
      const potentialFilterAbsoluteMin = data[`${potentialInternetPriceKey.base}AbsoluteMin` as keyof PotentialFilters];
      const potentialFilterAbsoluteMax = data[`${potentialInternetPriceKey.base}AbsoluteMax` as keyof PotentialFilters];

      potentialFilter = {
        potentialFilter: potentialInternetPriceKey.base as PotentialFilterType,
        potentialFilterAbsoluteMin:
          potentialFilterAbsoluteMin !== undefined ? makeMonetaryAmount(potentialFilterAbsoluteMin) : undefined,
        potentialFilterAbsoluteMax:
          potentialFilterAbsoluteMax !== undefined ? makeMonetaryAmount(potentialFilterAbsoluteMax) : undefined,
        potentialFilterRelativeMin:
          data[`${potentialInternetPriceKey.base}RelativeMin` as 'potentialInternetPriceAverageRelativeMin'],
        potentialFilterRelativeMax:
          data[`${potentialInternetPriceKey.base}RelativeMax` as 'potentialInternetPriceAverageRelativeMax'],
      };

      break;
    }
  }

  // This code groups the originSource array elements to a string if the first word is the same
  // and connect them with ",". Because in the Form you select all keys of one group all at once
  const groupedOriginSource = // group the array elements to a string if the first word is the same and connect them with ","
    data.originSource
      ?.reduce((acc, item) => {
        const [firstWord] = item.split(' ');
        const lastItem = acc[acc.length - 1];

        if (lastItem && lastItem[0].startsWith(firstWord)) {
          lastItem.push(item);
        } else {
          acc.push([item]);
        }

        return acc;
      }, [] as string[][])
      .map((item) => item.join(','));

  const transformed: Partial<CarsFilterSettings> = {
    ...pick(
      data,
      'anyOtherUserRatingMin',
      'carIds',
      'colors',
      'countryOrigin',
      'currentBidState',
      'expiredBidState',
      'damageType',
      'endDateFilter',
      'endDateFrom',
      'startDateFilter',
      'startDateFrom',
      'startDateTo',
      'endDateTo',
      'search',
      'searchExact',
      'marketingChannel',
      'taxType',
      'source',
      'promotion',
      'promotions',
      'normalizedTransmission',
      'normalizedBrand',
      'normalizedModel',
      'normalizedEquipmentLine',
      'normalizedFuel',
      'normalizedVersion',
      'equipmentCodes',
      'equipments',
      'hasUserComment',
      'hasAnyOtherUserComment',
      'ratingMin',
      'validity',
      'offerType',
      'buyableStatus',
    ),
    powerKw:
      data.powerKwMin !== undefined && data.powerKwMax !== undefined ? [data.powerKwMin, data.powerKwMax] : undefined,
    engineCo2:
      data.engineCo2Min !== undefined && data.engineCo2Max !== undefined
        ? [data.engineCo2Min, data.engineCo2Max]
        : undefined,
    mileage:
      data.mileageMin !== undefined && data.mileageMax !== undefined ? [data.mileageMin, data.mileageMax] : undefined,
    numAuctions:
      data.numAuctionsMin !== undefined && data.numAuctionsMax !== undefined
        ? [data.numAuctionsMin, data.numAuctionsMax]
        : undefined,
    priceDamage:
      data.priceDamageMin !== undefined && data.priceDamageMax !== undefined
        ? [makeMonetaryAmount(data.priceDamageMin), makeMonetaryAmount(data.priceDamageMax)]
        : undefined,
    purchasePriceGross:
      data.purchasePriceGrossMin !== undefined && data.purchasePriceGrossMax !== undefined
        ? [makeMonetaryAmount(data.purchasePriceGrossMin), makeMonetaryAmount(data.purchasePriceGrossMax)]
        : undefined,
    registrationDate: [
      data.registrationDateMin ?? new Date(0).toISOString(),
      data.registrationDateMax ?? new Date().toISOString(),
    ],
    vinSearch: data.carIds?.toString(),
    originSource: groupedOriginSource,
    ...potentialFilter,
  };

  return transformed;
};
