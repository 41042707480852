import { List, ListItem, makeStyles } from '@material-ui/core';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { mean } from 'lodash';
import { formatDate } from 'src/modules/date-helpers';
import { makeMonetaryAmount, MonetaryAmountStrict, SupportedCurrencyCode } from '../modules/currency';
import { SourceRegisterValuationIncludedCarDTO } from '../modules/generated/api';
import { formatUnitValue } from '../modules/unit-helpers';
import UnitValue from './UnitValue';

const useStyles = makeStyles(() => ({
  list: {
    '& li': {
      padding: 0,
      paddingBottom: '5px',
    },
  },
}));

type RefCarsKpiProps = {
  refCarsList: SourceRegisterValuationIncludedCarDTO[];
  valuationDate?: string;
};

const getMinMonetaryAmount = (values: MonetaryAmountStrict[]): MonetaryAmountStrict | undefined =>
  values.reduce<MonetaryAmountStrict>((prev, value) => (value.amount < prev.amount ? value : prev), values[0]);

const getMaxMonetaryAmount = (values: MonetaryAmountStrict[]): MonetaryAmountStrict | undefined =>
  values.reduce<MonetaryAmountStrict>((prev, value) => (value.amount > prev.amount ? value : prev), values[0]);

const getAvgMonetaryAmount = (values: MonetaryAmountStrict[]): MonetaryAmountStrict | undefined => {
  if (values.length === 0) return undefined;

  const avg = values.reduce((sum, v) => sum + v.amount, 0) / values.length;
  return makeMonetaryAmount(avg, values[0]?.currency as SupportedCurrencyCode);
};

const RefCarsKpi = ({ refCarsList, valuationDate }: RefCarsKpiProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const calcOnlineTime = (firstVisible?: string, lastVisible?: string) => {
    if (!firstVisible) return undefined;
    return DateTime.fromISO(firstVisible)
      .until(lastVisible ? DateTime.fromISO(lastVisible) : DateTime.now().startOf('day'))
      .toDuration('days')
      .toObject();
  };

  const priceLastList = refCarsList.map((car) =>
    makeMonetaryAmount(car.priceLast?.amount || 0, car.priceLast?.currency as SupportedCurrencyCode),
  );

  const onlineTimeAvg = mean(
    refCarsList.map((car) => (calcOnlineTime(car.firstVisibleAt, car.lastVisibleAt)?.days as number) || 0),
  );
  const minPrice = getMinMonetaryAmount(priceLastList);
  const maxPrice = getMaxMonetaryAmount(priceLastList);
  const avgPrice = getAvgMonetaryAmount(priceLastList);
  const similarity = mean(refCarsList.filter((car) => car.score !== undefined).map((car) => car.score || 0));

  return (
    <List disablePadding className={classes.list}>
      <ListItem>
        <strong>{t('common.car_plural')}</strong>: {refCarsList.length}
      </ListItem>
      <ListItem>
        <strong>{t('referencePopup.similarity')}</strong>: {similarity && formatUnitValue(similarity, '%')}
      </ListItem>
      <ListItem>
        <strong>{t('car.onlineTime')}</strong>:{' '}
        {onlineTimeAvg && (
          <>
            {Math.round(onlineTimeAvg)} {t('referencePopup.day_plural')}
          </>
        )}
      </ListItem>
      <ListItem>
        <strong>{t('referencePopup.lastPrice')}</strong>:{' '}
        {minPrice && <UnitValue value={minPrice.amount} unit={minPrice.currency} />}
        {maxPrice && minPrice && minPrice.amount !== maxPrice.amount && (
          <>
            {' - '}
            <UnitValue value={maxPrice.amount} unit={maxPrice.currency} />
          </>
        )}
      </ListItem>
      <ListItem>
        <strong>{t('referencePopup.avgPrice')}</strong>:{' '}
        {avgPrice && <UnitValue value={avgPrice.amount} unit={avgPrice.currency} />}
      </ListItem>
      {valuationDate && (
        <ListItem>
          <strong>{t('referencePopup.valuationDate')}</strong>: {formatDate(valuationDate)}
        </ListItem>
      )}
    </List>
  );
};

export default RefCarsKpi;
