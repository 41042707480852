import UserRole from 'src/types/UserRoles';
import useRole from './useRole';
import { useUser } from './useUser';

export enum UserPermissions {
  CAN_USE_TRANSPORTATION = 'CAN_USE_TRANSPORTATION',
}

// me query -> user.id
const permissions = {
  [UserPermissions.CAN_USE_TRANSPORTATION]: {
    users: ['99741'],
    adminsAllowed: true,
  },
};

export const useUserPermissions = (permission: UserPermissions): boolean => {
  const { hasRole } = useRole();
  const { data: user } = useUser();
  const userId = user?.user?.id;

  if (!userId) return false;
  if (permissions[permission].adminsAllowed && hasRole(UserRole.ADMIN)) return true;
  return permissions[permission].users.includes(userId);
};
