import React from 'react';
import { RouteComponentProps } from '@reach/router';
import CarDetail from 'src/containers/CarDetail';
import { SourceType } from 'src/modules/generated/api';
import PageContainer from '@components/PageContainer';
import CarsTableProvider from 'src/stores/CarsTable';
import { PageMeta } from '@components/page-meta';

type CarPageProps = RouteComponentProps<{ source: SourceType; carId: string }>;

const CarPage = ({ source, carId }: CarPageProps) => (
  <>
    <PageMeta title="common.car" />
    <CarsTableProvider>
      <PageContainer maxWidth="xl">
        <CarDetail source={source as SourceType} carId={carId as string} isDetailPage />
      </PageContainer>
    </CarsTableProvider>
  </>
);

export default CarPage;
