import { BidsTableNext } from '@components/cars-table-next/BidsTableNext';
import { BidsFilterProvider } from '@components/cars-table-next/filter/useBidsFilter';
import { BidTypes } from '@components/cars-table-next/types';
import { PageMeta } from '@components/page-meta';
import { RouteComponentProps } from '@reach/router';

const OrdersPage = (_props: RouteComponentProps) => (
  <>
    <PageMeta title="bids.boughtCars" />
    <BidsFilterProvider bidsTableType="BoughtBidsTable">
      <BidsTableNext bidType={BidTypes.Orders} />
    </BidsFilterProvider>
  </>
);

export default OrdersPage;
