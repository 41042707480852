import { TextField, InputAdornment, IconButton, makeStyles } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCustomSnackbarGlobal from 'src/hooks/useSnackbarGlobal';
import ApiService from 'src/modules/api-service';
import { DetailedDealerDTO } from 'src/modules/generated/api';

const useStyles = makeStyles({
  nameColumn: {
    width: '100%',
  },
});

export type DealerNameColProps = {
  dealer: DetailedDealerDTO;
  edit: boolean;
  refetch: () => void;
};

export const DealerNameCol = ({ dealer, refetch }: DealerNameColProps): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { showError, showSuccess } = useCustomSnackbarGlobal();

  const saveName = async (newName: string) => {
    const dealerId = dealer.dealer?.id;
    const response = await ApiService.dealer.dealerControllerChangeNameForDealer(dealerId as string, {
      name: newName,
    });
    if (response.status !== 200) {
      showError(t('alerts.errorRaised'));
      return;
    }
    showSuccess(t('alerts.successSaved'));
    refetch();
  };

  const [name, setName] = useState('');

  const handleName = () => {
    saveName(name);
  };

  return (
    <div className={classes.nameColumn}>
      <TextField
        fullWidth
        onChange={(newValue) => setName(newValue.target.value)}
        type="text"
        size="small"
        variant="outlined"
        defaultValue={dealer.name}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="save new company name"
                edge="end"
                color="primary"
                type="submit"
                onClick={() => handleName()}
              >
                <Save />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};
