import { ColumnMeta } from '@tanstack/react-table';
import { get } from 'lodash';
import { Trans } from 'react-i18next';
import {
  ConditionalFormattingType,
  SourceRegisterPotentialTaxDTO,
  ValuationCountryCode,
} from 'src/modules/generated/api';
import { Tax } from 'src/types/tax';
import { NumericCell, NumericCellFallback, TextCell, TextCellFallback } from '../cells';
import { formatIntPercentage } from '../format';
import { isMonetaryAmount, isPercentEntry } from '../lib';
import { CarsTableNextItem, StrictColumnDef } from '../types';
import { getTaxId } from './lib';

const createTaxColumn = (
  country: ValuationCountryCode,
  tax: Tax,
  meta?: ColumnMeta<CarsTableNextItem, unknown>,
): StrictColumnDef<CarsTableNextItem> => ({
  id: getTaxId(country, tax),
  accessorFn: (car) => get(car, ['taxes', country, 'tax', tax]),
  cell: (props) => {
    const val = props.getValue<SourceRegisterPotentialTaxDTO | undefined>();
    const withPercent = isPercentEntry(val);
    const withMoney = val && isMonetaryAmount(val.total);

    if (withMoney) {
      return <NumericCell value={val.total ?? '-'} supportText={withPercent && formatIntPercentage(val.percent)} />;
    }

    return <NumericCell value={val?.percent ?? '-'} format={formatIntPercentage} />;
  },
  enableSorting: false,
  meta: {
    supportLabel: `regions.${country}`,
    isNumeric: true,
    columnVisible: false,
    fallback: () => <NumericCellFallback textWidth="80%" />,
    headerCellProps: {
      supportText: country,
    },
    roles: [getTaxId(country, tax)],
    ...meta,
  },
});

export const taxColumns: StrictColumnDef<CarsTableNextItem>[] = [
  {
    id: 'taxType',
    accessorKey: 'taxType',
    cell: ({ getValue }) => (
      <TextCell
        text={
          getValue() ? (
            <Trans
              i18nKey={`carsTableNext.car.taxType.${getValue<CarsTableNextItem['taxType']>() as 'regelbesteuert'}`}
            />
          ) : (
            '-'
          )
        }
      />
    ),
    meta: {
      columnVisible: false,
      fallback: () => <TextCellFallback textWidth="80%" />,
      conditionalFormat: ConditionalFormattingType.TaxType,
    },
    enableSorting: false,
  },
  createTaxColumn(ValuationCountryCode.At, Tax.VAT, {
    conditionalFormat: ConditionalFormattingType.Vat,
    export: {
      key: 'taxes.AT_LOT.tax.VAT.percent',
    },
  }),
  createTaxColumn(ValuationCountryCode.At, Tax.NOVA, {
    conditionalFormat: ConditionalFormattingType.Nova,
    export: {
      key: 'taxes.AT_LOT.tax.NOVA.percent',
    },
  }),
  createTaxColumn(ValuationCountryCode.AtLot, Tax.VAT, {
    conditionalFormat: ConditionalFormattingType.Vat,
    export: {
      key: 'taxes.AT_LOT.tax.VAT.percent',
    },
  }),
  createTaxColumn(ValuationCountryCode.AtLot, Tax.NOVA, {
    conditionalFormat: ConditionalFormattingType.Nova,
    export: {
      key: 'taxes.AT_LOT.tax.NOVA.percent',
    },
  }),
  createTaxColumn(ValuationCountryCode.De, Tax.VAT, {
    conditionalFormat: ConditionalFormattingType.Vat,
    export: {
      key: 'taxes.DE.tax.VAT.percent',
    },
  }),
  createTaxColumn(ValuationCountryCode.Es, Tax.REGISTRATION, {
    conditionalFormat: ConditionalFormattingType.RegistrationAbsolute,
    export: {
      key: 'taxes.ES.tax.REGISTRATION.percent',
    },
  }),
  createTaxColumn(ValuationCountryCode.Es, Tax.VAT, {
    conditionalFormat: ConditionalFormattingType.Vat,
    export: {
      key: 'taxes.ES.tax.VAT.percent',
    },
  }),
  createTaxColumn(ValuationCountryCode.It, Tax.ECOMALUS, {
    conditionalFormat: ConditionalFormattingType.Ecomalus,
    export: {
      key: 'taxes.IT.tax.ECOMALUS.total',
    },
  }),
  createTaxColumn(ValuationCountryCode.It, Tax.TRANSCRIPTION, {
    conditionalFormat: ConditionalFormattingType.Transcription,
    export: {
      key: 'taxes.IT.tax.TRANSCRIPTION.total',
    },
  }),
  createTaxColumn(ValuationCountryCode.It, Tax.VAT, {
    conditionalFormat: ConditionalFormattingType.Vat,
    export: {
      key: 'taxes.IT.tax.VAT.percent',
    },
  }),
  createTaxColumn(ValuationCountryCode.Fr, Tax.ECO, {
    conditionalFormat: ConditionalFormattingType.Eco,
    export: {
      key: 'taxes.FR.tax.ECO.total',
    },
  }),
  createTaxColumn(ValuationCountryCode.Fr, Tax.VAT, {
    conditionalFormat: ConditionalFormattingType.Vat,
    export: {
      key: 'taxes.FR.tax.VAT.percent',
    },
  }),
  createTaxColumn(ValuationCountryCode.Cz, Tax.REGISTRATION, {
    conditionalFormat: ConditionalFormattingType.RegistrationAbsolute,
    export: {
      key: 'taxes.CZ.tax.REGISTRATION.total',
    },
  }),
  createTaxColumn(ValuationCountryCode.Cz, Tax.VAT, {
    conditionalFormat: ConditionalFormattingType.Vat,
    export: {
      key: 'taxes.CZ.tax.VAT.percent',
    },
  }),
  createTaxColumn(ValuationCountryCode.Pl, Tax.CIVIL, {
    conditionalFormat: ConditionalFormattingType.Civil,
    export: {
      key: 'taxes.PL.tax.CIVIL.percent',
    },
  }),
  createTaxColumn(ValuationCountryCode.Pl, Tax.EXCISE, {
    conditionalFormat: ConditionalFormattingType.Excise,
    export: {
      key: 'taxes.PL.tax.EXCISE.percent',
    },
  }),
  createTaxColumn(ValuationCountryCode.Pl, Tax.VAT, {
    conditionalFormat: ConditionalFormattingType.Vat,
    export: {
      key: 'taxes.PL.tax.VAT.percent',
    },
  }),
  createTaxColumn(ValuationCountryCode.Hu, Tax.AUTHENTICATION, {
    conditionalFormat: ConditionalFormattingType.Authentication,
    export: {
      key: 'taxes.HU.tax.AUTHENTICATION.total',
    },
  }),
  createTaxColumn(ValuationCountryCode.Hu, Tax.PROPERTY_ACQUISITION, {
    conditionalFormat: ConditionalFormattingType.PropertyAcquisition,
    export: {
      key: 'taxes.HU.tax.PROPERTY_ACQUISITION.total',
    },
  }),
  createTaxColumn(ValuationCountryCode.Hu, Tax.REGISTRATION, {
    conditionalFormat: ConditionalFormattingType.RegistrationAbsolute,
    export: {
      key: 'taxes.HU.tax.REGISTRATION.total',
    },
  }),
  createTaxColumn(ValuationCountryCode.Hu, Tax.VAT, {
    conditionalFormat: ConditionalFormattingType.Vat,
    export: {
      key: 'taxes.HU.tax.VAT.percent',
    },
  }),
  createTaxColumn(ValuationCountryCode.Ro, Tax.VAT, {
    conditionalFormat: ConditionalFormattingType.Vat,
    export: {
      key: 'taxes.RO.tax.VAT.percent',
    },
  }),
  createTaxColumn(ValuationCountryCode.Si, Tax.VAT, {
    conditionalFormat: ConditionalFormattingType.Vat,
    export: {
      key: 'taxes.SI.tax.VAT.percent',
    },
  }),
  createTaxColumn(ValuationCountryCode.Si, Tax.REGISTRATION, {
    conditionalFormat: ConditionalFormattingType.RegistrationAbsolute,
    export: {
      key: 'taxes.SI.tax.REGISTRATION.total',
    },
  }),
  createTaxColumn(ValuationCountryCode.Hr, Tax.VAT, {
    conditionalFormat: ConditionalFormattingType.Vat,
    export: {
      key: 'taxes.HR.tax.VAT.percent',
    },
  }),
  createTaxColumn(ValuationCountryCode.Hr, Tax.REGISTRATION, {
    conditionalFormat: ConditionalFormattingType.RegistrationAbsolute,
    export: {
      key: 'taxes.HR.tax.REGISTRATION.total',
    },
  }),
  createTaxColumn(ValuationCountryCode.Sk, Tax.VAT, {
    conditionalFormat: ConditionalFormattingType.Vat,
    export: {
      key: 'taxes.SK.tax.VAT.percent',
    },
  }),
  createTaxColumn(ValuationCountryCode.Pt, Tax.VAT, {
    conditionalFormat: ConditionalFormattingType.Vat,
    export: {
      key: 'taxes.PT.tax.VAT.percent',
    },
  }),
  createTaxColumn(ValuationCountryCode.Pt, Tax.REGISTRATION, {
    conditionalFormat: ConditionalFormattingType.RegistrationAbsolute,
    export: {
      key: 'taxes.PT.tax.REGISTRATION.total',
    },
  }),
  createTaxColumn(ValuationCountryCode.Se, Tax.VAT, {
    conditionalFormat: ConditionalFormattingType.Vat,
    export: {
      key: 'taxes.SE.tax.VAT.percent',
    },
  }),
  createTaxColumn(ValuationCountryCode.Bg, Tax.VAT, {
    conditionalFormat: ConditionalFormattingType.Vat,
    export: {
      key: 'taxes.BG.tax.VAT.percent',
    },
  }),
  createTaxColumn(ValuationCountryCode.Mk, Tax.VAT, {
    conditionalFormat: ConditionalFormattingType.Vat,
    export: {
      key: 'taxes.MK.tax.VAT.percent',
    },
  }),
  createTaxColumn(ValuationCountryCode.Al, Tax.VAT, {
    conditionalFormat: ConditionalFormattingType.Vat,
    export: {
      key: 'taxes.AL.tax.VAT.percent',
    },
  }),
  createTaxColumn(ValuationCountryCode.Xk, Tax.VAT, {
    conditionalFormat: ConditionalFormattingType.Vat,
    export: {
      key: 'taxes.XK.tax.VAT.percent',
    },
  }),
];
