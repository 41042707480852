import CarDetailDialog from '@components/CarDetailDialog';
import RefCarDialog from '@components/RefCarDialog';
import { EmptyMessage, EmptyMessageIcon, MenuAction } from '@components/ui';
import SidebarButton from '@components/ui/SidebarButton';
import SidebarContainer from '@components/ui/SidebarContainer';
import { Button, makeStyles } from '@material-ui/core';
import { grey, orange } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { navigate, useLocation } from '@reach/router';
import {
  Column,
  PaginationState,
  Header as RTHeader,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { isFunction } from 'lodash';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { Trans, useTranslation } from 'react-i18next';
import { SetterOrUpdater, useRecoilState } from 'recoil';
import carCulatorLogoImage from 'src/assets/images/carculator.png';
import useCarDetailPopup from 'src/hooks/useCarDetailPopup';
import { BidsOrderedTableSidebar } from './BidsOrderedTableSidebar';
import { BidsPastTableSidebar } from './BidsPastTableSidebar';
import { BidsPendingTableSidebar } from './BidsPendingTableSidebar';
import { BidsTableHeader } from './BidsTableHeader';
import { CarsTableSidebar } from './CarsTableSidebar';
import { Header } from './Header';
import { Navigation } from './Navigation';
import { TableVirtualizer, VirtualHeaderRender, VirtualRowRender, VirtualizedTable } from './VirtualizedTable';
import { defaultColumn } from './columns';
import { CarsTableNextColumnAction, PrimaryKey, SidebarRoutePaths, rowSize } from './constants';
import { getConditionalFormatStyles, getConditionalRowFormatStyles, isDefined } from './lib';
import {
  bidOrderedColumnOrderState,
  bidPastColumnOrderState,
  bidPendingColumnOrderState,
  bidsSortingState,
  columnCarsVisibilityState,
  columnOrderState,
  columnOrderedBidsVisibilityState,
  columnPastBidsVisibilityState,
  columnPendingBidsVisibilityState,
  columnPinningState,
  columnSizingState,
  sidebarOpenState,
  sortingState,
} from './state';
import { TableCell, TableFooter, TableHeaderCell, TablePagination } from './table';
import { BidTypes, TableType, type BidsTableNextItem, type CarsTableNextItem, type StrictColumnDef } from './types';
import { useConditionalFormats } from './useConditionalFormats';

const useStyles = makeStyles({
  // visually separate external columns
  table: {
    '& .header_cell___highlight': {
      background: orange[50],
    },
    '& .header_cell___external': {
      borderTop: '2px solid',
      borderTopColor: orange[900],
      background: orange[50],
      // CARculator logo
      '&:not(.header_cell___external ~ .header_cell___external)::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: -4,
        width: 100,
        height: 24,
        background: `url(${carCulatorLogoImage}) no-repeat center`,
        backgroundSize: 'contain',
        transform: 'translate(0, -100%)',
      },
    },
    [`& .table_cell___external:not(.table_cell___external ~ .table_cell___external),
      & .header_cell___external:not(.header_cell___external ~ .header_cell___external)`]: {
      borderLeft: '2px solid',
      borderLeftColor: orange[900],
    },
  },
});

type TableNextProps = {
  tableColumns: StrictColumnDef<CarsTableNextItem | BidsTableNextItem>[];
  totalRecords?: number;
  data: CarsTableNextItem[] | BidsTableNextItem[] | undefined;
  isLoading: boolean;
  refetch: () => Promise<unknown>;
  isPreviousData: boolean;
  resetFilter: () => void;
  tableType: TableType;
  bidType?: BidTypes;
  pagination: PaginationState;
  setPagination: SetterOrUpdater<PaginationState>;
  numbCars?: number;
  numbBids?: number;
};

export const TableNext = ({
  tableColumns,
  totalRecords,
  data,
  isLoading,
  refetch,
  isPreviousData,
  resetFilter,
  tableType,
  bidType,
  pagination,
  setPagination,
  numbCars,
  numbBids,
}: TableNextProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  function getVisibilityState() {
    if (bidType === 'pending') {
      return columnPendingBidsVisibilityState;
    }
    if (bidType === 'past') {
      return columnPastBidsVisibilityState;
    }
    if (bidType === 'orders') {
      return columnOrderedBidsVisibilityState;
    }
    return columnCarsVisibilityState;
  }
  function getColumnOrderState() {
    if (bidType === 'pending') {
      return bidPendingColumnOrderState;
    }
    if (bidType === 'past') {
      return bidPastColumnOrderState;
    }
    if (bidType === 'orders') {
      return bidOrderedColumnOrderState;
    }
    return columnOrderState;
  }
  function getColumnSortingState() {
    if (bidType && [BidTypes.Pending, BidTypes.Past, BidTypes.Orders].includes(bidType)) {
      return bidsSortingState;
    }

    return sortingState;
  }

  const [columnSizing, setColumnSizing] = useRecoilState(columnSizingState);
  const [columnVisibility, setColumnVisibility] = useRecoilState(getVisibilityState());
  const [columnOrder, setColumnOrder] = useRecoilState(getColumnOrderState());
  const [columnPinning, setColumnPinning] = useRecoilState(columnPinningState);
  const [sorting, setSorting] = useRecoilState(getColumnSortingState());
  const [sidebarIsOpen, setSidebarIsOpen] = useRecoilState(sidebarOpenState);
  const { data: conditionalFormats = [] } = useConditionalFormats();
  const { carDetail, closeCarDetail, index: carDetailIndex, paginateCarDetail, onRowClick } = useCarDetailPopup();

  const virtualizer = useRef<TableVirtualizer>(null);

  const table = useReactTable({
    data: data ?? [],
    columns: tableColumns,
    pageCount: totalRecords,
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (car, index) => car.carId || String(index),
    defaultColumn,
    columnResizeMode: 'onChange',
    state: {
      columnVisibility,
      columnOrder,
      columnSizing,
      columnPinning,
      pagination,
      sorting,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onColumnSizingChange: setColumnSizing,
    onColumnPinningChange: setColumnPinning,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    meta: {
      getCellProps: (cell) => ({
        ...(isFunction(cell.column.columnDef.meta?.tableCellProps)
          ? cell.column.columnDef.meta?.tableCellProps(cell)
          : cell.column.columnDef.meta?.tableCellProps),
        style: {
          ...(isFunction(cell.column.columnDef.meta?.tableCellProps)
            ? cell.column.columnDef.meta?.tableCellProps(cell).style
            : cell.column.columnDef.meta?.tableCellProps?.style),
          ...getConditionalFormatStyles(cell, conditionalFormats),
        },
        className: clsx({
          table_cell___external: cell.column.columnDef.meta?.isExternalColumn,
        }),
      }),
      getRowProps: (row) => {
        const rowBgStyles = getConditionalRowFormatStyles(row, conditionalFormats);
        return {
          onClick: () => onRowClick(data ?? [])([], { dataIndex: row.index }),
          style: {
            cursor: 'pointer',
            '--row-background': rowBgStyles?.backgroundImage ?? rowBgStyles?.background,
          },
        };
      },
      getHeaderProps: (header: RTHeader<CarsTableNextItem | BidsTableNextItem, unknown>) => ({
        className: clsx({
          header_cell___external: header.column.columnDef.meta?.isExternalColumn,
          header_cell___highlight: header.column.columnDef.meta?.isTableHeaderHighlighted,
        }),
      }),
    },
  });
  const { rows } = table.getRowModel();
  const animationKey = useMemo(() => rows.map((row) => row.original.carId).join(''), [rows]);

  const renderHeader = useCallback<VirtualHeaderRender>(
    (header: RTHeader<CarsTableNextItem | BidsTableNextItem, unknown>) => {
      const ctx = header.getContext();
      const { align, isNumeric } = header.column.columnDef.meta ?? {};
      const headerProps = ctx.table.options.meta?.getHeaderProps(header);
      const columnActions: MenuAction[] = [
        header.id !== PrimaryKey && {
          id: CarsTableNextColumnAction.hide,
          title: <Trans i18nKey="carsTableNext.header.actions.hide" />,
          icon: VisibilityOffIcon,
        },
        {
          id: CarsTableNextColumnAction.showColumns,
          title: <Trans i18nKey="carsTableNext.header.actions.showColumns" />,
          icon: ViewColumnIcon,
        },
      ].filter(isDefined);

      const columnMenuReducer = (action: MenuAction, column: Column<CarsTableNextItem, unknown>) => {
        switch (action.id) {
          case CarsTableNextColumnAction.showColumns:
            navigate(SidebarRoutePaths.columnSettings);
            break;
          case CarsTableNextColumnAction.hide:
            ctx.table.getColumn(column.id).toggleVisibility(false);
            break;
          // no default
        }
      };

      return (
        <TableHeaderCell
          // th
          {...headerProps}
          style={{
            ...headerProps?.style,
            ...(header.column.getIsPinned()
              ? {
                  position: 'sticky',
                  zIndex: 2,
                  borderRight: '1px solid',
                  borderRightColor: grey[200],
                }
              : {
                  position: 'absolute',
                }),
            marginTop: 24, // add additional spacing for CARculator logo
            left: header.getStart(), // use placement instead of transform to perceive the stacking context
            width: header.getSize(),
            height: rowSize,
          }}
          align={align ?? (isNumeric ? 'right' : undefined)}
          menuReducer={(action) => columnMenuReducer(action, header.column)}
          actions={columnActions}
          resizeHandleProps={{
            onMouseDown: header.getResizeHandler(),
            onTouchStart: header.getResizeHandler(),
          }}
        >
          {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
        </TableHeaderCell>
      );
    },
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderRow = useCallback<VirtualRowRender>(
    memo(
      ({
        virtualRowKey,
        virtualRowStart,
        virtualRowSize,
        virtualColumnItems,
        row,
        table: tableProvided,
        isLoading: isLoadingProvided,
        isPreviousData: isPreviousDataProvided,
      }) => {
        const cells = row?.getVisibleCells();
        const visibleColumns = table.getVisibleLeafColumns();
        const rowProps = row && tableProvided.options.meta?.getRowProps(row);

        return (
          <Flipped flipId={virtualRowKey}>
            <div
              // tr
              {...rowProps}
              // Dynamic makeStyle will break the animation!
              style={{
                ...rowProps?.style,
                position: 'absolute',
                top: virtualRowStart,
                height: virtualRowSize,
                width: tableProvided.getTotalSize(),
                opacity: isPreviousDataProvided ? 0.7 : 1,
                transition: 'opacity 100ms ease-in-out',
              }}
            >
              {virtualColumnItems.map((virtualColumn) => {
                const column = visibleColumns[virtualColumn.index];
                const cell = cells?.at(virtualColumn.index);
                const cellCtx = cell?.getContext();
                // TODO: should cell props get evaluated while loading?
                const cellProps = cell && cellCtx?.table.options.meta?.getCellProps(cell);

                return (
                  <TableCell
                    // td
                    key={virtualColumn.key}
                    {...cellProps}
                    tabIndex={-1}
                    style={{
                      ...cellProps?.style,
                      ...(column.getIsPinned()
                        ? {
                            position: 'sticky',
                            zIndex: 2,
                            borderRight: '1px solid',
                            borderRightColor: grey[200],
                          }
                        : {
                            position: 'absolute',
                          }),
                      top: 0,
                      left: 0,
                      width: column.getSize(),
                      height: virtualRowSize,
                      transform: `translateX(${column.getStart()}px)`,
                    }}
                  >
                    {isLoadingProvided || !cell || !cellCtx
                      ? flexRender(column.columnDef.meta?.fallback, { column, table })
                      : flexRender(cell.column.columnDef.cell, cellCtx)}
                  </TableCell>
                );
              })}
            </div>
          </Flipped>
        );
      },
    ),
    [],
  );

  const noDataFallback = useMemo(
    () => (
      <EmptyMessage
        headline={t('carsTableNext.noDataFallback.headline')}
        body={
          <>
            {t('carsTableNext.noDataFallback.body1')}
            <br />
            {t('carsTableNext.noDataFallback.body2')}
          </>
        }
        icon={
          <EmptyMessageIcon>
            <SearchIcon fontSize="large" />
          </EmptyMessageIcon>
        }
        actions={
          <div>
            <Button size="small" color="secondary" variant="contained" onClick={() => resetFilter()}>
              {t('carsTableNext.noDataFallback.actions.reset')}
            </Button>
          </div>
        }
        style={{
          position: 'sticky',
          left: 0,
        }}
      />
    ),
    [resetFilter, t],
  );

  // Open sidebar if subroute matches
  useEffect(() => {
    const paths = Object.values(SidebarRoutePaths);
    if (paths.some((path) => path === location.pathname)) {
      setSidebarIsOpen(true);
    }
  }, [location, setSidebarIsOpen]);

  // Reset virtualizer on page changes
  useEffect(() => {
    virtualizer.current?.row.scrollToIndex(0);
  }, [pagination.pageIndex]);

  function getTable() {
    switch (bidType) {
      case BidTypes.Pending:
        return <BidsPendingTableSidebar table={table} />;
      case BidTypes.Past:
        return <BidsPastTableSidebar table={table} />;
      case BidTypes.Orders:
        return <BidsOrderedTableSidebar table={table} />;
      default:
        return <CarsTableSidebar table={table} refetch={refetch} />;
    }
  }

  return (
    <>
      <SidebarContainer sidebarIsOpen={sidebarIsOpen} numColumns={3}>
        <Navigation tableType={tableType} bidType={bidType} />

        {getTable()}

        <SidebarButton
          expanded={!sidebarIsOpen}
          onClick={() => setSidebarIsOpen((prev) => !prev)}
          colIdx={3}
          label={t('carsTableNext.menuButton.label')}
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {tableType === 'cars' ? (
            <Header isLoading={isLoading} table={table} />
          ) : (
            <BidsTableHeader
              bidType={bidType}
              isLoading={isLoading}
              table={table}
              numbBids={numbBids}
              numbCars={numbCars}
            />
          )}
          <VirtualizedTable
            rowSize={rowSize}
            table={table}
            header={renderHeader}
            row={renderRow}
            isLoading={isLoading}
            isPreviousData={isPreviousData}
            className={classes.table}
            virtualizer={virtualizer}
            noDataFallback={noDataFallback}
            containerComponent={Flipper}
            containerProps={{ flipKey: animationKey, spring: 'gentle' }}
          />
        </div>
        <TableFooter>
          <TablePagination
            rowsPerPage={table.getState().pagination.pageSize}
            page={table.getState().pagination.pageIndex}
            count={numbCars ?? totalRecords ?? table.getPageCount()}
            onPageChange={(_event, newPage) => table.setPageIndex(newPage)}
            onRowsPerPageChange={(event) => table.setPageSize(+event.target.value)}
          />
        </TableFooter>
      </SidebarContainer>
      <CarDetailDialog
        car={carDetail}
        index={carDetailIndex}
        maxCars={data?.length ?? 0}
        moveToNext={() => paginateCarDetail(data ?? [], 1)}
        moveToPrev={() => paginateCarDetail(data ?? [], -1)}
        onClose={closeCarDetail}
        onEdit={refetch}
      />
      <RefCarDialog />
    </>
  );
};
