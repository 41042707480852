import React, { memo } from 'react';
import { QuestionAnswer, StarRounded } from '@material-ui/icons';
import { Grid, IconButton, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { IndividualizedCarDTO } from '../modules/generated/api';
import ExternalUserRating from './ExternalUserRating';
import ActionTooltip from './ActionTooltip';
import ExternalUserComments from './ExternalUserComments';

type CarsTableCellExternalUserInformationProps = {
  individualizations?: IndividualizedCarDTO[];
  className?: string;
  iconSpacing?: number;
};

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
  },
  bg: {
    borderRadius: '15px',
    backgroundColor: 'rgb(232, 244, 253)',
    width: '80%',
  },
  icon: {
    color: '#2196f3',
    fontSize: '1rem',
    marginLeft: theme.spacing(0.3),
    '&--greyOut': {
      color: theme.palette.grey[400],
    },
    '&--comment': {
      fontSize: '0.9rem',
      marginLeft: theme.spacing(0.4),
    },
  },
  commentIconWrapper: (props: { iconSpacing?: number }) => ({
    marginTop: theme.spacing(props.iconSpacing || 1.1),
    marginBottom: theme.spacing(0.4),
  }),
  ratingIconWrapper: {
    marginTop: theme.spacing(0.8),
  },
  tooltip: {
    fontSize: '0.8rem',
    maxWidth: theme.spacing(70),
  },
}));

const CarsTableCellExternalUserInformation = ({
  individualizations,
  className,
  iconSpacing,
}: CarsTableCellExternalUserInformationProps) => {
  const classes = useStyles({ iconSpacing });
  const { t } = useTranslation();
  const ratings = individualizations?.filter((individualization) => individualization.rating !== undefined);
  const comments = individualizations?.filter((individualization) => individualization.userComment !== undefined);
  const showRatings = ratings && ratings.length !== 0;
  const showComments = comments && comments.length !== 0;
  return (
    <Grid container direction="column" className={clsx(classes.container, className)}>
      <div className={classes.bg}>
        <Grid item>
          <ActionTooltip
            position="right"
            title={
              showRatings ? <ExternalUserRating individualizations={ratings} /> : t('carsTable.noExternalUserInfoFound')
            }
            classes={{ tooltip: classes.tooltip }}
            arrow
          >
            <IconButton size="small" edge="start" className={classes.ratingIconWrapper}>
              <StarRounded className={clsx(classes.icon, !showRatings && `${classes.icon}--greyOut`)} />
            </IconButton>
          </ActionTooltip>
        </Grid>
        <Grid item>
          <ActionTooltip
            title={showComments ? <ExternalUserComments comments={comments} /> : t('carsTable.noExternalUserInfoFound')}
            classes={{ tooltip: classes.tooltip }}
            arrow
          >
            <IconButton size="small" edge="start" className={classes.commentIconWrapper}>
              <QuestionAnswer
                className={clsx(classes.icon, `${classes.icon}--comment`, !showComments && `${classes.icon}--greyOut`)}
              />
            </IconButton>
          </ActionTooltip>
        </Grid>
      </div>
    </Grid>
  );
};

export default memo(CarsTableCellExternalUserInformation);
