import {
  Badge,
  Button,
  FormControlLabel,
  InputAdornment,
  TextFieldProps as MuiTextFieldProps,
  Popover,
  Switch,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import { isString } from 'lodash';
import { ChangeEventHandler, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchVinAdder from '../legacy/SearchVinAdder';
import { useControlled } from '../useControlled';
import { muiInputLabelProps } from './constants';

type SearchProps = {
  value: SearchData;
  onChange?: (data: SearchData) => void;
  TextFieldProps?: Partial<MuiTextFieldProps>;
};

type SearchData = {
  value: string;
  regex: boolean;
  vins: string[];
};

const useStyles = makeStyles({
  vinBadge: {
    '& .MuiBadge-badge': {
      top: '0.75em',
      right: '-0.75em',
    },
  },
  hidden: {
    '& .MuiInputBase-root': {
      display: 'none',
    },
    '& .MuiFormLabel-root': {
      display: 'none',
    },
    '& .MuiButton-label': {
      fontSize: 'larger',
    },
  },
});

const defaultValue: SearchData = { value: '', regex: false, vins: [] };

export const Search = ({
  value: initialValue,
  onChange,
  TextFieldProps: { label, ...textFieldProps } = {},
}: SearchProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useControlled({ controlled: initialValue, default: defaultValue });
  const [vinDialog, setVinDialog] = useState(false);
  const vinButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleChangeInput: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value: searchValue } = event.target;
    onChange?.({ ...value, value: searchValue });
    setValue((prevValue = defaultValue) => ({ ...prevValue, value: searchValue }));
  };

  const handleChangeExact: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { checked: regex } = event.target;
    onChange?.({ ...value, regex });
    setValue((prevValue = defaultValue) => ({ ...prevValue, regex }));
  };

  const handleChangeVins = (newVins: string[] = []) => {
    onChange?.({ ...value, vins: newVins });
    setValue((prevValue = defaultValue) => ({ ...prevValue, vins: newVins }));
    setVinDialog(false);
  };

  return (
    <TextField
      value={value.value}
      onChange={handleChangeInput}
      variant="outlined"
      size="small"
      fullWidth
      InputLabelProps={muiInputLabelProps}
      placeholder={t('carsTableNext.filter.search.placeholder')}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <FormControlLabel
              control={<Switch size="small" checked={value.regex} onChange={handleChangeExact} />}
              label={
                <Typography
                  variant="body2"
                  style={{
                    color: grey[600],
                  }}
                >
                  {t('carsTableNext.filter.exact')}
                </Typography>
              }
              style={{
                marginInlineEnd: 0,
              }}
            />
          </InputAdornment>
        ),
      }}
      helperText={
        <>
          <Badge badgeContent={value.vins.length} overlap="rectangular" color="secondary" className={classes.vinBadge}>
            <Button
              ref={vinButtonRef}
              size="small"
              style={{
                fontSize: 'inherit',
                textTransform: 'none',
              }}
              color="secondary"
              startIcon={<AddIcon />}
              onClick={() => setVinDialog(true)}
            >
              {t('carsTableNext.filter.search.searchVin')}
            </Button>
          </Badge>
          <Popover
            open={vinDialog}
            onClose={() => setVinDialog(false)}
            anchorEl={vinButtonRef.current}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <SearchVinAdder value={value.vins} onSubmit={handleChangeVins} />
          </Popover>
        </>
      }
      label={isString(label) ? t(label as any) : label}
      {...textFieldProps}
    />
  );
};
