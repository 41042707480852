import { makeStyles, Tooltip } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Apps } from '@material-ui/icons';
import { SourceType } from '../modules/generated/api';
import { formatDate } from '../modules/date-helpers';
import { getLabel, sources } from '../modules/labels';
import { isVwfs } from '../modules/car-source-helper';

type CarsTableStartDateAndIdProps = {
  isoDate: string;
  packageId?: string;
  source?: SourceType;
  carId?: string;
};

const useStyles = makeStyles(() => ({
  dateDiv: {
    whiteSpace: 'nowrap',
  },
  iconValue: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    '& svg': {
      filter: ' invert(46%) sepia(0%) saturate(15%) hue-rotate(249deg) brightness(98%) contrast(97%);',
      width: '15px',
    },
  },
}));

const CarsTableStartDateAndId = ({ isoDate, packageId, source, carId }: CarsTableStartDateAndIdProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.dateDiv}>{formatDate(isoDate)}</div>
      {isVwfs(source as SourceType) ? 'VWFS' : getLabel(sources, source as SourceType)} {carId}
      {packageId && (
        <>
          <br />
          <div className={classes.iconValue}>
            <Tooltip title={`${t('common.package')}-${t('carsTable.ID')}`}>
              <Apps />
            </Tooltip>
            {packageId}
          </div>
        </>
      )}
    </>
  );
};

export default CarsTableStartDateAndId;
