import { useCallback, useRef, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { trackPromise } from 'react-promise-tracker';

type UseApiOptions = {
  onError?: (error: AxiosError) => void;
};

const cache: Record<string, any> = {};

const useApi = <T extends unknown>(cacheKey?: string, options?: UseApiOptions) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();
  const [data, setData] = useState<T | undefined>();
  const lastPromise = useRef<Promise<AxiosResponse<T> | undefined>>();
  const optionsRef = useRef(options);
  optionsRef.current = options;

  const fetchApi = useCallback(
    async (request: Promise<AxiosResponse<T>>, enforceRecency = false) => {
      setLoading(true);

      if (cacheKey && cache[cacheKey]) {
        setData(cache[cacheKey]);
      }

      try {
        lastPromise.current = request;
        const result = await trackPromise(request);

        if (!enforceRecency || (enforceRecency && lastPromise.current === request)) {
          setData(result.data);
          setError(undefined);
          setLoading(false);

          if (cacheKey) {
            cache[cacheKey] = result.data;
          }

          return result;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        setError(e as AxiosError);
        setLoading(false);
        optionsRef.current?.onError?.(e as AxiosError);
      }

      return undefined;
    },
    [cacheKey],
  );

  return {
    loading,
    error,
    data,
    fetch: fetchApi,
    mutate: setData,
  };
};

export default useApi;
