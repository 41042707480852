import { Grid, Slider, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { DateTime, Interval } from 'luxon';
import { memo, useCallback, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { grey } from '@material-ui/core/colors';
import { debounce } from 'lodash';
import { useDidUpdateEffect } from '../hooks/useDidUpdateEffect';
import { REGISTRATION_DATE_FORMAT } from '../modules/date-helpers';
import { InputLabel } from './ui';

const minDate = DateTime.fromISO('1990-01-01');
const defaultValue: [start: string, end: string] = [minDate.toISODate(), DateTime.now().endOf('month').toISODate()];

const monthDifference = (dateTime: DateTime): number =>
  Math.ceil(Interval.fromDateTimes(minDate, dateTime).length('months'));

const useStyles = makeStyles((theme) => ({
  datePicker: {
    maxWidth: 200,
    marginTop: theme.spacing(-0.5),
  },
}));

// Transform legacy `registrationDate` state
const fixState = (value: any) => {
  if (typeof value === 'string') {
    const parsedTime = DateTime.fromISO(value);

    if (parsedTime < minDate) {
      return minDate.toISODate();
    }
  }

  return value;
};

type RegistrationDatePickerProps = {
  prefix?: string;
};

const RegistrationDatePicker = ({ prefix = '' }: RegistrationDatePickerProps) => {
  const name = `${prefix || ''}registrationDate`;
  const classes = useStyles();
  const { t } = useTranslation();
  const max = monthDifference(DateTime.now());
  const { getValues: getFormValues, setValue: setFormValue, control } = useFormContext();
  const formValue = useWatch({ control, name });
  const [value, setValue] = useState(getFormValues(name));
  const displayValue = value ?? defaultValue;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setFormValueDebounced = useCallback(debounce(setFormValue, 400), [setFormValue]);

  // populate internal state updates
  useDidUpdateEffect(() => {
    setFormValueDebounced(name, value);

    return () => {
      setFormValueDebounced.cancel();
    };
  }, [setFormValueDebounced, value, name]);

  // sync external state updates
  useDidUpdateEffect(() => {
    setValue(formValue);
  }, [setValue, formValue]);

  return (
    <div>
      <InputLabel
        style={{
          marginBlockEnd: 2,
          fontWeight: 'normal',
          fontSize: 12,
          color: grey[600],
        }}
        id="registration-date-label"
      >
        {t('car.firstRegistration')}
      </InputLabel>

      <Slider
        min={0}
        max={max}
        step={1}
        aria-labelledby="registration-date-label"
        value={[
          monthDifference(DateTime.fromISO(fixState(displayValue[0]))),
          monthDifference(DateTime.fromISO(fixState(displayValue[1]))),
        ]}
        onChange={(_, changeValue) => {
          setValue([
            minDate.plus({ months: (changeValue as number[])[0] }).toISODate(),
            minDate.plus({ months: (changeValue as number[])[1] }).toISODate(),
          ]);
        }}
        color="secondary"
      />
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <KeyboardDatePicker
            className={classes.datePicker}
            value={fixState(displayValue[0])}
            onChange={(changeValue) => {
              if (changeValue && !changeValue.invalidReason) {
                setValue((prev: [string, string]) =>
                  [changeValue?.startOf('month').toISODate(), prev[1]].sort((a, b) => a.localeCompare(b)),
                );
              }
            }}
            views={['year', 'month']}
            format={REGISTRATION_DATE_FORMAT}
            placeholder="MM/JJJJ"
            inputVariant="outlined"
            margin="dense"
            minDate={minDate}
            maxDate={DateTime.now().plus({ month: 1 }).startOf('month')}
          />
        </Grid>
        <Grid item xs={6}>
          <KeyboardDatePicker
            className={classes.datePicker}
            value={fixState(displayValue[1])}
            onChange={(changeValue) => {
              if (changeValue && !changeValue.invalidReason) {
                setValue((prev: [string, string]) =>
                  [prev[0], changeValue?.endOf('month').toISODate()].sort((a, b) => a.localeCompare(b)),
                );
              }
            }}
            views={['year', 'month']}
            format={REGISTRATION_DATE_FORMAT}
            placeholder="MM/JJJJ"
            inputVariant="outlined"
            margin="dense"
            minDate={minDate}
            maxDate={DateTime.now().plus({ month: 1 }).endOf('month')}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(RegistrationDatePicker);
