import { usePacket, usePacketCreate, usePacketUpdate } from '@components/packet-service';
import { ServicePacketDrawer } from '@components/packet-service/ServicePacketDrawer';
import { ServicePacket } from '@components/packet-service/types';
import { PageMeta } from '@components/page-meta';
import { navigate, Redirect, RouteComponentProps, useParams } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RoutePaths } from 'src/constants';

type PageParams = {
  id: string;
};

const PacketDetailPage = (_props: RouteComponentProps) => {
  const { t } = useTranslation();
  const { id: packetId } = useParams<PageParams>();
  const isCreateMode = packetId === 'create';
  const { data: packet, isLoading } = usePacket(isCreateMode ? undefined : packetId);
  const { mutate: createPacket } = usePacketCreate();
  const { mutate: updatePacket } = usePacketUpdate();

  const handleSubmit = (packetData: ServicePacket) => {
    if (isCreateMode) {
      createPacket(packetData);
    } else {
      updatePacket(packetData);
    }

    navigate(RoutePaths.packets);
  };

  const handleClose = () => {
    navigate(RoutePaths.packets);
  };

  if (!isCreateMode && !packet && !isLoading) {
    return <Redirect to={RoutePaths.packets} noThrow />;
  }

  return (
    <>
      <PageMeta
        title={
          isCreateMode
            ? t('packetDetailPage.title.create')
            : t('packetDetailPage.title.edit', { packetName: packet?.name })
        }
      />

      <ServicePacketDrawer
        open
        onSubmit={handleSubmit}
        onClose={handleClose}
        servicePacket={isCreateMode || !packet ? undefined : packet}
      />
    </>
  );
};

export default PacketDetailPage;
