import { LinearProgress, makeStyles } from '@material-ui/core';
import React from 'react';
import { scaleLinear } from 'd3';

type PropsStyle = {
  backgroundColor: string;
};

const useStyles = makeStyles({
  barColorPrimary: {
    backgroundColor: (props: PropsStyle) => props.backgroundColor,
  },
});

const getColor = (progress: number, steps: number[], colors: string[]) => {
  const colorScale = scaleLinear<string>()
    .domain([...steps])
    .range([...colors]);

  return colorScale(progress);
};

type CellProgressProps = { progress: number; steps: number[]; colors: string[] };

export const CellProgress = ({ progress, steps, colors }: CellProgressProps) => {
  const color = getColor(progress, steps, colors);

  const barProps = {
    backgroundColor: color,
  };
  const classes = useStyles(barProps);

  return (
    <LinearProgress
      classes={{ barColorPrimary: classes.barColorPrimary }}
      variant="determinate"
      value={Math.abs(progress)}
      style={{
        backgroundColor: 'white',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
      }}
    />
  );
};
