import React, { ReactNode } from 'react';
import { makeStyles, TableCell } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    fontWeight: 'bold',
    paddingRight: 0,
  },
  bigger: {
    minWidth: '140px',
  },
}));

type CarDetailTableHeadCellProps = {
  className?: string;
  children?: ReactNode;
};

const CarDetailTableHeadCell = ({ children, className }: CarDetailTableHeadCellProps) => {
  const classes = useStyles();

  return (
    <TableCell className={clsx(classes.root, className === 'bigger' && classes.bigger)} component="th" scope="row">
      {children}
    </TableCell>
  );
};

export default CarDetailTableHeadCell;
