import React, { memo, useEffect, useRef } from 'react';
import { FormControl, FormLabel, Grid, InputAdornment, makeStyles, MenuItem, Select } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import { DateFilter } from '../modules/generated/api';
import { DATE_TIME_FORMAT } from '../modules/date-helpers';
import { useCarsFilter } from '../hooks/useCarsFilter';

const useStyles = makeStyles(() => ({
  formGroup: {
    flexDirection: 'row',
  },
  datePicker: {
    marginTop: 0,
    minWidth: '14.5vw',
    '& .MuiIconButton-root': {
      display: 'none',
    },
  },
}));

type DateFilterRadiosProps = {
  dateType: string;
};

const DateTimeFilterSelect = ({ dateType }: DateFilterRadiosProps) => {
  const classes = useStyles();
  const { setValue, watch } = useFormContext();
  const filter = dateType === 'end' ? watch('endDateFilter') : watch('startDateFilter');
  const didMountRef = useRef(false);
  const { t } = useTranslation();
  const { defaultValues } = useCarsFilter();

  useEffect(() => {
    if (didMountRef.current && filter !== DateFilter.Individual) {
      if (dateType === 'end') {
        setValue('endDateFrom', defaultValues.endDateFrom);
        setValue('endDateTo', defaultValues.endDateTo);
        return;
      }
      setValue('startDateFrom', defaultValues.startDateFrom);
      setValue('startDateTo', defaultValues.startDateTo);
    }
  }, [defaultValues, filter, didMountRef, setValue, dateType]);

  return (
    <>
      <FormControl component="fieldset" margin="dense" fullWidth>
        {dateType === 'end' ? (
          <>
            <FormLabel component="legend">{t('carsTable.endingTime')}</FormLabel>
            <Controller
              name="endDateFilter"
              render={({ field }) => (
                <Select {...field}>
                  <MenuItem value={DateFilter.All}>{t('common.all')}</MenuItem>
                  <MenuItem value={DateFilter.Next30Minutes}>{t('carsTable.nextxOminutes', { count: 30 })}</MenuItem>
                  <MenuItem value={DateFilter.Next1Hour}>{t('carsTable.nextxOhour', { count: 1 })}</MenuItem>
                  <MenuItem value={DateFilter.Next3Hour}>{t('carsTable.nextxOhour', { count: 3 })}</MenuItem>
                  <MenuItem value={DateFilter.Next6Hour}>{t('carsTable.nextxOhour', { count: 6 })}</MenuItem>
                  <MenuItem value={DateFilter.Individual}>{t('configuration.individual')}</MenuItem>
                </Select>
              )}
            />
          </>
        ) : (
          <>
            <FormLabel component="legend">{t('carsTable.startingTime')}</FormLabel>
            <Controller
              name="startDateFilter"
              render={({ field }) => (
                <Select {...field}>
                  <MenuItem value={DateFilter.All}>{t('common.all')}</MenuItem>
                  <MenuItem value={DateFilter.Last30Minutes}>{t('carsTable.lastxOminutes', { count: 30 })}</MenuItem>
                  <MenuItem value={DateFilter.Last1Hour}>{t('carsTable.lastxOhour', { count: 1 })}</MenuItem>
                  <MenuItem value={DateFilter.Last3Hour}>{t('carsTable.lastxOhour', { count: 3 })}</MenuItem>
                  <MenuItem value={DateFilter.Last6Hour}>{t('carsTable.lastxOhour', { count: 6 })}</MenuItem>
                  <MenuItem value={DateFilter.Individual}>{t('configuration.individual')}</MenuItem>
                </Select>
              )}
            />
          </>
        )}
      </FormControl>

      {filter === DateFilter.Individual && (
        <Grid container>
          <Grid item style={{ marginRight: 16 }}>
            <Controller
              name={dateType === 'end' ? 'endDateFrom' : 'startDateFrom'}
              render={({ field: { onChange, ref, ...field } }) => (
                <KeyboardDateTimePicker
                  {...field}
                  ampm={false}
                  className={classes.datePicker}
                  format={DATE_TIME_FORMAT}
                  invalidDateMessage={t('carsTable.formError.dateTimeError')}
                  InputProps={{ startAdornment: <InputAdornment position="start">{t('common.from')}</InputAdornment> }}
                  inputRef={ref}
                  inputVariant="outlined"
                  margin="dense"
                  onChange={(value) => {
                    onChange(value);
                  }}
                  openTo="date"
                  placeholder="TT.MM.JJ SS:MM"
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              name={dateType === 'end' ? 'endDateTo' : 'startDateTo'}
              render={({ field: { onChange, ref, ...field } }) => (
                <KeyboardDateTimePicker
                  {...field}
                  ampm={false}
                  className={classes.datePicker}
                  format={DATE_TIME_FORMAT}
                  invalidDateMessage={t('carsTable.formError.dateTimeError')}
                  InputProps={{ startAdornment: <InputAdornment position="start">{t('common.to')}</InputAdornment> }}
                  inputRef={ref}
                  inputVariant="outlined"
                  margin="dense"
                  onChange={(value) => {
                    onChange(value);
                  }}
                  openTo="date"
                  placeholder="TT.MM.JJ SS:MM"
                />
              )}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default memo(DateTimeFilterSelect);
