import { BidInfo } from '@components/cars-table-next/legacy/BidInfo';
import ImageOverlay from '@components/ui/ImageOverlay';
import { Badge, createStyles, makeStyles, SvgIconTypeMap, Tooltip, withStyles } from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import { DefaultComponentProps } from '@material-ui/core/OverridableComponent';
import { MoneyOff, Star } from '@material-ui/icons';
import { transparentize } from 'polished';
import { useTranslation } from 'react-i18next';
import { BidSuccessStatus, IndividualBidDTO, IndividualBidStateInfoDTO } from '../../../modules/generated/api';

type CarImageProps = {
  bidStateInfo?: IndividualBidStateInfoDTO;
  imageThumbnailLink?: string;
  expired?: boolean;
};

const useStyles = makeStyles((theme) => ({
  backgroundBlackAndWhite: {
    filter: 'grayscale(100%)',
  },
  iconStyle: {
    backgroundColor: transparentize(0.2, theme.palette.error.main),
    borderRadius: 90,
    color: '#fff',
    border: '1px solid white',
  },
}));

const HighestBidBadge = withStyles(() =>
  createStyles({
    badge: {
      background: yellow[50],
      color: yellow[900],
      padding: 0,
    },
  }),
)(Badge);

const getIsHighestBidder = (bids: IndividualBidDTO[]) => bids.some((bid) => bid.status === BidSuccessStatus.Highest);

export const CarImage = ({ expired, bidStateInfo, imageThumbnailLink }: CarImageProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isHighestBidder = bidStateInfo?.ownCurrentBids && getIsHighestBidder(bidStateInfo.ownCurrentBids);
  // check cars is in the url
  const isCarsTable = window.location.pathname.includes('cars');

  return (
    <HighestBidBadge
      badgeContent={
        isHighestBidder ? (
          <Tooltip title={t('bids.status.highest')} arrow placement="right">
            <Star fontSize="small" color="inherit" />
          </Tooltip>
        ) : null
      }
      overlap="rectangular"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Badge
        badgeContent={isCarsTable && bidStateInfo ? <BidInfo bidStatus={bidStateInfo} /> : null}
        overlap="rectangular"
      >
        <ImageOverlay
          src={imageThumbnailLink ?? '/images/icons/car.svg'}
          icon={(iconProps: DefaultComponentProps<SvgIconTypeMap>) => (
            <MoneyOff fontSize="medium" className={classes.iconStyle} {...iconProps} />
          )}
          height={40}
          tooltipText={t('bids.expired')}
          disableOverlay={!isCarsTable || !expired}
        />
      </Badge>
    </HighestBidBadge>
  );
};
