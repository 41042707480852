import React, { ReactElement, ReactNode, Ref, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemIcon, ListItemText, Menu as MuiMenu, MenuItem } from '@material-ui/core';
import { SvgIconComponent } from '@material-ui/icons';

export type MenuProps<TAction extends MenuAction> = {
  className?: string;
  actions: TAction[];
  children: (triggerProps: { ref: Ref<any>; onClick: () => void }) => ReactElement;
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  onAction: (action: TAction) => void;
};

// TODO: convert to generic
export type MenuAction = {
  id: string | number;
  icon?: SvgIconComponent;
  title: ReactNode;
  disabled?: boolean;
};

export const useMenu = () => {
  const [isOpen, setOpen] = useState(false);

  const open = () => setOpen(true);
  const close = () => setOpen(false);

  return {
    isOpen,
    open,
    close,
    setOpen,
  };
};

export const Menu = <TAction extends MenuAction = MenuAction>({
  actions,
  open,
  children: Trigger,
  onClose,
  onAction,
  onOpen,
  className,
}: MenuProps<TAction>): JSX.Element => {
  const { t } = useTranslation();
  const [anchorElement, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClickMenuItem = (action: TAction) => {
    onAction(action);
    onClose();
  };

  return (
    <div ref={setAnchorEl} className={className}>
      {Trigger({ ref: setAnchorEl, onClick: onOpen })}

      <MuiMenu anchorEl={anchorElement} open={open} onClose={onClose}>
        {actions.map((action) => {
          const { id, title, icon: Icon, disabled } = action;

          return (
            <MenuItem key={id} onClick={() => handleClickMenuItem(action)} disabled={disabled}>
              {Icon ? (
                <ListItemIcon>
                  <Icon fontSize="small" />
                </ListItemIcon>
              ) : null}
              <ListItemText
                // @ts-expect-error: dynamic translation key
                primary={typeof title === 'string' ? t(title) : title}
              />
            </MenuItem>
          );
        })}
      </MuiMenu>
    </div>
  );
};
