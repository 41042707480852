import React, { useMemo, useState } from 'react';
import { ComposableMap, Geographies, Geography, ZoomableGroup } from 'react-simple-maps';
import ReactTooltip from 'react-tooltip';
import * as d3 from 'd3';
import countries from 'i18n-iso-countries';
import { formatUnitValue } from '../modules/unit-helpers';
import { CarsPotentialTypes, countryCodeToOption } from '../modules/labels';
import { getMaxPotential, getMaxRegion, getMinPotential, getMinRegion } from '../modules/map-calculations-helper';
import i18n from '../setup/i18n';
import { useCurrency } from '../hooks/useCurrency';

type EuropeMapProps = {
  potentials: {
    [key: string]: { amount: number; currency: string } | number;
  };
  value: CarsPotentialTypes;
};

const EuropeMap = ({ potentials, value }: EuropeMapProps) => {
  const potentialCountries = Object.keys(potentials);
  const maxPotential = useMemo(
    () =>
      getMaxPotential(
        potentialCountries.filter((country) => potentials[country] !== undefined),
        potentials,
      ) || 0,
    [potentialCountries, potentials],
  );
  const minPotential = useMemo(
    () =>
      getMinPotential(
        potentialCountries.filter((country) => potentials[country] !== undefined),
        potentials,
      ) || 0,
    [potentialCountries, potentials],
  );
  const [tooltipText, setTooltipText] = useState('');
  const { format, convert } = useCurrency();

  let colorRange: string[];
  let domainValues: number[];
  if (minPotential < 0) {
    domainValues = [minPotential, 0, maxPotential];
    colorRange = ['#AF0606', '#FDF0C2', '#10712F'];
  } else {
    domainValues = [minPotential, maxPotential];
    colorRange = ['#FDF0C2', '#10712F'];
  }
  const colors = d3.scaleLinear<string>().domain(domainValues).range(colorRange);
  const colorsReverse = d3.scaleLinear<string>().domain([minPotential, maxPotential]).range(['#10712F', '#FDF0C2']);

  const geoUrl =
    'https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/v2.2.0/topojson-maps/world-110m.json';

  const getText = (country: string) => {
    let text;
    let mean;
    let peak: number;

    if (['purchaseGross', 'purchaseNetRetailRepaired', 'purchaseNetRetailUnrepaired'].includes(value)) {
      peak = getMinRegion(country, potentialCountries, potentials) || 0;
    } else {
      peak = getMaxRegion(country, potentialCountries, potentials) || 0;
    }
    if (potentialCountries?.includes(country)) {
      const countryRegions = potentialCountries
        .filter((potentialCountry) => potentialCountry.substring(0, 2) === country)
        .sort();
      let regionText;
      if (countryRegions.length > 1) {
        regionText = countryRegions.map((selectedCountry, idx) => {
          mean = potentials[selectedCountry];
          if (!mean)
            return `${idx === 0 ? '' : '<br />'}${
              selectedCountry !== country
                ? selectedCountry.substring(country.length + 1)
                : countryCodeToOption(country, i18n.language).label
            }: - `;
          if (typeof mean === 'object') {
            if (mean.amount === peak) {
              return `${idx === 0 ? '' : '<br />'}${
                selectedCountry !== country
                  ? selectedCountry.substring(country.length + 1)
                  : countryCodeToOption(country, i18n.language).label
              }: <span style="color:lightgreen">${format(convert(mean, {}), {})}</span>`;
            }
            return `${idx === 0 ? '' : '<br />'}${
              selectedCountry !== country
                ? selectedCountry.substring(country.length + 1)
                : countryCodeToOption(country, i18n.language).label
            }: ${format(convert(mean, {}), {})}`;
          }
          if (mean === peak) {
            return `${idx === 0 ? '' : '<br />'}${
              selectedCountry !== country
                ? selectedCountry.substring(country.length + 1)
                : countryCodeToOption(country, i18n.language).label
            }: <span style="color:lightgreen">${formatUnitValue(mean as number, '%')}</span>`;
          }
          return `${idx === 0 ? '' : '<br />'}${
            selectedCountry !== country
              ? selectedCountry.substring(country.length + 1)
              : countryCodeToOption(country, i18n.language).label
          }: ${formatUnitValue(mean as number, '%')}`;
        });
        text = `${regionText}`;
      } else {
        mean = potentials[country] || 0;
        if (!mean) {
          text = `${countryCodeToOption(country, i18n.language).label}: -`;
        } else if (typeof mean === 'object') {
          text = `${countryCodeToOption(country, i18n.language).label}: ${format(convert(mean, {}), {})}`;
        } else {
          text = `${countryCodeToOption(country, i18n.language).label}: ${formatUnitValue(mean as number, '%')}`;
        }
      }
    }
    return text;
  };

  return (
    <>
      <ComposableMap
        projection="geoAzimuthalEqualArea"
        projectionConfig={{
          rotate: [-15.0, -50.0, 0],
          scale: 1200,
        }}
        data-tip=""
      >
        <ZoomableGroup>
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const {
                  properties: { ISO_A2: country, NAME: name },
                } = geo;
                const minRegionValue: number | undefined = potentialCountries.includes(country)
                  ? getMinRegion(country, potentialCountries, potentials)
                  : undefined;
                const maxRegionValue: number | undefined = potentialCountries.includes(country)
                  ? getMaxRegion(country, potentialCountries, potentials)
                  : undefined;
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onMouseEnter={() => {
                      if (potentialCountries.includes(country)) {
                        setTooltipText(getText(country) || name);
                      } else {
                        setTooltipText(countries.getName(country, i18n.language));
                      }
                    }}
                    onMouseLeave={() => {
                      setTooltipText('');
                    }}
                    style={{
                      hover: {
                        filter: 'brightness(85%)',
                        outline: 'none',
                      },
                    }}
                    fill={
                      // eslint-disable-next-line no-nested-ternary
                      potentialCountries.includes(country) // eslint-disable-next-line no-nested-ternary
                        ? ['purchaseGross', 'purchaseNetRetailRepaired', 'purchaseNetRetailUnrepaired'].includes(value)
                          ? minRegionValue
                            ? colorsReverse(minRegionValue)
                            : '#898282'
                          : maxRegionValue
                            ? colors(maxRegionValue)
                            : '#898282'
                        : '#D6D6DA'
                    }
                  />
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      {/* TODO: replace with Tooltip(`followCursor`) after MUIv5 upgrade */}
      <ReactTooltip multiline html>
        {tooltipText}
      </ReactTooltip>
    </>
  );
};

export default EuropeMap;
