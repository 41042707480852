import { isArray, isBoolean, isEmpty, isEqual, isNumber, isObject, round } from 'lodash';
import { MUIDataTableColumn } from 'mui-datatables';
import { UseFormSetValue } from 'react-hook-form';
import { CarsTableNextFilterData } from '../components/cars-table-next/filter/types';
import { MonetaryAmountStrict } from './currency';
import {
  BidDTO,
  BidSuccessStatus,
  DateFilter,
  DateTimeFilterDTO,
  ListCarDTO,
  PriceType,
  SourceRegisterPotentialDTO,
} from './generated/api';
import { fixedVariablesToCalculationResponseKeys, getInputUri } from './map-calculations-helper';
import { BidsListCarDTO, CarTableColumn } from './table-data';

export const calcPage = (currPage: number, prevRowsPerPage: number, newRowsPerPage: number) => {
  const offset = currPage * prevRowsPerPage;
  return Math.floor(offset / newRowsPerPage);
};

const handleAdaptPrices = (bid: BidDTO, car: ListCarDTO): BidsListCarDTO => {
  const priceBought = bid.bids?.find((bidIter) => bidIter.status === BidSuccessStatus.Bought);

  return {
    ...car,
    ...bid,
    potential: priceBought?.potential,
    oldBasePrice: car.potential?.base,
  };
};

export const mergeBidCars = (cars: ListCarDTO[], bids: BidDTO[], adaptPrices: boolean) => {
  if (cars.length !== bids.length) throw RangeError('Bids and cars must be of same length');
  const result = [];
  for (let i = 0; i < cars.length; i += 1) {
    if (adaptPrices) {
      result.push(handleAdaptPrices(bids[i], cars[i]));
    } else {
      result.push({
        ...cars[i],
        ...bids[i],
      });
    }
  }
  return result;
};

export const setPotentialCalculations = (
  data: SourceRegisterPotentialDTO,

  carId: string,
  setValue: UseFormSetValue<any>,
  exchangeMonetaryAmount: (val: MonetaryAmountStrict, to?: string) => MonetaryAmountStrict,
  idx?: number,
) => {
  Object.entries(fixedVariablesToCalculationResponseKeys).forEach(([priceType, dataKey]) => {
    type ValueType = number | MonetaryAmountStrict | undefined;
    const potential = data[dataKey as keyof SourceRegisterPotentialDTO] as ValueType;

    const inputUri = getInputUri(carId, priceType as PriceType, idx || 1);
    if (typeof potential === 'number') {
      setValue(inputUri, potential && round(potential, 3));
    } else {
      setValue(inputUri, potential && exchangeMonetaryAmount(potential));
    }
  });
};

export const checkColumnOrderAllowed = (oldPosition: number, newPosition: number, columns: MUIDataTableColumn[]) => {
  const anchorColumn = columns.findIndex((column) => column.name === CarTableColumn.CarCulatorLink);
  if (oldPosition < anchorColumn) return newPosition < anchorColumn;
  if (oldPosition > anchorColumn) return newPosition > anchorColumn;
  // eslint-disable-next-line no-console
  console.warn('Anchor column dragged');
  return false;
};

export const isDefaultFilter = (
  defaults: CarsTableNextFilterData,
  name: keyof CarsTableNextFilterData,
  filter: CarsTableNextFilterData,
) => {
  const value = filter[name];
  const defaultValue = defaults[name];

  const isSimpleCurrencySlider = isArray(value) && isObject(value[0]);
  const isDateTimeObject = isObject(value) && 'timeType' in value;
  const isMinMaxCurrencySlider = isObject(value) && !isArray(value);
  const isDefaultSlider = [
    'mileage',
    'engineCo2Wltp',
    'engineCo2Nedc',
    'numAuctions',
    'powerKw',
    'registrationDate',
    'bidSuccessStates',
    'bidVisibility',
    'bidCreatorDealerCountry',
    'bidCreatorDealerId',
  ].includes(name);

  if (isDateTimeObject) {
    const timeType = (value as DateTimeFilterDTO)?.timeType as DateFilter;
    if (timeType !== DateFilter.All) {
      return { name, value };
    }
  } else if (isSimpleCurrencySlider || isMinMaxCurrencySlider || isDefaultSlider || isBoolean(value)) {
    // sliders and true/false (comment) count only if they are not default
    if (!isEqual(defaultValue, value)) {
      return { name, value };
    }
  } else if (isNumber(value)) {
    // for ratings
    if (value > 0) {
      return { name, value };
    }
  } else if (!isEmpty(value)) {
    return { name, value };
  }
  return false;
};
