import {
  BidDTO,
  DealerId,
  IndividualBidDTO,
  ListCarDTO,
  PriceType,
  SourceRegisterNormalizedDTO,
  SourceRegisterPotentialDTO,
  SourceRegisterValuationPriceDTO,
  UserIdAndName,
} from './generated/api';
import { tr } from './i18n-helpers';

export type BidsListCarDTO = ListCarDTO & BidDTO & { oldBasePrice?: SourceRegisterPotentialDTO['base'] };
export type CarProperty =
  | keyof BidsListCarDTO
  | keyof SourceRegisterPotentialDTO
  | keyof SourceRegisterValuationPriceDTO
  | `normalized.${keyof SourceRegisterNormalizedDTO}`
  | `bids.${keyof IndividualBidDTO}.${keyof UserIdAndName | keyof DealerId}`
  | `bids.${keyof IndividualBidDTO}`;
type Column = {
  sortOn?: CarProperty | 'normalizedFullModelName' | 'marketPrice';
  label: string;
  exports: Array<CarProperty>;
  priceType?: PriceType;
};

export enum CarTableColumn {
  ImageThumbnailLink = 'imageThumbnailLink',
  UserInfo = 'userInfo',
  startDateAndId = 'startDateAndId',
  OriginSource = 'originSource',
  DateEnd = 'dateEnd',
  Model = 'model',
  NormalizedModel = 'normalizedModel',
  Highlights = 'highlights',
  FirstRegistration = 'firstRegistration',
  Mileage = 'mileage',
  Damage = 'priceDamage',
  Co2 = 'co2',
  DateOnlineTimeInMinutes = 'onlineTimeInMinutes',
  NumAuctions = 'numAuctions',
  BaseFixedOrientationPrice = 'baseFixedOrientationPrice',
  PurchaseNetRetailRepaired = 'purchaseNetRetailRepaired',
  PurchaseGross = 'purchaseGross',
  MarketPrice = 'marketPrice',
  RecommendedRetailPrice = 'recommendedRetailPrice',
  RecommendedRetailPriceAbsolute = 'recommendedRetailPriceDeductionAbsolute',
  RecommendedRetailPriceRelative = 'recommendedRetailPriceDeductionRelative',
  CarCulatorLink = 'carCulatorLink',
  PotentialMinAbsolute = 'potentialMinAbsolute',
  PotentialMinRelative = 'potentialMinRelative',
  PotentialMinMeanAbsolute = 'potentialMinMeanAbsolute',
  PotentialMinMeanRelative = 'potentialMinMeanRelative',
  PotentialMeanAbsolute = 'potentialMeanAbsolute',
  PotentialMeanRelative = 'potentialMeanRelative',
  PurchaseNetWholesaleUnrepaired = 'purchaseNetWholesaleUnrepaired',
  Taxes = 'taxes',
  TaxesDe = 'taxesDe',
  TaxesAt = 'taxesAt',
  TaxesAtLot = 'taxesAtLot',
  TaxesFr = 'taxesFr',
  TaxesIt = 'taxesIt',
  TaxesEs = 'taxesEs',
  TaxesCz = 'taxesCz',
  TaxesHu = 'taxesHu',
  TaxesPl = 'taxesPl',
  TaxesRo = 'taxesRo',
  TaxesSi = 'taxesSi',
  TaxesHr = 'taxesHr',
  TaxesSk = 'taxesSk',
  TaxesPt = 'taxesPt',
  TaxesSe = 'taxesSe',
  TaxesBg = 'taxesBG',
  TaxesAl = 'taxesAl',
  TaxesMk = 'taxesMk',
  TaxesXk = 'taxesXk',
  promotions = 'promotions',
}

export enum BidTableColumnTypes {
  BidsCreated = 'created',
  BidsBiddingTime = 'biddingTime',
  BidsSentTime = 'sentTime',
  Bids = 'bids',
  BidsBidSuccessStatus = 'successStatus',
  BidsBuyer = 'bidsBuyer',
  BidsBuyingTime = 'bidsBuyingTime',
}

export const BIDS_TABLE_COLUMNS: { [key in BidTableColumnTypes]: Column } = {
  [BidTableColumnTypes.BidsCreated]: {
    label: 'bids.status.created',
    exports: ['created'],
  },
  [BidTableColumnTypes.BidsBiddingTime]: {
    label: 'bids.status.scheduled',
    exports: ['biddingTime'],
  },
  [BidTableColumnTypes.BidsSentTime]: {
    label: 'bids.status.sent',
    exports: ['sentTime'],
  },
  [BidTableColumnTypes.Bids]: {
    label: 'common.bid_plural',
    exports: ['bids.bid', 'bids.biddingTime', 'bids.user.name', 'bids.dealer.id', 'bids.status'],
  },
  [BidTableColumnTypes.BidsBidSuccessStatus]: {
    label: 'common.status',
    exports: ['successStatus'],
  },
  [BidTableColumnTypes.BidsBuyer]: {
    label: 'common.buyer',
    exports: [],
  },
  [BidTableColumnTypes.BidsBuyingTime]: {
    label: 'bids.buyingTime',
    exports: ['boughtTime'],
  },
};

export enum RefCarTableColumTypes {
  ModelInformation = 'modelinformation',
  DateOnlineTimeInMinutes = 'onlineTimeInMinutes',
  PriceLast = 'priceLast',
  Location = 'zipCode',
  Similarity = 'score',
}

export const CARS_TABLE_COLUMNS: { [key in CarTableColumn]: Column } = {
  [CarTableColumn.ImageThumbnailLink]: {
    label: 'car.picture',
    exports: ['imageThumbnailLink'],
  },
  [CarTableColumn.UserInfo]: {
    sortOn: 'rating',
    label: 'carsTable.userInfo',
    exports: ['rating', 'userComment'],
  },
  [CarTableColumn.startDateAndId]: {
    sortOn: 'dateStart',
    label: 'carsTable.start/ID',
    exports: ['dateStart', 'carId', 'vin', 'packageId'],
  },
  [CarTableColumn.OriginSource]: {
    sortOn: 'originSource',
    label: 'carsTableNext.filter.originSource',
    exports: ['originSource'],
  },
  [CarTableColumn.DateEnd]: { sortOn: 'dateEnd', label: 'carsTable.dateEnd', exports: ['dateEnd'] },
  [CarTableColumn.Model]: { sortOn: 'model', label: 'car.model', exports: ['model'] },
  [CarTableColumn.NormalizedModel]: {
    sortOn: 'normalizedFullModelName',
    label: 'car.modelTechdata',
    exports: [
      'normalized.brand',
      'normalized.model',
      'normalized.equipmentLine',
      'normalized.version',
      'normalized.fullModelName',
    ],
  },
  [CarTableColumn.Highlights]: {
    label: 'car.highlights',
    exports: ['countryOrigin', 'colorId', 'equipmentKeys', 'assessmentLink'],
  },
  [CarTableColumn.FirstRegistration]: {
    sortOn: 'firstRegistration',
    label: 'car.firstRegistration_abbr',
    exports: ['firstRegistration'],
  },
  [CarTableColumn.Mileage]: { sortOn: 'mileage', label: 'car.mileage', exports: ['mileage'] },
  [CarTableColumn.Damage]: {
    sortOn: 'priceDamage',
    label: 'car.damage',
    exports: ['priceDamage', 'damageType'],
  },
  [CarTableColumn.Co2]: {
    sortOn: 'nova',
    label: 'carsTable.co2',
    exports: ['co2ApproximationMethod', 'engineCo2Wltp', 'engineCo2Nedc'],
  },
  [CarTableColumn.DateOnlineTimeInMinutes]: {
    sortOn: 'onlineTimeInMinutes',
    label: 'car.onlineTime',
    exports: ['onlineTimeInMinutes'],
  },
  [CarTableColumn.NumAuctions]: { sortOn: 'numAuctions', label: 'car.numAuctions', exports: ['numAuctions'] },
  [CarTableColumn.BaseFixedOrientationPrice]: {
    sortOn: 'priceOrientation',
    label: 'car.prices.basePrice',
    exports: ['priceFixed', 'potential.base' as CarProperty, 'priceOrientation'],
    priceType: PriceType.OfferPrice,
  },
  [CarTableColumn.PurchaseNetWholesaleUnrepaired]: {
    sortOn: 'purchaseNetWholesaleUnrepaired',
    label: 'car.prices.purchaseNetWholesaleUnrepaired',
    exports: ['potential.purchaseNetWholesaleUnrepaired' as CarProperty],
    priceType: PriceType.PurchaseNetWholesaleUnrepaired,
  },

  [CarTableColumn.PurchaseNetRetailRepaired]: {
    sortOn: 'purchaseNetRetailRepaired',
    label: 'car.prices.purchaseNetRetailRepaired',
    exports: ['potential.purchaseNetRetailRepaired' as CarProperty],
    priceType: PriceType.PurchaseNetRetailRepaired,
  },
  [CarTableColumn.PurchaseGross]: {
    sortOn: 'purchaseGross',
    label: 'car.prices.purchaseGross',
    exports: ['potential.purchaseGross' as CarProperty],
    priceType: PriceType.PurchaseGross,
  },
  [CarTableColumn.RecommendedRetailPrice]: {
    sortOn: 'recommendedRetailPrice',
    label: 'car.prices.recommendedRetailPrice',
    exports: ['recommendedRetailPrice'] as any,
  },
  [CarTableColumn.RecommendedRetailPriceAbsolute]: {
    sortOn: 'recommendedRetailPriceDeductionAbsolute',
    label: 'car.prices.recommendedRetailPriceAbsolute',
    exports: ['recommendedRetailPriceDeductionAbsolute'] as any,
  },
  [CarTableColumn.RecommendedRetailPriceRelative]: {
    sortOn: 'recommendedRetailPriceDeductionRelative',
    label: 'car.prices.recommendedRetailPriceRelative',
    exports: ['recommendedRetailPriceDeductionRelative'],
  },

  [CarTableColumn.CarCulatorLink]: { label: 'carsTable.carCulatorLink', exports: [] },
  [CarTableColumn.MarketPrice]: {
    sortOn: 'marketPrice',
    label: 'car.prices.marketPrice',
    exports: ['valuation.price.mean' as CarProperty],
  },
  [CarTableColumn.PotentialMinAbsolute]: {
    sortOn: 'potentialMinAbsolute',
    label: 'car.prices.potentialMinAbsolute',
    exports: ['potential.potentialMinAbsolute' as CarProperty],
    priceType: PriceType.PotentialMinAbsolute,
  },
  [CarTableColumn.PotentialMinRelative]: {
    sortOn: 'potentialMinRelative',
    label: 'car.prices.potentialMinRelative',
    exports: ['potential.potentialMinRelative' as CarProperty],
    priceType: PriceType.PotentialMinRelative,
  },
  [CarTableColumn.PotentialMinMeanAbsolute]: {
    sortOn: 'potentialMinMeanAbsolute',
    label: 'car.prices.potentialMinMeanAbsolute',
    exports: ['potential.potentialMinMeanAbsolute' as CarProperty],
    priceType: PriceType.PotentialMinMeanAbsolute,
  },
  [CarTableColumn.PotentialMinMeanRelative]: {
    sortOn: 'potentialMinMeanRelative',
    label: 'car.prices.potentialMinMeanRelative',
    exports: ['potential.potentialMinMeanRelative' as CarProperty],
    priceType: PriceType.PotentialMinMeanRelative,
  },
  [CarTableColumn.PotentialMeanAbsolute]: {
    sortOn: 'potentialMeanAbsolute',
    label: 'car.prices.potentialMeanAbsolute',
    exports: ['potential.potentialMeanAbsolute' as CarProperty],
    priceType: PriceType.PotentialMeanAbsolute,
  },
  [CarTableColumn.PotentialMeanRelative]: {
    sortOn: 'potentialMeanRelative',
    label: 'car.prices.potentialMeanRelative',
    exports: ['potential.potentialMeanRelative' as CarProperty],
    priceType: PriceType.PotentialMeanRelative,
  },
  [CarTableColumn.Taxes]: {
    label: 'carsTable.tax_plural',
    exports: [
      'taxes',
      // Taxes `includes` are calculated based on the current selected valuation country and are
      // altered in the `ExportPrompt` context, in order to keep the static behavior of `CARS_TABLE_COLUMNS`.
    ],
  },
  [CarTableColumn.TaxesDe]: {
    label: 'carsTable.taxDe_plural',
    exports: ['taxes.DE.tax.VAT.percent'] as any,
  },
  [CarTableColumn.TaxesAt]: {
    label: 'carsTable.taxAt_plural',
    exports: ['taxes.AT.tax.VAT.percent', 'taxes.AT.tax.NOVA.percent'] as any,
  },
  [CarTableColumn.TaxesAtLot]: {
    label: 'carsTable.taxAtLot_plural',
    exports: ['taxes.AT_LOT.tax.VAT.percent', 'taxes.AT_LOT.tax.NOVA.percent'] as any,
  },
  [CarTableColumn.TaxesFr]: {
    label: 'carsTable.taxFr_plural',
    exports: ['taxes.FR.tax.ECO.total', 'taxes.FR.tax.VAT.percent'] as any,
  },
  [CarTableColumn.TaxesIt]: {
    label: 'carsTable.taxIt_plural',
    exports: ['taxes.IT.tax.ECOMALUS.total', 'taxes.IT.tax.TRANSCRIPTION.total', 'taxes.IT.tax.VAT.percent'] as any,
  },
  [CarTableColumn.TaxesEs]: {
    label: 'carsTable.taxEs_plural',
    exports: ['taxes.ES.tax.REGISTRATION.percent', 'taxes.ES.tax.VAT.percent'] as any,
  },
  [CarTableColumn.TaxesCz]: {
    label: 'carsTable.taxCz_plural',
    exports: ['taxes.CZ.tax.REGISTRATION.total', 'taxes.CZ.tax.VAT.percent'] as any,
  },
  [CarTableColumn.TaxesHu]: {
    label: 'carsTable.taxHu_plural',
    exports: [
      'taxes.HU.tax.AUTHENTICATION.total',
      'taxes.HU.tax.PROPERTY_ACQUISITION.total',
      'taxes.HU.tax.REGISTRATION.total',
      'taxes.HU.tax.VAT.percent',
    ] as any,
  },
  [CarTableColumn.TaxesPl]: {
    label: 'carsTable.taxPl_plural',
    exports: ['taxes.PL.tax.CIVIL.percent', 'taxes.PL.tax.EXCISE.percent', 'taxes.PL.tax.VAT.percent'] as any,
  },
  [CarTableColumn.TaxesRo]: {
    label: 'carsTable.taxRo_plural',
    exports: ['taxes.RO.tax.VAT.percent'] as any,
  },
  [CarTableColumn.TaxesSi]: {
    label: 'carsTable.taxSi_plural',
    exports: ['taxes.SI.tax.VAT.percent', 'taxes.SI.tax.REGISTRATION.total'] as any,
  },
  [CarTableColumn.TaxesHr]: {
    label: 'carsTable.taxHr_plural',
    exports: ['taxes.HR.tax.VAT.percent', 'taxes.HR.tax.REGISTRATION.total'] as any,
  },
  [CarTableColumn.TaxesSk]: {
    label: 'carsTable.taxSk_plural',
    exports: ['taxes.SK.tax.VAT.percent'] as any,
  },
  [CarTableColumn.TaxesPt]: {
    label: 'carsTable.taxPt_plural',
    exports: ['taxes.PT.tax.VAT.percent', 'taxes.PT.tax.REGISTRATION.total'] as any,
  },
  [CarTableColumn.TaxesSe]: {
    label: 'carsTable.taxSe_plural',
    exports: ['taxes.SE.tax.VAT.percent'] as any,
  },
  [CarTableColumn.TaxesBg]: {
    label: 'carsTable.taxBg_plural',
    exports: ['taxes.BG.tax.VAT.percent'] as any,
  },
  [CarTableColumn.TaxesAl]: {
    label: 'carsTable.taxAl_plural',
    exports: ['taxes.AL.tax.VAT.percent'] as any,
  },
  [CarTableColumn.TaxesMk]: {
    label: 'carsTable.taxMk_plural',
    exports: ['taxes.MK.tax.VAT.percent'] as any,
  },
  [CarTableColumn.TaxesXk]: {
    label: 'carsTable.taxXk_plural',
    exports: ['taxes.XK.tax.VAT.percent'] as any,
  },
  [CarTableColumn.promotions]: {
    label: 'filterSearch.promotions',
    exports: ['promotions'],
  },
};

export const CARS_BIDS_TABLE_COLUMNS = { ...CARS_TABLE_COLUMNS, ...BIDS_TABLE_COLUMNS };

export const getColumnDeclaration = (name: CarTableColumn | BidTableColumnTypes): { label: string; name: string } => ({
  label: tr(CARS_BIDS_TABLE_COLUMNS[name].label),
  name,
});
