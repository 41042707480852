import { IconButton, Popover, Tooltip } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import React, { memo, MouseEvent, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SearchVinAdder from './SearchVinAdder';

const AddVinFilter = () => {
  const anchorEl = useRef<HTMLDivElement | null>(null);
  const [edit, setEdit] = useState(false);
  const { getValues } = useFormContext();
  const { t } = useTranslation();
  const vinValue: string[] = getValues('vinSearch');

  const handleClose = async () => {
    setEdit(false);
  };
  const handleOnClick = (event: MouseEvent) => {
    event.stopPropagation();
    setEdit(true);
  };
  return (
    <>
      <Tooltip title={t('filterSearch.searchForVins')}>
        <div ref={anchorEl}>
          <IconButton onClick={(e) => handleOnClick(e)}>
            <AddBoxIcon color={vinValue ? 'primary' : 'action'} />
          </IconButton>
        </div>
      </Tooltip>
      {edit && (
        <Popover
          open
          onClose={handleClose}
          onSubmit={handleClose}
          anchorEl={anchorEl.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <SearchVinAdder />
        </Popover>
      )}
    </>
  );
};

export default memo(AddVinFilter);
