import React, { Suspense } from 'react';
import { ErrorFallback, HelpCenterHeader } from '@components/help-center';
import { Container } from '@material-ui/core';
import { RouteComponentProps, useMatch } from '@reach/router';
import type { Merge } from 'type-fest';
import { RouteFallback } from '@components/route';
import { ErrorBoundary } from 'react-error-boundary';

type HelpIndexPageProps = {
  children?: React.ReactNode;
};

export const HelpIndexPage = ({ children }: Merge<RouteComponentProps, HelpIndexPageProps>): JSX.Element => {
  const matchRoot = useMatch('/help');
  const isRoot = matchRoot !== null;

  return (
    <>
      <Container>
        <HelpCenterHeader minimized={!isRoot} />
      </Container>

      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Suspense fallback={<RouteFallback />}>{children}</Suspense>
      </ErrorBoundary>
    </>
  );
};
