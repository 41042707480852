import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { TaxType } from '../modules/generated/api';
import OptionCheckboxes from './OptionCheckboxes';

const TaxTypeCheckboxes = () => {
  const { t } = useTranslation();
  return (
    <OptionCheckboxes
      name="taxType"
      label={t('carsTable.taxationType')}
      options={[
        { value: TaxType.Regelbesteuert, label: t('carsTable.regularilyTaxed') },
        { value: TaxType.Differenzbesteuert, label: t('carsTable.differentialTaxation') },
      ]}
    />
  );
};

export default memo(TaxTypeCheckboxes);
