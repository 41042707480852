import React, { memo, ReactNode } from 'react';
import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import HorizontalDivider from './HorizontalDivider';

const useStyles = makeStyles((theme) => ({
  hintIcon: {
    verticalAlign: 'middle',
    margin: theme.spacing(0.22),
  },
  smaller: {
    fontSize: '1rem',
  },
}));

type InfoTooltipProps = {
  title?: string;
  hint?: ReactNode;
  divider?: boolean;
  moreInfo?: ReactNode;
  xsIcon?: boolean;
};

const InfoTooltip = ({ title, hint, divider, moreInfo, xsIcon }: InfoTooltipProps) => {
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      placement="right"
      title={
        <>
          <Typography variant="caption">
            {title && (
              <>
                <strong>{title}</strong> <br />
              </>
            )}
            {hint}
          </Typography>

          {divider && <HorizontalDivider variant="middle" light />}

          {moreInfo}
        </>
      }
      className={clsx(classes.hintIcon, xsIcon && classes.smaller)}
    >
      <InfoOutlined fontSize="small" />
    </Tooltip>
  );
};

export default memo(InfoTooltip);
