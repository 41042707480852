import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Chip,
  IconButton,
  makeStyles,
  TableCell,
  TableFooter,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { Redirect, RouteComponentProps } from '@reach/router';
import { Add as AddIcon, Edit } from '@material-ui/icons';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { getSourceOptions, useSources } from 'src/hooks/useSources';
import { PageMeta } from '@components/page-meta';
import PageContainer from '@components/PageContainer';
import useApi from 'src/hooks/useApi';
import ApiService from 'src/modules/api-service';
import { DetailedDealerDTO, PacketDTO } from 'src/modules/generated/api';
import getMUIDataTableDefaultOptions from 'src/setup/MUIDataTableOptions';
import useRole from 'src/hooks/useRole';
import UserRole from 'src/types/UserRoles';
import useCustomSnackbarGlobal from 'src/hooks/useSnackbarGlobal';
import FilterAutocomplete from '@components/FilterAutocomplete';
import theme from 'src/setup/theme';
import { getLabel, sources } from 'src/modules/labels';
import { isEmpty } from 'lodash';
import { EditDealerDrawer } from './EditDealerDrawer';

type NewDealerRowProps = {
  onSuccess: (newDealer: DetailedDealerDTO) => void;
  onError: () => void;
};

const useStyles = makeStyles({
  headline: {
    marginBottom: theme.spacing(2),
  },
  btnCell: {
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      minWidth: '180px',
    },
  },
  headCell: {
    marginLeft: '-14px',
    textAlign: 'left',
  },
  endCell: {
    textAlign: 'right',
  },
  select: {
    maxWidth: '150px',
    fontSize: '14px',
    marginTop: 20,
  },
  selected: {
    marginTop: 10,
  },
  nameColumn: {
    width: theme.spacing(25),
  },
  chip: {
    margin: theme.spacing(0.25),
  },
});

const useNewDealerRowStyles = makeStyles({
  cell: {
    padding: 4,
  },
  availableSources: {
    maxWidth: 320,
  },
});

const NewDealerRow = ({ onSuccess, onError }: NewDealerRowProps): JSX.Element => {
  const classes = useNewDealerRowStyles();
  const { t } = useTranslation();
  const form = useForm({
    defaultValues: {
      allowedSource: [],
      kvps: '',
      name: '',
    },
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = form;

  const onSubmit = handleSubmit(async (data) => {
    const newDealer = {
      name: data.name,
      dealer: {
        id: data.kvps,
      },
      allowedSource: data.allowedSource,
      isAllowedToLogin: false,
      shouldChangeDeliveryAddress: true,
    };

    try {
      const res = await ApiService.dealer.dealerControllerSaveDealer(newDealer.dealer.id, newDealer);
      if (!isEmpty(res.data)) {
        onSuccess(res.data);
      } else {
        onError();
      }
    } catch (e) {
      onError();
    }

    reset();
  });

  const { data: source = [] } = useSources();
  const sourceOptions = getSourceOptions(source);

  return (
    <FormProvider {...form}>
      <TableFooter>
        <TableRow>
          <TableCell className={classes.cell}>
            <Controller
              control={control}
              name="name"
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  label={t('common.name')}
                  type="text"
                  size="small"
                  variant="outlined"
                  error={!!errors.name}
                  {...field}
                />
              )}
            />
          </TableCell>
          <TableCell className={classes.cell} colSpan={2}>
            <Controller
              control={control}
              name="kvps"
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  label={t('car.ID')}
                  type="text"
                  size="small"
                  variant="outlined"
                  error={!!errors.kvps}
                  {...field}
                />
              )}
            />
          </TableCell>
          <TableCell className={classes.cell}>
            <div className={classes.availableSources}>
              <FilterAutocomplete
                name="allowedSource"
                label={t('common.source_plural')}
                multiple
                options={sourceOptions}
              />
            </div>
          </TableCell>
          <TableCell className={classes.cell}>{/* status placeholder */}</TableCell>
          <TableCell className={classes.cell} align="right">
            <Button variant="contained" startIcon={<AddIcon />} color="primary" onClick={onSubmit}>
              {t('common.add')}
            </Button>
          </TableCell>
        </TableRow>
      </TableFooter>
    </FormProvider>
  );
};

const DealersPage = (_props: RouteComponentProps) => {
  const classes = useStyles();
  const { fetch, data: dealers } = useApi<DetailedDealerDTO[]>();
  const { showError, showSuccess } = useCustomSnackbarGlobal();
  const { hasRole } = useRole();
  const tableRef = useRef<any>();
  const [newDealerIndex, setNewDealerIndex] = useState<number>();
  const [dealerID, setDealerID] = useState<string | null>(null);

  const { t } = useTranslation();

  const getData = useCallback(() => {
    fetch(ApiService.dealer.dealerControllerGetDealers());
  }, [fetch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleNewDealerSuccess = async (newDealer: DetailedDealerDTO) => {
    const res = await fetch(ApiService.dealer.dealerControllerGetDealers());
    const index = res?.data.findIndex((dealer) => dealer.dealer?.id === newDealer.dealer?.id);
    const table = tableRef.current;

    if (index !== undefined && table) {
      table.changePage(Math.ceil((index + 1) / table.state.rowsPerPage) - 1);
      setNewDealerIndex(index);
    }

    showSuccess(t('alerts.successSaved'));
  };

  const handleNewDealerError = () => {
    showError(t('alerts.errorRaised'));
  };

  useEffect(() => {
    fetch(ApiService.dealer.dealerControllerGetDealers());
  }, [fetch, dealerID]);

  const columns = [
    {
      name: 'name',
      label: t('common.name'),
      options: {
        customHeadLabelRender: () => <div className={classes.headCell}>{t('common.name')}</div>,
        customBodyRenderLite: (dataIndex: number) => {
          if (dealers !== undefined) {
            const dealer = dealers[dataIndex];
            return <div>{dealer.name}</div>;
          }
          return '';
        },
        searchable: true,
        filter: false,
      },
    },
    {
      name: 'dealer',
      label: 'ID',
      options: {
        customBodyRender: (value: any) => value.id,
        customHeadLabelRender: () => <div className={classes.headCell}>ID</div>,
        searchable: true,
        filter: false,
      },
    },
    {
      name: 'allowedToLogin',
      label: t('common.status'),
      options: {
        customHeadLabelRender: () => <div className={classes.headCell}>{t('common.status')}</div>,
        customBodyRenderLite: (dataIndex: number) => {
          if (dealers !== undefined) {
            const dealer = dealers[dataIndex];
            let statusType;
            if (dealer.isAllowedToLogin) {
              statusType = t('configuration.activated');
            } else {
              statusType = t('configuration.notActivated');
            }
            return statusType;
          }
          return '';
        },
        searchable: false,
        filter: true,
      },
    },
    {
      name: 'sources',
      label: t('common.source_plural'),
      options: {
        customHeadLabelRender: () => <div className={classes.headCell}>{t('common.source_plural')}</div>,
        customBodyRenderLite: (dataIndex: number) => {
          const dealerId = dealers?.[dataIndex]?.dealer?.id;
          const allowedSrc = dealers?.[dataIndex]?.allowedSource;
          if (!dealerId) return '';
          return allowedSrc?.map((item) => (
            <Chip className={classes.chip} key={item} size="small" label={getLabel(sources, item)} />
          ));
        },
      },
      filter: false,
      searchable: false,
    },
    {
      name: 'packages',
      label: t('common.package_plural'),
      options: {
        customHeadLabelRender: () => <div className={classes.headCell}>{t('common.package_plural')}</div>,
        customBodyRenderLite: (dataIndex: number) => {
          if (dealers !== undefined) {
            const dealer = dealers[dataIndex];
            const { data: source = [] } = useSources();
            const sourceOptions = getSourceOptions(source);

            const assignedPackets = sourceOptions
              .map((src) => src.value)
              .map((item) => dealer.assignedPackets?.[item])
              .filter((value) => value !== undefined) as PacketDTO[];
            if (assignedPackets) {
              return (
                <div>
                  {assignedPackets &&
                    assignedPackets.map((item) => (
                      <Chip
                        className={classes.chip}
                        variant="outlined"
                        size="small"
                        key={item?.packetId}
                        label={item?.name}
                      />
                    ))}
                </div>
              );
            }
          }
          return '';
        },
      },
      filter: false,
      searchable: false,
    },

    {
      name: 'edit',
      label: t('common.edit'),
      options: {
        customHeadLabelRender: () => <div className={classes.endCell}>{t('common.edit')}</div>,
        customBodyRenderLite: (dataIndex: number) => {
          const dealer = dealers?.[dataIndex];

          return (
            <div className={classes.endCell}>
              <Tooltip title={t('common.linkPackages')}>
                <IconButton onClick={() => dealer?.dealer?.id && setDealerID(dealer.dealer?.id)}>
                  <Edit />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
      filter: false,
      searchable: false,
    },
  ];

  useEffect(() => {
    const timeout = window.setTimeout(() => {
      setNewDealerIndex(undefined);
    }, 5000);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [newDealerIndex]);

  return (
    <>
      <PageMeta title="navigation.dealerAdministration" />
      <PageContainer>
        <Typography variant="h1" className={classes.headline}>
          {t('navigation.dealerAdministration')}
        </Typography>
        {hasRole(UserRole.ADMIN) || hasRole(UserRole.COUNTRYMANAGER) ? (
          <MUIDataTable
            title=""
            columns={columns}
            data={dealers || []}
            innerRef={tableRef}
            options={{
              ...getMUIDataTableDefaultOptions(),
              download: false,
              sort: false,
              draggableColumns: {
                enabled: false,
              },
              viewColumns: false,
              rowHover: false,
              customTableBodyFooterRender: () => (
                <NewDealerRow onSuccess={handleNewDealerSuccess} onError={handleNewDealerError} />
              ),
              setRowProps: (_row, dataIndex) => ({
                style: {
                  background: dataIndex === newDealerIndex ? theme.palette.success.light : undefined,
                },
              }),
            }}
          />
        ) : (
          <Redirect to="/cars" />
        )}
        {/* make sure to unmount component, in order to simplify the rehydration process  */}
        {!!dealerID && (
          <EditDealerDrawer
            detailedDealer={dealers?.find((dealer) => dealer.dealer?.id === dealerID)}
            open
            onClose={() => setDealerID(null)}
            dealerId={dealerID}
            refetch={getData}
          />
        )}
      </PageContainer>
    </>
  );
};
export default DealersPage;
