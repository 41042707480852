import { atom } from 'recoil';
import { DEFAULT_VALUATION_COUNTRY } from '../modules/data';
import { ListCarDTO, SourceRegisterPotentialDTO, ValuationCountryCode } from '../modules/generated/api';

export type RefCarVehicleStateType = {
  carId: string;
  refCar: ListCarDTO;
  potential?: SourceRegisterPotentialDTO;
  offerId?: string;
  valuationCountry?: ValuationCountryCode;
};

export const refCarDialogState = atom<null | RefCarVehicleStateType>({
  key: 'carsTableNext/refCarDialogState',
  default: null,
});

export const currentTabIndex = atom<number>({
  key: 'bids/tabIdx',
  default: 3,
});

export const chosenValuationCountryState = atom<ValuationCountryCode>({
  key: 'bids/chosenValuationCountry',
  default: DEFAULT_VALUATION_COUNTRY,
});

export const bidPotentialCalculationsState = atom<{
  1: SourceRegisterPotentialDTO | undefined;
  2: SourceRegisterPotentialDTO | undefined;
  3: SourceRegisterPotentialDTO | undefined;
}>({
  key: 'bids/calculations',
  default: { 1: undefined, 2: undefined, 3: undefined },
});
