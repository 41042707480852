export { default as client } from './client';

export enum Language {
  de = 'de',
  en = 'en',
  fr = 'fr',
  it = 'it',
  es = 'es',
}

export const DefaultLanguage = Language.de;

export const isSupportedLang = (lang: string): lang is Language => Object.values(Language).includes(lang as Language);

export const normalizeId = (id: string): string => id.replace(/^drafts\./, '');
