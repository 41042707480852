import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { QueryKeys } from 'src/constants';
import useCustomSnackbarGlobal from 'src/hooks/useSnackbarGlobal';
import ApiService from 'src/modules/api-service';
import { createStrictPacket, patchPacket } from './lib';
import { ServicePacket } from './types';

const updatePacket = (updatedPacket: ServicePacket): Promise<ServicePacket> =>
  ApiService.packet
    .packetControllerUpdatePacket(updatedPacket.id, patchPacket(updatedPacket))
    .then((res) => createStrictPacket(res.data));

export const usePacketUpdate = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { showInfo, showError } = useCustomSnackbarGlobal();

  const mutation = useMutation(updatePacket, {
    onMutate: async (updatedPacket) => {
      await queryClient.cancelQueries([QueryKeys.servicePackets]);
      await queryClient.cancelQueries([QueryKeys.servicePacket, updatedPacket.id]);

      const prevList = queryClient.getQueryData<ServicePacket[] | undefined>([QueryKeys.servicePackets]);
      const prevPacket = queryClient.getQueryData<ServicePacket | undefined>([
        QueryKeys.servicePacket,
        updatedPacket.id,
      ]);

      queryClient.setQueryData<ServicePacket[] | undefined>([QueryKeys.servicePackets], (prev = []) =>
        prev.map((packet) => {
          if (packet.id === updatedPacket.id) {
            return {
              ...packet,
              ...updatedPacket,
            };
          }

          return packet;
        }),
      );

      return { prevList, prevPacket };
    },
    onError: (_error, _variables, snapshot) => {
      queryClient.setQueryData<ServicePacket[] | undefined>([QueryKeys.servicePackets], snapshot?.prevList);
      queryClient.setQueryData<ServicePacket | undefined>(
        [QueryKeys.servicePacket, snapshot?.prevPacket?.id],
        snapshot?.prevPacket,
      );
      showError(t('servicePacketItem.actions.update.error'));
    },
    onSuccess: (packet) => {
      queryClient.setQueryData<ServicePacket>([QueryKeys.servicePacket, packet.id], packet);
      showInfo(t('servicePacketItem.actions.update.success', { packetName: packet.name }));
    },
    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.servicePackets]);
    },
  });

  return mutation;
};
