import { isObject } from 'lodash';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { DATE_TIME_FORMAT_NO_TIME, formatDate } from 'src/modules/date-helpers';
import { biddingTimeTypes, getLabel } from 'src/modules/labels';
import { DateFilter, DateTimeFilterDTO } from '../../../modules/generated/api/api';
import FilterChip from './FilterChip';
import { useBidsFilter } from './useBidsFilter';

type TimeRangeFilterChipProps = {
  value: DateTimeFilterDTO;
  name: string;
  timeFilterName: 'biddingTimeFilter' | 'boughtTimeFilter';
  label: string;
};

const TimeRangeFilterChip = ({ value, name, timeFilterName, label }: TimeRangeFilterChipProps) => {
  const { t } = useTranslation();
  const { timeType, timeFrom, timeTo } = value;
  const { setFilter, watch } = useBidsFilter();
  const filterState = watch();
  // dates are always set but are only active if the filter is individual
  const dateFrom = isObject(timeFrom)
    ? formatDate((timeFrom as DateTime).toISODate(), DATE_TIME_FORMAT_NO_TIME) // after first setting the value its a date object
    : formatDate(timeFrom as string, DATE_TIME_FORMAT_NO_TIME);
  const dateTo = isObject(timeTo)
    ? formatDate((timeTo as unknown as DateTime).toISODate(), DATE_TIME_FORMAT_NO_TIME) // after first setting the value its a date object
    : formatDate(timeTo as string, DATE_TIME_FORMAT_NO_TIME);
  if (timeType === DateFilter.Individual) {
    return (
      <FilterChip
        name={name}
        key={`${name}-${value}`}
        tooltip={t(`carsTableNext.export.choosePeriod-${timeFilterName}`)}
        label={`${dateFrom} - ${dateTo}`}
        onDelete={() =>
          setFilter({
            ...filterState,
            [timeFilterName === 'biddingTimeFilter' ? 'biddingTimeFilter' : 'boughtTimeFilter']: {
              ...value,
              timeType: DateFilter.All,
            },
          })
        }
        value={value}
      />
    );
  }
  if (timeType && timeType !== DateFilter.All) {
    return (
      <FilterChip
        name={name}
        key={`${name}-${value}`}
        tooltip={t(`carsTableNext.export.choosePeriod-${timeFilterName}`)}
        label={getLabel(biddingTimeTypes, timeType)}
        onDelete={() =>
          setFilter({
            ...filterState,
            [timeFilterName === 'biddingTimeFilter' ? 'biddingTimeFilter' : 'boughtTimeFilter']: {
              ...value,
              timeType: DateFilter.All,
            },
          })
        }
        value={value}
      />
    );
  }
  return null;
};

export default TimeRangeFilterChip;
