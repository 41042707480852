import React, { useState } from 'react';
import type { Merge } from 'type-fest';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useVoteMutation, VoteDirection } from '@components/help-center/useVoteMutation';

export type FeedbackProps = {
  documentId: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    marginInlineEnd: theme.spacing(2),
  },
  button: {
    // counteract vertical padding
    marginBlock: -12,
  },
}));

export const Feedback = ({
  className,
  documentId,
  ...props
}: Merge<React.ComponentProps<'div'>, FeedbackProps>): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [voteDir, setVoteDir] = useState<VoteDirection>();
  const { mutate: vote } = useVoteMutation();

  const handleClickVote = (direction: VoteDirection) => {
    vote({
      direction,
      documentId,
    });
    setVoteDir(direction);
  };

  return (
    <div className={clsx(classes.root, className)} {...props}>
      <Typography className={classes.text}>{t('helpCenter.feedback.title')}</Typography>
      <IconButton
        color={voteDir === VoteDirection.up ? 'primary' : 'secondary'}
        className={classes.button}
        onClick={() => handleClickVote(VoteDirection.up)}
      >
        <ThumbUpIcon />
      </IconButton>
      <IconButton
        color={voteDir === VoteDirection.down ? 'primary' : 'secondary'}
        className={classes.button}
        onClick={() => handleClickVote(VoteDirection.down)}
      >
        <ThumbDownIcon />
      </IconButton>
    </div>
  );
};
