import React from 'react';
import { Spinner } from '@components/spinner';
import { Grid, styled } from '@material-ui/core';

const StyledRouteFallback = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#fff',
  minHeight: '75vh',
}));

export const RouteFallback = () => (
  <StyledRouteFallback container justifyContent="center">
    <Spinner isLoading busyDelayMs={200} size="large" />
  </StyledRouteFallback>
);
