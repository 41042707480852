import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import clsx from 'clsx';
import { isString } from 'lodash';
import React, { ComponentProps } from 'react';

const useStyles = makeStyles({
  supportText: {
    color: grey[600],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 'normal',
  },
});

// eslint-disable-next-line react/prop-types
export const SupportText = ({ className, children, ...props }: ComponentProps<'div'>) => {
  const classes = useStyles();

  return (
    <div {...props} className={clsx(classes.supportText, className)} title={isString(children) ? children : undefined}>
      {children}
    </div>
  );
};
