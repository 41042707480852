import { ReactElement, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { extendUsersnapCtx } from '../hooks/useExtendUsersnap';
import { useAuthenticationStore } from '../stores/Authentication';

type UsersnapConfig = {
  locale: string;
  button: {
    position: 'bottomRight' | 'rightCenter' | 'rightBottom' | 'bottomLeft' | 'leftCenter';
  };
};

// Shallow types - no types provided by usersnap :(
interface UsersnapApi {
  init(config: UsersnapConfig): any;
  on(event: string, cb: (event: any) => void): void;
  setValue(key: string, value: string): void;
  destroy(): void;
}

declare global {
  interface Window {
    onUsersnapCXLoad?: (api: UsersnapApi) => void;
  }
}

type UsersnapProps = {
  custom?: Record<string, unknown>;
};

const widgetUrl = `https://widget.usersnap.com/load/${process.env.REACT_APP_USERSNAP_ID}?onload=onUsersnapCXLoad`;
// Integration might not be available in all stages
const integrationDisabled = !process.env.REACT_APP_USERSNAP_ID;

const createAsyncScript = (src: string): HTMLScriptElement => {
  const script = document.createElement('script');
  script.src = src;
  script.async = true;
  return script;
};

const prettyPrintLogs = (logs: Record<string, unknown>) => JSON.stringify(logs, null, 2);

/**
 * Wraps the usersnap widget and provides some default user meta data.
 * Also extends functionality and sources data from the custom `useExtendUserSnap` hook.
 */

export const Usersnap = ({ custom }: UsersnapProps): ReactElement | null => {
  const { custom: dataExtension } = useContext(extendUsersnapCtx);
  const apiRef = useRef<UsersnapApi>();
  const scriptRef = useRef<HTMLScriptElement>();
  const {
    i18n: { language },
  } = useTranslation();
  const {
    state: { objectToken },
  } = useAuthenticationStore();
  const userMailRef = useRef<string>();
  userMailRef.current = objectToken?.email;
  const mergedDataRef = useRef<typeof dataExtension>();
  mergedDataRef.current = {
    firstName: objectToken?.first_name,
    lastName: objectToken?.last_name,
    country: objectToken?.country,
    dealerNumber: objectToken?.dealer_id,
    internalId: objectToken?.user_id,
    authProvider: objectToken?.authentication_provider,
    ...custom,
    ...dataExtension,
  };

  useEffect(() => {
    if (integrationDisabled) {
      return;
    }

    const initApiWithDefaults = (api: UsersnapApi) => {
      api.init({
        locale: language,
        button: {
          position: 'rightCenter',
        },
      });
    };

    const bindApiEvents = (api: UsersnapApi) => {
      api.on('open', (event) => {
        event.api.setValue('visitor', userMailRef.current);
        event.api.setValue('custom', prettyPrintLogs(mergedDataRef.current!));
      });
    };

    if (apiRef.current) {
      initApiWithDefaults(apiRef.current);
      bindApiEvents(apiRef.current);
    } else {
      if (!scriptRef.current) {
        const script = createAsyncScript(widgetUrl);
        // Mount script
        document.head.appendChild(script);
        scriptRef.current = script;
      }

      window.onUsersnapCXLoad = (api) => {
        initApiWithDefaults(api);
        bindApiEvents(api);
        apiRef.current = api;
      };
    }
  }, [language]);

  // implicit mounts script in the `head`-tag
  return null;
};
