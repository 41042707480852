import PageContainer from '@components/PageContainer';
import CarImages from '@components/image-export-gallerie/CarImages';
import { PageHeader } from '@components/page-header';
import { PageMeta } from '@components/page-meta';
import { Link, Typography, makeStyles } from '@material-ui/core';
import { useParams } from '@reach/router';
import { useTranslation } from 'react-i18next';

type ImageExportPageParams = {
  vin: string;
};

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    marginBlockEnd: theme.spacing(4.5),
  },
  verticalCenter: {
    verticalAlign: 'middle',
  },
}));

export const ImageExport = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { vin } = useParams<ImageExportPageParams>();

  return (
    <>
      <PageMeta title={t('imageExport.title')} />
      <PageContainer>
        <PageHeader
          className={classes.pageHeader}
          headline={
            <>
              {t('imageExport.pageHeader.headline')}{' '}
              <Typography component="span" className={classes.verticalCenter}>
                {vin}
              </Typography>
            </>
          }
          body={
            <>
              {t('imageExport.pageHeader.body')}{' '}
              <Link href="mailto:alluc@porsche.co.at" target="_blank">
                alluc@porsche.co.at
              </Link>
            </>
          }
        />

        <CarImages vin={vin} />
      </PageContainer>
    </>
  );
};

export default ImageExport;
