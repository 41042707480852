import { isBoolean, isNil, isNumber, isString, pick } from 'lodash';
import { Tax } from 'src/types/tax';
import { MonetaryAmountStrict, makeMonetaryAmount } from './currency';
import { TaxContainer, ValuationCountryCode } from './generated/api';
import { getAccountValuationCountry } from './valuation-country-helpers';

export type TaxEntry = {
  key: string;
  value: string | number;
};

export type CalculationParam = {
  key: string;
  value: string | number;
  unit?: string;
};

const paramUnits: Record<string, string> = {
  co2: 'g',
  weight: 'kg',
  power: 'kW',
  displacement: 'cm³',
};

export const relevantTaxes: Record<ValuationCountryCode, string[]> = {
  [ValuationCountryCode.At]: [Tax.VAT, Tax.NOVA],
  [ValuationCountryCode.AtLot]: [Tax.VAT, Tax.NOVA],
  [ValuationCountryCode.De]: [Tax.VAT],
  [ValuationCountryCode.Es]: [Tax.REGISTRATION, Tax.REGISTRATION_RELATIVE, Tax.VAT],
  [ValuationCountryCode.It]: [Tax.ECOMALUS, Tax.TRANSCRIPTION, Tax.VAT],
  [ValuationCountryCode.Fr]: [Tax.ECO, Tax.VAT],
  [ValuationCountryCode.Cz]: [Tax.REGISTRATION, Tax.VAT],
  [ValuationCountryCode.Pl]: [Tax.CIVIL, Tax.EXCISE, Tax.VAT],
  [ValuationCountryCode.Hu]: [Tax.AUTHENTICATION, Tax.PROPERTY_ACQUISITION, Tax.REGISTRATION, Tax.VAT],
  [ValuationCountryCode.Ro]: [Tax.VAT],
  [ValuationCountryCode.Si]: [Tax.REGISTRATION, Tax.VAT],
  [ValuationCountryCode.Hr]: [Tax.REGISTRATION, Tax.VAT],
  [ValuationCountryCode.Sk]: [Tax.VAT],
  [ValuationCountryCode.Pt]: [Tax.REGISTRATION, Tax.VAT],
  [ValuationCountryCode.Se]: [Tax.VAT],
  [ValuationCountryCode.Bg]: [Tax.VAT],
  [ValuationCountryCode.Mk]: [Tax.VAT],
  [ValuationCountryCode.Al]: [Tax.VAT],
  [ValuationCountryCode.Xk]: [Tax.VAT],
};

const createCalculationParamEntry = (key: string, value: unknown): CalculationParam => {
  let val: string | number = '';
  const unit = paramUnits[key];

  if (key === 'fuel' && isString(value)) {
    val = `car.engineFuels.${value.toLowerCase()}`;
  } else if (key === 'co2_type' && isString(value)) {
    val = value.toUpperCase();
  } else if (isNumber(value) || isString(value)) {
    val = value;
  } else if (isBoolean(value)) {
    val = value ? 'common.yes' : 'common.no';
  }

  return {
    key,
    value: val,
    unit,
  };
};

export const createTaxList = (
  tax: TaxContainer,
  valuationCountry: ValuationCountryCode = getAccountValuationCountry(),
  formats?: {
    percent?: (v: number) => string;
    currency?: (v: MonetaryAmountStrict) => string;
  },
): TaxEntry[] => {
  const taxWithFallback = tax.tax || {};
  const relevantKeys = relevantTaxes[valuationCountry];
  const source = relevantKeys ? pick(taxWithFallback, ...relevantKeys) : taxWithFallback;

  const taxList = Object.entries(source).reduce<TaxEntry[]>((res, [taxKey, taxValue]) => {
    let entry: TaxEntry | undefined;

    if (!isNil(taxValue.percent)) {
      entry = formats?.percent
        ? { key: taxKey, value: formats.percent(taxValue.percent / 100) }
        : { key: taxKey, value: taxValue.percent };
    } else if (!isNil(taxValue.total?.amount)) {
      entry = formats?.currency
        ? { key: taxKey, value: formats.currency(makeMonetaryAmount(taxValue.total!)) }
        : { key: taxKey, value: taxValue.total!.amount };
    }

    return !isNil(entry) ? [...res, entry] : res;
  }, []);

  taxList.sort((a, b) => a.key.localeCompare(b.key));

  return taxList;
};

export const createCalculationParamList = (tax: TaxContainer): CalculationParam[] =>
  Object.entries(tax.parameters || {}).reduce<CalculationParam[]>(
    (res, entry) => [...res, createCalculationParamEntry(...entry)],
    [],
  );
