import { Typography } from '@material-ui/core';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ListCarDTO } from '../modules/generated/api';
import InfoTooltip from './InfoTooltip';
import PriceRangeperUnit from './PriceRangeperUnit';
import { wasAuctionAlreadyVisible } from '../modules/bid-buy-helpers';

type AuctionsHintHoverProps = {
  car: ListCarDTO;
};

const AuctionsHintHover = ({ car }: AuctionsHintHoverProps) => {
  const { t } = useTranslation();

  const numAuctionsVisible = car.previousAuctions
    ?.map((auction) => wasAuctionAlreadyVisible(auction.visibleForKvps))
    .filter(Boolean).length;

  // find the Minimum and Maximum per Unit on a given data-structure
  const findMinMaxPerUnit = (prices: Record<string, number[]>) =>
    Object.keys(prices).map((unit) => ({
      unit,
      minValue: Math.min(...prices[unit]),
      maxValue: Math.max(...prices[unit]),
    }));

  // make special data structure to find auction price details based on the unit
  const createUnitMap = (priceType: 'priceFixed' | 'priceOrientation') => {
    const priceList: Record<string, number[]> = {};

    car.previousAuctions?.forEach((auction) => {
      const priceObject = auction[priceType];

      if (priceObject?.currency && priceObject?.amount) {
        if (!priceList[priceObject.currency as string]) {
          priceList[priceObject.currency] = [];
        }
        priceList[priceObject.currency].push(priceObject.amount);
      }
    });

    return findMinMaxPerUnit(priceList);
  };

  return (
    <InfoTooltip
      title={t('bids.auctionDetails')}
      divider
      moreInfo={
        car.previousAuctions && (
          <Typography variant="caption">
            {t('bids.auctionVisibleAggregated', { count: numAuctionsVisible })}
            <br />
            {`${t('car.prices.fixedPrice')}: `}
            {createUnitMap('priceFixed')
              .filter((unitMap) => unitMap.unit === 'EUR')
              .map(({ unit, minValue, maxValue }) => (
                <PriceRangeperUnit
                  unit={unit}
                  minValue={minValue}
                  maxValue={maxValue}
                  className="highlight"
                  key={`${car.id}-pf-${unit}`}
                />
              ))}
            <br />
            {`${t('car.prices.priceOrientation')}: `}
            {createUnitMap('priceOrientation')
              .filter((unitMap) => unitMap.unit === 'EUR')
              .map(({ unit, minValue, maxValue }) => (
                <PriceRangeperUnit
                  unit={unit}
                  minValue={minValue}
                  maxValue={maxValue}
                  className="highlight"
                  key={`${car.id}-pf-${unit}`}
                />
              ))}
          </Typography>
        )
      }
      xsIcon
    />
  );
};
export default memo(AuctionsHintHover);
