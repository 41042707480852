import React, { ReactNode } from 'react';
import { makeStyles, Paper, Table, TableBody, TableContainer } from '@material-ui/core';
import clsx from 'clsx';
import CarDetailHeadline from './CarDetailHeadline';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  disableElevation: {
    borderRadius: 0,
  },
  headingBox: {
    padding: theme.spacing(2),
  },
  layoutFixed: {
    tableLayout: 'fixed',
  },
}));

type CarDetailTableProps = {
  title: string;
  children?: ReactNode;
  layoutFixed?: boolean;
  disableHeader?: boolean;
  disableElevation?: boolean;
  className?: string;
};

const CarDetailTable = ({
  title,
  children,
  layoutFixed,
  disableHeader = false,
  disableElevation = false,
  className,
}: CarDetailTableProps) => {
  const classes = useStyles();

  return (
    <TableContainer
      component={Paper}
      elevation={disableElevation ? 0 : 3}
      className={clsx(classes.root, disableElevation && classes.disableElevation, className)}
    >
      {!disableHeader && (
        <div className={classes.headingBox}>
          <CarDetailHeadline title={title} />
        </div>
      )}
      <Table size="small" className={layoutFixed ? classes.layoutFixed : ''}>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default CarDetailTable;
