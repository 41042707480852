import React, { ChangeEvent, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem, makeStyles } from '@material-ui/core';
import { SourceRegisterPotentialDTO } from '../modules/generated/api';
import { CarsPotentialTypes, CARS_POTENTIAL_PROPERY_LABELS } from '../modules/labels';
import { tr } from '../modules/i18n-helpers';
import EuropeMap from './EuropeMap';

const useStyles = makeStyles(() => ({
  headline: {
    fontSize: '16px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    '& div': {
      alignSelf: 'flex-end',
    },
    '& span': {
      alignSelf: 'center',
    },
  },
}));

type FilterMapProps = {
  potentials: {
    [key: string]: SourceRegisterPotentialDTO;
  };
};

const FilterMap = ({ potentials }: FilterMapProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const countries = Object.keys(potentials);
  const [cardValues, setCardValues] = useState('potentialMeanRelative');
  let defaultValueArray: {} = {};
  countries.forEach((country) => {
    defaultValueArray = { ...defaultValueArray, [country]: potentials[country][cardValues as CarsPotentialTypes] };
  });
  const [cardPotentials, setCardPotentials] = useState(defaultValueArray);

  const handleChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const {
      target: { value },
    } = event;
    setCardValues(value as CarsPotentialTypes);
    let tempObj: {} = {};
    countries.forEach((country) => {
      tempObj = { ...tempObj, [country]: potentials[country][value as CarsPotentialTypes] as [] };
    });
    setCardPotentials(tempObj);
  };

  return (
    <>
      <div className={classes.header}>
        <span className={classes.headline}>{t('cardDetailPage.valuesOnCard')}</span>
        <Select
          id="card_pricetype_select"
          value={cardValues}
          onChange={handleChange}
          autoWidth
          label={t('car.prices.parameter')}
        >
          {Object.keys(CARS_POTENTIAL_PROPERY_LABELS)
            .filter(
              (priceType) =>
                priceType !== 'purchaseNetWholesaleUnrepaired' && priceType !== 'purchaseNetRetailRepaired',
            )
            .map((priceType) => (
              <MenuItem value={priceType} key={priceType}>
                {
                  // @ts-ignore
                  tr(CARS_POTENTIAL_PROPERY_LABELS[priceType])
                }
              </MenuItem>
            ))}
        </Select>
      </div>

      <EuropeMap potentials={cardPotentials} value={cardValues as CarsPotentialTypes} />
    </>
  );
};

export default memo(FilterMap);
