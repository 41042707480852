import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

type ColumnHeaderFromToProps = {
  singleLine: boolean | undefined;
  min: string;
  max: string;
};

const useStyles = makeStyles((theme) => ({
  caption: {
    color: theme.palette.text.secondary,
  },
  captionMultiLine: {
    position: 'relative',
    '&::after': {
      content: '"–"',
      position: 'absolute',
      top: '50%',
      left: 0,
      right: 0,
      width: '100%',
      textAlign: 'center',
      transform: 'translateY(-10px)',
    },
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
}));

const ColumnHeaderFromTo = ({ singleLine, min, max }: ColumnHeaderFromToProps) => {
  const classes = useStyles();
  return (
    <Typography
      variant="caption"
      component="div"
      className={clsx(classes.caption, singleLine ? classes.noWrap : classes.captionMultiLine)}
    >
      {singleLine ? (
        <>
          <span className={classes.noWrap}>{min || <br />}</span>
          {' - '}
          <span className={classes.noWrap}>{max || <br />}</span>
        </>
      ) : (
        <>
          <div className={classes.noWrap}>{min || <br />}</div>
          <div className={classes.noWrap}>{max || <br />}</div>
        </>
      )}
    </Typography>
  );
};

export default ColumnHeaderFromTo;
