import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'src/constants';
import ApiService from 'src/modules/api-service';
import { SearchAgentDTO } from 'src/modules/generated/api';

export const useSearchAgentDelete = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation((id: string) => ApiService.searchAgent.searchAgentControllerDeleteSearchAgent(id), {
    onMutate: async (targetId: string) => {
      await queryClient.cancelQueries([QueryKeys.searchAgents]);

      const snapshot = queryClient.getQueryData<SearchAgentDTO[]>([QueryKeys.searchAgents]);

      queryClient.setQueryData<SearchAgentDTO[]>([QueryKeys.searchAgents], (prev = []) =>
        prev.filter((searchAgent) => searchAgent.id !== targetId),
      );

      return snapshot;
    },
    onError: (_error, _targetId, snapshot) => {
      queryClient.setQueryData<SearchAgentDTO[] | undefined>([QueryKeys.searchAgents], snapshot);
    },
    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.searchAgents]);
    },
  });

  return mutation;
};
