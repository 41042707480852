import { IconButton, makeStyles } from '@material-ui/core';
import React, { KeyboardEvent, memo, useEffect, useState } from 'react';
import { NumberFormatValues } from 'react-number-format';
import { CheckCircle } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useCurrency } from 'src/hooks/useCurrency';
import useApi from '../hooks/useApi';
import ApiService from '../modules/api-service';
import { DamageType, DetailCarDTO, ListCarDTO } from '../modules/generated/api';
import { useCarsTableStore } from '../stores/CarsTable';
import AmountInput from './AmountInput';
import DamageTypeHighlights from './DamageTypeHighlights';
import CarTableEditButton from './CarTableEditButton';
import UnitValueCell from './UnitValueCell';
import handleStopPropagation from '../modules/jsx-helpers';
import theme from '../setup/theme';
import useCustomSnackbarGlobal from '../hooks/useSnackbarGlobal';
import { makeMonetaryAmount, MonetaryAmountStrict, SUPPORTED_CURRENCIES } from '../modules/currency';

type CarsTableDamageCellProps = {
  car: ListCarDTO;
  damageType?: DamageType[];
  priceDamage?: MonetaryAmountStrict;
};

const useStyles = makeStyles(() => ({
  dmgContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '7rem',
  },
  submitBtn: {
    '& .MuiSvgIcon-root': { fontSize: '1rem' },
  },
  input: {
    border: 'none',
    fontFamily: 'Arial, sans-serif',
    fontSize: '0.875rem',
    textAlign: 'right',
    width: '75%',
  },

  line: {
    width: '80%',
    float: 'right',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  damageTypes: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(0.25),
  },
}));

const CarsTableDamageCell = ({ car, damageType, priceDamage: currentPriceDamage }: CarsTableDamageCellProps) => {
  const { currency, convert } = useCurrency();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [priceDamage, setPriceDamage] = useState<MonetaryAmountStrict>(
    convert(makeMonetaryAmount(currentPriceDamage ?? 0), {}),
  );
  const { updateCar } = useCarsTableStore();

  const { error: updateCarError, fetch, loading: isLoading } = useApi<DetailCarDTO>();

  const { carId, source } = car;
  const classes = useStyles();
  const { t } = useTranslation();
  const snackbar = useCustomSnackbarGlobal();

  const handleOnValueChange = async ({ floatValue }: NumberFormatValues) => {
    if (floatValue === undefined) {
      setPriceDamage((prev) => ({ ...prev, amount: 0 }));
      return;
    }
    setPriceDamage((prev) => ({ ...prev, amount: floatValue }));
  };

  const handleOnSubmit = async () => {
    const updatedPriceDamage = { unit: priceDamage?.currency, value: priceDamage.amount };
    const updatedCar = { priceDamage: updatedPriceDamage };
    const response = await fetch(
      ApiService.listCars.listCarsControllerUpdateCar(source!, carId!, updatedCar, 2, car.potential?.country),
    );
    if (!response?.data || response?.status !== 200) {
      snackbar.showError(t('alerts.errorRaised'));
      updateCar(car);
      return;
    }
    const { data: updatedCarFromApi } = response;
    updateCar(updatedCarFromApi);
    snackbar.showSuccess(t('alerts.successSaved'));
    setIsEdit(false);
  };

  const handleOnKeyDown = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') return;
    await handleOnSubmit();
  };

  useEffect(() => {
    if (!updateCarError && !isLoading) return;
    if (updateCarError) {
      snackbar.showError(t('alerts.editError'));
    }
  }, [updateCarError, isEdit, snackbar, t, isLoading]);

  useEffect(() => {
    if (currentPriceDamage?.amount === undefined) return;
    setPriceDamage(convert(makeMonetaryAmount(currentPriceDamage), {}));
  }, [currentPriceDamage, convert]);

  useEffect(() => {
    setIsEdit(false);
  }, [carId]);

  return (
    <div onKeyDown={handleStopPropagation} role="button" tabIndex={0} onClick={handleStopPropagation}>
      {isEdit ? (
        <div className={classes.dmgContainer}>
          <IconButton size="small" type="submit" className={classes.submitBtn} onClick={handleOnSubmit}>
            <CheckCircle />
          </IconButton>
          <AmountInput
            className={classes.input}
            decimalScale={2}
            disabled={!carId || !source}
            onValueChange={handleOnValueChange}
            onKeyDown={handleOnKeyDown}
            suffix={` ${SUPPORTED_CURRENCIES[currency].symbol}`}
            value={priceDamage.amount}
            autoFocus
          />
        </div>
      ) : (
        <div className={classes.dmgContainer}>
          <CarTableEditButton onClick={() => setIsEdit(true)} />
          <span className={classes.line}>
            <UnitValueCell value={priceDamage.amount} unit={priceDamage.currency} />
          </span>
        </div>
      )}
      <div className={classes.damageTypes}>
        <DamageTypeHighlights damageType={damageType} />
      </div>
    </div>
  );
};
export default memo(CarsTableDamageCell);
