import React, { memo } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import GavelIcon from '@material-ui/icons/Gavel';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { isEmpty, isNil } from 'lodash';
import { DEFAULT_CURRENCY } from 'src/modules/currency';
import { IndividualBidDTO, IndividualBidStateInfoDTO } from '../modules/generated/api';
import { formatDate, BID_DATE_TIME_FORMAT } from '../modules/date-helpers';
import { joinJSX } from '../modules/jsx-helpers';
import { useCurrency } from '../hooks/useCurrency';

const detailExists = <T extends {}>(x: T | null | undefined | boolean): x is T => !!x;

const joinCondDetails = (details: (JSX.Element | null | undefined | boolean)[]): JSX.Element[] =>
  joinJSX(details.filter(detailExists), ', ');

const useStyles = makeStyles(({ palette, spacing }) => ({
  overlay: {
    zIndex: 50,
    color: 'white',
    borderRadius: '50%',
    padding: '2px',
  },
  orange: {
    backgroundColor: palette.primary.main,
  },
  blue: {
    backgroundColor: palette.info.main,
  },
  gray: {
    backgroundColor: palette.secondary.light,
  },
  single: {
    zIndex: 50,
    position: 'absolute',
  },
  arrange: {
    zIndex: 50,
    position: 'absolute',
    padding: '2px',
    display: 'grid',
    width: '40px',
    gridTemplateColumns: 'repeat(auto-fit,  minmax(5px, max-content))',
    marginRight: '10px',
  },
  tooltip: {
    margin: 0,
    marginTop: spacing(0.5),
    whiteSpace: 'nowrap',
  },
  noMaxWidth: {
    maxWidth: 'none',
  },
}));

type GravelBidInfoProps = {
  bidStatus: IndividualBidStateInfoDTO;
  className?: string;
};

const GravelBidInfo = ({ bidStatus, className }: GravelBidInfoProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { format, convert } = useCurrency();

  const getBidInfo = (bids: IndividualBidDTO[], withName?: boolean) => (
    <div key={bids.length}>
      {bids.map((bid) => (
        <p className={classes.tooltip} key={`(${bid.created}-${bid.bid?.value}-${bid.dealer?.id}) `}>
          {withName && (
            <>
              {bid.dealer?.id && `(${bid.dealer.id}) `}
              {`${bid.user?.name || bid.user?.id}: `}
            </>
          )}

          {joinCondDetails([
            !isNil(bid.bid?.value) && (
              <span key="value">
                {format(convert(bid.bid!.value, { from: bid.bid?.unit ?? DEFAULT_CURRENCY.code }), {})}
              </span>
            ),
            !isNil(bid.created) && (
              <span key="created">
                {t('bids.status.created')}: {formatDate(bid.created, BID_DATE_TIME_FORMAT)}
              </span>
            ),
            !isNil(bid.sentTime) && (
              <span key="sentTime">
                {t('bids.status.sent')}: {formatDate(bid.sentTime, BID_DATE_TIME_FORMAT)}
              </span>
            ),
            isNil(bid.sentTime) && !isNil(bid.biddingTime) && (
              <span key="biddingTime">
                {t('bids.status.scheduled')}: {formatDate(bid.biddingTime, BID_DATE_TIME_FORMAT)}
              </span>
            ),
          ])}
        </p>
      ))}
    </div>
  );

  const icons = [];
  if (!isEmpty(bidStatus.allHistoricBids))
    icons.push(
      <Tooltip
        arrow
        classes={{ tooltip: classes.noMaxWidth }}
        key="allHistorical"
        placement="right"
        title={
          <>
            <strong>{t('bids.filter.expired')}</strong>
            {bidStatus.allHistoricBids && getBidInfo(bidStatus.allHistoricBids, true)}
          </>
        }
      >
        <GavelIcon className={clsx(classes.overlay, classes.gray)} color="primary" />
      </Tooltip>,
    );
  if (!isEmpty(bidStatus.otherCurrentBids))
    icons.push(
      <Tooltip
        arrow
        classes={{ tooltip: classes.noMaxWidth }}
        key="otherCurr"
        placement="right"
        title={
          <>
            <strong>{t('bids.filter.currentOther')}</strong>
            {bidStatus.otherCurrentBids && getBidInfo(bidStatus.otherCurrentBids, true)}
          </>
        }
      >
        <GavelIcon className={clsx(classes.overlay, classes.blue)} color="primary" />
      </Tooltip>,
    );
  if (!isEmpty(bidStatus.ownCurrentBids))
    icons.push(
      <Tooltip
        arrow
        classes={{ tooltip: classes.noMaxWidth }}
        key="ownCurr"
        placement="right"
        title={
          <>
            <strong>{t('bids.filter.current')}</strong>
            {bidStatus.ownCurrentBids && getBidInfo(bidStatus.ownCurrentBids)}
          </>
        }
      >
        <GavelIcon className={clsx(classes.overlay, classes.orange)} />
      </Tooltip>,
    );

  return <div className={clsx(icons.length > 1 ? classes.arrange : classes.single, className)}>{icons}</div>;
};

export default memo(GravelBidInfo);
