import React, { forwardRef } from 'react';
import { Card, CardContent, styled } from '@material-ui/core';
import { DismissButton, FocusScope, mergeProps, useDialog, useModal, useOverlay } from 'react-aria';
import { orange } from '@material-ui/core/colors';

export type DropdownProps = {
  title: (props: { titleProps: React.DOMAttributes<Element> }) => JSX.Element;
  children?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  style?: React.CSSProperties;
  className?: string;
};

const DropdownContainer = styled(Card)({
  '&:focus-visible': {
    outlineColor: orange[500],
  },
});

const DropdownContent = styled(CardContent)({});

export const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  ({ title, children, isOpen, onClose, style, ...otherProps }, ref) => {
    const { overlayProps } = useOverlay(
      {
        onClose,
        isOpen,
        isDismissable: true,
      },
      ref as any, // FIXME: inspect types
    );

    const { modalProps } = useModal();

    const { dialogProps, titleProps } = useDialog(
      {},
      ref as any, // FIXME: inspect types
    );

    return (
      <FocusScope contain restoreFocus>
        <DropdownContainer
          {...mergeProps(overlayProps, dialogProps, otherProps, modalProps)}
          ref={ref}
          raised
          style={{
            ...style,
          }}
        >
          <DropdownContent>
            {title({ titleProps })}
            {children}
          </DropdownContent>
          <DismissButton onDismiss={onClose} />
        </DropdownContainer>
      </FocusScope>
    );
  },
);
